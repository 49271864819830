const axios = require('axios');
const logger = require('./logger');
const { HUBSPOT_URL, LOGIN_FORM, REGISTER_FORM } = require('./constants/hubspotAPIEndpoints');

// eslint-disable-next-line prefer-destructuring
const FRONTBASE_URL = process.env.FRONTBASE_URL;

const applicationProtocol = new URL(FRONTBASE_URL).protocol;

const getRandomString = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const submitDataToHubspot = (formValues, type) => {
  const portalId = process.env.HUBSPOT_REGISTER_KEY;
  const env = process.env.APP_ENV;
  const hubspotFormsId = {
    [LOGIN_FORM]:
      env === 'production' ? 'fcc8f9f7-3c2a-424d-9398-c55e62264d2f' : '1250a6af-d829-4006-a76e-cde2bcdec408',
    [REGISTER_FORM]:
      env === 'production' ? 'b9ccc983-8fbb-4b2e-a45b-1d40cbee0fcd' : '7435f3af-80c0-4ba9-916c-00818d2642aa',
  };

  const formId = hubspotFormsId[type];

  axios.post(`${HUBSPOT_URL}/${portalId}/${formId}`, formValues).catch(error => {
    logger.error(`Error sending data to hubspot formid: ${formId}`, { error });
  });
};

const getIpFromHeaders = req =>
  req.headers['x-original-forwarded-for']?.split(',')?.[0]?.trim() ||
  req.headers['x-forwarded-for'] ||
  req.headers['cf-connecting-ip'] ||
  null;

const getVisitorIdFromCookies = cookies => {
  if (!cookies) return null;
  const visitorIdMatch = cookies.match(/(?:^|;\s*)visitorId=([^;]*)/);
  return visitorIdMatch ? visitorIdMatch[1] : null;
};

module.exports = {
  applicationProtocol,
  getRandomString,
  submitDataToHubspot,
  getIpFromHeaders,
  getVisitorIdFromCookies,
};
