import React, { useState, useEffect } from 'react';

const withLayoutEffect = Component => ({ ...props }) => {
  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    setRenderComponent(true);
  }, []);

  if (!renderComponent) {
    return null;
  }

  return <Component {...props} />;
};

export default withLayoutEffect;
