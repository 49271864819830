export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const SET_TOKEN = 'app/global/SET_TOKEN';
export const SET_LANGUAGE = 'app/global/SET_LANGUAGE';
export const GET_COUNTRIES = 'app/global/GET_COUNTRIES';
export const GET_REGIONS = 'app/global/GET_REGIONS';
export const GET_PERFORMANCES_NUMBER = 'app/global/GET_PERFORMANCES_NUMBER';
export const GET_ARTISTS_NUMBER = 'app/global/GET_ARTISTS_NUMBER';
export const GET_USER_CONSTANTS = 'app/global/GET_USER_CONSTANTS';
export const SET_TAB_SCROLL_POSITION = 'app/global/SET_TAB_SCROLL_POSITION';
