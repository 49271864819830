import { takeLatest, put, call, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { PRODUCTION_VALIDITY_IDENTIFIERS } from 'constants/index';

// eslint-disable-next-line import/named
import { getProductions, getPerformances, getWork } from '../../utils/API';
import { enqueueSnackbarAction } from '../App/actions';
import { getWorkAction, getProductionsAction, getPerformancesAction } from './actions';
import { selectProductions, selectPerformances } from './selectors';

export function* getWorkDataRequest({ payload, serverCookies }) {
  try {
    const response = yield call(getWork, get(payload, 'workId'), get(payload, 'query'), serverCookies);
    yield put(getWorkAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getWorkAction.failure(err.message));
  } finally {
    yield put(getWorkAction.fulfill());
  }
}

export function* fetchProductions({ payload, serverCookies }) {
  try {
    const response = yield call(
      getProductions,
      {
        ...(payload?.params || {}),
        approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      },
      payload?.pagination || {},
      serverCookies,
    );
    const productionsData = yield select(selectProductions());
    if (productionsData?.data?.length > 0 && !payload?.clearData) {
      response?.data?.data.unshift(...productionsData.data);
    }
    yield put(getProductionsAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getProductionsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: err?.message,
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getProductionsAction.fulfill());
  }
}

function* fetchPerformances({ payload, serverCookies }) {
  try {
    const response = yield call(
      getPerformances,
      {
        ...(payload?.params || {}),
        approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      },
      payload?.pagination,
      serverCookies,
    );
    const performanceData = yield select(selectPerformances());
    if (performanceData?.data?.length > 0 && !payload?.clearData) {
      response?.data?.data.unshift(...performanceData.data);
    }
    yield put(getPerformancesAction.success(response?.data));
  } catch (err) {
    yield put(getPerformancesAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: err?.message,
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getPerformancesAction.fulfill());
  }
}

export default function* workSaga() {
  yield takeLatest(getWorkAction.TRIGGER, getWorkDataRequest);
  yield takeLatest(getPerformancesAction.TRIGGER, fetchPerformances);
  yield takeLatest(getProductionsAction.TRIGGER, fetchProductions);
}
