export const waitfor = (checkfn, tries = 10, delay = 1000) => {
  let iterations = 0;
  return new Promise((resolve, reject) => {
    const check = async () => {
      iterations += 1;
      if (iterations > tries) {
        reject();
        return;
      }
      if (await checkfn()) {
        resolve();
        return;
      }
      setTimeout(check, delay);
    };
    check();
  });
};
