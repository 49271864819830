import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import get from 'lodash/get';
import { ARTIST_TYPE_ID, TP } from 'constants/index';
import { AGENCY_ACTORS } from 'constants/deprecate';
import { errorMessageConverter } from 'utils/common';
import {
  getOfficesAction,
  getArtistsAction,
  getAgencyAgreementsAction,
  getAgencyInfoAction,
  deleteArtistFromRosterAction,
  addArtistToRosterAction,
  getUserProfileAgentInfoAction,
  checkArtistInAgencyAction,
  getSingleArtistInfoAction,
  getAgencyCommonInfoAction,
  getAgentsAction,
  getArtistsListSuggestionsAction,
  updateAgencyAction,
  updateArtistAction,
} from './actions';
import {
  getOffices,
  getAgencyArtists,
  getAgency,
  getArtistsAgreements,
  removeArtistFromRoster,
  addArtistToAgencyRoster,
  getAgencyAgents,
  createArtistsAgreement,
  searchProfiles,
  getAgencyArtist,
  updateAgency,
  deleteAgencyOffice,
  updateAgencyOffice,
  createAgencyOffice,
  updateAgencyArtist,
  createUserProfile,
} from '../../utils/API';
import { selectUserProfileAgentInfo } from './selectors';
import { enqueueSnackbarAction } from '../App/actions';
import { i18n } from '../../src/i18n';

export function* fetchAgencyInfo({ payload, serverCookies }) {
  try {
    const agencyId = get(payload, 'agencyId', '');
    const query = get(payload, 'query', {});
    yield call(fetchOffices, { payload: { agency_id: agencyId, ...query }, serverCookies });
    yield call(fetchArtists, { payload: { agencyId, query }, serverCookies });
  } catch (err) {
    yield put(getAgencyInfoAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_AGENCY_INFO`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getAgencyInfoAction.fulfill());
  }
}

export function* fetchAgencyCommonInfo({ payload, serverCookies }) {
  try {
    const agencyId = get(payload, 'agencyId', '');
    const query = get(payload, 'query', {});
    const agencyInfo = yield call(getAgency, agencyId, query, serverCookies);
    const agencyData = get(agencyInfo, 'data');
    yield put(getAgencyCommonInfoAction.success(agencyData));
  } catch (err) {
    yield put(getAgencyCommonInfoAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_AGENCY_INFO`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getAgencyCommonInfoAction.fulfill());
  }
}

export function* fetchOffices({ payload, serverCookies }) {
  try {
    const response = yield call(getOffices, payload, {}, serverCookies);
    yield put(getOfficesAction.success(get(response, 'data.data', [])));
  } catch (err) {
    yield put(getOfficesAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_OFFICES`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getOfficesAction.fulfill());
  }
}

export function* fetchArtists({ payload, serverCookies }) {
  try {
    const response = yield call(
      getAgencyArtists,
      get(payload, 'agencyId'),
      { ...get(payload, 'query'), as_edit: true, limit: 1000 },
      {},
      serverCookies,
    );
    const artists = get(response, 'data.data', []);
    yield put(getArtistsAction.success(artists));
    const ids = artists.map(artist => get(artist, 'id'));
    if (ids.length) {
      yield call(fetchAgencyAgreements, { payload: { ids } });
    }
  } catch (err) {
    yield put(getArtistsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_ARTIST`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getArtistsAction.fulfill());
  }
}

export function* fetchAgencyAgreements({ payload, serverCookies }) {
  try {
    const response = yield call(getArtistsAgreements, get(payload, 'ids'), { limit: 1000 }, serverCookies);

    yield put(getAgencyAgreementsAction.success(get(response, 'data.data', [])));
  } catch (err) {
    yield put(getAgencyAgreementsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_AGREEMENTS`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getAgencyAgreementsAction.fulfill());
  }
}

export function* fetchAgencyAgents({ payload, serverCookies }) {
  try {
    const agencyId = get(payload, 'agencyId');
    const response = yield call(getAgencyAgents, agencyId, get(payload, 'query'), serverCookies);
    yield put(getAgentsAction.success(get(response, 'data.data', [])));
  } catch (err) {
    yield put(getAgentsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_AGENTS`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getAgentsAction.fulfill());
  }
}

export function* deleteArtistFromRoster({ payload, serverCookies }) {
  try {
    const agencyId = get(payload, 'agencyId');
    const { callback } = payload;
    yield call(removeArtistFromRoster, agencyId, get(payload, 'artistId'), serverCookies);
    yield put(deleteArtistFromRosterAction.success());
    yield call(fetchArtists, { payload: { agencyId }, serverCookies });
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    yield put(deleteArtistFromRosterAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_DELETE_ARTIST`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(deleteArtistFromRosterAction.fulfill());
  }
}

export function* addArtistToRoster({ payload, serverCookies }) {
  const { callback: callbackFunc, actor = AGENCY_ACTORS.AGENCY, activeProfileId } = payload;

  try {
    const agentInfo = yield select(selectUserProfileAgentInfo());
    const artistExists = !!payload?.artist?.profile?.id;
    let artistData = get(payload, 'artist');
    const professions = get(payload, 'professions', []);
    if (!artistExists) {
      const createdArtistProfile = yield call(
        createUserProfile,
        {
          ...artistData.profile,
          profileType: { id: ARTIST_TYPE_ID },
          professions: professions?.map(artistProfession => ({
            profession: {
              ...artistProfession?.profession,
            },
          })),
        },
        serverCookies,
      );
      const profileData = get(createdArtistProfile, 'data');
      artistData = {
        profile: {
          id: profileData?.id,
        },
      };
    }
    const response = yield call(
      addArtistToAgencyRoster,
      get(payload, 'agencyId'),
      activeProfileId,
      get(payload, 'query'),
      {
        ...(get(agentInfo, 'id')
          ? {
              agent: {
                id: get(agentInfo, 'id'),
                ...(get(agentInfo, 'agent.profile.id') ? { profile: { id: get(agentInfo, 'agent.profile.id') } } : {}),
              },
            }
          : {}),
        artist: artistData,
        agencyConnectionProfessions: artistExists ? professions : [],
        actor,
      },
      serverCookies,
    );
    yield put(addArtistToRosterAction.success(get(response, 'data')));

    if (callbackFunc && typeof callbackFunc === 'function') {
      callbackFunc(true, get(response, 'data'), artistExists);
    }
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_ADDING_ARTIST_TO_ROSTER`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    yield put(addArtistToRosterAction.failure(err));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_ADDING_ARTIST_TO_ROSTER`, { error: errorMessageConverter(err) }),
        options: {
          variant: 'error',
        },
      }),
    );

    if (callbackFunc && typeof callbackFunc === 'function') {
      callbackFunc(false);
    }
  } finally {
    yield put(addArtistToRosterAction.fulfill());
  }
}

export function* fetchAgentInfo({ payload, serverCookies }) {
  try {
    const agencyId = get(payload, 'agencyId');
    const profileId = get(payload, 'profileId');
    let agentInfoResponse = {};
    if (profileId) {
      agentInfoResponse = yield call(
        getAgencyAgents,
        agencyId,
        {
          profile_id: get(payload, 'profileId'),
          all: true,
        },
        serverCookies,
      );
    }
    yield put(getUserProfileAgentInfoAction.success(get(agentInfoResponse, 'data.data[0]', {})));
  } catch (err) {
    yield put(getUserProfileAgentInfoAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_AGENT`, { error: err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getUserProfileAgentInfoAction.fulfill());
  }
}

export function* isArtistInRoster({ payload, serverCookies }) {
  try {
    const agencyId = get(payload, 'agencyId');
    const artistInfoResponse = yield call(
      getAgencyArtists,
      agencyId,
      {
        artist_profile_id: get(payload, 'profileId'),
        show_all: true,
      },
      {},
      serverCookies,
    );
    yield put(checkArtistInAgencyAction.success(get(artistInfoResponse, 'data.data[0]', null)));
  } catch (err) {
    yield put(checkArtistInAgencyAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_ARTIST_IN_ROSTER_INFO`, { error: err?.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(checkArtistInAgencyAction.fulfill());
  }
}

export function* getArtistInfo({ payload, serverCookies }) {
  try {
    const agencyId = get(payload, 'agencyId');
    const artistId = get(payload, 'artistId');
    const artistInfoResponse = yield call(getAgencyArtist, agencyId, artistId, get(payload, 'query'), serverCookies);
    // TODO: OLD contract doesn't match
    const artistAgreementsResponse = yield call(
      getArtistsAgreements,
      [artistId],
      {
        limit: 100,
      },
      serverCookies,
    );

    yield put(
      getSingleArtistInfoAction.success({
        ...get(artistInfoResponse, 'data'),
        agreements: get(artistAgreementsResponse, 'data.data', []),
      }),
    );
  } catch (err) {
    yield put(getSingleArtistInfoAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_ARTIST`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getSingleArtistInfoAction.fulfill());
  }
}

export function* getArtistsSuggestionsList({ payload, serverCookies }) {
  try {
    const agencyId = get(payload, 'agencyId');
    const response = yield call(
      searchProfiles,
      ARTIST_TYPE_ID,
      get(payload, 'query'),
      payload?.exclude_id,
      {},
      { validation_status: 'pending,processing,approved' },
      null,
      serverCookies,
    );
    const responseData = get(response, 'data.data', []);
    let artistsInfoResponse = [];
    if (responseData.length > 0) {
      artistsInfoResponse = yield call(
        getAgencyArtists,
        agencyId,
        {
          artist_profile_id: responseData.map(artist => get(artist, 'id')),
        },
        {},
        serverCookies,
      );
    }
    yield put(
      getArtistsListSuggestionsAction.success({
        artists: responseData,
        agencyArtists: get(artistsInfoResponse, 'data.data', []),
      }),
    );
  } catch (err) {
    yield put(getArtistsListSuggestionsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_FETCHING_ARTISTS_SUGGESTIONS`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getArtistsListSuggestionsAction.fulfill());
  }
}

export function* updateAgencyData({ payload, serverCookies }) {
  try {
    const { agencyId, profileId, agencyData, deletedOffices, updatedOffices, createdOffices, callback } = payload;
    yield all([
      call(updateAgency, agencyId, get(agencyData, 'data'), get(agencyData, 'query'), serverCookies),
      ...deletedOffices.map(id => call(deleteAgencyOffice, id, agencyId, profileId, serverCookies)),
      ...updatedOffices.map(office =>
        call(updateAgencyOffice, get(office, 'id'), agencyId, profileId, get(office, 'data'), serverCookies),
      ),
      ...createdOffices.map(office =>
        call(createAgencyOffice, agencyId, profileId, get(office, 'data'), serverCookies),
      ),
    ]);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    yield put(updateAgencyAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_AGENCY`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(updateAgencyAction.fulfill());
  }
}

export function* updateArtistData({ payload, serverCookies }) {
  const { profileId, artistId, artistData, actor = AGENCY_ACTORS.AGENCY, agreements, callback } = payload;

  try {
    const agencyArtistData = yield call(
      updateAgencyArtist,
      get(artistData, 'agencyId'),
      get(artistData, 'artistId'),
      get(artistData, 'data'),
      get(artistData, 'query'),
      actor,
      serverCookies,
    );

    const professionIdMapping = agencyArtistData?.data?.agencyConnectionProfessions?.reduce((acc, prof) => {
      acc[prof?.profession?.id] = prof?.id;

      return acc;
    }, {});

    const createdAgreementsWithProfessionId = agreements?.map(({ profession, ...rest }) => ({
      ...rest,
      agencyConnectionProfession: professionIdMapping?.[profession]
        ? { id: professionIdMapping?.[profession] }
        : rest?.agencyConnectionProfession,
    }));

    yield call(createArtistsAgreement, artistId, createdAgreementsWithProfessionId, profileId, actor, serverCookies);

    if (callback && typeof callback === 'function') {
      callback(true);
    }
    yield put(updateArtistAction.success());
  } catch (err) {
    yield put(updateArtistAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_AGENCY_ARTIST`, { error: err.message }),
        options: {
          variant: 'error',
        },
      }),
    );

    if (callback && typeof callback === 'function') {
      callback(false);
    }
  } finally {
    yield put(updateArtistAction.fulfill());
  }
}

export default function* dataAgencies() {
  yield takeLatest(getAgencyInfoAction.TRIGGER, fetchAgencyInfo);
  yield takeLatest(getAgencyCommonInfoAction.TRIGGER, fetchAgencyCommonInfo);
  yield takeLatest(getOfficesAction.TRIGGER, fetchOffices);
  yield takeLatest(getAgentsAction.TRIGGER, fetchAgencyAgents);
  yield takeLatest(deleteArtistFromRosterAction.TRIGGER, deleteArtistFromRoster);
  yield takeLatest(addArtistToRosterAction.TRIGGER, addArtistToRoster);
  yield takeLatest(getUserProfileAgentInfoAction.TRIGGER, fetchAgentInfo);
  yield takeLatest(checkArtistInAgencyAction.TRIGGER, isArtistInRoster);
  yield takeLatest(getSingleArtistInfoAction.TRIGGER, getArtistInfo);
  yield takeLatest(getArtistsListSuggestionsAction.TRIGGER, getArtistsSuggestionsList);
  yield takeLatest(updateArtistAction.TRIGGER, updateArtistData);
  yield takeLatest(updateAgencyAction.TRIGGER, updateAgencyData);
}
