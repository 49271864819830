import {
  getProductsAction,
  getUserPaymentsAction,
  getArtistsWorkedWithAction,
  getGiftSubscriptionsAction,
  getUserReferralsAction,
  getUserRewardBalance,
} from './actions';
import { defaultListParams, listReducer } from '../../../utils/crudUtils';

export const key = 'AccountSettings';

export const initialState = {
  products: defaultListParams,
  userSubscriptions: defaultListParams,
  userPaymentMethods: defaultListParams,
  languages: defaultListParams,
  userInvoices: defaultListParams,
  userPayments: defaultListParams,
  artistsWorkedWith: defaultListParams,
  giftSubscriptions: defaultListParams,
  userReferrals: defaultListParams,
  rewardBalance: defaultListParams,
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getProductsAction.TRIGGER:
    case getProductsAction.FAILURE:
    case getProductsAction.SUCCESS:
      return listReducer(state, type, payload, 'products');

    case getUserPaymentsAction.TRIGGER:
    case getUserPaymentsAction.FAILURE:
    case getUserPaymentsAction.SUCCESS:
      return listReducer(state, type, payload, 'userPayments');

    case getArtistsWorkedWithAction.TRIGGER:
    case getArtistsWorkedWithAction.SUCCESS:
    case getArtistsWorkedWithAction.FAILURE:
      return listReducer(state, type, payload, 'artistsWorkedWith');

    case getGiftSubscriptionsAction.TRIGGER:
    case getGiftSubscriptionsAction.SUCCESS:
    case getGiftSubscriptionsAction.FAILURE:
      return listReducer(state, type, payload, 'giftSubscriptions');

    case getUserReferralsAction.TRIGGER:
    case getUserReferralsAction.FAILURE:
    case getUserReferralsAction.SUCCESS:
      return listReducer(state, type, payload, 'userReferrals');

    case getUserRewardBalance.TRIGGER:
    case getUserRewardBalance.FAILURE:
    case getUserRewardBalance.SUCCESS:
      return listReducer(state, type, payload, 'rewardBalance');

    default:
      return state;
  }
};

export default settingsReducer;
