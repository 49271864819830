export const convertMapToArray = (map = {}, title) => {
  const keys = Object.keys(map) || [];

  return keys?.map(key => ({
    [title || key]: key,
    data: map?.[key],
  }));
};

export const getInitialsFromName = (name = '', count = 2) =>
  name
    ?.split(' ')
    ?.slice(0, count)
    ?.map(str => str?.[0]?.toUpperCase())
    ?.join('');

export const getBuildImageUrl = path =>
  (process?.env?.NODE_ENV === 'development' ? '' : `${process.env.STATIC_ASSET_URL}/${process.env.BUILD_ID}`) + path;

export const getCityCountry = (city, country) => {
  if (city && country) {
    return `${city}, ${country}`;
  }

  if (city && !country) {
    return city;
  }

  if (country && !city) {
    return country;
  }

  return null;
};

export const escapeBadCharacter = str => str?.replace(/[\\/]/g, '');
