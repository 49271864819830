import { createRoutine } from 'redux-saga-routines';
import {
  GET_TITLES,
  GET_COMPOSERS,
  GET_CITY_STATS,
  GET_COUNTRY_STATS,
  GET_COMBINED_STATS,
  GET_ALL_STATS,
  GET_COMBINED_COMPOSERS_STATS,
} from './constants';

export const getAllAction = createRoutine(GET_ALL_STATS);
export const getTitlesAction = createRoutine(GET_TITLES);
export const getComposersAction = createRoutine(GET_COMPOSERS);
export const getCityStatsAction = createRoutine(GET_CITY_STATS);
export const getCountryStatsAction = createRoutine(GET_COUNTRY_STATS);
export const getCombinedStatsAction = createRoutine(GET_COMBINED_STATS);
export const getCombinedComposersStatsAction = createRoutine(GET_COMBINED_COMPOSERS_STATS);
