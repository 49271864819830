import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { ARTIST_TYPE_ID } from 'constants/index';
import { getArtistsAction } from './actions';
// eslint-disable-next-line import/named
import { searchProfiles } from '../../utils/API';

export function* fetchArtists({ payload, serverCookies }) {
  try {
    const response = yield call(
      searchProfiles,
      ARTIST_TYPE_ID,
      '',
      payload?.exclude_id,
      payload?.pagination,
      payload?.filters,
      null,
      serverCookies,
    );
    yield put(getArtistsAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getArtistsAction.failure(err.message));
  } finally {
    yield put(getArtistsAction.fulfill());
  }
}

export default function* artistsSaga() {
  yield takeLatest(getArtistsAction.TRIGGER, fetchArtists);
}
