const NextI18Next = require('@truelinked/next-i18next').default;
const config = require('../config/config.json');
// const keyTagMapping = require('../public/locales/en/KEY_TAG_MAPPING.json');
const defaultLanguage = 'en';
const configLanguages = config.FRONTBASE_LANGUAGES || [];
const localeSubpaths = {};
// eslint-disable-next-line no-return-assign
configLanguages.forEach(language => (localeSubpaths[language] = language));
const otherLanguages = configLanguages.filter(language => language !== defaultLanguage);

const ignoreRoutes = [
  '/_next/',
  '/static/',
  '/public/',
  '/api/',
  '/auth/',
  '/images',
  '/locales',
  '/favicon.ico',
  '/build-info.txt',
  '/robots.txt',
  '/__nextjs',
  '/healthcheck',
  '/sign_auth',
  '/opensearch.xml',
  '/svg/',
];
const options = {
  defaultNS: 'NS_APP_GLOBALS',
  defaultLanguage,
  otherLanguages,
  localePath: 'public/locales',
  localeSubpaths,
  ignoreRoutes,
  keySeparator: '.',
  shallowRender: false,
  fallbackLng: [],
  browserLanguageDetection: false,
  serverLanguageDetection: false,
  detection: {
    order: null,
    caches: null,
  },
};

const getPageTranslations = (namespaces = []) => ['NS_APP_GLOBALS', 'NS_GLOBAL_SEARCH', 'NS_BANNERS', ...namespaces];

const nextI18Next = new NextI18Next(options);

const getNamespaceFile = namespace => {
  if (namespace) {
    const showKeys = nextI18Next?.i18n?.language?.toLowerCase() === 'keys';

    if (!showKeys) {
      return namespace;
    }
  }

  return 'translation';
};

module.exports = {
  defaultLanguage,
  Trans: args => nextI18Next.Trans({ ...args, ns: getNamespaceFile(args?.ns) }),
  Link: nextI18Next.Link,
  Router: nextI18Next.Router,
  i18n: nextI18Next.i18n,
  initPromise: nextI18Next.initPromise,
  config: nextI18Next.config,
  useTranslation: args => nextI18Next.useTranslation(getNamespaceFile(args)),
  withTranslation: args => {
    const translationNamespace = 'translation' || args;
    // TODO: Use args to get namespaces | Removed for now to have SSR backward compatibility until proper tagging
    // const translationNamespace = args || 'translation';

    return nextI18Next.withTranslation(translationNamespace);
  },
  appWithTranslation: nextI18Next.appWithTranslation,
  getServerLanguage: req =>
    nextI18Next?.i18n?.language?.toLowerCase() || req?.query?.lng || req?.language || defaultLanguage,
  ignoreRoutes,
  getPageTranslations,
};
