import * as fetchUtils from 'utils/fetch';
import { getAuthRequest } from 'utils/API';
import { getCookie } from 'utils/cookie';
import { OB_EVENTS_AUTH_TOKEN } from 'constants/cookieConstants';
import { EVENTS_ENDPOINTS } from './constants';

const textEncoder = new TextEncoder();

export const sentToGoogleAnanytics = data => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    // window.ga('send', 'pageview');
    window.dataLayer.push({ event: data.name, ...data });
  }
};

const { EVENTS_BASE_URL } = process.env;

export const getAuthToken = async () => {
  let authToken = getCookie(OB_EVENTS_AUTH_TOKEN);

  if (authToken) {
    return authToken;
  }

  try {
    const data = await getAuthRequest(EVENTS_ENDPOINTS.GET_ACCESS).then(response => response?.data?.data);

    authToken = data?.authToken;
  } catch (error) {
    console.error('Error fetching auth token', error);
  }

  return authToken;
};

export const refreshAuthToken = async () => {
  let authToken;

  try {
    const data = await getAuthRequest(EVENTS_ENDPOINTS.REFRESH_ACCESS).then(response => response?.data?.data);

    authToken = data?.authToken;
  } catch (error) {
    console.error('Error refreshing auth token', error);
  }

  return authToken;
};

export const sendEventsToBackend = async payload => {
  let body = JSON.stringify(payload);

  if (process.env.APP_ENV === 'production') {
    const uint8Array = textEncoder.encode(body);
    const binaryString = Array.from(uint8Array)
      .map(byte => String.fromCharCode(byte))
      .join('');
    const base64EncodedPayload = btoa(binaryString);
    body = JSON.stringify({ data: base64EncodedPayload });
  }

  const authToken = await getAuthToken();

  const response = await fetchUtils.fetchJson(`${EVENTS_BASE_URL}${EVENTS_ENDPOINTS.EVENTS}`, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });

  return response;
};

// TODO: Need to add sentry logging / Page Block
export const checkHealth = async (retryLimit, retryDelay, retryCount = 0) => {
  try {
    const pingResponse = await fetchUtils.fetchJson(`${EVENTS_BASE_URL}${EVENTS_ENDPOINTS.SYSTEM_PING}`);
    return pingResponse.body.status === 'ok';
  } catch (error) {
    if (retryCount < retryLimit) {
      // eslint-disable-next-line no-restricted-properties
      await new Promise(resolve => setTimeout(resolve, retryDelay * Math.pow(2, retryCount)));
      return checkHealth(retryLimit, retryDelay, retryCount + 1);
    }
    return false;
  }
};
