import React, { useState, useEffect, useLayoutEffect, useCallback, useContext, createContext } from 'react';
import { useMediaQuery } from '@mui/material';
import isMobile from 'ismobilejs';

export const DEVICE_TYPES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

const LayoutContext = createContext({});

const getDeviceType = userAgent => {
  let type = DEVICE_TYPES.DESKTOP;
  const deviceTypeDetected = isMobile(userAgent);

  if (deviceTypeDetected.phone) {
    type = DEVICE_TYPES.MOBILE;
  } else if (deviceTypeDetected.tablet) {
    type = DEVICE_TYPES.TABLET;
  }

  return {
    deviceType: deviceTypeDetected || null,
    type,
  };
};

export const LayoutContextProvider = ({ children, userAgent, locale }) => {
  const [deviceTypeLayout, setDeviceTypeLayout] = useState(() => {
    const { deviceType, type } = getDeviceType(userAgent);

    return {
      deviceType,
      type,
      layout: type,
    };
  });

  const canUseDOM = typeof window !== 'undefined';

  const isTabletScreen = useMediaQuery('(max-width: 1280px) and (min-width: 600px)', { noSsr: true });
  const isMobileScreen = useMediaQuery('(max-width: 600px)', { noSsr: true });

  const updateTypeLayout = useCallback(() => {
    const { deviceType, type } = getDeviceType(userAgent);
    let layout = DEVICE_TYPES.DESKTOP;

    if (canUseDOM) {
      if (isMobileScreen) {
        layout = DEVICE_TYPES.MOBILE;
      } else if (isTabletScreen) {
        layout = DEVICE_TYPES.TABLET;
      }
    }

    setDeviceTypeLayout({ deviceType, layout, type });
  }, [canUseDOM, isMobileScreen, isTabletScreen, userAgent]);

  const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => {
    updateTypeLayout();

    if (canUseDOM) {
      window.addEventListener('resize', updateTypeLayout);
    }

    return () => {
      if (canUseDOM) {
        window.removeEventListener('resize', updateTypeLayout);
      }
    };
  }, [canUseDOM, isMobileScreen, isTabletScreen, userAgent]);

  return (
    <LayoutContext.Provider
      value={{
        ...deviceTypeLayout,
        isMobile: deviceTypeLayout.layout === DEVICE_TYPES.MOBILE,
        isTablet: deviceTypeLayout.layout === DEVICE_TYPES.TABLET,
        isDesktop: deviceTypeLayout.layout === DEVICE_TYPES.DESKTOP,
        locale: locale || 'en', // @TODO move to pageContext
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

const useDeviceTypeLayouts = () => useContext(LayoutContext);

export default useDeviceTypeLayouts;
