import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MPopover from '@mui/material/Popover';
import classnames from 'classnames';

const Popover = ({ children, anchorEl, styles, onClose, container, ...rest }) => {
  const onCloseHandler = useCallback(
    e => {
      if (anchorEl && onClose) {
        e.stopPropagation();
        onClose();
      }
    },
    [anchorEl, onClose],
  );

  return (
    <MPopover
      open={Boolean(anchorEl)}
      container={container}
      anchorEl={anchorEl}
      onClose={onCloseHandler}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: classnames({
          [styles?.root]: !!styles?.root,
        }),
      }}
      {...rest}
    >
      {children}
    </MPopover>
  );
};

Popover.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.element,
  container: PropTypes.element,
  styles: PropTypes.object,
  onClose: PropTypes.func,
};

export default Popover;
