const HttpError = (props, innerException) => {
  const message = `HTTP Error [${props.status || 500}] ${props.statusText} on ${props.url || 'unknown url'}`;

  if (!innerException?.stack) {
    if (innerException && typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(innerException, innerException.constructor);
    }
  }

  const error = new Error(message, innerException);
  error.stack = innerException?.stack;
  error.status = props.status || 500;
  error.body = props.body || null;
  error.method = props.method || 'GET';
  error.url = props.url || 'unknown url';
  error.innerException = innerException;
  error.name = 'HttpError';
  error.responseText = props.responseText || '';
  return error;
};

export const NotFoundError = (props, innerException) => HttpError({ status: 404, ...props }, innerException);

export default HttpError;
