/* eslint-disable import/no-cycle */
import { useMemo } from 'react';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import usePageContext from 'utils/hooks/usePageContext';
import { sentryCaptureMessage } from 'utils/globals/sentry';
import { convertArrayValuesToStrings } from '../utils';

const getEditPageMeta = (edit, queryParams) => {
  if (!edit) {
    return {};
  }

  const { tab, ...rest } = queryParams || {};
  return {
    filters: {
      ...rest,
    },
    pageTab: tab,
  };
};

const usePageInfo = () => {
  const {
    mainPath,
    pathname,
    language,
    entityId,
    entityType,
    entitySlug,
    pro,
    subPath,
    filterParams,
    filterTypeUsage,
    edit,
    countrySlug,
    queryParams,
  } = usePageContext();
  const router = useRouter();
  const isWindowAvailable = typeof window !== 'undefined';

  const pageInfo = useMemo(() => {
    let referrer = isWindowAvailable ? window.document?.referrer : null;
    const editPageMeta = getEditPageMeta(edit, queryParams);

    // NOTE: Check if the referrer is from an external domain
    if (referrer) {
      const currentDomain = window.location.origin;
      referrer = referrer.startsWith(currentDomain) ? null : referrer;
    }

    const isValidEntityId = entityId && typeof entityId === 'number';

    if (entityId && !isValidEntityId) {
      sentryCaptureMessage('Invalid EntityId in usePageInfo (AnalyticsProvider)!', {
        extra: { entityId, pathname },
      });
    }

    const usedAdvancedFilters = filterTypeUsage?.usedAdvancedFilters;

    return {
      pageTab: mainPath || editPageMeta.pageTab,
      pageSubTab: subPath,
      pagePath: pathname,
      referrer,
      language,
      isProView: pro,
      isEditView: edit,
      pageEntityId: isValidEntityId ? entityId : undefined,
      pageEntityType: entityType,
      pageEntityName: entitySlug,
      filters: {
        ...editPageMeta.filters,
        ...(countrySlug && { country_id: countrySlug }),
        ...convertArrayValuesToStrings(filterParams),
      },
      advancedFilters: _isEmpty(usedAdvancedFilters) ? undefined : usedAdvancedFilters,
    };
    // NOTE: Dont add queryparams (its an object and not needed, its covered in pathaame)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,
    language,
    router.pathname,
    entityType,
    mainPath,
    subPath,
    pro,
    edit,
    entityId,
    entitySlug,
    isWindowAvailable,
  ]);
  return pageInfo;
};

export default usePageInfo;
