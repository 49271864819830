import {
  getCountriesAction,
  getArtistsNumberAction,
  getPerformancesNumberAction,
  getRegionsAction,
  getUserConstants,
  setLanguageAction,
  setTabScrollPositionAction,
} from './actions';
import { logoutAction } from '../containers/App/actions';
import { defaultListParams, listReducer } from '../utils/crudUtils';

export const initialAppState = {
  token: null,
  isLoggedIn: false,
  countries: [],
  regions: defaultListParams,
  error: false,
  isLoginLoading: false,
  userConstants: [],
  language: null,
  scrollPosition: null,
};

function appReducer(state = initialAppState, { type, payload }) {
  switch (type) {
    case getRegionsAction.TRIGGER:
    case getRegionsAction.SUCCESS:
    case getRegionsAction.FAILURE:
      return listReducer(state, type, payload, 'regions');

    case getCountriesAction.TRIGGER:
      return { ...state, countries: [] };
    case getCountriesAction.SUCCESS:
      return { ...state, countries: payload };
    case getCountriesAction.FAILURE:
      return { ...state, error: payload };

    case setLanguageAction.TRIGGER:
    case setLanguageAction.SUCCESS:
      return { ...state, language: payload };
    case setLanguageAction.FAILURE:
      return { ...state, error: payload };

    case getArtistsNumberAction.SUCCESS:
      return { ...state, artistsNumber: payload };
    case getPerformancesNumberAction.SUCCESS:
      return { ...state, performancesNumber: payload };
    case logoutAction.TRIGGER:
      return { ...state, token: undefined };

    case getUserConstants.TRIGGER:
      return { ...state, userConstants: [] };
    case getUserConstants.SUCCESS:
      return { ...state, userConstants: payload };
    case getUserConstants.FAILURE:
      return { ...state, error: payload };

    case setTabScrollPositionAction.TRIGGER:
      return { ...state, scrollPosition: payload };

    default:
      return state;
  }
}

export default appReducer;
