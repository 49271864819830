import React, { useState, useMemo } from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import Popover from 'components/uiLibrary/Popover';
import ExternalLink from 'components/Globals/ExternalLink';

import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './MediaAttribution.module.scss';

const MediaAttribution = ({ className, attribution }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [anchor, setAnchor] = useState(false);
  const { source_info, credit, copyright } = attribution || {};

  const infoItems = useMemo(
    () =>
      [
        { label: 'FN_MEDIA_SOURCE', value: source_info, isLink: true },
        { label: 'FN_MEDIA_CREDIT', value: credit },
        { label: 'FN_MEDIA_COPYRIGHT', value: copyright },
      ].filter(item => item.value),
    [credit, copyright, source_info],
  );

  if (!source_info && !credit && !copyright) {
    return null;
  }

  return (
    <div className={classnames(classes.root, { [className]: !!className })}>
      <div className={classes.credit}>
        {infoItems.length && (
          <SpriteIcon
            className={classes.credit__icon}
            icon="copyright"
            size={18}
            onClick={e => setAnchor(e.currentTarget)}
          />
        )}
      </div>

      <Popover
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.creditInformation}>
          {infoItems.map(infoItem => (
            <Typography variant="p" key={infoItem.label}>
              <Typography weight="bold">{t(`${TP}.${infoItem.label}`)}: </Typography>
              {infoItem.isLink ? (
                <ExternalLink title={t(`${TP}.FN_MEDIA_SOURCE`)} url={infoItem.value} />
              ) : (
                <Typography>{infoItem.value}</Typography>
              )}
            </Typography>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default MediaAttribution;
