// eslint-disable-next-line import/no-cycle
import productionQueries from 'containers/Productions/queries'; // TODO: Solve dependency cycle
import globalQueries from 'containers/Globals/queries';
import mediaQueries from 'containers/Media/queries';
import reviewQueries from 'containers/Reviews/queries';
import artistQueries from 'containers/Artist/queries';
import workQueries from 'containers/Work/queries';
import orgQueries from 'containers/Organizations/queries';
import accountsQueries from 'containers/Accounts/queries';
import managerQueries from 'containers/Managers/queries';

import {
  ENTITY_TYPE,
  DATE_FORMATS,
  PRODUCTION_VALIDITY_IDENTIFIERS,
  AGGREGATION_ON,
  LOCATION_TYPE,
  UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
  PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
  SORT_OPTION_VALUES,
  ENTITY_DETAILS_TAB,
  PROFILE_TYPES,
  REPERTOIRE_SECTION_TYPES,
  PRIVACY_ATTRIBUTE_IDS,
  ENTITY_API_PATH,
} from 'constants/index';

import {
  transformUpcomingPerformanceFilters,
  transformPastDateFilters,
  transformUpcomingDateFilters,
} from 'utils/globals/filters';
import { getBaseSeasonFilters, getEntitySeasonType } from 'utils/productions';

import { createDate } from 'utils/date';

const QUERIES = {
  ACCOUNTS: {
    GET_USER_DETAILS: () => ({
      queryFn: accountsQueries.getUserDetails,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_USER_PERMISSIONS: () => ({
      queryFn: accountsQueries.getUserPermissions,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_USER_SUBSCRIPTIONS: () => ({
      queryFn: accountsQueries.getUserSubscriptions,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_USER_PROFILES: () => ({
      queryFn: accountsQueries.getUserProfiles,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_ACTIVE_PROFILE_ID: () => ({
      queryFn: accountsQueries.getActiveProfileId,
      isEnabled: ({ extras }) => !!extras?.user?.id,
      getProps: ({ extras }) => ({
        id: extras?.user?.id,
      }),
    }),
    GET_ACTIVE_PROFILE_DETAILS: () => ({
      queryFn: accountsQueries.getActiveProfileDetails,
      isEnabled: ({ extras }) => !!extras?.activeProfileId,
      getProps: ({ extras }) => ({
        id: extras?.activeProfileId,
      }),
    }),
    GET_ACTIVE_PROFILE_PERMISSIONS: () => ({
      queryFn: accountsQueries.getActiveProfilePermissions,
      isEnabled: ({ extras }) => !!extras?.activeProfileId,
      getProps: ({ extras }) => ({
        id: extras?.activeProfileId,
      }),
    }),
    GET_ACTIVE_PROFILE_SUBSCRIPTIONS: () => ({
      queryFn: accountsQueries.getActiveProfileSubscriptions,
      isEnabled: ({ extras }) => !!extras?.activeProfile?.id,
      getProps: ({ extras }) => ({
        id: extras?.activeProfile?.id,
        activeProfile: extras?.activeProfile,
      }),
    }),
  },
  GLOBAL: {
    GET_ENTITY_DETAILS: ({ entityType, entityId, showApprovedHidden, asEdit, program_published, queryConfig }) => {
      let fetchEntityDetails = null;

      switch (entityType) {
        case ENTITY_TYPE.ARTIST:
          fetchEntityDetails = artistQueries.getArtistDetails;
          break;
        case ENTITY_TYPE.ORGANIZATION:
          fetchEntityDetails = orgQueries.getOrganizationDetails;
          break;
        case ENTITY_TYPE.WORK:
          fetchEntityDetails = workQueries.getWorkDetails;
          break;
        case ENTITY_TYPE.MANAGER:
          fetchEntityDetails = managerQueries.getManagerDetails;
          break;
        case ENTITY_TYPE.PRODUCTION:
          fetchEntityDetails = productionQueries.getProductionDetails;
          break;
        default: {
          fetchEntityDetails = () => ({});
          break;
        }
      }

      return {
        queryFn: fetchEntityDetails,
        getProps: () => ({
          id: entityId,
          showApprovedHidden,
          asEdit,
          program_published,
          queryConfig,
        }),
      };
    },
    GET_HOME_NUMBERS: () => ({
      queryFn: globalQueries.getHomeNumbers,
    }),
    GET_TESTIMONIALS: ({ limit }) => ({
      queryFn: globalQueries.getTestimonials,
      getProps: () => ({
        limit,
      }),
    }),
    GET_ENTITY_RECOMMENDATIONS: ({ limit }) => ({
      queryFn: globalQueries.getEntityRecommendations,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        limit,
      }),
    }),
    GET_LOCATION: () => ({
      queryFn: globalQueries.getLocation,
      isEnabled: ({ context }) => !!context?.countrySlug,
      getProps: ({ context }) => ({
        type: LOCATION_TYPE.COUNTRY,
        filters: {
          slug: context?.countrySlug,
        },
      }),
    }),
    GET_COMPOSER_LIST: ({ query }) => ({
      queryFn: globalQueries.getComposerList,
      getProps: () => ({
        filters: {
          query,
        },
        page: 1,
      }),
    }),
    GET_GLOBAL_SEARCH_SUGGESTIONS: ({ limit = 16 }) => ({
      queryFn: globalQueries.getGlobalSearchSuggestions,
      getProps: ({ context }) => ({
        filters: {
          ...context?.filterParams,
          entity_type: ENTITY_API_PATH[context?.entityType],
        },
        limit,
      }),
    }),
  },
  ARTISTS: {
    GET_PROFESSION_DETAILS: ({ slug }) => ({
      queryFn: artistQueries.getProfessionDetails,
      getProps: () => ({
        filters: {
          slug,
        },
      }),
    }),
    GET_ARTISTS: ({ filters, limit, infinite }) => ({
      queryFn: artistQueries.getArtists,
      getProps: () => ({
        filters,
        limit,
      }),
      ...(infinite && { infinite: true }),
    }),
    GET_FEATURED: ({ limit }) => ({
      queryFn: artistQueries.getArtists,
      getProps: () => ({
        filters: {
          sort: 'random',
          tier: 'A',
        },
        limit,
      }),
    }),
    GET_PROFESSION_OPTIONS: () => ({
      queryFn: artistQueries.getProfessionOptions,
    }),
    GET_ALL_PROFESSIONS_LIST: () => ({
      queryFn: artistQueries.getArtistProfessions,
    }),
    GET_TRENDING_ARTISTS: ({ filters, limit }) => ({
      queryFn: artistQueries.getTrendingArtist,
      getProps: () => ({
        filters,
        limit,
      }),
      infinite: true,
    }),
    GET_PROFILE_PROFESSION_AGGREGATION: ({ value }) => ({
      queryFn: artistQueries.getProfileProfessionAggregation,
      getProps: () => ({
        filters: {
          aggregation_type: value,
        },
      }),
    }),
    GET_BIO: () => ({
      queryFn: globalQueries.getEntityBioDetails,
      getProps: ({ context }) => ({
        isEnabled: ({ extras }) => extras?.entity?.stats?.about?.exists,
        entityType: context?.entityType,
        entityId: context?.entityId,
      }),
    }),
    GET_PREFERRED_ENTITIES: ({ section }) => ({
      queryFn: artistQueries.getFeaturedEntities,
      isEnabled: ({ extras }) => {
        const isPaidProfile = extras?.entity?.subscriptionStatus === PROFILE_TYPES.PRO;

        return isPaidProfile && extras?.entity?.stats?.preferredEntities?.[section]?.visibility;
      },
      getProps: ({ context }) => ({
        id: context?.entityId,
        filters: {
          section,
        },
      }),
    }),
    GET_REPERTOIRE_DETAILS: ({ type }) => ({
      queryFn: artistQueries.getRepertoireDetails,
      isEnabled: ({ extras }) => {
        const isPaidProfile = extras?.entity?.subscriptionStatus === PROFILE_TYPES.PRO;

        if (!isPaidProfile) {
          return false;
        }

        if (type === REPERTOIRE_SECTION_TYPES.FUTURE) {
          return extras?.entity?.stats?.repertoire?.future?.exists;
        }

        return extras?.entity?.stats?.repertoire?.past?.exists;
      },
      getProps: ({ context }) => ({
        id: context?.entityId,
        type,
        filters: context?.filters,
      }),
    }),
  },
  ORGANIZATIONS: {
    GET_ORGANIZATION_TYPES: () => ({
      queryFn: orgQueries.getAggregatedOrgTypes,
    }),
    GET_TRENDING_ORGANIZATIONS: ({ filters }) => ({
      queryFn: orgQueries.getTrendingOrganizations,
      getProps: ({ context }) => ({
        filters: filters || context?.filterParams,
        limit: 8,
      }),
      infinite: true,
    }),
    GET_ORGANIZATIONS: ({ filters, limit = 16, infinite = false }) => ({
      queryFn: orgQueries.getOrganizations,
      getProps: ({ context }) => ({
        filters: filters || context?.filterParams,
        limit,
      }),
      infinite,
    }),
    GET_TRANSLATIONS: () => ({
      queryFn: orgQueries.getTranslations,
      isEnabled: ({ extras }) => extras?.entity?.stats?.about?.exists,
      getProps: ({ context }) => ({
        id: context?.entityId,
      }),
    }),
    GET_ASSOCIATED_ORGANIZATIONS: () => ({
      queryFn: orgQueries.getAssociatedOrganizations,
      getProps: ({ context }) => ({
        id: context?.entityId,
      }),
    }),
  },
  PRODUCTIONS: {
    GET_RENTALS: () => ({
      queryFn: productionQueries.getRentals,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: context?.filterParams,
      }),
      infinite: true,
    }),
    GET_FEATURED: ({ limit, infinite = false }) => ({
      queryFn: productionQueries.getPerformances,
      getProps: () => {
        const today = createDate(new Date()).format(DATE_FORMATS.FULL_DATE);

        return {
          filters: {
            date_from: today,
            date_to: today,
            is_producer_approved: true,
            approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
            sort: SORT_OPTION_VALUES.YEAR_ASC,
          },
          limit,
        };
      },
      infinite,
    }),
    GET_COUNTRIES: ({ aggregationOn = AGGREGATION_ON.PRODUCTIONS, filters, limit = 300 }) => ({
      queryFn: productionQueries.getCountries,
      getProps: () => ({
        aggregationOn,
        filters,
        limit,
      }),
    }),
    GET_RENTAL_CONTACTS: () => ({
      queryFn: orgQueries.getProductionRentalContacts,
      isEnabled: ({ extras }) => extras?.entity?.rentals?.length > 0,
      getProps: ({ extras }) => ({
        entity: extras.entity,
      }),
    }),
    GET_DESCRIPTIONS: () => ({
      queryFn: productionQueries.getProductionDescriptions,
      isEnabled: ({ extras }) => extras?.entity?.stats?.about?.exists,
      getProps: ({ context }) => ({
        id: context?.entityId,
      }),
    }),
    GET_UPCOMING_PRODUCTIONS: ({ limit, infinite }) => ({
      queryFn: productionQueries.getProductions,
      isEnabled: ({ extras }) => extras?.entity?.stats?.productions?.future?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        limit,
        filters: transformUpcomingPerformanceFilters(context?.filterParams),
      }),
      infinite,
    }),
    GET_SEASONS: ({ upcoming = false }) => ({
      queryFn: productionQueries.getSeasons,
      getProps: ({ context, extras }) => {
        const { entityType } = context;
        const { entity } = extras;
        const filters = getBaseSeasonFilters({ upcoming, entityType, entity });

        const type = getEntitySeasonType({ entityType, entity });

        return {
          filters: {
            ...filters,
            aggregation_season_type: type,
            ...(entityType === ENTITY_TYPE.ARTIST
              ? { ctx_entity: `a${entity?.id}` }
              : { ctx_entity: `o${entity?.id}` }),
          },
        };
      },
    }),
    GET_SEASON_PRODUCTIONS: ({ limit = 10, upcoming }) => ({
      queryFn: productionQueries.getProductions,
      isEnabled: ({ context, extras }) => {
        if (extras?.isTrustedBot) {
          return true;
        }

        const { filterTypeUsage } = context;

        return !filterTypeUsage?.limitExceeded;
      },
      getProps: ({ context, extras }) => {
        const { entityType, mainPath, subPath, filterParams } = context;
        const { entity, selectedSeasonKey } = extras;
        const isUpcomingProduction =
          upcoming || (mainPath && subPath !== ENTITY_DETAILS_TAB.PAST && !filterParams?.season_id?.[0]);
        const baseFilters = getBaseSeasonFilters({ upcoming: isUpcomingProduction, entityType, entity });

        return {
          entityType,
          entityId: entity?.id,
          filters: {
            ...baseFilters,
            ...filterParams,
            ...(selectedSeasonKey && { season_id: selectedSeasonKey }),
          },
          limit,
        };
      },
      infinite: true,
    }),
    GET_SEASON_PERFORMANCES: ({ limit = 15 }) => ({
      queryFn: productionQueries.getPerformances,
      isEnabled: ({ context, extras }) => {
        if (extras?.isTrustedBot) {
          return extras?.entity?.stats?.performances?.future?.exists;
        }

        const { filterTypeUsage } = context;
        return extras?.entity?.stats?.performances?.future?.exists && !filterTypeUsage?.limitExceeded;
      },
      getProps: ({ context, extras }) => {
        const { entityType, subPath, filterParams } = context;
        const { entity, selectedSeasonKey } = extras;
        const isUpcomingProduction = subPath !== ENTITY_DETAILS_TAB.PAST && !filterParams?.season_id?.[0];
        const baseFilters = getBaseSeasonFilters({ upcoming: isUpcomingProduction, entityType, entity });

        return {
          entityType,
          entityId: entity?.id,
          filters: {
            ...baseFilters,
            ...filterParams,
            ...(selectedSeasonKey && { season_id: selectedSeasonKey }),
          },
          limit,
        };
      },
      infinite: true,
    }),
    GET_PAST_PRODUCTIONS: ({ limit, mediaLimit, hasMedia }) => ({
      queryFn: productionQueries.getProductions,
      isEnabled: ({ extras }) =>
        hasMedia
          ? extras?.entity?.stats?.productions?.past?.exists &&
            extras?.entity?.stats?.media?.images?.production?.past?.exists
          : extras?.entity?.stats?.media?.images?.production?.past?.exists,
      getProps: ({ context, extras }) => {
        const { entityType } = context;
        const { entity } = extras;
        const baseFilters = getBaseSeasonFilters({ upcoming: false, entityType, entity });

        return {
          entityType: context?.entityType,
          entityId: context?.entityId,
          filters: {
            ...baseFilters,
            has_media: hasMedia,
            ...(hasMedia && { include_virtual_contributors: false }),
          },
          limit,
          mediaLimit,
        };
      },
      infinite: true,
    }),
    GET_PRODUCTION_CREDITS: () => ({
      queryFn: productionQueries.getProductions,
      isEnabled: ({ extras }) => extras?.entity?.stats?.productions?.exists,
      getProps: ({ context, extras }) => {
        const { entityType } = context;
        const { entity } = extras;
        const { date_max: _dateMax, date_to: _dateTo, ...restFilters } = getBaseSeasonFilters({
          upcoming: false,
          entityType,
          entity,
        });

        return {
          entityType: context?.entityType,
          entityId: context?.entityId,
          filters: restFilters,
          limit: 5,
        };
      },
      infinite: true,
    }),
  },
  MEDIA: {
    GET_MEDIA_SUGGESTIONS: () => ({
      queryFn: mediaQueries.getMediaSuggestions,
      isEnabled: ({ extras, context }) => {
        if (context?.entityType === ENTITY_TYPE.PRODUCTION) {
          return extras?.entity?.stats?.media?.exists;
        }

        return !context?.mainPath && extras?.entity?.stats?.media?.widget?.exists;
      },
      getProps: ({ context }) => ({
        entityType: context.entityType,
        entityId: context?.entityId,
      }),
    }),
    GET_DIGITAL_VIDEOS: ({ limit }) => ({
      queryFn: mediaQueries.getDigitalVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.digital?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: context?.filterParams,
        limit,
      }),
    }),
    GET_FEATURED_VIDEOS: ({ limit, infinite }) => ({
      queryFn: mediaQueries.getFeaturedVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.featured?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: context?.filterParams,
        limit,
      }),
      infinite,
    }),
    GET_PRODUCTION_PAST_VIDEOS: ({ distinctLimit }) => ({
      queryFn: mediaQueries.getProductionVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.production?.past?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: transformPastDateFilters(context?.filterParams),
        distinctLimit,
        sort: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
      }),
      infinite: true,
    }),
    GET_PRODUCTION_UPCOMING_VIDEOS: ({ distinctLimit }) => ({
      queryFn: mediaQueries.getProductionVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.production?.future?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: transformUpcomingDateFilters(context?.filterParams),
        distinctLimit,
        sort: UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
      }),
      infinite: true,
    }),
    GET_FEATURED_PHOTOS: ({ limit, infinite }) => ({
      queryFn: mediaQueries.getFeaturedPhotos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.images?.featured?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: context?.filterParams,
        limit,
      }),
      infinite,
    }),
    GET_PRODUCTION_PAST_PHOTOS: ({ distinctLimit }) => ({
      queryFn: mediaQueries.getProductionPhotos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.images?.production?.past?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: transformPastDateFilters(context?.filterParams),
        distinctLimit,
        sort: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
      }),
      infinite: true,
    }),
    GET_PRODUCTION_UPCOMING_PHOTOS: ({ distinctLimit }) => ({
      queryFn: mediaQueries.getProductionPhotos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.images?.production?.future?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: transformUpcomingDateFilters(context?.filterParams),
        distinctLimit,
        sort: UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
      }),
      infinite: true,
    }),
    GET_SHOWREEL_VIDEOS: ({ showreelTypeId, limit }) => ({
      queryFn: mediaQueries.getShowreelVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.showreels?.intro?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        id: showreelTypeId,
        limit,
      }),
    }),
    GET_PRODUCTION_VIDEOS: ({ limit }) => ({
      queryFn: mediaQueries.getProductionVideos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.videos?.production?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        sort: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
        limit,
        filters: {
          sort: SORT_OPTION_VALUES.CUSTOM_DATE,
        },
      }),
      infinite: true,
    }),
    GET_PRODUCTION_PHOTOS: ({ limit }) => ({
      queryFn: mediaQueries.getProductionPhotos,
      isEnabled: ({ extras }) => extras?.entity?.stats?.media?.images?.production?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        sort: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
        limit,
        filters: {
          sort: SORT_OPTION_VALUES.CUSTOM_DATE,
        },
      }),
      infinite: true,
    }),
  },
  REVIEWS: {
    GET_FEATURED_REVIEWS: ({ limit }) => ({
      queryFn: reviewQueries.getFeaturedReviews,
      isEnabled: ({ extras }) => extras?.entity?.stats?.reviews?.featured?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        filters: context?.filterParams,
        limit,
      }),
    }),
    GET_PRODUCTION_REVIEWS: ({ limit }) => ({
      queryFn: reviewQueries.getProductionReviews,
      isEnabled: ({ extras }) => extras?.entity?.stats?.reviews?.production?.exists,
      getProps: ({ context }) => ({
        entityType: context?.entityType,
        entityId: context?.entityId,
        limit,
      }),
    }),
  },
  MANAGERS: {
    GET_AGENCIES: () => ({
      queryFn: managerQueries.getEntityManagerRepresentations,
      isEnabled: ({ extras }) => extras?.entity?.stats?.agencies?.exists,
      getProps: ({ context }) => ({
        entityId: context?.entityId,
        entityType: context?.entityType,
        filters: {
          privacyAttributes: [
            PRIVACY_ATTRIBUTE_IDS.PUBLIC,
            PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL,
            PRIVACY_ATTRIBUTE_IDS.AGENCY,
            PRIVACY_ATTRIBUTE_IDS.AOS,
            PRIVACY_ATTRIBUTE_IDS.ARTIST,
            PRIVACY_ATTRIBUTE_IDS.FREE_AND_PRO,
          ],
        },
      }),
    }),
  },
};

export default QUERIES;
