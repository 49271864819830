import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import defaultTo from 'lodash/defaultTo';
import compact from 'lodash/compact';

import { createDate } from 'utils/date';

import route from 'constants/routes';
import { dateFormats } from 'constants/consts';

import { nextPageQueryName } from '../auth';
import { getHttpInstance } from '../fetch';

let pingInterval;
const TIME_INTERVAL = 60000;
export const baseURL = process.env.FRONTBASE_URL.replace(/\/$/, '');

export const setIntervalForPing = () => {
  clearIntervalForPing();
  ping();
  pingInterval = setInterval(() => {
    ping();
  }, TIME_INTERVAL);
};

const ping = async () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const url = `${process.env.FRONTBASE_URL}/auth/status`;
  const httpInstance = getHttpInstance(new URL(url).origin);

  let payload;
  try {
    payload = await httpInstance(url, {
      method: 'PUT',
      headers,
      data: {},
    });
  } catch (err) {
    payload = err.response;
    if (err.response?.status === 401) {
      // eslint-disable-next-line no-console
      const redirectUrl = `${route.LOGIN}?reason=logout&${nextPageQueryName}=${encodeURIComponent(
        window.location.href,
      )}`;
      window.location.replace(redirectUrl);
    }
  }
  return payload?.data;
};

export const clearIntervalForPing = () => {
  if (pingInterval) {
    clearInterval(pingInterval);
  }
};

export const useArtsConsolidatedJsonLd = () => ({
  id: 'https://www.operabase.com/en',
  type: 'Organization',
  name: 'OperaBase',
  legalName: 'Arts Consolidated Aps',
  url: 'https://www.operabase.com/en',
  logo: 'https://www.operabase.com/images/operabase-logo-large.png',
  image: 'https://www.operabase.com/images/operabase-logo-large.png',
  email: 'contact@operabase.com',
  telephone: '-',
  address: {
    addressLocality: 'København, Denmark',
    streetAddress: 'Lyngbyvej 20',
    postalCode: '2100',
    addressCountry: 'DK',
  },
  sameAs: [
    'https://www.facebook.com/operabase/',
    'https://twitter.com/BaseOpera',
    'https://en.wikipedia.org/wiki/Operabase',
    'https://www.linkedin.com/company/operabase-global',
    'https://www.instagram.com/operabaseglobal/',
  ],
  foundingDate: '1996',
});

export const useOpenSearchJsonLd = () => ({
  url: 'https://www.operabase.com/',
  potentialActions: [
    {
      target: 'https://www.operabase.com/search?query',
      queryInput: 'search_term_string',
    },
  ],
});

export const createSelectFieldOptions = (
  arrayOfObjects,
  valueName,
  labelName = null,
  customProps = {},
  additionalProp,
) => {
  const label = labelName || valueName;
  let options = [];
  if (Array.isArray(arrayOfObjects)) {
    options = arrayOfObjects.map(item => ({
      value: get(item, valueName, null),
      label: typeof labelName === 'function' ? labelName(item) : (get(item, label, '') || '').toString(),
      ...customProps,
      ...(additionalProp && item),
    }));
  }

  return options;
};

export const getCityWithAreasLabel = city => {
  const areas = city?.areas?.map(area => area?.name).join(', ');
  return `${city?.name} ${areas?.length > 0 ? `(${areas})` : ''}`;
};

export const getRoutePartWithSlug = infoObject => `${get(infoObject, 'slug', '')}-${get(infoObject, 'id')}`;
export const getRouteWithSlug = (mainRoute, infoObject) => `${mainRoute}/${getRoutePartWithSlug(infoObject)}`;
export const getRouteWithCountryAndSlug = (mainRoute, infoObject) =>
  `${mainRoute}/${get(infoObject, 'country.slug')}/${getRoutePartWithSlug(infoObject)}`;

export const fetchAbortController = () => {
  let abortController;
  if (typeof window !== 'undefined' && AbortController) {
    abortController = new AbortController();
  }
  return abortController;
};

export const dateSendFormat = date => {
  const momentData = createDate(date);
  return momentData.isValid() ? momentData.format(dateFormats.basic) : null;
};

export const changeItemInObjectsArray = (array, item, param = 'id') => {
  const data = Array.isArray(array) ? array : [];
  data.splice(findIndex(array, { [param]: get(item, param) }), 1, item);
  return data;
};

export const getWithDefault = (obj, valuePath, defaultValue) => defaultTo(get(obj, valuePath), defaultValue);

export const separateExcludedIncludedCountries = countries => {
  const included = [];
  const excluded = [];
  countries.forEach(country => {
    if (get(country, 'isExcluded')) {
      excluded.push(country);
    } else {
      included.push(country);
    }
  });
  return { included, excluded };
};

export const getOption = (object, valueName = 'id', labelName = 'name', props = {}, allProps) => {
  const emptyString = '';
  const getNotNullFieldValue = (v, defaultValue) => (v === null ? defaultValue : v);

  let option;
  if (object) {
    option = {
      ...props,
      ...(allProps && object),
      // lodash 'get' returns default value only if the value is resolved as undefined
      value: getNotNullFieldValue(get(object, valueName, emptyString), emptyString),
      label:
        typeof labelName === 'function'
          ? labelName(object)
          : getNotNullFieldValue(get(object, labelName, emptyString), emptyString),
    };
  }
  return option;
};

export const removeWithTranslationProps = props => {
  const strippedProps = { ...props };
  delete strippedProps.defaultNS;
  delete strippedProps.i18n;
  delete strippedProps.i18nOptions;
  delete strippedProps.lng;
  delete strippedProps.reportNS;
  delete strippedProps.t;
  delete strippedProps.tReady;
  delete strippedProps.forwardedRef;
  delete strippedProps.subpath;
  delete strippedProps.signed_login_token;
  return strippedProps;
};

export const getPageParamFromQuery = (path, query) => {
  const cleanQuery = removeWithTranslationProps(query);
  const pageParams = Object.entries(cleanQuery);
  const compactQuery = compact(pageParams.map(([key, val]) => (val ? `${key}=${val}` : null)));
  const stringifiedPageParams = `?${compactQuery.join('&')}`;
  return `${path}${pageParams.length ? stringifiedPageParams : ''}`;
};

export const debugSSR = (queries = []) => {
  const totalQueries = queries?.length;
  let totalTime = 0;
  let totalPayload = 0;
  let slowestQuery = null;
  let heaviestQuery = null;

  const langPath = window?.location?.pathname?.match(/(?:\/\w{2})+$/g)?.[0];
  const langPathParts = langPath?.split('/').filter(Boolean);
  const language = langPathParts?.find(path => path.length === 2) || 'en';

  const apis = queries?.map(query => {
    const data = query?.state?.data;
    // eslint-disable-next-line no-underscore-dangle
    const { responseTime = 0, size = 0 } = data?.__performance__ || {};
    const payloadSize = Number.parseFloat(size);

    const response = {
      language: query?.queryKey?.[0],
      isInvalidLanguage: language !== query?.queryKey?.[0],
      key: query?.queryKey,
      data,
      time: responseTime,
      size: payloadSize,
    };

    if (responseTime > 0) {
      if (responseTime > (slowestQuery?.time || 0)) {
        slowestQuery = response;
      }

      totalTime += responseTime;
    }

    if (payloadSize > 0) {
      if (payloadSize > (heaviestQuery?.size || 0)) {
        heaviestQuery = response;
      }

      totalPayload += payloadSize;
    }

    return response;
  });

  const logger = console;

  const logColoredValue = (label, value, unit, limit) => {
    logger.log(
      `${label} %c${value || ''}${unit}`,
      value > limit ? 'color: red' : 'color: green',
      `(Target: ${limit}${unit})`,
    );
  };

  const logQuery = api => {
    logger.groupCollapsed(`%c${api?.key?.[1]}`, 'color: blue');
    logger.log(`Language: %c${api?.language}`, api?.isInvalidLanguage ? 'color: red' : 'color: green');
    logColoredValue('Time:', api?.time, 'ms', 500);
    logColoredValue('Size:', api?.size, 'kB', 50);
    logger.log('Query Key:', api?.key);
    logger.log('Data:', api?.data);
    logger.groupEnd();
  };

  logColoredValue('SSR Time:', totalTime, 'ms', 500);
  logColoredValue('SSR Payload:', totalPayload, 'kB', 50);
  logger.groupCollapsed(`SSR Queries: %c${totalQueries}`, 'color: blue');
  apis.forEach(api => logQuery(api));
  logger.groupEnd();

  if (slowestQuery) {
    logger.groupCollapsed(`Slowest Query %c${slowestQuery?.key?.[1]}`, 'color: blue');
    logColoredValue('Time:', slowestQuery?.time, 'ms', 500);
    logColoredValue('Size:', slowestQuery?.size, 'kB', 50);
    logQuery(slowestQuery);
    logger.groupEnd();
  }

  if (heaviestQuery) {
    logger.groupCollapsed(`Heaviest Query %c${heaviestQuery?.key?.[1]}`, 'color: blue');
    logColoredValue('Time:', heaviestQuery?.time, 'ms', 500);
    logColoredValue('Size:', heaviestQuery?.size, 'kB', 50);
    logQuery(heaviestQuery);
    logger.groupEnd();
  }

  return apis;
};

export const updateSubDomainDataToImages = pathname => {
  if (!pathname) {
    return pathname;
  }

  const url = pathname
    .replace('https://quality-data.operabase.net', 'https://images.operabase.com/bucketqa')
    .replace('https://data.operabase.com', 'https://images.operabase.com/bucket');

  return url;
};
