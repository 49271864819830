import { useMemo } from 'react';
import maxBy from 'lodash/maxBy';

import { SUBSCRIPTION_STATUSES, ARTIST_TYPE_ID } from 'constants/index';
import { useQuery, useMutation, useSetQueryData, useInvalidateQueries } from 'utils/react-query';
import { waitfor } from 'utils/hooks/useWaitFor';

import queries, { fetchUserSubscriptions } from 'containers/Accounts/queries';

import useAppContext from 'utils/hooks/useAppContext';

const EMPTY_ARRAY_READ_ONLY = Object.freeze([]);
const EMPTY_OBJECT_READ_ONLY = Object.freeze({});

const getActiveProfileId = (profiles, savedActiveProfileId) => {
  const allUserProfileIds = profiles?.map(profile => profile.id);

  if (!savedActiveProfileId || !allUserProfileIds.includes(savedActiveProfileId)) {
    return allUserProfileIds?.[0];
  }

  return savedActiveProfileId;
};

const useBaseUserAuthQuery = (queryFn, select) => {
  const { isLoggedIn, userId } = useAppContext();
  const query = queryFn({ id: userId, queryConfig: { enabled: isLoggedIn, select } });

  const { data, isFetching, isSuccess, refetch } = useQuery(query);

  return {
    queryKey: query.queryKey,
    data,
    isFetching,
    isSuccess,
    refetch,
  };
};

const useUserData = () => {
  const { data: userData } = useBaseUserAuthQuery(queries.getUserDetails);

  return userData || EMPTY_OBJECT_READ_ONLY;
};

const useUserPermissions = () => {
  const { userId } = useAppContext();
  const { data, isFetching, isSuccess } = useBaseUserAuthQuery(queries.getUserPermissions, response => response?.data);

  return {
    isLoading: isFetching,
    loadedOnce: (isSuccess && !!data) || !userId,
    permissions: data || EMPTY_ARRAY_READ_ONLY,
  };
};

const useUserPaymentMethods = () => {
  const { data, isFetching, isSuccess, refetch } = useBaseUserAuthQuery(
    queries.getUserPaymentMethods,
    response => response?.data,
  );

  return {
    refetchPaymentMethods: callback => {
      if (!callback) {
        return refetch();
      }

      return refetch().then(({ data: methods }) => {
        const newestMethod = maxBy(methods, met => met.id);

        callback(newestMethod);
      });
    },
    isLoading: isFetching,
    loadedOnce: isSuccess && !!data,
    data: data || EMPTY_ARRAY_READ_ONLY,
  };
};

const useUserDefaultPaymentMethod = () => {
  const { data } = useBaseUserAuthQuery(queries.getUserPaymentMethods, response =>
    response?.data?.find(pm => pm?.is_default),
  );

  return data;
};

const useUserProfiles = () => {
  const { data } = useBaseUserAuthQuery(queries.getUserProfiles, response => response?.data);

  return data || EMPTY_ARRAY_READ_ONLY;
};

const useAccessPendingProfileIds = () => {
  const { data } = useBaseUserAuthQuery(queries.getUserProfiles, response => response?.accessPendingProfileIds);

  return data || EMPTY_ARRAY_READ_ONLY;
};

const useActiveProfileId = () => {
  const userProfiles = useUserProfiles();
  const { data: savedActiveProfileId } = useBaseUserAuthQuery(queries.getActiveProfileId, response => response?.data);

  const activeProfileId = useMemo(() => getActiveProfileId(userProfiles, savedActiveProfileId), [
    savedActiveProfileId,
    userProfiles,
  ]);

  return activeProfileId;
};

const useActiveProfileData = () => {
  const activeProfileId = useActiveProfileId();
  const { data } = useQuery(
    queries.getActiveProfileDetails({ id: activeProfileId, queryConfig: { enabled: !!activeProfileId } }),
  );

  return data || EMPTY_OBJECT_READ_ONLY;
};

const useActiveProfilePermissions = () => {
  const activeProfileId = useActiveProfileId();
  const { data, isFetching, isSuccess } = useQuery(
    queries.getActiveProfilePermissions({
      id: activeProfileId,
      queryConfig: { enabled: !!activeProfileId, select: response => response?.data },
    }),
  );

  return {
    isLoading: isFetching,
    loadedOnce: (isSuccess && !!data) || !activeProfileId,
    permissions: data || EMPTY_ARRAY_READ_ONLY,
  };
};

const useActiveProfileSubscriptions = () => {
  const activeProfile = useActiveProfileData();
  const { data } = useQuery(
    queries.getActiveProfileSubscriptions({
      id: activeProfile?.id,
      activeProfile,
      queryConfig: { enabled: !!activeProfile?.id, select: response => response?.data },
    }),
  );

  return data || EMPTY_ARRAY_READ_ONLY;
};

const useUserSubscriptions = () => {
  const { data, isFetching, isSuccess, refetch } = useBaseUserAuthQuery(
    queries.getUserSubscriptions,
    response => response?.data,
  );

  return {
    refetchUserSubscriptions: refetch,
    isLoading: isFetching,
    loadedOnce: isSuccess && !!data,
    data: data || EMPTY_ARRAY_READ_ONLY,
  };
};

const useSetUserData = () => {
  const { language, userId, setLoggedInUserId } = useAppContext();
  const setQueryData = useSetQueryData();

  return payload => {
    // TODO: The query key is hardcoded here, it should be moved to a constant
    const forUserId = payload?.id || userId;
    setQueryData([language, '_GET_USER_DETAILS', `${forUserId}`], payload);

    if (forUserId !== userId) {
      setLoggedInUserId(forUserId);
    }
  };
};

const useUpdateUserData = () => {
  const { userId } = useAppContext();
  const { mutate, ...rest } = useMutation(queries.updateUserDetails());
  const setUserData = useSetUserData();

  return {
    ...rest,
    updateUserData: (payload = {}, options = {}) => {
      const { callback, ...restPayload } = payload;
      const { onSuccess, onSettled } = options;

      mutate(
        {
          endpointParams: { id: userId },
          data: restPayload,
        },
        {
          ...options,
          onSuccess: (data, variables, context) => {
            setUserData(data?.data);

            if (onSuccess) {
              onSuccess(data, variables, context);
            }
          },
          onSettled: (data, error, variables, context) => {
            if (typeof onSettled === 'function') {
              onSettled(data, error, variables, context);
            } else if (typeof callback === 'function') {
              const status = error ? 'error' : 'ok';

              callback({
                status,
                data: data?.data,
                error,
              });
            }
          },
        },
      );
    },
  };
};

const useSetActiveProfileId = () => {
  const { language, userId } = useAppContext();
  const { mutate, ...rest } = useMutation(queries.updateActiveProfileId());
  const invalidateQueries = useInvalidateQueries();

  return {
    ...rest,
    setActiveProfileId: (payload = {}, options = {}) => {
      const { profileId } = payload;
      const { onSuccess } = options;

      mutate(
        {
          endpointParams: { id: userId },
          data: {
            value: profileId,
          },
        },
        {
          ...options,
          onSuccess: (data, variables, context) => {
            invalidateQueries({ queryKey: [language, '_GET_USER_ACTIVE_PROFILE_ID'], exact: false });
            if (onSuccess) {
              onSuccess(data, variables, context);
            }
          },
        },
      );
    },
  };
};

const useSubscriptionChurnedStatus = () => {
  const activeProfile = useActiveProfileData();
  const activeProfileSubscriptions = useActiveProfileSubscriptions();

  const status = useMemo(() => {
    if (activeProfile?.profileType?.id !== ARTIST_TYPE_ID) {
      return false;
    }

    const cancelledSubscription = activeProfileSubscriptions.find(
      subscription =>
        subscription?.status === SUBSCRIPTION_STATUSES.CANCELLED &&
        subscription?.profile?.profileType?.id === ARTIST_TYPE_ID &&
        subscription?.profile?.id === activeProfile?.id,
    );
    const validSubscriptions = activeProfileSubscriptions.some(
      subscription => subscription?.status !== SUBSCRIPTION_STATUSES.CANCELLED,
    );

    return !validSubscriptions && cancelledSubscription;
  }, [activeProfile, activeProfileSubscriptions]);

  return status;
};

const useWaitForUserSubscriptionUpdate = () => (targetSubscription, checkFn, { tries, delay } = {}) =>
  waitfor(
    async () => {
      const subscriptions = await fetchUserSubscriptions({ id: targetSubscription?.user?.id });
      const matchedSubscription = subscriptions.data.find(sub => sub.id === targetSubscription?.id);

      return checkFn(matchedSubscription);
    },
    tries,
    delay,
  );

const useRefetchUserQueries = () => {
  const invalidateQueries = useInvalidateQueries();

  const QUERY_NAME_MAP = {
    details: [
      '_GET_USER_DETAILS',
      '_GET_USER_PROFILES',
      '_GET_USER_ACTIVE_PROFILE_ID',
      '_GET_USER_ACTIVE_PROFILE_DETAILS',
    ],
    permissions: ['_GET_USER_PERMISSIONS', '_GET_USER_ACTIVE_PROFILE_PERMISSIONS'],
    subscriptions: ['_GET_USER_SUBSCRIPTIONS', '_GET_USER_ACTIVE_PROFILE_SUBSCRIPTIONS'],
    payments: ['_GET_USER_PAYMENT_METHODS'],
  };

  return options =>
    invalidateQueries({
      predicate: query => {
        const { details = false, permissions = false, subscriptions = false, payments = false } = options || {};
        const queryName = query?.queryKey?.[1];

        if (typeof queryName === 'string' && queryName.startsWith('_GET_USER')) {
          if (!options) {
            return true;
          }

          if (details && QUERY_NAME_MAP.details.includes(queryName)) {
            return true;
          }

          if (permissions && QUERY_NAME_MAP.permissions.includes(queryName)) {
            return true;
          }

          if (subscriptions && QUERY_NAME_MAP.subscriptions.includes(queryName)) {
            return true;
          }

          if (payments && QUERY_NAME_MAP.payments.includes(queryName)) {
            return true;
          }
        }

        return false;
      },
    });
};

export {
  getActiveProfileId,
  useUserData,
  useUserPermissions,
  useUserSubscriptions,
  useActiveProfileSubscriptions,
  useUserPaymentMethods,
  useUserDefaultPaymentMethod,
  useUserProfiles,
  useActiveProfileData,
  useActiveProfileId,
  useActiveProfilePermissions,
  useUpdateUserData,
  useSetUserData,
  useSetActiveProfileId,
  useSubscriptionChurnedStatus,
  useWaitForUserSubscriptionUpdate,
  useAccessPendingProfileIds,
  useRefetchUserQueries,
};
