import {
  getWorkAction,
  getWorkPerfFutureAction,
  getWorkPerfPastAction,
  getProductionsAction,
  getPerformancesAction,
} from './actions';
import { defaultListParams, listReducer } from '../../utils/crudUtils';
import { SET_WORKS_PERFORMANCES } from './constants';

export const key = 'Work';

export const initialState = {
  selectedWork: {
    data: null,
    loading: false,
    loadedOnce: false,
    error: null,
  },
  selectedWorkFutureProd: defaultListParams,
  selectedWorkPastProd: defaultListParams,
};

const worksReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getWorkAction.TRIGGER:
      return { ...state, selectedWork: { ...state.selectedWork, loading: true, error: null } };
    case getWorkAction.SUCCESS:
      return {
        ...state,
        selectedWork: { loadedOnce: true, loading: false, data: payload, error: null },
      };
    case getWorkAction.FAILURE:
      return { ...state, selectedWork: { ...state.selectedWork, loading: false, error: payload } };
    default:
      return state;

    case getWorkPerfFutureAction.TRIGGER:
    case getWorkPerfFutureAction.SUCCESS:
    case getWorkPerfFutureAction.FAILURE:
      return listReducer(state, type, payload, 'selectedWorkFutureProd');

    case getWorkPerfPastAction.TRIGGER:
    case getWorkPerfPastAction.SUCCESS:
    case getWorkPerfPastAction.FAILURE:
      return listReducer(state, type, payload, 'selectedWorkPastProd');

    case getProductionsAction.TRIGGER:
    case getProductionsAction.SUCCESS:
    case getProductionsAction.FAILURE:
      return listReducer(state, type, payload, initialState.productions.listName);

    case getPerformancesAction.TRIGGER:
    case getPerformancesAction.SUCCESS:
    case getPerformancesAction.FAILURE:
      return listReducer(state, type, payload, initialState.performances.listName);

    case SET_WORKS_PERFORMANCES:
      return { ...state, performances: payload.performances };
  }
};

export default worksReducer;
