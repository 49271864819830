/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { createSelector } from 'reselect';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import remove from 'lodash/remove';
import { set, sortBy } from 'lodash';
import { SINGER_PROFESSION_TYPE_ID } from 'constants/deprecate';

import { createSelectFieldOptions, getWithDefault, separateExcludedIncludedCountries } from 'utils/globals/app';
import { INSTRUMENTALIST_PROFESSION_TYPE_ID } from 'constants/consts';
import { initialState } from './reducer';
import { AGREEMENTS_TYPES, professionAgreementsFormFields, commonArtistFields } from './constants';
import { getProfessionAgreementsFormValues, groupArtistAgreementsByProfession } from './utils';

const selectAgenciesDomain = state => state.Agencies || initialState;

const selectAgencyInfo = () => createSelector(selectAgenciesDomain, subState => get(subState, 'agencyInfo'));
const selectAgencyContacts = () =>
  createSelector(selectAgenciesDomain, subState => get(subState, 'agencyContact.data', []));

const selectOffices = () => createSelector(selectAgenciesDomain, subState => get(subState, 'offices'));
const selectArtistsArray = () => createSelector(selectAgenciesDomain, subState => get(subState, 'artists'));

const selectArtists = () =>
  createSelector(selectAgenciesDomain, subState => {
    const agreements = get(subState, 'agreements', []);
    const groupedAgreements = groupBy(agreements, 'connection.id');
    Object.keys(groupedAgreements).forEach(connectionId => {
      const allConnectionAgreements = groupedAgreements[connectionId];
      set(groupedAgreements, connectionId, groupArtistAgreementsByProfession(allConnectionAgreements));
    });

    let unacknowledgedArtists = [];
    const artists = getWithDefault(subState, 'artists', []).reduce((allArtists, artistInfo) => {
      const { acknowledged } = artistInfo;
      const multiplyByProfessions = (artistInfo?.agencyConnectionProfessions || [])?.map(pr => {
        const artistDuplicate = {
          ...artistInfo,
          connectionProfession: pr,
          professionAgreements: getWithDefault(groupedAgreements, `${artistInfo?.id}.${pr?.id}`, []),
        };
        return artistDuplicate;
      });

      if (!acknowledged) {
        unacknowledgedArtists = [...unacknowledgedArtists, ...multiplyByProfessions];
      }
      return acknowledged ? [...allArtists, ...multiplyByProfessions] : allArtists;
    }, []);
    const sortSectionByName = section => {
      const updatedSection = {};
      for (const key in section) {
        updatedSection[key] = sortBy(section[key], 'artist.profile.lastName');
      }
      return updatedSection;
    };
    // set agreements
    artists.forEach(artist => {
      const updatedArtist = artist;
      const artistAgreements = artist?.professionAgreements;
      remove(artistAgreements, { agreementType: AGREEMENTS_TYPES.BOTH }); // for now we don`t show such agreements

      updatedArtist.agreements = artistAgreements.map(agreement => {
        const { included, excluded } = separateExcludedIncludedCountries([
          ...get(agreement, 'countries', []),
          ...get(agreement, 'regions', []),
        ]);
        return {
          ...agreement,
          includedCountries: included,
          excludedCountries: excluded,
        };
      });
    });
    // group artists
    const nonParentProfessions = artists?.filter(artist => !artist?.connectionProfession?.profession?.parentProfession);
    const groupedProfessions = groupBy(nonParentProfessions, 'connectionProfession.profession.id');
    const grouppedParentProfessions = groupBy(artists, 'connectionProfession.profession.parentProfession.id');
    const singers = grouppedParentProfessions[SINGER_PROFESSION_TYPE_ID];
    const instrumentalist = grouppedParentProfessions[INSTRUMENTALIST_PROFESSION_TYPE_ID];
    const voiceTypes = groupBy(singers, 'connectionProfession.profession.id');
    const instrumentTypes = groupBy(instrumentalist, 'connectionProfession.profession.id');
    let undefinedProfession = [];

    if (voiceTypes.undefined) {
      undefinedProfession = undefinedProfession.concat(voiceTypes.undefined);
      delete voiceTypes.undefined;
    }

    if (instrumentTypes.undefined) {
      undefinedProfession = undefinedProfession.concat(instrumentTypes.undefined);
      delete instrumentTypes.undefined;
    }

    if (groupedProfessions.undefined) {
      undefinedProfession = undefinedProfession.concat(groupedProfessions.undefined);
      delete groupedProfessions.undefined;
    }

    undefinedProfession = [
      ...undefinedProfession,
      ...subState?.artists?.filter(artist => !artist.agencyConnectionProfessions?.length > 0),
    ];

    const undefinedProfessionToSet =
      undefinedProfession.length > 0 ? { 'profession not specified': undefinedProfession } : {};

    const unacknowledgedArtistsToSet = unacknowledgedArtists.length > 0 ? { list: unacknowledgedArtists } : {};
    return {
      voices: sortSectionByName(voiceTypes),
      instrumentalist: sortSectionByName(instrumentTypes),
      professions: sortSectionByName(groupedProfessions),
      notSpecified: sortSectionByName(undefinedProfessionToSet),
      unacknowledgedArtists: sortSectionByName(unacknowledgedArtistsToSet),
    };
  });

const selectAgreements = () => createSelector(selectAgenciesDomain, subState => get(subState, 'agreements'));

const selectAgencyCountries = () =>
  createSelector(selectAgenciesDomain, subState => get(subState, 'agencyCountries.data', []));

const selectAgencyAgents = () => createSelector(selectAgenciesDomain, subState => get(subState, 'agencyAgents'));
const selectAgencyAgentsOptions = () =>
  createSelector(selectAgenciesDomain, subState =>
    createSelectFieldOptions(get(subState, 'agencyAgents'), 'id', 'agent.profile.name', {}, true),
  );

const selectAgencyCountriesOptions = () =>
  createSelector(selectAgenciesDomain, subState =>
    createSelectFieldOptions(get(subState, 'agencyCountries.data', []), 'id', 'name', {}, true),
  );

const selectUserProfileAgentInfo = () =>
  createSelector(selectAgenciesDomain, subState => get(subState, 'agentInfoOfUserProfile'));

const selectIfArtistInRoster = () =>
  createSelector(selectAgenciesDomain, subState => get(subState, 'artistInRosterData'));

const selectArtistInfo = () => createSelector(selectAgenciesDomain, subState => get(subState, 'artistInfo'));

const selectSingleArtistInfoInitialValues = () =>
  createSelector(selectAgenciesDomain, subState => {
    const {
      LAST_NAME,
      FIRST_NAME,
      EMAIL,
      PHONE,
      PROFESSIONS,
      ARTIST_PROFESSIONS,
      AGENCY_PROFESSIONS,
    } = commonArtistFields;

    const artistInfo = get(subState, 'artistInfo');
    const groupedAgreements = groupArtistAgreementsByProfession(artistInfo?.agreements);

    const artistProfessionMetaData = artistInfo?.artist?.profile?.professions?.reduce(
      (res, { id, name, parentProfession }) => {
        let type = 'profession';
        if (parentProfession?.id === SINGER_PROFESSION_TYPE_ID) {
          type = 'voice';
        } else {
          type = 'instrument';
        }
        res[id] = { value: id, label: name, type: 'profession', selected: false, type };

        return res;
      },
      {},
    );
    const agencyProfessionMetaData = artistInfo?.agencyConnectionProfessions?.reduce((res, { profession }) => {
      if (!artistProfessionMetaData?.[profession?.id]) {
        let type = 'profession';
        if (profession?.parentProfession?.id === SINGER_PROFESSION_TYPE_ID) {
          type = 'voice';
        } else {
          type = 'instrument';
        }
        res[profession.id] = {
          value: profession?.id,
          label: profession?.name,
          selected: false,
          type,
        };
        return res;
      }

      return res;
    }, {});

    const artistProfessions = artistInfo?.artist?.profile?.professions?.map(({ id, name, parentProfession }) => {
      let type = 'profession';
      if (parentProfession?.id === SINGER_PROFESSION_TYPE_ID) {
        type = 'voice';
      } else {
        type = 'instrument';
      }

      return {
        value: id,
        label: name,
        type,
      };
    });

    let agencyProfessions = artistInfo?.agencyConnectionProfessions?.map(({ profession, parentProfession }) => {
      let type = 'profession';
      if (parentProfession?.id === SINGER_PROFESSION_TYPE_ID) {
        type = 'voice';
      } else {
        type = 'instrument';
      }

      return {
        value: profession?.id,
        label: profession?.name,
        type,
      };
    });

    agencyProfessions = agencyProfessions?.filter(aProf => !artistProfessionMetaData[aProf?.value]);

    const selectedArtistProfession = [];
    const selectedAgencyProfession = [];

    const profession = (artistInfo?.agencyConnectionProfessions || []).map(pr => {
      const profId = pr?.profession?.id;
      if (artistProfessionMetaData?.[profId]) {
        artistProfessionMetaData[profId].selected = true;
        selectedArtistProfession.push(profId.toString());
      }

      if (agencyProfessionMetaData?.[profId]) {
        agencyProfessionMetaData[profId].selected = true;
        selectedAgencyProfession.push(profId.toString());
      }

      return {
        id: pr?.id,
        existProfession: pr?.profession,
        [professionAgreementsFormFields.AGREEMENTS_DATA]: getProfessionAgreementsFormValues(
          get(groupedAgreements, pr?.id, []),
        ),
      };
    });

    return {
      artistData: artistInfo,
      artistProfessionList: artistProfessions,
      agencyProfessionList: agencyProfessions,
      artistProfessionMetaData,
      agencyProfessionMetaData,
      id: artistInfo?.id,
      [LAST_NAME]: getWithDefault(artistInfo, 'artist.profile.lastName', ''),
      [FIRST_NAME]: getWithDefault(artistInfo, 'artist.profile.firstName', ''),
      [EMAIL]: getWithDefault(artistInfo, 'artist.email', ''),
      [PHONE]: getWithDefault(artistInfo, 'artist.phoneNumber', ''),
      [ARTIST_PROFESSIONS]: selectedArtistProfession ?? [],
      [AGENCY_PROFESSIONS]: selectedAgencyProfession ?? [],
      [PROFESSIONS]: profession,
    };
  });

const selectArtistsSuggestinsList = () =>
  createSelector(selectAgenciesDomain, subState => {
    const artistsSuggestions = get(subState, 'artistsSuggestionsList');
    const artistsList = get(artistsSuggestions, 'artists', []);
    const artistsInAgency = get(artistsSuggestions, 'agencyArtists', []);
    const updatedArtists = artistsList.map(artist => {
      const artistData = artistsInAgency.find(ar => get(ar, 'artist.profile.id') === get(artist, 'id'));
      const isInAgencyRoster = Boolean(artistData);
      const additionalInfo = { isInRoster: isInAgencyRoster };
      if (isInAgencyRoster) {
        additionalInfo.connection_id = get(artistData, 'id');
      }
      return { ...artist, ...additionalInfo };
    });
    return updatedArtists;
  });

const selectArtistsSuggestinsListLoading = () =>
  createSelector(selectAgenciesDomain, subState => get(subState, 'artistsSuggestionsListLoading'));

const selectAgencyUpdating = () => createSelector(selectAgenciesDomain, subState => get(subState, 'agencyUpdating'));

const selectCheckingArtistInAgencyState = () =>
  createSelector(selectAgenciesDomain, subState => get(subState, 'checkArtistInAgencyLoading'));

export {
  selectAgencyInfo,
  selectOffices,
  selectArtists,
  selectArtistsArray,
  selectAgreements,
  selectAgencyCountries,
  selectAgencyCountriesOptions,
  selectUserProfileAgentInfo,
  selectIfArtistInRoster,
  selectSingleArtistInfoInitialValues,
  selectAgencyAgents,
  selectAgencyAgentsOptions,
  selectArtistsSuggestinsList,
  selectArtistsSuggestinsListLoading,
  selectAgencyContacts,
  selectAgencyUpdating,
  selectCheckingArtistInAgencyState,
  selectArtistInfo,
};
