import { fetchAbortController, conditionallyRemoveViewByFilters } from '../../utils/common';
import { getAggregations as getAggregationsAPI } from '../../utils/API';
import { queryClient as sharedQueryClient, newQueryClient, getLanguage } from '../../utils/queriesUtil';

export const getAggregations = async (apiEndpoint, aggregationParams, pagination, serverCookies) => {
  const abortController = fetchAbortController();
  const signal = abortController ? abortController.signal : null;
  return getAggregationsAPI(apiEndpoint, aggregationParams, pagination, signal, serverCookies).then(
    response => response.data,
  );
};

export const getOptions = async (aggregationQuery, serverCookies, language) => {
  const { aggregation_type } = aggregationQuery?.query;
  const pagination = { limit: aggregation_type === 'year_id' || aggregation_type === 'season_id' ? 1000 : 10 };
  const validQuery = conditionallyRemoveViewByFilters(aggregationQuery.query);
  const queryKey = [getLanguage(language), 'GET_AGGREGATION', JSON.stringify({ ...validQuery })];
  const isServerSide = typeof window === 'undefined';
  const queryClient = isServerSide ? newQueryClient() : sharedQueryClient;
  if (typeof queryClient.getQueryData(queryKey) !== 'undefined') {
    return Promise.resolve(queryClient.getQueryData(queryKey));
  }
  const optionsPromise = await queryClient.fetchQuery(
    queryKey,
    async () => getAggregations(aggregationQuery.endpoint, validQuery, pagination, serverCookies),
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      initialStale: true,
      onSuccess: () => {},
    },
  );
  return optionsPromise;
};
