import { createRoutine } from 'redux-saga-routines';

import {
  GET_COMPOSER,
  GET_UPCOMMING_PRODUCTIONS,
  GET_COMPOSER_WORKS,
  GET_COMPOSER_IMAGES,
  GET_COMPOSER_VIDEOS,
  GET_COMPOSER_PRODUCTIONS,
  GET_COMPOSER_REVIEWS,
} from './constants';

export const getComposerAction = createRoutine(GET_COMPOSER);
export const getComposerWorksAction = createRoutine(GET_COMPOSER_WORKS);
export const getComposerReviewsAction = createRoutine(GET_COMPOSER_REVIEWS);
export const getComposerImagesAction = createRoutine(GET_COMPOSER_IMAGES);
export const getComposerVideosAction = createRoutine(GET_COMPOSER_VIDEOS);
export const getComposerProductionsAction = createRoutine(GET_COMPOSER_PRODUCTIONS);
export const getUpcomingProductionsAction = createRoutine(GET_UPCOMMING_PRODUCTIONS);
