import axios from 'axios';
import HttpAgent, { HttpsAgent } from 'agentkeepalive';
import { stringify } from 'query-string';
import { set, get, has, cloneDeep } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import { sentryCaptureException, sentrySanitizeUrl } from 'utils/globals/sentry';

import { DATE_FORMATS } from 'constants/index';

import HttpError from './HttpError';
import { i18n } from '../src/i18n';
import { createDate } from './date';

const axiosInstances = {};

export const getHttpInstance = host => {
  if (!host) {
    throw new Error('Missing host for http instance');
  }

  if (!axiosInstances[host]) {
    if (typeof window !== 'undefined') {
      axiosInstances[host] = axios.create();
    } else {
      const httpAgent = new HttpAgent({ keepAlive: true, maxSockets: 64 });
      const httpsAgent = new HttpsAgent({ keepAlive: true, maxSockets: 64 });

      axiosInstances[host] = axios.create({
        httpAgent,
        httpsAgent,
      });
    }
  }

  return axiosInstances[host];
};

export const fetchJson = (url, opt = { credentials: 'include' }) => {
  const options = cloneDeep(opt);
  const headersObject = getRequestHeaders(options);
  delete options.user;
  if (options.files) {
    const formData = new FormData();
    formData.append('body', options.body);
    Object.keys(options.files).forEach(key => {
      if (options.files[key]) {
        formData.append(key, options.files[key]);
      }
    });
    delete options.files;
    options.body = formData;
  }
  const httpInstance = getHttpInstance(new URL(url).origin);
  return httpInstance(url, {
    ...options,
    data: options.body,
    headers: headersObject,
  })
    .then(response => ({
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      body: response.data,
    }))
    .catch(error => {
      const errorInfo = {
        url: url || error.url,
        method: error.method,
        statusText: get(error.response, 'statusText', error.errno),
        headers: headersObject,
        status: parseInt(get(error.response, 'status', error.code), 10) || 421,
        body: get(error.response, 'body', null),
        responseText: get(error.response?.data, 'message', ''),
        expiryTime: get(error.response?.data, 'endTime', ''),
      };

      checkAuthStatus(errorInfo.status);

      const err = HttpError(errorInfo, error);

      if (errorInfo && errorInfo.status >= 400 && errorInfo.status !== 404) {
        const errorMessage = error.response?.data?.message || error?.response?.statusText;
        const apiPath = sentrySanitizeUrl(url);
        const exceptionError = new Error(`${errorMessage} on ${apiPath}`);
        exceptionError.status = err?.response?.status || error?.code;
        exceptionError.url = url;
        exceptionError.method = error?.method;

        sentryCaptureException(exceptionError, {
          extra: {
            errorMessage,
            url,
            statusCode: error?.status,
            error: err,
            ...(options.body && { payload: options.body }),
          },
        });
      }
      if (errorInfo && errorInfo.status === 503) {
        const endDateTime = createDate(errorInfo.expiryTime).format(DATE_FORMATS.FULL_DATE_TIME);
        enqueueSnackbar(`${errorInfo.responseText} ${endDateTime}`, {
          variant: 'error',
          preventDuplicate: true,
        });
      }

      return Promise.reject(err);
    });
};

export const checkAuthStatus = status => {
  // NOTE: run only on browser environment
  if (status === 401 && typeof window !== 'undefined') {
    console.error('Forcing logout');
    window.location.href = `/auth/logout?lang=${i18n.language}`;
  }
};

const getRequestHeaders = options => {
  const requestHeaders = options.headers || { Accept: 'application/json; charset=utf-8' };
  if (!has(requestHeaders, 'Content-Type') && options && options.body) {
    if (!options.files) {
      set(requestHeaders, 'Content-Type', 'application/json; charset=utf-8');
    }
  }
  return requestHeaders;
};

export const queryParameters = stringify;

const isValidObject = value => {
  if (!value) {
    return false;
  }

  const isArray = Array.isArray(value);
  const isBuffer = Buffer.isBuffer(value);
  const isObject = Object.prototype.toString.call(value) === '[object Object]';
  const hasKeys = !!Object.keys(value).length;

  return !isArray && !isBuffer && isObject && hasKeys;
};

export const flattenObject = (value, path = []) => {
  if (isValidObject(value)) {
    return Object.assign({}, ...Object.keys(value).map(key => flattenObject(value[key], path.concat([key]))));
  }
  if (Array.isArray(value)) {
    return { [path]: value.join(',') };
  }
  return path.length ? { [path.join('.')]: value } : value;
};
