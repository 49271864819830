import React, { forwardRef, useCallback, useMemo } from 'react';
import {
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarContent,
  useSnackbar,
  enqueueSnackbar,
} from 'notistack';
import classNames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Link from 'components/uiLibrary/Link';

import classes from './Snackbar.module.scss';

const snackbarAnchorOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const SNACKBAR_AUTO_HIDE_DURATION = 4000;

const SNACKBAR_VARIANTS = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

const snackbarClasses = {
  root: classes.snackbarRoot,
  containerRoot: classes.snackbarContainer,
};

const MAX_NO_OF_SNACKBAR = 3;

const SnackbarProvider = ({ children }) => (
  <NotistackSnackbarProvider
    maxSnack={MAX_NO_OF_SNACKBAR}
    anchorOrigin={snackbarAnchorOrigin}
    classes={snackbarClasses}
    autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
    Components={{
      [SNACKBAR_VARIANTS.SUCCESS]: Snackbar,
      [SNACKBAR_VARIANTS.DEFAULT]: Snackbar,
      [SNACKBAR_VARIANTS.ERROR]: Snackbar,
      [SNACKBAR_VARIANTS.WARNING]: Snackbar,
      [SNACKBAR_VARIANTS.INFO]: Snackbar,
    }}
  >
    {children}
  </NotistackSnackbarProvider>
);

const Snackbar = forwardRef(
  (
    { id, message, variant = SNACKBAR_VARIANTS.DEFAULT, hasCloseIcon = true, hasVariantIcon = true, actionData },
    ref,
  ) => {
    const icons = useMemo(
      () => ({
        [SNACKBAR_VARIANTS.DEFAULT]: (
          <SpriteIcon icon="check_circle_black_24dp" className={classNames(classes.icon, classes.successIcon)} />
        ),
        [SNACKBAR_VARIANTS.SUCCESS]: (
          <SpriteIcon icon="check_circle_black_24dp" className={classNames(classes.icon, classes.successIcon)} />
        ),
        [SNACKBAR_VARIANTS.ERROR]: <SpriteIcon icon="error" className={classNames(classes.icon, classes.errorIcon)} />,
        [SNACKBAR_VARIANTS.WARNING]: (
          <SpriteIcon icon="warning" className={classNames(classes.icon, classes.warningIcon)} />
        ),
        [SNACKBAR_VARIANTS.INFO]: (
          <SpriteIcon icon="warning" className={classNames(classes.icon, classes.warningIcon)} />
        ),
      }),
      [],
    );

    const { closeSnackbar } = useSnackbar();
    const handleCloseSnackbar = useCallback(() => closeSnackbar(id), [closeSnackbar, id]);

    return (
      <SnackbarContent ref={ref}>
        <div className={classes.snackbar}>
          {hasVariantIcon && <div className={classes.iconContainer}>{icons[variant]}</div>}
          <div className={classes.message}>{message}</div>
          {actionData && (
            <span className={classes.actionContainer}>
              <Link href={actionData?.url} disableUnderline>
                {actionData?.label}
              </Link>
              <SpriteIcon icon="chevron_right" className={classes.closeIcon} />
            </span>
          )}
          {hasCloseIcon && !actionData && (
            <SpriteIcon icon="close" className={classes.closeIcon} aria-hidden onClick={handleCloseSnackbar} />
          )}
        </div>
      </SnackbarContent>
    );
  },
);

export { useSnackbar, enqueueSnackbar, SNACKBAR_VARIANTS };
export default SnackbarProvider;
