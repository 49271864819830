import { useEffect } from 'react';
import useAppContext from './useAppContext';

export const useScrollBlocker = (isBlocking = false) => {
  const { scrollBarWidth } = useAppContext();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return () => {};
    }

    document.body.style.overflow = isBlocking ? 'hidden' : '';
    document.body.style.paddingRight = isBlocking ? `${scrollBarWidth}px` : '';

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, [isBlocking, scrollBarWidth]);
};

export default useScrollBlocker;
