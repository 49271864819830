import { takeLatest, put, call } from 'redux-saga/effects';
import { getArticle as getArticleAPI } from '../../utils/API';

import { getArticle } from './actions';
import get from 'lodash/get';

export function* getArticleRequest({ payload, serverCookies }) {
  try {
    const response = yield call(getArticleAPI, payload.articleId, serverCookies);
    yield put(getArticle.success(get(response, 'data')));
  } catch (err) {
    yield put(getArticle.failure(err.message));
  } finally {
    yield put(getArticle.fulfill());
  }
}

export default function* articleSaga() {
  yield takeLatest(getArticle.TRIGGER, getArticleRequest);
}
