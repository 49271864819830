import React from 'react';
import PropTypes from 'prop-types';

import LinkButton from 'components/uiLibrary/LinkButton';

const Link = ({
  href,
  children,
  className,
  target,
  disabled,
  rel,
  startIcon,
  endIcon,
  title,
  onClick,
  ariaLabel,
  stopPropagation,
  tabIndex,
  external = false,
  download = false,
  disableUnderline,
  iconStyle,
  disableHover,
  ...rest
}) => (
  <LinkButton
    isLink
    overrideButtonStyles
    href={href}
    target={target}
    disabled={disabled}
    rel={rel}
    leftIcon={startIcon}
    rightIcon={endIcon}
    title={title}
    onClick={onClick}
    variant="text"
    ariaLabel={ariaLabel}
    stopPropagation={stopPropagation}
    tabIndex={tabIndex}
    external={external}
    download={download}
    disableUnderline={disableUnderline}
    disableHover={disableHover}
    styles={{ root: className, icon: iconStyle }}
    {...rest}
  >
    {children}
  </LinkButton>
);

Link.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  target: PropTypes.string,
  disabled: PropTypes.bool,
  rel: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  title: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  stopPropagation: PropTypes.bool,
  tabIndex: PropTypes.number,
};

Link.defaultProps = {
  disabled: false,
  rel: '',
  target: '_self',
  tabIndex: 0,
};

export default Link;
