import React, { useCallback, useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';

import Modal from 'components/uiLibrary/Modal';
import Image from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import LinkButton, { SecondaryButton } from 'components/uiLibrary/LinkButton';
import Loader from 'components/uiLibrary/Loader';
import { SECTIONS, COMPONENTS, ACTION_CALLBACK_STEPS } from 'components/Globals/Analytics/constants';

import useAppContext from 'utils/hooks/useAppContext';
import useLanguageDisplayLabel from 'utils/hooks/useLanguageDisplayLabel';
import usePageContext from 'utils/hooks/usePageContext';
import useTracking from 'components/Globals/Analytics';

import { allLanguageCodes } from 'constants/languageCodes';
import { TP } from 'constants/index';

import LogoImage from 'public/images/logo.png';
import { useTranslation } from 'src/i18n';

import CommonTrans from '../CommonTrans';
import classes from './LanguageSwitchModal.module.scss';

const SwitchLanguageOptions = ({ selectedLanguage, onChangeLanguage }) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const getLanguageLabel = useLanguageDisplayLabel();
  const { getSuggestedLanguages } = useAppContext();
  const { suggestedLanguageOptions, otherLanguageOptions } = useMemo(() => {
    const suggestedLanguages = getSuggestedLanguages();
    const suggestedLanguageISOCodes = suggestedLanguages.map(lang => lang.iso);

    return {
      suggestedLanguageOptions: suggestedLanguages.filter(lang => lang.iso !== selectedLanguage?.iso),
      otherLanguageOptions: allLanguageCodes.filter(
        lang => !suggestedLanguageISOCodes.includes(lang.iso) && lang.iso !== selectedLanguage?.iso,
      ),
    };
  }, [selectedLanguage, getSuggestedLanguages]);

  const onExpandLanguages = useCallback(() => {
    track.click({
      section: SECTIONS.LANGUAGE_MODAL,
      component: COMPONENTS.LANGUAGE_SHOW_EXTRA,
    });
    setShowAll(true);
  }, [track]);

  return (
    <div className={classes.switchLanguage}>
      <Typography variant="p">{t(`${TP}.FN_LANGUAGE_EXPLORE_OTHER`)}</Typography>
      <div className={classes.switchLanguage__quick_options}>
        {suggestedLanguageOptions?.map(language => (
          <SecondaryButton
            key={language.iso}
            size="small"
            onClick={() => onChangeLanguage(language, COMPONENTS.LANGUAGE_SUGGESTED_AS_PREFERRED)}
          >
            {getLanguageLabel(language)}
          </SecondaryButton>
        ))}
        {showAll &&
          otherLanguageOptions?.map(language => (
            <SecondaryButton
              key={language.iso}
              size="small"
              onClick={() => onChangeLanguage(language, COMPONENTS.LANGUAGE_EXTRA_AS_PREFERRED)}
            >
              {getLanguageLabel(language)}
            </SecondaryButton>
          ))}
      </div>

      {!showAll && (
        <LinkButton variant="text" size="small" onClick={onExpandLanguages}>
          <Typography size="12" color="link">
            {t(`${TP}.FN_LANGUAGE_MORE_COUNT`, { count: otherLanguageOptions.length })}
          </Typography>
        </LinkButton>
      )}
    </div>
  );
};

const ModalWrapper = ({ isOpen, selectedLanguage, onChangeLanguage, isSwitchingLanguage, children }) => (
  <Modal isOpen={isOpen} styles={{ modalRoot: classes.modalRoot, drawerRoot: classes.modalRoot }} allowMobileDrawer>
    <div className={classnames(classes.root, { [classes.root_isLoading]: isSwitchingLanguage })}>
      <div className={classes.header}>
        <Image src={LogoImage} alt="Operabase Logo" height={14} width={140} />
      </div>

      {children}
      <SwitchLanguageOptions selectedLanguage={selectedLanguage} onChangeLanguage={onChangeLanguage} />
      {isSwitchingLanguage && <Loader className={classes.loader} />}
    </div>
  </Modal>
);

const SELECTED_LANGUAGE_TYPE = {
  CURRENT: 'CURRENT',
  PREFERRED: 'PREFERRED',
  LAST_ACCESSED: 'LAST_ACCESSED',
  OTHER: 'OTHER',
};

const LanguageSwitchModal = () => {
  const [isSwitchingLanguage, setIsSwitchingLanguage] = useState(false);
  const getLanguageLabel = useLanguageDisplayLabel();
  const { navigate, pageURL } = usePageContext();
  const { language, preferredLanguage, lastAccessedLanguage, languageSwitcher } = useAppContext();
  const track = useTracking();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isOpen, onSetPreferredLanguage } = languageSwitcher;

  useEffect(() => {
    if (isOpen) {
      track.actionCallback({
        section: SECTIONS.LANGUAGE_MODAL,
        meta: {
          step: ACTION_CALLBACK_STEPS.SHOW_LANGUAGE_MODAL,
          isOpen: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onChangeLanguage = useCallback(
    (lang, sourceComponent) => {
      if (typeof window === 'undefined' && !lang.iso) {
        return;
      }

      setIsSwitchingLanguage(true);
      onSetPreferredLanguage(lang.iso);

      let type = SELECTED_LANGUAGE_TYPE.OTHER;

      // NOTE: Order of IF statements is important, placed according to priority
      if (lang.iso === language) {
        type = SELECTED_LANGUAGE_TYPE.CURRENT;
      }

      if (lang.iso === lastAccessedLanguage) {
        type = SELECTED_LANGUAGE_TYPE.LAST_ACCESSED;
      }

      if (lang.iso === preferredLanguage) {
        type = SELECTED_LANGUAGE_TYPE.PREFERRED;
      }

      track.click({
        section: SECTIONS.LANGUAGE_MODAL,
        component: sourceComponent,
        meta: {
          id: lang.iso,
          type,
        },
      });

      const [path, query] = navigate
        .removeLocaleFromPath(pageURL)
        ?.replace(/\/$/, '')
        ?.split('?');

      window.location.href = `${path}/${lang.iso}${query ? `?${query}` : ''}`;
    },
    [onSetPreferredLanguage, language, lastAccessedLanguage, preferredLanguage, track, navigate, pageURL],
  );

  const { renderedLanguageOption, preferredLanguageOption } = useMemo(
    () =>
      allLanguageCodes.reduce((acc, lang) => {
        const isPreferredLanguage = lang.iso === preferredLanguage;
        const isRenderedLanguage = lang.iso === language;

        if (isPreferredLanguage) {
          acc.preferredLanguageOption = lang;
        }

        if (isRenderedLanguage) {
          acc.renderedLanguageOption = lang;
        }

        return acc;
      }, {}),
    [language, preferredLanguage],
  );

  const isMissingPreferredLanguage = !preferredLanguage;
  const renderedLanguageLabel = getLanguageLabel(renderedLanguageOption, true);
  const preferredLanguageLabel = getLanguageLabel(preferredLanguageOption, true);

  if (isMissingPreferredLanguage) {
    return (
      <ModalWrapper
        isOpen={isOpen}
        selectedLanguage={renderedLanguageOption}
        onChangeLanguage={onChangeLanguage}
        isSwitchingLanguage={isSwitchingLanguage}
      >
        <>
          <Typography variant="p" size={16}>
            <CommonTrans
              ns="NS_APP_GLOBALS"
              i18nKey={`${TP}.FN_LANGUAGE_BROWSING_IN`}
              values={{ language: renderedLanguageLabel }}
            />
          </Typography>

          <div className={classes.actions}>
            <LinkButton
              variant="primary"
              size="large"
              shape="rectangle"
              onClick={() => onChangeLanguage(renderedLanguageOption, COMPONENTS.LANGUAGE_CURRENT_AS_PREFERRED)}
            >
              {t(`${TP}.FN_LANGUAGE_CONTINUE_WITH`, { language: renderedLanguageLabel })}
            </LinkButton>
          </div>
        </>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      selectedLanguage={renderedLanguageOption}
      onChangeLanguage={onChangeLanguage}
      isSwitchingLanguage={isSwitchingLanguage}
    >
      <>
        <Typography size={16}>
          <CommonTrans
            ns="NS_APP_GLOBALS"
            i18nKey={`${TP}.FN_LANGUAGE_PREFERRED`}
            values={{ language: preferredLanguageLabel }}
          />
        </Typography>

        <div className={classes.actions}>
          <LinkButton
            variant="primary"
            size="large"
            shape="rectangle"
            onClick={() => onChangeLanguage(preferredLanguageOption, COMPONENTS.LANGUAGE_SWITCH_TO_PREFERRED)}
          >
            {t(`${TP}.FN_LANGUAGE_SWITCH_TO`, { language: preferredLanguageLabel })}
          </LinkButton>
          <LinkButton
            variant="text"
            size="large"
            shape="rectangle"
            onClick={() => onChangeLanguage(renderedLanguageOption, COMPONENTS.LANGUAGE_CURRENT_AS_PREFERRED)}
          >
            {t(`${TP}.FN_LANGUAGE_CONTINUE_WITH`, { language: renderedLanguageLabel })}
          </LinkButton>
        </div>
      </>
    </ModalWrapper>
  );
};

export default LanguageSwitchModal;
