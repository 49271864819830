import { useQuery } from 'utils/react-query';

import globalQueries from 'containers/Globals/queries';
import orgQueries from 'containers/Organizations/queries';
import artistQueries from 'containers/Artist/queries';

export const useAppliedOrgTypeFilter = filter => {
  const shouldFetchFilterValue = !filter?.name && (!!filter?.id || !!filter?.slug);

  const { data: organizationType } = useQuery(
    orgQueries.getAggregatedOrgTypes({
      queryConfig: {
        enabled: shouldFetchFilterValue,
        select: response => response?.data?.find(({ slug, id }) => filter?.slug === slug || filter?.id === id),
        ...(!shouldFetchFilterValue && filter?.name && filter?.id && { initialData: { data: [filter] } }),
      },
    }),
  );

  return organizationType;
};

export const useAppliedProfessionFilter = filter => {
  const shouldFetchFilterValue = !filter?.name && (!!filter?.id || !!filter?.slug);

  const { data: profession } = useQuery(
    artistQueries.getProfessionDetails({
      filters: {
        ...(filter?.id && { id: filter.id }),
        ...(!filter?.id && filter?.slug && { slug: filter.slug }),
      },
      queryConfig: {
        enabled: shouldFetchFilterValue,
        select: response => response?.data,
        ...(!shouldFetchFilterValue && filter?.name && filter?.id && { initialData: { data: filter } }),
      },
    }),
  );

  return profession;
};

export const useAppliedCountryRegionFilter = filter => {
  const shouldFetchFilterValue = !filter?.name && (!!filter?.id || !!filter?.slug);
  const shouldFetchCountry = shouldFetchFilterValue && filter?.type === 'country';
  const shouldFetchRegion = shouldFetchFilterValue && filter?.type === 'region';

  const { data: country } = useQuery(
    globalQueries.getCountries({
      filters: {
        ...(filter?.id && { id: filter.id }),
        ...(!filter?.id && filter?.slug && { slug: filter.slug }),
      },
      limit: 1,
      queryConfig: {
        enabled: shouldFetchCountry,
        select: response => response?.data?.[0],
        ...(!shouldFetchCountry && filter?.name && filter?.id && { initialData: { data: [filter] } }),
      },
    }),
  );

  const { data: region } = useQuery(
    globalQueries.getRegions({
      filters: {
        ...(filter?.id && { id: filter.id }),
        ...(!filter?.id && filter?.slug && { slug: filter.slug }),
      },
      limit: 1,
      queryConfig: {
        enabled: shouldFetchRegion,
        select: response => response?.data?.[0],
        ...(!shouldFetchRegion && filter?.name && filter?.id && { initialData: { data: [filter] } }),
      },
    }),
  );

  if (shouldFetchRegion) {
    return region;
  }

  return country;
};
