import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { getOnthisdayAction } from './actions';
import { getOnthisday } from '../../utils/API';

export function* fetchOnthisday({ payload: { id, ...query }, serverCookies }) {
  try {
    const response = yield call(getOnthisday, id, query, {}, serverCookies);
    yield put(getOnthisdayAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getOnthisdayAction.failure(err.message));
  } finally {
    yield put(getOnthisdayAction.fulfill());
  }
}
export default function* onThisDaySaga() {
  yield takeLatest(getOnthisdayAction.TRIGGER, fetchOnthisday);
}
