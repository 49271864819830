import React, { useEffect } from 'react';
import { dehydrate, QueryClient } from 'utils/react-query';
import { FpjsProvider, CacheLocation } from '@fingerprintjs/fingerprintjs-pro-react';
import { defaultEndpoint, defaultScriptUrlPattern } from '@fingerprintjs/fingerprintjs-pro';
import createCache from '@emotion/cache';
import { END } from 'redux-saga';
import get from 'lodash/get';

import { useRouter, Router } from 'next/router';
import NProgress from 'nprogress';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

import PaywallModal from 'components/Globals/FootPrintTracking/PaywallModal';
import AppLayout from 'components/Globals/Layout/AppLayout';
import ReactQueryProvider from 'components/Globals/Layout/ReactQueryProvider';
import PwaTracker from 'components/PwaTracker';
import TrackingTester from 'components/Globals/TrackingTester';
import { QuickViewProvider } from 'components/Globals/Layout/QuickView';
import { AnalyticsProvider } from 'components/Globals/Analytics';
import LanguageSwitchModal from 'components/Globals/LanguageSwitchModal';

import { LayoutContextProvider } from 'utils/hooks/useDeviceTypeLayouts';
import { PageContextProvider } from 'utils/hooks/usePageContext';
import { PaywallContextProvider } from 'utils/hooks/usePaywallContext';
import { AppContextProvider } from 'utils/hooks/useAppContext';
import useCustomWindowOpen from 'utils/hooks/useCustomWindowOpen';
import { debugSSR } from 'utils/globals/app';
import AppVersionCheck from 'utils/hocs/AppVersionCheck';
import {
  OB_UTM,
  OB_VISITOR_ID,
  OB_VISITOR_LOCATION,
  USER_SESSION_INFO,
  LANGUAGE_PREFERRED,
  LANGUAGE_LAST_ACCESSED,
} from 'constants/cookieConstants';
import QUERIES from 'utils/globals/ssrQueries/queries';
import { fetchPrerequisiteQueries, fetchQueryPromises } from 'utils/globals/ssrQueries';
import '../styles/globals.scss';

import { COOKIE_NAME, parseCookieValue } from 'server/middlewares/fingerprintMiddleware';
import { appWithTranslation, getServerLanguage } from '../src/i18n';
import { getCountriesAction, getUserConstants, setLanguageAction } from '../store/actions';
import { getCookiesOnServer } from '../utils/auth';
import { trackUserActivityEvents } from '../utils/userActivityEvents';
import { wrapper } from '../store/store';
import { getCookie, processServerCookies } from '../utils/cookie';
import { allLanguageCodes } from '../constants/languageCodes';

import 'dayjs/locale/en';
import 'dayjs/locale/bg';
import 'dayjs/locale/da';
import 'dayjs/locale/es';
import 'dayjs/locale/fi';
import 'dayjs/locale/hu';
import 'dayjs/locale/lt';
import 'dayjs/locale/nl';
import 'dayjs/locale/pt';
import 'dayjs/locale/sk';
import 'dayjs/locale/ca';
import 'dayjs/locale/de';
import 'dayjs/locale/et';
import 'dayjs/locale/fr';
import 'dayjs/locale/is';
import 'dayjs/locale/lv';
import 'dayjs/locale/ro';
import 'dayjs/locale/sl';
import 'dayjs/locale/cs';
import 'dayjs/locale/el';
import 'dayjs/locale/eu';
import 'dayjs/locale/ga';
import 'dayjs/locale/it';
import 'dayjs/locale/mt';
import 'dayjs/locale/pl';
import 'dayjs/locale/ru';
import 'dayjs/locale/sv';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh';
import 'dayjs/locale/ko';
import 'dayjs/locale/nb';
import 'react-dropdown-tree-select/dist/styles.css';
import '../styles/styles.scss';
import 'react-day-picker/lib/style.css';
import 'nprogress/nprogress.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/variables.scss';
import 'overlayscrollbars/styles/overlayscrollbars.css';

dayjs.extend(localeData);

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// NOTE: Client-side cache, shared for the whole session of the user in the browser.
let muiCache;
let tssStylesCache;
// NOTE: Client-side cache, shared for the whole session of the user in the browser.
/* eslint-disable */
export const createMuiCache = () =>
  (muiCache = createCache({
    key: 'mui-cache',
    prepend: true,
  }));
export const createTssCache = () =>
  (tssStylesCache = createCache({
    key: 'tss-cache',
    prepend: false,
  }));
/* eslint-enable */

const fingerPrintApiKey = process.env.FINGER_PRINT_SECRET_ACCESS_KEY_ID;
const isFingerPrintEnabled = process.env.FINGER_PRINT_ENABLED === 'true';
const fingerprintScriptUrl = process.env.FINGER_PRINT_SCRIPT_URL;

const addDayjsLocale = locale => {
  // NOTE: check if datetime locale override is present
  const selectedAppLanguage = allLanguageCodes.find(o => o.iso === locale);
  const dateTimeLocale = get(selectedAppLanguage, 'datetimeLocale', locale);

  dayjs.locale(dateTimeLocale);
};

function OBApp({ Component, pageProps, emotionCache, tssCache }) {
  const router = useRouter();

  useEffect(() => {
    if (pageProps?.redirect?.destination) {
      router.replace(pageProps?.redirect?.destination);
    }
  }, [router, pageProps.redirect]);

  useCustomWindowOpen({ isEnabled: process.env.APP_ENV !== 'production' });

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.debugSSR = () => debugSSR(pageProps?.dehydratedState?.queries);
    }

    trackUserActivityEvents();

    // NOTE: Only to be executed once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: Cannot use effect as should wait for locale before render
  if (pageProps?.locale && pageProps?.locale !== dayjs.locale() && pageProps?.locale !== 'keys') {
    addDayjsLocale(pageProps?.locale);
  }

  if (pageProps?.redirect?.destination) {
    return null;
  }

  const fingerprint = parseCookieValue(getCookie(COOKIE_NAME));
  const isTrustedFromCookies = fingerprint?.isBot && fingerprint.trusted === 'yes';
  const fingerPrintEndPoints = [process.env.FINGER_PRINT_API_URL, defaultEndpoint];
  const fingerPrintScriptEndPoints = [fingerprintScriptUrl, defaultScriptUrlPattern];
  const enableFingerPrintProvider =
    fingerPrintApiKey && isFingerPrintEnabled && pageProps?.isTrustedBot === undefined
      ? !isTrustedFromCookies
      : !pageProps?.isTrustedBot;

  const APP = (
    <ReactQueryProvider dehydratedState={pageProps?.dehydratedState}>
      <AppContextProvider
        sessionUser={pageProps?.user}
        locale={pageProps?.locale}
        isTrustedBot={isTrustedFromCookies || pageProps?.isTrustedBot}
        serverCookies={pageProps?.serverCookies}
      >
        <AppLayout
          tssCache={tssCache ?? tssStylesCache ?? createTssCache()}
          emotionCache={emotionCache ?? muiCache ?? createMuiCache()}
        >
          <LayoutContextProvider userAgent={pageProps?.userAgent}>
            <PageContextProvider
              isFingerPrintEnabled={enableFingerPrintProvider}
              serverCookies={pageProps?.serverCookies}
            >
              <AnalyticsProvider pageCategory={pageProps?.pageCategory} pageType={pageProps?.pageType}>
                <PaywallContextProvider>
                  <TrackingTester>
                    <PaywallModal>
                      <QuickViewProvider>
                        <AppVersionCheck>
                          <Component {...pageProps} />
                          <PwaTracker />
                          <LanguageSwitchModal />
                        </AppVersionCheck>
                      </QuickViewProvider>
                    </PaywallModal>
                  </TrackingTester>
                </PaywallContextProvider>
              </AnalyticsProvider>
            </PageContextProvider>
          </LayoutContextProvider>
        </AppLayout>
      </AppContextProvider>
    </ReactQueryProvider>
  );

  if (enableFingerPrintProvider) {
    return (
      <FpjsProvider
        loadOptions={{
          apiKey: fingerPrintApiKey,
          ...(process.env.NODE_ENV === 'production' && { endpoint: fingerPrintEndPoints }),
          ...(process.env.NODE_ENV === 'production' && { scriptUrlPattern: fingerPrintScriptEndPoints }),
        }}
        cacheLocation={CacheLocation.LocalStorage}
      >
        {APP}
      </FpjsProvider>
    );
  }

  return APP;
}

OBApp.getInitialProps = async ({ Component, ctx }) => {
  // NOTE: set the logged-in state
  const { store } = ctx;
  const userAgent = ctx?.req?.headers['user-agent'];
  const isTrustedBot = (ctx?.req?.fingerprint?.isBot && ctx?.req?.fingerprint?.trusted === 'yes') || undefined;
  const isNextDataRequest = ctx?.req?.nextdata || false;
  const language = getServerLanguage(ctx?.req);
  const cookies = getCookiesOnServer(ctx);
  const state = store.getState();

  let isLoggedIn = false;
  let user = null;
  if (ctx?.req?.session) {
    const userSession = ctx?.req?.session?.get(USER_SESSION_INFO);
    isLoggedIn = userSession?.isLoggedIn || false;
    user = userSession?.user;
  }

  if (language && language !== dayjs.locale() && language !== 'keys') {
    addDayjsLocale(language);
  }

  const { countries, userConstants } = state.global || {};

  if (!userConstants?.length) {
    store.dispatch(getUserConstants());
  }

  if (language) {
    store.dispatch(setLanguageAction(language));
  }

  const withGetInitialProps = !!Component.getInitialProps;
  const staticPageCategory = Component?.pageCategory;
  const staticPageType = Component?.pageType;

  const pageProps = {
    ...(withGetInitialProps ? await Component.getInitialProps(ctx) : {}),
  };

  const queryClient = new QueryClient();
  const hasUseQueries = withGetInitialProps && isLoggedIn;
  if (hasUseQueries) {
    const baseUserQueries = [];

    baseUserQueries.push({
      query: QUERIES.ACCOUNTS.GET_USER_PROFILES,
      key: 'userProfiles',
      select: response => response?.data,
    });

    baseUserQueries.push({
      query: QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_ID,
      key: 'savedActiveProfileId',
      select: response => response?.data,
    });

    const { userProfiles, savedActiveProfileId } = await fetchPrerequisiteQueries(queryClient, baseUserQueries, {
      extraParams: { user },
      cookies,
      language,
    });

    if (isLoggedIn && !isNextDataRequest) {
      const userQueries = [
        QUERIES.ACCOUNTS.GET_USER_DETAILS,
        QUERIES.ACCOUNTS.GET_USER_PERMISSIONS,
        QUERIES.ACCOUNTS.GET_USER_SUBSCRIPTIONS,
        QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_DETAILS,
        QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_PERMISSIONS,
        QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_SUBSCRIPTIONS,
      ];

      const promises = fetchQueryPromises(queryClient, userQueries, {
        extraParams: { userProfiles, savedActiveProfileId, user },
        cookies,
        language,
        prefetch: true,
      });

      await Promise.allSettled(promises);
    }
  }

  if (ctx.req) {
    ctx.store.dispatch(END);
    await ctx.store.sagaTask.toPromise();
  }

  if (countries?.length === 0) {
    store.dispatch(getCountriesAction());
  }

  const { getServerCookie } = processServerCookies(cookies?.cookies);

  return {
    pageProps: {
      user,
      userAgent,
      locale: language,
      isTrustedBot,
      isBot: ctx?.req?.fingerprint?.isBot,
      trusted: ctx?.req?.fingerprint?.trusted,
      ...(hasUseQueries && { dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))) }),
      pageCategory: staticPageCategory,
      pageType: staticPageType,
      serverCookies: {
        [OB_UTM]: getServerCookie(OB_UTM),
        [OB_VISITOR_LOCATION]: getServerCookie(OB_VISITOR_LOCATION),
        [OB_VISITOR_ID]: getServerCookie(OB_VISITOR_ID),
        [LANGUAGE_PREFERRED]: getServerCookie(LANGUAGE_PREFERRED),
        [LANGUAGE_LAST_ACCESSED]: getServerCookie(LANGUAGE_LAST_ACCESSED),
      },
      ...pageProps,
    },
  };
};

let webVitals = {};

export function reportWebVitals(metric) {
  webVitals = {
    ...webVitals,
    [metric.name]: Math.round(metric.value, 2),
  };
  window.webVitals = webVitals;
}

export default wrapper.withRedux(appWithTranslation(OBApp));
