import { TP } from 'constants/index';

export const venueFields = {
  venue: {
    name: 'venue_id',
    label: `${TP}.FN_CHOOSE_VENUE`,
    value: null,
  },
  stage: {
    name: 'stage_id',
    label: `${TP}.FN_CHOOSE_STAGE`,
    value: null,
  },
  city: {
    name: 'city_id',
    label: `${TP}.m_CITY`,
    value: null,
  },
  country: {
    name: 'country_id',
    label: `${TP}.m_COUNTRY`,
    value: null,
  },
};

export const getFieldName = (parentFieldName, field) => `${parentFieldName}.${field?.name}`;
