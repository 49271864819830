import { useEffect } from 'react';

const useCustomWindowOpen = ({ isEnabled = false }) => {
  useEffect(() => {
    if (isEnabled && typeof window !== 'undefined') {
      window.openNative = window.open;
      window.open = (...args) => {
        const targetWindow = window.openNative(...args);

        if (targetWindow && window.analyticsWindow) {
          targetWindow.onload = () => {
            targetWindow.analyticsWindow = window.analyticsWindow;
          };
        }

        return targetWindow;
      };
    }
    // NOTE: Should execute only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useCustomWindowOpen;
