import { PERFORMANCE_TYPES_ID, PRODUCTION_DOCUMENT_TYPES } from 'constants/deprecate';
import { changeItemInObjectsArray } from 'utils/globals/app';
import { getOrgVenuesAction } from 'containers/Organizations/actions';
import {
  getProductionDataAction,
  updateProductionAction,
  createProductionAction,
  getOrganizationsAction,
  getArtistNamesAction,
  createProductionCastAction,
  getVenuesAction,
  createComposerAction,
  setProfessions,
  setRoles,
  getProductionCastAction,
  getProductionDatesAction,
  getArtistProductionDatesAction,
  getArtistsSuggestionsAction,
  getMediaAttributes,
  getFestivalsAction,
  getPerformanceCitiesAction,
  clearPerformanceCitiesAction,
  getPerformanceStagesAction,
  clearPerformanceStagesAction,
  updateProductionPerformancesAction,
  uploadSeveralMediaAction,
  getWorksAction,
  getProductionSinopsisAction,
  uploadProductionDocumentsAction,
  updateProductionSynopsisDocumentsAction,
  getProductionImagesAction,
  getProductionVideoAction,
  getProductionTrailerAction,
  deleteProductionTrailerAction,
  deleteProductionPosterAction,
  getProductionPosterAction,
  addProductionImageAction,
  addProductionPosterAction,
  addProductionTrailerAction,
  addProductionVideoAction,
  getProductionLivestreamsAction,
  getComposersAction,
  getProductionVideoOnDemandAction,
  updateVideoOnDemandAction,
  getVideoCostTypesAction,
  getProductionVideoFormats,
  getProductionImageFormatsAction,
  createDigitalEventAction,
  deleteDigitalEventAction,
  updateLivestreamAction,
  createProductionSynopsisAndDescription,
  getProductionSynopsisAndDescription,
  getEnsembles,
  createProductionEnsembles,
  getProductionEnsembles,
  getReviewsAction,
  getReviewDetailsAction,
  getProductionDescriptionsAction,
  getProductionSynopsisAction,
  getCastAndCrewAction,
  getProductionBookletsAction,
  productionMediaActions,
  getProductionWorksAction,
  createPerformancesAction,
  getWorkCastCrewAction,
  getProductionDatesV1Action,
  getProductionCalendarDatesAction,
  deleteCastProxyAction,
  getPerformanceImagesAction,
  getPerformanceVideoAction,
  deleteMediaAction,
  deleteProductionEnsembleAction,
  createProductionDatesProxyAction,
  getWorkStageTypeAction,
  clearWorkStagingTypesAction,
  clearWorksAction,
  clearComposersAction,
} from './actions';
import {
  defaultEntityParams,
  entityReducer,
  defaultListParams,
  listReducer,
  namedListParams,
  namedEntityParams,
} from '../../utils/crudUtils';
import {
  SET_PRODUCTION,
  CLEAN_OPTIONS_VALUES,
  CLEAR_PRODUCTION,
  DELETE_PRODUCTION_DOCUMENT,
  CLIENT_REMOVE_MEDIA,
  UPDATE_MEDIA,
  SET_PRODUCTION_WORKS,
} from './constants';

export const key = 'ProductionUpdate';

export const initialState = {
  production: defaultEntityParams,
  casts: defaultListParams,
  companies: defaultListParams,
  organization: defaultListParams,
  loadingFieldName: '', // Need it for field array requests
  professions: defaultListParams,
  roles: defaultListParams,
  names: defaultListParams,
  venues: defaultListParams,
  stages: defaultListParams,
  cities: defaultListParams,
  dates: defaultListParams,
  datesUpdating: false, // to prevent reset form values
  artistProductionDates: defaultListParams,
  artistsSuggestions: defaultListParams,
  images: defaultListParams,
  video: defaultListParams,
  mediaAttributes: defaultListParams,
  festivals: defaultListParams,
  companyVenues: defaultListParams,
  works: defaultListParams,
  documents: defaultListParams,
  booklets: [],
  descriptions: defaultListParams,
  synopsis: defaultListParams,
  livestreams: namedListParams('livestreams'),
  composers: defaultListParams,
  workStageTypes: namedListParams('workStageTypes'),
  videoOnDemand: namedListParams('videoOnDemand'),
  videoCostTypes: namedListParams('videoCostTypes'),
  videoFormats: namedListParams('videoFormats'),
  imageFormats: namedListParams('imageFormats'),
  reviews: namedListParams('reviews'),
  reviewDetails: namedEntityParams('reviewDetails'),
  productionWorks: defaultListParams,
  performances: defaultEntityParams,
  workCastCrew: defaultEntityParams,
  productionDatesV1: namedListParams('productionDatesV1'),
  calendarDates: namedListParams('calendarDates'),
  performanceImages: defaultListParams,
  performanceVideos: defaultListParams,
};

const productionUpdateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getProductionDataAction.TRIGGER:
    case getProductionDataAction.SUCCESS:
    case getProductionDataAction.FAILURE:
    case updateProductionAction.TRIGGER:
    case updateProductionAction.SUCCESS:
    case updateProductionAction.FAILURE:
    case createProductionAction.TRIGGER:
    case createProductionAction.SUCCESS:
    case createProductionAction.FAILURE:
      return entityReducer(state, type, payload, 'production');

    case getOrganizationsAction.TRIGGER:
      return { ...state, organization: [], orgsLoading: true };
    case getOrganizationsAction.SUCCESS:
    case getOrganizationsAction.FAILURE:
      return { ...listReducer(state, type, payload, 'organization'), orgsLoading: false };

    case SET_PRODUCTION:
      return { ...state, production: { ...state.production, entity: payload?.production } };

    case CLEAN_OPTIONS_VALUES:
      return { ...state, [payload.field]: defaultListParams };

    case CLEAR_PRODUCTION:
      return {
        ...state,
        production: defaultEntityParams,
        casts: defaultListParams,
        companyVenues: defaultListParams,
        media: defaultListParams,
        artistProductionDates: defaultListParams,
        companies: defaultListParams,
        loadingFieldName: '',
        roles: defaultListParams,
        names: defaultListParams,
        venues: defaultListParams,
        stages: defaultListParams,
        cities: defaultListParams,
        dates: defaultListParams,
        datesUpdating: false,
        images: defaultListParams,
        video: defaultListParams,
        festivals: defaultListParams,
        works: defaultListParams,
        documents: defaultListParams,
        booklets: [],
        livestreams: namedListParams('livestreams'),
        composers: defaultListParams,
        videoOnDemand: namedListParams('videoOnDemand'),
      };

    case setProfessions.TRIGGER:
    case setProfessions.SUCCESS:
    case setProfessions.FAILURE:
      return listReducer(state, type, payload, 'professions');

    case setRoles.TRIGGER:
    case setRoles.SUCCESS:
    case setRoles.FAILURE:
      return listReducer(state, type, payload, 'roles');

    case getArtistNamesAction.TRIGGER:
      return { ...state, names: [], namesLoading: true };
    case getArtistNamesAction.SUCCESS:
    case getArtistNamesAction.FAILURE:
      return { ...listReducer(state, type, payload, 'names'), namesLoading: false };

    case createProductionCastAction.TRIGGER:
      return { ...state, loadingFieldName: 'castCreating' };
    case createProductionCastAction.SUCCESS:
      return { ...state, loadingFieldName: '', casts: { ...state?.casts, loadedOnce: false } };
    case createProductionCastAction.FAILURE:
      return { ...state, loadingFieldName: '' };

    case getVenuesAction.TRIGGER:
    case getVenuesAction.SUCCESS:
    case getVenuesAction.FAILURE:
      return { ...listReducer(state, type, payload, 'venues'), loadingFieldName: payload?.params?.fieldName || '' };

    case createComposerAction.TRIGGER:
    case createComposerAction.SUCCESS:
    case createComposerAction.FAILURE:
      return entityReducer(state, type, payload, 'composer');

    case getProductionCastAction.TRIGGER:
    case getProductionCastAction.SUCCESS:
    case getProductionCastAction.FAILURE:
      return listReducer(state, type, payload, 'casts');

    case getProductionDatesAction.TRIGGER:
    case getProductionDatesAction.SUCCESS:
    case getProductionDatesAction.FAILURE:
      return listReducer(state, type, payload, 'dates');

    case updateProductionPerformancesAction.TRIGGER:
      return { ...state, datesUpdating: true };
    case updateProductionPerformancesAction.FAILURE:
      return { ...state, datesUpdating: false };
    case updateProductionPerformancesAction.SUCCESS:
      return { ...state, dates: { ...state.dates, data: payload?.entity?.dates || [] }, datesUpdating: false };

    case getArtistProductionDatesAction.TRIGGER:
    case getArtistProductionDatesAction.SUCCESS:
    case getArtistProductionDatesAction.FAILURE:
      return listReducer(state, type, payload, 'artistProductionDates');

    case getArtistsSuggestionsAction.TRIGGER:
    case getArtistsSuggestionsAction.SUCCESS:
    case getArtistsSuggestionsAction.FAILURE:
      return listReducer(state, type, payload, 'artistsSuggestions');

    case getProductionImagesAction.TRIGGER:
    case getProductionImagesAction.SUCCESS:
    case getProductionImagesAction.FAILURE:
      return listReducer(state, type, payload, 'images');

    case getProductionVideoAction.TRIGGER:
    case getProductionVideoAction.SUCCESS:
    case getProductionVideoAction.FAILURE:
      return listReducer(state, type, payload, 'video');

    case getProductionTrailerAction.TRIGGER:
    case getProductionTrailerAction.FAILURE:
    case getProductionTrailerAction.SUCCESS:
      return listReducer(state, type, payload, 'trailers');

    case deleteProductionTrailerAction.TRIGGER:
    case deleteProductionTrailerAction.FAILURE:
      return { ...state, loadingFieldName: payload?.fieldName };
    case deleteProductionTrailerAction.SUCCESS: {
      return {
        ...state,
        trailers: {
          ...state.trailers,
          data: state.trailers.data.filter(media => media?.id !== payload?.id),
        },
      };
    }

    case deleteProductionPosterAction.TRIGGER:
    case deleteProductionPosterAction.FAILURE:
      return { ...state, loadingFieldName: payload?.fieldName };
    case deleteProductionPosterAction.SUCCESS: {
      return {
        ...state,
        posters: {
          ...state.posters,
          data: state.posters.data.filter(media => media?.id !== payload?.id),
        },
      };
    }

    case getProductionPosterAction.TRIGGER:
    case getProductionPosterAction.FAILURE:
    case getProductionPosterAction.SUCCESS:
      return listReducer(state, type, payload, 'posters');

    case getMediaAttributes.TRIGGER:
    case getMediaAttributes.SUCCESS:
    case getMediaAttributes.FAILURE:
      return listReducer(state, type, payload, 'mediaAttributes');

    case productionMediaActions.TRIGGER:
    case productionMediaActions.SUCCESS:
    case productionMediaActions.FAILURE:
      return listReducer(state, type, payload, 'mediaActions');

    case getFestivalsAction.TRIGGER:
    case getFestivalsAction.SUCCESS:
    case getFestivalsAction.FAILURE:
      return { ...listReducer(state, type, payload, 'festivals'), loadingFieldName: payload?.fieldName };

    case addProductionImageAction.SUCCESS:
      return {
        ...state,
        images: { ...state.images, data: [...state.images.data, payload?.entity] },
        loadingFieldName: '',
      };
    case addProductionImageAction.TRIGGER:
    case addProductionImageAction.FAILURE:
      return { ...state, loadingFieldName: payload?.fieldName };

    case addProductionPosterAction.SUCCESS: {
      return {
        ...state,
        posters: { ...state.posters, data: [...state.posters.data, payload?.entity] },
        loadingFieldName: '',
      };
    }
    case addProductionPosterAction.TRIGGER:
    case addProductionPosterAction.FAILURE:
      return { ...state, loadingFieldName: payload?.fieldName };

    case addProductionTrailerAction.SUCCESS:
      return {
        ...state,
        trailers: { ...state.trailers, data: [...state.trailers.data, payload?.entity] },
        loadingFieldName: '',
      };
    case addProductionTrailerAction.TRIGGER:
    case addProductionTrailerAction.FAILURE:
      return { ...state, loadingFieldName: payload?.fieldName };

    case addProductionVideoAction.SUCCESS:
      return {
        ...state,
        video: { ...state.video, data: [...state.video.data, payload?.entity] },
        loadingFieldName: '',
      };
    case addProductionVideoAction.TRIGGER:
    case addProductionVideoAction.FAILURE:
      return { ...state, loadingFieldName: payload?.fieldName };

    case UPDATE_MEDIA: {
      const mediaTypeField = payload?.type === 'image' ? 'images' : 'video';
      return {
        ...state,
        [mediaTypeField]: {
          ...state[mediaTypeField],
          data: changeItemInObjectsArray(state[mediaTypeField]?.data, payload?.entity),
        },
      };
    }

    case CLIENT_REMOVE_MEDIA: {
      const mediaTypeField = payload?.type === 'image' ? 'images' : 'video';
      return {
        ...state,
        [mediaTypeField]: {
          ...state[mediaTypeField],
          data: state[mediaTypeField].data.filter(media => media?.id !== payload?.id),
        },
      };
    }
    case uploadSeveralMediaAction.TRIGGER:
      return { ...state, loadingFieldName: 'images' };
    case uploadSeveralMediaAction.SUCCESS:
      return { ...state, images: { ...state.images, data: [...state.images.data, ...payload] }, loadingFieldName: '' };
    case uploadSeveralMediaAction.FAILURE:
      return { ...state, loadingFieldName: '' };

    case getPerformanceCitiesAction.TRIGGER:
    case getPerformanceCitiesAction.SUCCESS:
    case getPerformanceCitiesAction.FAILURE:
      return { ...listReducer(state, type, payload, 'cities'), loadingFieldName: payload?.fieldName };

    case clearPerformanceCitiesAction.TRIGGER:
      return { ...state, cities: defaultListParams };

    case getPerformanceStagesAction.TRIGGER:
    case getPerformanceStagesAction.SUCCESS:
    case getPerformanceStagesAction.FAILURE:
      return { ...listReducer(state, type, payload, 'stages'), loadingFieldName: payload?.fieldName };

    case getOrgVenuesAction.TRIGGER:
    case getOrgVenuesAction.SUCCESS:
    case getOrgVenuesAction.FAILURE:
      return listReducer(state, type, payload, 'companyVenues');

    case getWorksAction.TRIGGER:
      return { ...state, loadingFieldName: 'works' };
    case getWorksAction.SUCCESS:
    case getWorksAction.FAILURE:
      return { ...listReducer(state, type, payload, 'works'), loadingFieldName: payload?.fieldName || '' };

    case clearWorksAction.TRIGGER:
      return { ...state, loadingFieldName: 'works' };
    case clearWorksAction.SUCCESS:
    case clearWorksAction.FAILURE:
      return { ...state, works: defaultListParams, loadingFieldName: payload?.fieldName || '' };

    case clearPerformanceStagesAction.TRIGGER:
      return { ...state, stages: defaultListParams };

    case getProductionSinopsisAction.TRIGGER:
    case getProductionSinopsisAction.FAILURE:
      return { ...listReducer(state, type, payload, 'documents'), booklets: [] };
    case getProductionSinopsisAction.SUCCESS: {
      const booklets = [];
      const other = [];
      const dataToSplit = [...payload?.data];
      dataToSplit.forEach(file => {
        if (file?.documentType === PRODUCTION_DOCUMENT_TYPES.BOOKLET) {
          booklets.push(file);
        } else {
          other.push(file);
        }
      });
      return { ...listReducer(state, type, { ...payload, data: other }, 'documents'), booklets };
    }
    case DELETE_PRODUCTION_DOCUMENT:
      return {
        ...state,
        booklets: state?.booklets?.filter(d => d?.id !== payload?.id),
      };

    case updateLivestreamAction.TRIGGER:
    case updateLivestreamAction.FAILURE:
      return { ...state, loadingFieldName: payload?.fieldName };
    case updateLivestreamAction.SUCCESS: {
      const livestreamsName = state.livestreams.listName;
      return {
        ...state,
        [livestreamsName]: {
          ...state[livestreamsName],
          data: changeItemInObjectsArray(state[livestreamsName]?.data, payload?.entity),
        },
        loadingFieldName: '',
      };
    }

    case getProductionDescriptionsAction.TRIGGER:
    case getProductionDescriptionsAction.FAILURE:
    case getProductionDescriptionsAction.SUCCESS:
      return listReducer(state, type, payload, 'descriptions');

    case uploadProductionDocumentsAction.TRIGGER:
      return { ...state, loadingFieldName: payload?.fieldName };
    case uploadProductionDocumentsAction.FAILURE:
      return { ...state, loadingFieldName: '' };
    case uploadProductionDocumentsAction.SUCCESS:
      return {
        ...state,
        booklets: [...state.booklets, ...payload],
        loadingFieldName: '',
      };

    case getProductionBookletsAction.TRIGGER:
    case getProductionBookletsAction.FAILURE:
    case getProductionBookletsAction.SUCCESS:
      return listReducer(state, type, payload, 'documents');

    case createProductionSynopsisAndDescription.TRIGGER:
      return { ...state, loadingFieldName: payload?.fieldName };
    case createProductionSynopsisAndDescription.FAILURE:
      return { ...state, loadingFieldName: '' };
    case createProductionSynopsisAndDescription.SUCCESS:
      return {
        ...state,
        synopsis: payload,
        loadingFieldName: '',
      };

    case getProductionSynopsisAndDescription.TRIGGER:
    case getProductionSynopsisAndDescription.SUCCESS:
    case getProductionSynopsisAndDescription.FAILURE:
      return listReducer(state, type, payload, 'productionSynopsis');

    case getCastAndCrewAction.TRIGGER:
    case getCastAndCrewAction.SUCCESS:
    case getCastAndCrewAction.FAILURE:
      return listReducer(state, type, payload, 'castAndCrew');

    case getProductionSynopsisAction.TRIGGER:
    case getProductionSynopsisAction.SUCCESS:
    case getProductionSynopsisAction.FAILURE:
      return listReducer(state, type, payload, 'synopsis');

    case getEnsembles.TRIGGER:
    case getEnsembles.SUCCESS:
    case getEnsembles.FAILURE:
      return { ...listReducer(state, type, payload, 'ensembles'), loadingFieldName: payload?.fieldName };

    case createProductionEnsembles.TRIGGER:
    case createProductionEnsembles.SUCCESS:
    case createProductionEnsembles.FAILURE:
      return listReducer(state, type, payload, 'productionEnsembles');

    case getProductionEnsembles.TRIGGER:
    case getProductionEnsembles.SUCCESS:
    case getProductionEnsembles.FAILURE:
      return { ...listReducer(state, type, payload, 'productionEnsembles'), loadingFieldName: payload?.fieldName };

    case updateProductionSynopsisDocumentsAction.TRIGGER:
      return { ...state, loadingFieldName: payload?.fieldName };
    case updateProductionSynopsisDocumentsAction.SUCCESS:
    case updateProductionSynopsisDocumentsAction.FAILURE:
      return { ...state, loadingFieldName: '' };

    case getProductionLivestreamsAction.TRIGGER:
    case getProductionLivestreamsAction.SUCCESS:
    case getProductionLivestreamsAction.FAILURE:
      return listReducer(state, type, payload, 'livestreams');

    case getProductionVideoOnDemandAction.TRIGGER:
    case getProductionVideoOnDemandAction.SUCCESS:
    case getProductionVideoOnDemandAction.FAILURE:
      return listReducer(state, type, payload, state.videoOnDemand.listName);

    case updateVideoOnDemandAction.TRIGGER:
    case updateVideoOnDemandAction.FAILURE:
      return { ...state, loadingFieldName: payload?.fieldName };
    case updateVideoOnDemandAction.SUCCESS:
      return {
        ...state,
        [state.videoOnDemand.listName]: {
          ...state[state.videoOnDemand.listName],
          data: changeItemInObjectsArray(state[state.videoOnDemand.listName]?.data, payload?.entity),
        },
        loadingFieldName: '',
      };

    case getComposersAction.TRIGGER:
      return { ...state, loadingFieldName: 'composers' };
    case getComposersAction.SUCCESS:
    case getComposersAction.FAILURE:
      return {
        ...listReducer(state, type, payload, 'composers'),
        loadingFieldName: payload?.fieldName || '',
      };

    case clearComposersAction.TRIGGER:
      return { ...state, composers: defaultListParams };

    case getWorkStageTypeAction.TRIGGER:
    case getWorkStageTypeAction.SUCCESS:
    case getWorkStageTypeAction.FAILURE:
      return { ...listReducer(state, type, payload, 'workStageTypes'), loadingFieldName: payload?.fieldName || '' };

    case clearWorkStagingTypesAction.TRIGGER:
      return { ...state, workStageTypes: defaultListParams };

    case getVideoCostTypesAction.TRIGGER:
    case getVideoCostTypesAction.SUCCESS:
    case getVideoCostTypesAction.FAILURE:
      return listReducer(state, type, payload, state.videoCostTypes.listName);

    case getProductionVideoFormats.FAILURE:
    case getProductionVideoFormats.TRIGGER:
    case getProductionVideoFormats.SUCCESS:
      return listReducer(state, type, payload, state.videoFormats.listName);

    case getProductionImageFormatsAction.FAILURE:
    case getProductionImageFormatsAction.TRIGGER:
    case getProductionImageFormatsAction.SUCCESS:
      return listReducer(state, type, payload, state.imageFormats.listName);

    case createDigitalEventAction.FAILURE:
    case createDigitalEventAction.TRIGGER:
    case deleteDigitalEventAction.FAILURE:
    case deleteDigitalEventAction.TRIGGER:
      return { ...state, loadingFieldName: payload?.fieldName };

    case createDigitalEventAction.SUCCESS: {
      const updatedStoreName =
        payload?.typeId === PERFORMANCE_TYPES_ID.VIDEO_ON_DEMAND
          ? state.videoOnDemand.listName
          : state.livestreams.listName;
      return {
        ...state,
        [updatedStoreName]: {
          ...state[updatedStoreName],
          data: [...state[updatedStoreName]?.data, payload?.entity],
        },
        loadingFieldName: '',
      };
    }

    case deleteDigitalEventAction.SUCCESS: {
      const updatedStoreName =
        payload?.typeId === PERFORMANCE_TYPES_ID.VIDEO_ON_DEMAND
          ? state.videoOnDemand.listName
          : state.livestreams.listName;
      return {
        ...state,
        [updatedStoreName]: {
          ...state[updatedStoreName],
          data: state[updatedStoreName]?.data?.filter(r => r?.id !== payload?.id),
        },
        loadingFieldName: '',
      };
    }

    case getReviewsAction.TRIGGER:
    case getReviewsAction.SUCCESS:
    case getReviewsAction.FAILURE:
      return { ...listReducer(state, type, payload, 'reviews'), loadingFieldName: payload?.params?.fieldName || '' };

    case getProductionDatesV1Action.TRIGGER:
    case getProductionDatesV1Action.SUCCESS:
    case getProductionDatesV1Action.FAILURE:
      return {
        ...listReducer(state, type, payload, 'productionDatesV1'),
        loadingFieldName: payload?.params?.fieldName || '',
      };

    case getProductionCalendarDatesAction.TRIGGER:
    case getProductionCalendarDatesAction.SUCCESS:
    case getProductionCalendarDatesAction.FAILURE:
      return {
        ...listReducer(state, type, payload, 'calendarDates'),
        loadingFieldName: payload?.params?.fieldName || '',
      };

    case getReviewDetailsAction.TRIGGER:
    case getReviewDetailsAction.SUCCESS:
    case getReviewDetailsAction.FAILURE:
      return entityReducer(state, type, payload, 'reviewDetails');

    case getProductionWorksAction.TRIGGER:
    case getProductionWorksAction.SUCCESS:
    case getProductionWorksAction.FAILURE:
      return entityReducer(state, type, payload, 'productionWorks');
    case SET_PRODUCTION_WORKS:
      return {
        ...state,
        productionWorks: { ...state.productionWorks, data: payload?.works },
      };

    case getWorkCastCrewAction.TRIGGER:
    case getWorkCastCrewAction.SUCCESS:
    case getWorkCastCrewAction.FAILURE:
      return entityReducer(state, type, payload, 'workCastCrew');
    case createPerformancesAction.TRIGGER:
    case createPerformancesAction.SUCCESS:
    case createPerformancesAction.FAILURE:
      return entityReducer(state, type, payload, 'performances');

    case deleteCastProxyAction.TRIGGER:
      return { ...state, loadingFieldName: 'deleteCastProxy' };
    case deleteCastProxyAction.SUCCESS:
    case deleteCastProxyAction.FAILURE:
      return { ...state, loadingFieldName: '' };

    case getPerformanceImagesAction.TRIGGER:
    case getPerformanceImagesAction.SUCCESS:
    case getPerformanceImagesAction.FAILURE:
      return listReducer(state, type, payload, 'performanceImages');

    case getPerformanceVideoAction.TRIGGER:
    case getPerformanceVideoAction.SUCCESS:
    case getPerformanceVideoAction.FAILURE:
      return listReducer(state, type, payload, 'performanceVideos');

    case deleteMediaAction.TRIGGER:
      return { ...state, loadingFieldName: 'deleteMedia' };
    case deleteMediaAction.SUCCESS:
    case deleteMediaAction.FAILURE:
      return { ...state, loadingFieldName: '' };

    case deleteProductionEnsembleAction.TRIGGER:
      return { ...state, loadingFieldName: 'deleteProductionEnsemble' };
    case deleteProductionEnsembleAction.SUCCESS:
    case deleteProductionEnsembleAction.FAILURE:
      return { ...state, loadingFieldName: '' };
    case createProductionDatesProxyAction.TRIGGER:
      return { ...state, loadingFieldName: 'performanceDate' };
    case createProductionDatesProxyAction.SUCCESS:
    case createProductionDatesProxyAction.FAILURE:
      return { ...state, loadingFieldName: '' };

    default:
      return state;
  }
};

export default productionUpdateReducer;
