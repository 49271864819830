import { createSelector } from 'reselect';
import get from 'lodash/get';
import { initialState } from './reducer';

const selectWorkDomain = state => state.Work || initialState;

const selectWork = () => createSelector(selectWorkDomain, subState => get(subState, 'selectedWork'));
const selectWorkFuturePerf = () =>
  createSelector(selectWorkDomain, subState => get(subState, 'selectedWorkFutureProd'));
const selectWorkPastPerf = () => createSelector(selectWorkDomain, subState => get(subState, 'selectedWorkPastProd'));

const selectProductions = () => createSelector(selectWorkDomain, subState => get(subState, 'productions'));
const selectPerformances = () =>
  createSelector(selectWorkDomain, subState => {
    const performances = get(subState, 'performances');
    performances?.data.forEach(performance => {
      performance.companies = [performance?.companies];
      performance.venues = [performance?.venue];
      return performance;
    });
    return performances;
  });

const selectProductionsLoading = () =>
  createSelector(selectWorkDomain, subState => get(subState, 'productions.loading', false));
const selectPerformancesLoading = () =>
  createSelector(selectWorkDomain, subState => get(subState, 'performances.loading', false));

export {
  selectWork,
  selectWorkFuturePerf,
  selectWorkPastPerf,
  selectPerformances,
  selectProductions,
  selectPerformancesLoading,
  selectProductionsLoading,
};
