// TODO: need to replace this with the utils react query
import { QueryClient } from '@tanstack/react-query';
import { i18n } from '../src/i18n';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 3000,
      cacheTime: 30000,
    },
  },
});

export const newQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        // staleTime: 3000,
        cacheTime: 30000,
      },
    },
  });

export const getLanguage = language => {
  if (typeof window === 'undefined') {
    return language || 'en';
  }
  return i18n.language;
};
