export const GET_AGENCY_INFO = 'app/Managers/GET_AGENCY_INFO';
export const SET_AGENCY_INFO = 'app/Managers/SET_AGENCY_INFO';
export const GET_AGENCY_COMMON_INFO = 'app/Managers/GET_AGENCY_COMMON_INFO';
export const GET_OFFICES = 'app/Managers/GET_OFFICES';
export const GET_ARTISTS = 'app/Managers/GET_ARTISTS';
export const GET_AGENTS = 'app/Managers/GET_AGENTS';
export const GET_AGENCY_AGREEMENTS = 'app/Managers/GET_AGENCY_AGREEMENTS';
export const GET_AGENCIES_COUNTRIES = 'app/Managers/GET_AGENCIES_COUNTRIES';
export const GET_USER_PROFILE_AGENT_INFO = 'app/Managers/GET_USER_PROFILE_AGENT_INFO';
export const DELETE_ARTIST_FROM_ROSTER = 'app/Managers/DELETE_ARTIST_FROM_ROSTER';
export const ADD_ARTIST_TO_ROSTER = 'app/Managers/ADD_ARTIST_TO_ROSTER';
export const CLEAR_STATUS_OF_ARTIST_IN_AGENCY_DATA = 'app/Managers/CLEAR_STATUS_OF_ARTIST_IN_AGENCY_DATA';
export const IS_ARTIST_IN_AGENCY = 'app/Managers/IS_ARTIST_IN_AGENCY';

export const GET_SINGLE_ARTIST_INFO = 'app/Managers/GET_SINGLE_ARTIST_INFO';
export const GET_ARTISTS_LIST_SUGGESTIONS = 'app/Managers/GET_ARTISTS_LIST_SUGGESTIONS';
export const UPDATE_ARTIST_INFO = 'app/Managers/UPDATE_ARTIST_INFO';

export const UPDATE_AGENCY = 'app/Managers/UPDATE_AGENCY';
export const GET_AGENCY_CONTACT = 'app/Managers/GET_AGENCY_CONTACT';

export const AGREEMENTS_TYPES = {
  OPERA: 'opera',
  CONCERT: 'concert',
  BOTH: 'both',
  GENERAL_MENEGMENT: 'general management',
};

export const AGENCY_TERRITORIES = [
  {
    type: AGREEMENTS_TYPES.OPERA,
    isExclusive: true,
  },
  {
    type: AGREEMENTS_TYPES.OPERA,
    isExclusive: false,
  },
  {
    type: AGREEMENTS_TYPES.CONCERT,
    isExclusive: true,
  },
  {
    type: AGREEMENTS_TYPES.CONCERT,
    isExclusive: false,
  },
];

export const AGREEMENTS_CASES = {
  OPERA_EXCLUSIVE: 'operaExclusive',
  OPERA_NON_EXCLUSIVE: 'operaNonExclusive',
  CONCERT_EXCLUSIVE: 'concertExclusive',
  CONCERT_NON_EXCLUSIVE: 'concertNonExclusive',
  GENERAL_MENEGMENT: 'generalManagment',
};

export const TYPES_WITH_EXCLUDED_COUNTRIES = [AGREEMENTS_CASES.OPERA_EXCLUSIVE, AGREEMENTS_CASES.CONCERT_EXCLUSIVE];
export const EXCLUSIVE_AGREEMENTS_TYPES = [AGREEMENTS_CASES.OPERA_EXCLUSIVE, AGREEMENTS_CASES.CONCERT_EXCLUSIVE];

export const INCLUDED = 'included';
export const EXCLUDED = 'excluded';

export const professionAgreementsFormFields = {
  PROFESSION: 'profession',
  VOICE: 'voice',
  INSTRUMENT_TYPE: 'instrument',
  AGREEMENTS_DATA: 'agreementsData',
  AGREEMENTS: 'agreements',
  CONTACT_PERSONS: 'contactPersons',
  AGENTS: 'agents',
};

export const commonArtistFields = {
  LAST_NAME: 'lastName',
  FIRST_NAME: 'firstName',
  EMAIL: 'email',
  PHONE: 'phone',
  PROFESSIONS: 'professions',
  ARTIST_PROFESSIONS: 'artistProfessions',
  AGENCY_PROFESSIONS: 'agencyProfessions',
};

export const emptyProfessionAgreementsForm = {
  [professionAgreementsFormFields.PROFESSION]: null,
  [professionAgreementsFormFields.VOICE]: null,
  [professionAgreementsFormFields.INSTRUMENT_TYPE]: null,
  [professionAgreementsFormFields.AGREEMENTS_DATA]: {
    [professionAgreementsFormFields.AGREEMENTS]: {
      ...Object.values(AGREEMENTS_CASES).reduce(
        (acc, agreementName) => ({
          ...acc,
          [agreementName]: {},
        }),
        {},
      ),
      [AGREEMENTS_CASES.GENERAL_MENEGMENT]: false,
    },
  },
};
