import { useMutation } from 'utils/react-query';
import * as API from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import { getOption } from 'utils/globals/app';
import {
  REGISTRATION,
  PROFILES_ENDPOINT,
  COUNTRIES_ENDPOINT,
  LANGUAGES_LIST,
  PROFILE_TYPE_PREFERENCES,
  PROFILE_PREFERENCES,
  PROFILE,
  USER_PERFORMACES,
  AGENCIES,
  IDENTITY_VERIFICATION,
  ACCOUNT_CREATE,
  USERS_SUBSCRIPTIONS,
  SET_PROFILE_ACCESS_REQUESTS,
  DELETE_PROFILE_ACCESSES,
  USER_REFERRALS,
  USER_REFERRALS_STATUS,
  GIFT_SUBSCRIPTIONS,
  ORGANIZATIONS,
  MUSICAL_WORK_TYPES_ENDPOINT,
  PERFORMANCES_TYPES,
  ORGANIZATION_PERFORMANCE_TYPES,
  ORGANIZATION_WORK_TYPES,
  ORGANIZATION_STAGES,
  PROFILE_ACCESSES,
  ORGANIZATIONS_DEPARTMENTS,
  PROFESSIONS,
} from 'constants/endpointConstants';
import { deleteQuery, getQuery, postQuery, updateQuery } from 'utils/globals/queries';
import { PARENT_PROFESSION_SLUG } from 'constants/consts';
import { PROFILE_TYPE_IDS } from 'constants/index';

const {
  create,
  getWholeList,
  getOne,
  updateOne,
  getList,
  getProductsList,
  createOrganizationContacts,
  getCurrentUserRequestsAccess,
} = API;

const ENDPOINTS = {
  USERS: ({ id }) => `users${id ? `/${id}` : ''}`,
  USERS_SUBSCRIPTIONS: 'users/subscriptions',
  USER_PERMISSIONS: ({ id }) => `users/${id}/permissions`,
  USER_SUBSCRIPTIONS: ({ id }) => `users/${id}/subscriptions`,
  USER_PAYMENT_METHODS: ({ id }) => `users/${id}/paymentmethods`,
  USER_ACTIVE_PROFILE: ({ id }) => `users/${id}/settings/active_profile_id`,
  PROFILES: 'profiles',
  PROFILE_ACCESS_REQUESTS: 'profiles/profileaccessrequests',
  PROFILE_PERMISSIONS: ({ id }) => `profiles/${id}/permissions`,
};

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
};

const selectConfig = {
  select: response => ({
    ...response,
    data: response?.data?.map(option =>
      getOption(option, 'id', 'name', { id: option?.id, slug: option?.slug, name: option?.name }),
    ),
  }),
};

const fetchUserDetails = async ({ id }, cookies) =>
  getOne(ENDPOINTS.USERS({}), id, null, cookies).then(response => response?.data);

const fetchUserPermissions = async ({ id }, cookies) =>
  getList(ENDPOINTS.USER_PERMISSIONS({ id }), {}, cookies).then(response => response?.data);

const fetchOwnedProfiles = async ({ id }, cookies) => {
  const queryParams = {
    limit: 100,
    validation_status: 'pending,processing,approved,rejected,approved-hidden',
    access_user_id: id,
  };

  return getList(ENDPOINTS.PROFILES, { queryParams }, cookies).then(response => response?.data?.data);
};

const fetchProfileAccessRequests = async ({ id }, cookies) => {
  const queryParams = {
    limit: 100,
    page: 1,
    user_id: id,
  };

  return getList(ENDPOINTS.PROFILE_ACCESS_REQUESTS, { queryParams }, cookies).then(response => response?.data?.data);
};

const fetchActiveProfileId = async ({ id }, cookies) =>
  getList(ENDPOINTS.USER_ACTIVE_PROFILE({ id }), {}, cookies).then(response => {
    const idString = response?.data?.value;

    return {
      data: idString ? parseInt(idString, 10) : null,
    };
  });

const fetchProfilesWithPendingAccess = async ({ ids }, cookies) => {
  const queryParams = {
    limit: 100,
    validation_status: 'pending,processing,approved,rejected,approved-hidden',
    id: ids,
  };

  return getList(ENDPOINTS.PROFILES, { queryParams }, cookies).then(response => response?.data?.data);
};

const fetchActiveProfileData = async ({ id }, cookies) =>
  getOne(ENDPOINTS.PROFILES, id, null, cookies).then(response => response?.data);

const fetchActiveProfileSubscriptions = async ({ id, activeProfile }, cookies) => {
  const profileTypeId = activeProfile?.profileType?.id;
  let filterKey = 'profile_id';
  let filterValue = id;

  if (profileTypeId === PROFILE_TYPE_IDS.AGENT) {
    filterKey = 'agency_id';
    filterValue = activeProfile?.agencies?.[0]?.id;
  } else if (profileTypeId === PROFILE_TYPE_IDS.AOS) {
    filterKey = 'organization_id';
    filterValue = activeProfile?.organizations?.[0]?.organization?.id;
  }

  if (!filterValue) {
    return null;
  }

  const queryParams = {
    [filterKey]: filterValue,
  };

  return getList(ENDPOINTS.USERS_SUBSCRIPTIONS, { queryParams }, cookies).then(response => response?.data);
};

const fetchProfilePermissions = async ({ id }, cookies) =>
  getList(ENDPOINTS.PROFILE_PERMISSIONS({ id }), {}, cookies).then(response => response?.data);

const fetchUserProfiles = async ({ id }, cookies) => {
  const [profiles, profileAccessRequests] = await Promise.allSettled([
    fetchOwnedProfiles({ id }, cookies),
    fetchProfileAccessRequests({ id }, cookies),
  ]).then(results => results.map(result => result.value));

  let profilesWithPendingAccess = [];

  const accessPendingProfileIds =
    profileAccessRequests?.filter(p => p?.status === 'pending')?.map(p => p?.profile?.id) || [];

  if (accessPendingProfileIds?.length > 0) {
    profilesWithPendingAccess = await fetchProfilesWithPendingAccess({ ids: accessPendingProfileIds }, cookies);
  }

  return {
    data: [...(profiles || []), ...(profilesWithPendingAccess || [])],
    accessPendingProfileIds,
  };
};

export const fetchUserSubscriptions = async ({ id }, cookies) =>
  getList(ENDPOINTS.USER_SUBSCRIPTIONS({ id }), {}, cookies).then(response => response?.data);

const fetchUserPaymentMethods = async ({ id }, cookies) =>
  getList(ENDPOINTS.USER_PAYMENT_METHODS({ id }), {}, cookies).then(response => response?.data);

const queries = {
  /* NOTE: START OF USER RELATED QUERIES
    All user related queries that requires refetch together on certain conditions like
    user profile update or subscription update should be added here and the
    query key name should start with _GET_USER_
  */

  // NOTE: refetched when "details" options is true in useRefetchUserQueries
  getUserDetails: getQuery('_GET_USER_DETAILS', fetchUserDetails),
  getUserProfiles: getQuery('_GET_USER_PROFILES', fetchUserProfiles),
  getActiveProfileId: getQuery('_GET_USER_ACTIVE_PROFILE_ID', fetchActiveProfileId),
  getActiveProfileDetails: getQuery('_GET_USER_ACTIVE_PROFILE_DETAILS', fetchActiveProfileData),

  // NOTE: refetched when "permissions" options is true in useRefetchUserQueries
  getUserPermissions: getQuery('_GET_USER_PERMISSIONS', fetchUserPermissions),
  getActiveProfilePermissions: getQuery('_GET_USER_ACTIVE_PROFILE_PERMISSIONS', fetchProfilePermissions),

  // NOTE: refetched when "subscriptions" options is true in useRefetchUserQueries
  getUserSubscriptions: getQuery('_GET_USER_SUBSCRIPTIONS', fetchUserSubscriptions),
  getActiveProfileSubscriptions: getQuery('_GET_USER_ACTIVE_PROFILE_SUBSCRIPTIONS', fetchActiveProfileSubscriptions),

  // NOTE: refetched when "payments" options is true in useRefetchUserQueries
  getUserPaymentMethods: getQuery('_GET_USER_PAYMENT_METHODS', fetchUserPaymentMethods),
  // NOTE: END OF USER RELATED QUERIES

  updateUserDetails: updateQuery(ENDPOINTS.USERS),
  updateActiveProfileId: updateQuery(ENDPOINTS.USER_ACTIVE_PROFILE),
  cancelSubscription: updateQuery(ENDPOINTS.USERS_SUBSCRIPTIONS),
  getRegistration: ({ userId, registrationId, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'REGISTRATION', userId, registrationId],
    queryFn: async () => getOne(REGISTRATION(userId), registrationId, cookies).then(response => response.data),
    ...defaultConfig,
    ...queryConfig,
  }),
  getCurrentUserRequestAccess: ({ user_id, limit = 5, status, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_CURRENT_USER_REQUEST_ACCESS', `${user_id}`, `${limit}`],
    queryFn: async ({ pageParam = 1 }) =>
      getCurrentUserRequestsAccess({ user_id, limit, page: pageParam, status }, cookies),
    ...defaultConfig,
    ...queryConfig,
  }),
  getCountries: (cookies, language) => ({
    queryKey: [getLanguage(language), 'COUNTRIES'],
    queryFn: async () => getWholeList(COUNTRIES_ENDPOINT, cookies).then(response => response.data),
    ...selectConfig,
    ...defaultConfig,
  }),
  getLanguages: (cookies, language) => ({
    queryKey: [getLanguage(language), 'LANGUAGES'],
    queryFn: async () => getWholeList(LANGUAGES_LIST, cookies).then(response => response.data),
    ...selectConfig,
    ...defaultConfig,
  }),
  getProfilePreferences: ({ profileTypeId, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'PROFILE_PREFERENCES', profileTypeId],
    queryFn: async () => getOne(PROFILE_TYPE_PREFERENCES, profileTypeId, cookies).then(response => response.data),
    ...defaultConfig,
    ...queryConfig,
  }),
  getProfessions: (
    {
      hasParentProfession = false,
      userId,
      tags,
      tagType,
      querySearch,
      isSelect,
      limit,
      queryConfig = {},
      professionId,
    },
    cookies,
    language,
  ) => ({
    queryKey: [
      getLanguage(language),
      'PROFESSIONS',
      hasParentProfession,
      tags,
      tagType,
      isSelect,
      querySearch,
      professionId,
    ],
    queryFn: async () => {
      const query = {
        queryParams: {
          user_id: userId,
          tag: tags,
          tag_type: tagType,
          query: querySearch,
          limit: limit || 25,
          id: professionId || '',
          ...(hasParentProfession && {
            parent_profession: `${PARENT_PROFESSION_SLUG.SINGER},${PARENT_PROFESSION_SLUG.INSTRUMENTALIST}`,
          }),
          validation_status: 'pending,approved',
        },
      };

      return getList(PROFESSIONS, query, cookies).then(response => response.data);
    },
    ...defaultConfig,
    ...queryConfig,
    ...(isSelect && selectConfig),
  }),

  getProfessionSuggestions: (
    { userId, querySearch, tags, tagType, hasParentProfession, queryConfig = {} },
    cookies,
    language,
  ) => ({
    queryKey: [getLanguage(language), 'SUGGESTED_PROFESSIONS', querySearch, tags, tagType, hasParentProfession],
    queryFn: async () => {
      const query = {
        queryParams: {
          user_id: userId,
          query: querySearch,
          tag: tags,
          tag_type: tagType,
          ...(hasParentProfession && {
            parent_profession: `${PARENT_PROFESSION_SLUG.SINGER},${PARENT_PROFESSION_SLUG.INSTRUMENTALIST}`,
          }),
          validation_status: 'pending,approved',
        },
      };
      return getList(PROFESSIONS, query, cookies).then(response => response.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getOrganizations: ({ query = '', isOrg, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'ORGANIZATIONS', query, isOrg],
    queryFn: async () => {
      const url = isOrg ? ORGANIZATIONS : AGENCIES;
      const queryParams = { queryParams: { query, sort: isOrg ? '-production_count' : '' } };
      return getList(url, queryParams, cookies).then(response => response.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getProfile: ({ profileId, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'USER_PROFILES', profileId],
    queryFn: async () => getOne(PROFILES_ENDPOINT, profileId, cookies).then(response => response.data),
    ...defaultConfig,
    ...queryConfig,
  }),
  getRegistrations: ({ userId, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_REGISTRATIONS', userId],
    queryFn: async () => {
      const param = { queryParams: { limit: 100 } };
      return getList(`users/${userId}/registrations`, param, cookies).then(response => response.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getProducts: ({ product_type = [], profile_type_id = '', queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_PRODUCTS', profile_type_id, product_type],
    queryFn: async () => getProductsList(cookies, product_type, profile_type_id).then(response => response.data),
    ...defaultConfig,
    ...queryConfig,
  }),
  getSubscriptions: ({ profileId, agencyId, orgId, queryConfig = {}, query = {} }, cookies) => ({
    queryKey: [getLanguage(), 'GET_SUBSCRIPTIONS', profileId, agencyId, orgId],
    queryFn: async () => {
      const param = { queryParams: { profile_id: profileId, agency_id: agencyId, organization_id: orgId, ...query } };
      return getList(USERS_SUBSCRIPTIONS, param, cookies).then(response => response.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  searchProfile: ({ params, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_PROFILES', JSON.stringify(params)],
    queryFn: async () => {
      const query = {
        queryParams: params,
      };
      return getList(PROFILES_ENDPOINT, query, cookies).then(response => response.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getReferral: ({ isPersonalReferral, token, email, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_REFERRAL', isPersonalReferral, token, email],
    queryFn: async () => {
      const query = {
        queryParams: {
          token,
          email,
        },
      };
      const url = isPersonalReferral ? USER_REFERRALS_STATUS : USER_REFERRALS;
      return getList(url, query, cookies).then(response => response.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getIdentityVerification: ({ userId, queryConfig = {} }, cookies, language) => ({
    queryKey: [getLanguage(language), 'GET_IDENTITY_VERIFICATION', userId],
    queryFn: async () => getList(IDENTITY_VERIFICATION(userId), cookies).then(response => response.data),
    ...defaultConfig,
    ...queryConfig,
  }),
  getPerformanceTypes: ({ querySearch, queryConfig = {} }, cookies) => ({
    queryKey: [getLanguage(), 'ORGANIZATIONS_PERFORMANCE_TYPES', querySearch],
    queryFn: async () => {
      const params = { queryParams: { query: querySearch, validation_status_id: '1,2,3,4,5' } };
      return getList(PERFORMANCES_TYPES, params, cookies).then(response => response?.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getWorkTypes: ({ querySearch, queryConfig = {} }, cookies) => ({
    queryKey: [getLanguage(), 'WORK_TYPES', querySearch],
    queryFn: async () => {
      const params = { queryParams: { query: querySearch, validation_status: 'pending,approved', limit: 25 } };
      return getList(MUSICAL_WORK_TYPES_ENDPOINT, params, cookies).then(response => response?.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getOrgPerformanceTypes: ({ orgId, queryConfig = {} }, cookies) => ({
    queryKey: [getLanguage(), 'GET_ORGANIZATIONS_PERFORMANCE_TYPES', orgId],
    queryFn: async () => getList(ORGANIZATION_PERFORMANCE_TYPES(orgId), cookies).then(response => response?.data),
    ...defaultConfig,
    ...queryConfig,
  }),
  // TODO: Should be moved to containers/Organizations/queries.js
  getOrgWorkTypes: ({ orgId, queryConfig = {} }, cookies) => ({
    queryKey: [getLanguage(), 'GET_ORGANIZATIONS_WORK_TYPES', orgId],
    queryFn: async () => {
      const params = { queryParams: { limit: 50 } };
      return getList(ORGANIZATION_WORK_TYPES(orgId), params, cookies).then(response => response?.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getOrgStages: ({ orgId, queryConfig = {} }, cookies) => ({
    queryKey: [getLanguage(), 'GET_ORGANIZATIONS_STAGES', orgId],
    queryFn: async () => {
      const params = { queryParams: { limit: 50 } };
      return getList(ORGANIZATION_STAGES(orgId), params, cookies).then(response => response?.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getProfileAccesses: ({ userId, profileId, queryConfig = {} }, cookies) => ({
    queryKey: [getLanguage(), 'GET_PROFILE_ACCESSES', userId, profileId],
    queryFn: async () => {
      const params = { queryParams: { user_id: userId, profile_id: profileId, limit: 1000 } };
      return getList(PROFILE_ACCESSES, params, cookies).then(response => response?.data);
    },
    ...defaultConfig,
    ...queryConfig,
  }),
  getOrgDepartments: ({ query, queryConfig = {} }, cookies) => ({
    queryKey: [getLanguage(), 'GET_ORGANIZATIONS_DEPARTMENTS', query],
    queryFn: async () => {
      const params = { queryParams: { limit: 50, query } };
      return getList(ORGANIZATIONS_DEPARTMENTS, params, cookies).then(response => response?.data);
    },
    ...selectConfig,
    ...queryConfig,
  }),
  deleteProfileAccess: deleteQuery(({ profileAccessId }) => DELETE_PROFILE_ACCESSES(profileAccessId)),
  createSubscription: postQuery(USERS_SUBSCRIPTIONS),
};

const useCustomMutation = (mutationFn, options = {}) =>
  useMutation(mutationFn, {
    onSuccess: options?.onSuccess,
    onError: options?.onError,
    onSettled: options?.onSettled,
  });

export const useLoginMutation = (options = {}) => {
  const mutationFn = payload =>
    API.login({
      email: payload.email,
      password: payload.password,
      otp: payload.otp,
      isTrustedDevice: payload.isTrustedDevice,
      popupSource: payload?.popupSource,
    });
  return useCustomMutation(mutationFn, options);
};

export const useCreateUserMutation = (options = {}) => {
  const mutationFn = payload =>
    API.registerUser({
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      password: null,
      preferredLanguage: 'en',
    });
  return useCustomMutation(mutationFn, options);
};

export const useCreateRegistrationMutation = (options = {}) => {
  const mutationFn = payload => API.createRegistration(payload, payload.entities.user.id);
  return useCustomMutation(mutationFn, options);
};

export const useResendOtpMutation = (options = {}) => {
  const mutationFn = payload => API.requestValidateEmailOTP(payload);
  return useCustomMutation(mutationFn, options);
};

export const useVerifyOtpMutation = (options = {}) => {
  const mutationFn = payload => API.validateEmailOTP(payload, payload);
  return useCustomMutation(mutationFn, options);
};

export const useUpdateRegistrationMutation = (options = {}) => {
  const mutationFn = payload => updateOne(REGISTRATION(payload?.entities?.user?.id), payload?.id, payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateProfileMutation = (options = {}) => {
  const mutationFn = payload => create(PROFILES_ENDPOINT, payload);
  return useCustomMutation(mutationFn, options);
};

export const useResetUserPasswordMutation = (options = {}) => {
  const mutationFn = email => API.resetUserPassword(email);
  return useCustomMutation(mutationFn, options);
};

export const useCreateProfilePreferencesMutation = (options = {}) => {
  const mutationFn = payload => create(PROFILE_PREFERENCES(payload?.profileId), payload?.preferences);
  return useCustomMutation(mutationFn, options);
};

export const useCreateProfessionMutation = (options = {}) => {
  const mutationFn = payload => API.create(PROFESSIONS, payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateOrganizationMutation = (isAOSProfile, options = {}) => {
  const url = isAOSProfile ? ORGANIZATIONS : AGENCIES;
  const mutationFn = payload => create(url, payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateOrganizationContactsMutation = (options = {}) => {
  const mutationFn = payload => createOrganizationContacts(payload?.orgId, payload?.contacts);
  return useCustomMutation(mutationFn, options);
};

export const useUpdateProfilePhoto = (profileId, options = {}) => {
  const mutationFn = payload => {
    const { params, files } = payload;
    return API.updateOne(PROFILE(profileId), null, params, files);
  };
  return useMutation(mutationFn, options);
};

export const useAddPerformance = (userId, options = {}) => {
  const mutationFn = payload => API.create(USER_PERFORMACES(userId), payload);
  return useMutation(mutationFn, options);
};

export const useUpdatePerformance = (userId, options = {}) => {
  const mutationFn = payload => API.updateOne(USER_PERFORMACES(userId), payload?.id, payload);
  return useMutation(mutationFn, options);
};

export const useUpdateProfileMutation = (options = {}) => {
  const mutationFn = payload => updateOne(PROFILES_ENDPOINT, payload?.profileId, payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateIdentityVerificationMutation = (userId, options = {}) => {
  const mutationFn = payload => create(IDENTITY_VERIFICATION(userId), payload);
  return useCustomMutation(mutationFn, options);
};

export const useUpdateUserMutation = (userId, options = {}) => {
  const mutationFn = payload => updateOne(ACCOUNT_CREATE, userId, payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateProfileAccessMutation = (options = {}) => {
  const mutationFn = payload => create(SET_PROFILE_ACCESS_REQUESTS(payload?.profile?.id), payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateReferralStatusMutation = (options = {}) => {
  const mutationFn = payload => create(GIFT_SUBSCRIPTIONS(payload?.sender?.user?.id), payload);
  return useCustomMutation(mutationFn, options);
};

export const useUpdateReferralStatusMutation = (options = {}) => {
  const mutationFn = payload => updateOne(GIFT_SUBSCRIPTIONS(payload?.senderId), payload?.referralId, payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateOrgPerformanceTypesMutation = (orgId, options = {}) => {
  const mutationFn = payload => create(ORGANIZATION_PERFORMANCE_TYPES(orgId), payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreatePerformanceTypeMutation = (options = {}) => {
  const mutationFn = payload => create(PERFORMANCES_TYPES, payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateWorkTypeTypeMutation = (options = {}) => {
  const mutationFn = payload => create(MUSICAL_WORK_TYPES_ENDPOINT, payload);
  return useCustomMutation(mutationFn, options);
};

export const useCreateOrgWorkTypesMutation = (orgId, options = {}) => {
  const mutationFn = payload => create(ORGANIZATION_WORK_TYPES(orgId), payload);
  return useCustomMutation(mutationFn, options);
};

export default queries;
