import {
  useQuery as useOriginalQuery,
  useInfiniteQuery as useOriginalInfiniteQuery,
  QueryClientProvider,
  Hydrate,
  useMutation,
  useQueryClient,
  dehydrate,
  QueryClient,
} from '@tanstack/react-query';

import useAppContext from 'utils/hooks/useAppContext';
import { defaultLanguage } from 'src/i18n';

const useUpdatedQueryKey = props => {
  const { language: pageLanguage } = useAppContext();
  const queryKey = props?.queryKey;

  if (!queryKey) {
    return null;
  }

  const queryLanguage = queryKey?.[0];

  if (
    typeof window === 'undefined' &&
    pageLanguage &&
    queryLanguage === defaultLanguage &&
    pageLanguage !== defaultLanguage
  ) {
    queryKey[0] = pageLanguage;

    return queryKey;
  }

  return queryKey;
};

const useInvalidateAndRefetch = queryKey => {
  const queryClient = useQueryClient();

  return () => {
    const keyMatch = queryKey?.slice(0, 2);

    if (keyMatch.length > 0) {
      queryClient.invalidateQueries({
        queryKey: keyMatch,
        exact: false,
        refetchType: 'all',
      });
    }
  };
};

export const useQuery = (...props) => {
  const queryKey = useUpdatedQueryKey(props?.[0]);
  const args = queryKey ? [{ ...props?.[0], queryKey }] : props;
  const { isFetching, isError, ...rest } = useOriginalQuery(...args);
  const invalidateAndRefetch = useInvalidateAndRefetch(queryKey);

  return {
    ...rest,
    invalidateAndRefetch,
    isFetching,
    isError,
    isEmptyList: !isFetching && !isError && Array.isArray(rest?.data?.data) && rest.data.data.length === 0,
  };
};

// NOTE: Interval is in milliseconds or can be a function
export const usePollingQuery = (props, interval = 3000) => {
  // eslint-disable-next-line no-unused-vars
  const { refetchInterval, ...rest } = props;
  const queryKey = useUpdatedQueryKey(rest);

  useOriginalQuery({ ...rest, refetchInterval: interval, queryKey: [...queryKey, 'POLLING'] });
};

export const useInfiniteQuery = (...props) => {
  const queryKey = useUpdatedQueryKey(props?.[0]);
  const args = queryKey ? [{ ...props?.[0], queryKey }] : props;
  const queryMethods = useOriginalInfiniteQuery(...args);
  const invalidateAndRefetch = useInvalidateAndRefetch(queryKey);

  return {
    ...queryMethods,
    invalidateAndRefetch,
  };
};

export const useSetQueryData = () => {
  const queryClient = useQueryClient();

  return (key, updater) => {
    queryClient.setQueryData(key, updater);
  };
};

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  return filters => {
    const { queryKey, exact = true, refetchType = 'all', predicate } = filters || {};

    return queryClient.invalidateQueries({ queryKey, exact, refetchType, predicate });
  };
};

export { QueryClientProvider, Hydrate, useMutation, useQueryClient, QueryClient, dehydrate };
