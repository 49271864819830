// TODO: Deprecate this file and move the functions to either utils/productions or utils/artists
import map from 'lodash/map';
import filter from 'lodash/filter';
import head from 'lodash/head';
import join from 'lodash/join';

/**
 * Returns formatted name from composer object
 *
 * @param {Object} composer
 * @returns {string}
 */
export const getComposerName = ({ firstName, lastName, name, shortName } = {}) => {
  if (shortName) {
    return shortName;
  }

  if (name) {
    return name;
  }

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return name || firstName || lastName || '';
};

/**
 * Returns formatted name from creators array
 *
 * @param {Array} creators
 * @returns {string}
 */
export const getComposerNamesFromCreators = (creators = [], withSeparator = true) => {
  // Map the short names of all composers in the array
  const composerNames = filter(creators, creator => creator?.profession?.slug === 'composer')
    .map(composer => composer?.profile?.shortName || composer?.profile?.name)
    .filter(Boolean);

  return withSeparator ? join(composerNames, ' | ') : composerNames;
};

/**
 * Returns formatted name from creators array
 *
 * @param {Array} creators
 * @returns {string}
 */
export const getComposersFromCreators = (creators = []) => {
  const composers = filter(creators, creator => creator?.profession?.slug === 'composer');

  return composers;
};

/**
 * Returns formatted name from creators array
 *
 * @param {Array} creators
 * @returns {string}
 */
export const getComposerFromCreators = (creators = []) => {
  const composers = filter(creators, creator => creator?.profession?.slug === 'composer');

  return head(composers);
};

export const getUniqueComposersFromProduction = production => {
  const uniqueMap = {};
  map(production?.productionWorks, ({ work }) => {
    map(work?.creators, creator => {
      if (creator?.profession?.slug === 'composer') {
        uniqueMap[creator?.profile?.id] = creator?.profile?.shortName || creator?.profile?.name;
      }
    });
  });
  return Object.values(uniqueMap);
};
