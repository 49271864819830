import { createRoutine } from 'redux-saga-routines';

import {
  GET_AGENCIES_COUNTRIES,
  GET_AGENCY_INFO,
  GET_OFFICES,
  GET_ARTISTS,
  GET_AGENCY_AGREEMENTS,
  DELETE_ARTIST_FROM_ROSTER,
  ADD_ARTIST_TO_ROSTER,
  GET_USER_PROFILE_AGENT_INFO,
  IS_ARTIST_IN_AGENCY,
  GET_SINGLE_ARTIST_INFO,
  GET_AGENCY_COMMON_INFO,
  GET_AGENTS,
  GET_ARTISTS_LIST_SUGGESTIONS,
  CLEAR_STATUS_OF_ARTIST_IN_AGENCY_DATA,
  UPDATE_AGENCY,
  UPDATE_ARTIST_INFO,
  GET_AGENCY_CONTACT,
  SET_AGENCY_INFO,
} from './constants';

export const getAgencyInfoAction = createRoutine(GET_AGENCY_INFO);
export const getAgencyContactAction = createRoutine(GET_AGENCY_CONTACT);
export const getAgencyCommonInfoAction = createRoutine(GET_AGENCY_COMMON_INFO);
export const getOfficesAction = createRoutine(GET_OFFICES);
export const getAgentsAction = createRoutine(GET_AGENTS);
export const getArtistsAction = createRoutine(GET_ARTISTS);
export const getAgencyAgreementsAction = createRoutine(GET_AGENCY_AGREEMENTS);
export const getAgencyCountriesAction = createRoutine(GET_AGENCIES_COUNTRIES);
export const getUserProfileAgentInfoAction = createRoutine(GET_USER_PROFILE_AGENT_INFO);
export const deleteArtistFromRosterAction = createRoutine(DELETE_ARTIST_FROM_ROSTER);
export const addArtistToRosterAction = createRoutine(ADD_ARTIST_TO_ROSTER);
export const clearStatusOfArtistInAgencyDataAction = createRoutine(CLEAR_STATUS_OF_ARTIST_IN_AGENCY_DATA);
export const checkArtistInAgencyAction = createRoutine(IS_ARTIST_IN_AGENCY);
// agreement page
export const getSingleArtistInfoAction = createRoutine(GET_SINGLE_ARTIST_INFO);
export const getArtistsListSuggestionsAction = createRoutine(GET_ARTISTS_LIST_SUGGESTIONS);
export const updateArtistAction = createRoutine(UPDATE_ARTIST_INFO);
// update agency page
export const updateAgencyAction = createRoutine(UPDATE_AGENCY);

export function setAgency(agency) {
  return {
    type: SET_AGENCY_INFO,
    payload: { agency },
  };
}
