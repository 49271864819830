import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import route from '../../constants/routes';

export const GET_PERFORMANCES = 'app/Home/GET_PERFORMANCES';
export const GET_HOME_NUMBERS = 'app/Home/GET_HOME_NUMBERS';
export const GET_ARTISTS = 'app/Home/GET_ARTISTS';
export const GET_HIGHLIGHTS = 'app/Home/GET_HIGHLIGHTS';

export const viewBlocks = [
  {
    id: 1,
    title: 'operabase.general.m_FOOTER_NEWANDRARE',
    description: 'operabase.general.FN_NERANDRARETEXT',
    linkText: 'operabase.general.FN_VIEWNEWANDRARE',
    linkProps: { href: route.NEW_RARE, as: route.NEW_RARE },
  },
  {
    id: 2,
    title: 'operabase.general.m_HIGHLIGHTS',
    description: 'operabase.general.FN_HIGHLIGHTSTEXT',
    linkText: 'operabase.general.FN_VIEWHIGHLIGHTS',
    linkProps: { href: route.HIGHLIGHTS, as: route.HIGHLIGHTS },
  },
  {
    id: 3,
    title: 'operabase.general.m_FESTIVALS',
    description: 'operabase.general.FN_FESTIVALSTEXT',
    linkText: 'operabase.general.FN_VIEWFESTIVALS',
    linkProps: { href: route.FESTIVALS, as: route.FESTIVALS },
  },
];

export const sectionBlocks = [
  {
    id: 4,
    title: 'operabase.general.m_PROAGENT',
    description: 'operabase.general.FN_FORAGENTSTEXT',
    linkText: 'operabase.general.FN_LEARNMORE',
    linkProps: { href: route.DETAILS_AGENTS, as: route.DETAILS_AGENTS },
    subComponent: SUB_COMPONENTS.PRO_AGENCIES, // used for tracking
  },
  {
    id: 5,
    title: 'operabase.general.m_PROARTIST',
    description: 'operabase.general.FN_FORARTISTSTEXT',
    linkText: 'operabase.general.FN_LEARNMORE',
    linkProps: { href: route.DETAILS_ARTISTS, as: route.DETAILS_ARTISTS },
    subComponent: SUB_COMPONENTS.PRO_ARTISTS, // used for tracking
  },
  {
    id: 6,
    title: 'operabase.general.m_PROAO',
    description: 'operabase.general.FN_FORARTSORGANISATIONSTEXT',
    linkText: 'operabase.general.FN_LEARNMORE',
    linkProps: { href: route.DETAILS_AOS, as: route.DETAILS_AOS },
    subComponent: SUB_COMPONENTS.PRO_ORGANIZATIONS, // used for tracking
  },
];
