import { useCallback } from 'react';
import { useQuery } from 'utils/react-query';

import globalQueries from 'containers/Globals/queries';

const shouldFetchLocationFilter = filter =>
  (!filter?.name || !filter?.id) && (!!filter?.id || !!filter?.slug || !!filter?.name);

const useBaseLocationQuery = (queryFn, filter, options) => {
  const { filters: otherFilters, enabled = true, select } = options || {};
  const shouldFetchFilterValue = shouldFetchLocationFilter(filter);

  return useQuery(
    queryFn({
      filters: {
        ...(otherFilters && otherFilters),
        ...(filter?.id && { id: filter.id }),
        ...(!filter?.id && filter?.slug && { slug: filter.slug }),
        ...(!filter?.id && !filter?.slug && filter?.name && { query: filter.name }),
      },
      limit: 1,
      queryConfig: {
        enabled: enabled && shouldFetchFilterValue,
        select: response => {
          const value = response?.data?.[0];

          if (typeof select === 'function') {
            return select(value);
          }

          return value;
        },
        ...(!shouldFetchFilterValue && filter?.name && filter?.id && { initialData: { data: [filter] } }),
      },
    }),
  );
};

export const useAppliedRegionFilter = (filter, options = {}) =>
  useBaseLocationQuery(globalQueries.getRegions, filter, options);

export const useAppliedCountryFilter = (filter, options = {}) =>
  useBaseLocationQuery(globalQueries.getCountries, filter, options);

export const useAppliedCityFilter = (filter, options = {}) =>
  useBaseLocationQuery(globalQueries.getCities, filter, options);

export const useVisitorLocation = (ipLocation, options) => {
  const { enabled = true } = options || {};
  const { continent: ipContinent, country: ipCountry, city: ipCity } = ipLocation || {};
  const hasContinentQueryEnabled = !!ipContinent;
  const hasCountryQueryEnabled = !!ipCountry;
  const hasCityQueryEnabled = !!ipCity;

  const getLocationFilter = useCallback(value => {
    if (value) {
      return {
        id: value?.id,
        name: value?.name,
        slug: value?.slug,
        ...(value?.country && { country: value?.country }),
      };
    }

    return value;
  }, []);

  const { data: region, isFetching: isRegionFetching, isFetched: isRegionFetched } = useAppliedRegionFilter(
    {
      name: ipContinent,
    },
    {
      enabled: enabled && hasContinentQueryEnabled,
      select: getLocationFilter,
    },
  );
  const { data: country, isFetching: isCountryFetching, isFetched: isCountryFetched } = useAppliedCountryFilter(
    {
      name: ipCountry,
    },
    {
      enabled: enabled && hasCountryQueryEnabled,
      select: getLocationFilter,
    },
  );
  const { data: city, isFetching: isCityFetching, isFetched: isCityFetched } = useAppliedCityFilter(
    { name: ipCity },
    {
      enabled: enabled && hasCityQueryEnabled && !isCountryFetching,
      filters: {
        country: country?.id,
      },
      select: getLocationFilter,
    },
  );

  const isRegionReady = (isRegionFetched && !isRegionFetching) || !hasContinentQueryEnabled;
  const isCountryReady = (isCountryFetched && !isCountryFetching) || !hasCountryQueryEnabled;
  const isCityReady = (isCityFetched && !isCityFetching) || !hasCityQueryEnabled;

  if (enabled && isRegionReady && isCountryReady && isCityReady) {
    return {
      region,
      country: country || city?.country,
      city,
      isFetched: true,
    };
  }

  return {
    isFetched: false,
  };
};
