import { createRoutine } from 'redux-saga-routines';
import {
  DELETE_IMAGE,
  GET_EDIT_IMAGES_PRODUCTIONS,
  GET_IMAGES,
  GET_IMAGES_MEDIA_ATTRIBUTE_TYPES,
  GET_IMAGE_FORMAT_TYPES,
  UPDATE_IMAGE,
  CREATE_IMAGE,
  UPDATE_MEDIA_TAG,
  CLEAR_IMAGES,
} from './constants';

export const getImagesAction = createRoutine(GET_IMAGES);
export const getImagesMediaAttrTypesAction = createRoutine(GET_IMAGES_MEDIA_ATTRIBUTE_TYPES);
export const getImageFormatAttrTypesAction = createRoutine(GET_IMAGE_FORMAT_TYPES);
export const deleteImageAction = createRoutine(DELETE_IMAGE);
export const updateImageAction = createRoutine(UPDATE_IMAGE);
export const updateMediaTagAction = createRoutine(UPDATE_MEDIA_TAG);
export const createImagesAction = createRoutine(CREATE_IMAGE);
export const getEditImageProductionsAction = createRoutine(GET_EDIT_IMAGES_PRODUCTIONS);

export function clearImagesAction() {
  return {
    type: CLEAR_IMAGES,
    payload: {},
  };
}
