import { findIndex, get, pick } from 'lodash';
import {
  getChosenProfileDataAction,
  getChosenProfileRepertoireAction,
  updateRepertoireStatusAction,
  setChosenProfileActiveVideoAction,
  getChosenProfileReviewsAction,
  getChosenProductionReviewsAction,
  getChosenProfileProductionsAction,
  getProfileProductionsSeasonsAction,
  getProfileWorksListAction,
  updateProfileInfoAction,
  getChosenProfileBioAction,
  updateBioAction,
  addBioAction,
  getContactsAction,
  updateContactsAction,
  addReviewAction,
  getChosenProfileAgreementsAction,
  updateReviewAction,
  getArtistProfessions,
  getArtistRoles,
  getProductionCastAction,
  deleteProductionAction,
  getDatesInProductionsAction,
  getArtistImagesAction,
  getArtistBannerImagesAction,
  updateProfileVideoAction,
  addProfileVideoAction,
  getProfileVideoAction,
  getArtistProfileIntroShowReelsAction,
  getArtistProfileAuditionShowReelsAction,
  getRepertoireProductionListAction,
  updateRepertoireModalAction,
  getRepertoireVideoListAction,
  getArtistRecommendationsAction,
  getRepertoireImageListAction,
  deleteReviewAction,
  removeProductionAction,
  getArtistVideoFormatAttrTypesAction,
  getArtistImageFormatAttrTypesAction,
  updateRepertoireAction,
  getArtistProductionsAction,
  updateProductionAction,
  updateProductionPrivacyAction,
  getChosenProfileChangeRequestAction,
  getProfileLSVODAction,
  getArtistProductionImagesAction,
  getArtistProductionVideosAction,
  updateArtistBannerAction,
  uploadArtistBannerAction,
  deleteImageAction,
  getArtistTranslationsAction,
  deleteArtistTranslationsAction,
  getProfilePerformanceAction,
} from './actions';

import { addRepertoireAction } from '../AddRepertoire/actions';

import {
  defaultListParams,
  listReducer,
  entityReducer,
  defaultEntityParams,
  namedListParams,
} from '../../utils/crudUtils';
import {
  SET_PROFILE,
  CLEAR_PROFILE_WORKS_LIST,
  START_SEARCHING_PROFILE_WORKS_LIST,
  DELETE_BIO,
  DELETE_IMAGE,
  DELETE_VIDEO,
  REMOVE_REPERTOIRE_RECORD,
} from './constants';

export const key = 'Artist';

export const initialState = {
  profileData: defaultEntityParams,
  profileContacts: defaultListParams,
  artistProductions: defaultListParams,
  repetorieProductions: defaultEntityParams,
  repetorieImages: defaultEntityParams,
  repetorieVideos: defaultEntityParams,
  artistDatesInProductions: defaultListParams,
  repertoire: namedListParams('repertoire'),
  reviews: namedListParams('reviews'),
  productionReviews: namedListParams('productionReviews'),
  activeVideo: {},
  error: null,
  seasons: defaultListParams,
  worksList: defaultListParams,
  worksListStartSearching: false,
  professions: defaultListParams,
  roles: defaultListParams,
  productionCasts: defaultListParams,
  bio: namedListParams('bio'),
  images: namedListParams('images'),
  productionImages: namedListParams('productionImages'),
  bannerImages: namedListParams('bannerImages'),
  video: namedListParams('video'),
  productionVideos: namedListParams('productionVideos'),
  lsVOD: namedListParams('lsVOD'),
  introReels: namedListParams('introReels'),
  auditionReels: namedListParams('auditionReels'),
  repertoireModal: false,
  chosenRepetorie: {},
  mediaUpdating: false,
  productions: namedListParams('productions'),
  videoFormats: namedListParams('videoFormats'),
  imageFormats: namedListParams('imageFormats'),
  privacyProductionIdUpdating: null,
};

const artistReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getChosenProfileDataAction.TRIGGER:
    case getChosenProfileDataAction.SUCCESS:
    case getChosenProfileDataAction.FAILURE:
      return entityReducer(state, type, payload, 'profileData');
    case SET_PROFILE:
      return { ...state, profileData: payload.profile };

    case getChosenProfileRepertoireAction.TRIGGER:
    case getChosenProfileRepertoireAction.SUCCESS:
    case getChosenProfileRepertoireAction.FAILURE:
      return listReducer(state, type, payload, state?.repertoire?.listName);
    case updateRepertoireStatusAction.SUCCESS: {
      const updatedRepertoire = payload?.entity;
      const listName = state?.repertoire?.listName;
      const listValue = state?.[listName] ?? {};

      return {
        ...state,
        [listName]: {
          ...listValue,
          data: listValue?.data?.map(repertoire =>
            repertoire?.id === updatedRepertoire?.id ? updatedRepertoire : repertoire,
          ),
        },
      };
    }

    case addRepertoireAction.SUCCESS: {
      const updatedRepertoire = payload?.entity;
      const listName = state?.repertoire?.listName;
      const listValue = state?.[listName] ?? {};

      return {
        ...state,
        [listName]: {
          ...listValue,
          data: [...listValue?.data, updatedRepertoire],
        },
      };
    }

    case getChosenProfileReviewsAction.TRIGGER:
    case getChosenProfileReviewsAction.SUCCESS:
    case getChosenProfileReviewsAction.FAILURE:
      return listReducer(state, type, payload, state?.reviews?.listName);

    case getChosenProductionReviewsAction.TRIGGER:
    case getChosenProductionReviewsAction.SUCCESS:
    case getChosenProductionReviewsAction.FAILURE:
      return listReducer(state, type, payload, state?.productionReviews?.listName);

    case getArtistProfessions.TRIGGER:
    case getArtistProfessions.SUCCESS:
    case getArtistProfessions.FAILURE:
      return listReducer(state, type, payload, 'professions');

    case getArtistRoles.TRIGGER:
    case getArtistRoles.SUCCESS:
    case getArtistRoles.FAILURE:
      return listReducer(state, type, payload, 'roles');

    case getProductionCastAction.TRIGGER:
    case getProductionCastAction.SUCCESS:
    case getProductionCastAction.FAILURE:
      return listReducer(state, type, payload, 'productionCasts');

    case setChosenProfileActiveVideoAction.TRIGGER:
      return { ...state, activeVideo: payload };

    case getChosenProfileProductionsAction.FAILURE:
    case getChosenProfileProductionsAction.TRIGGER:
    case getChosenProfileProductionsAction.SUCCESS:
      return listReducer(state, type, payload, 'artistProductions');

    case getChosenProfileChangeRequestAction.FAILURE:
    case getChosenProfileChangeRequestAction.TRIGGER:
    case getChosenProfileChangeRequestAction.SUCCESS:
      return listReducer(state, type, payload, 'profileChangeRequests');

    case getProfilePerformanceAction.FAILURE:
    case getProfilePerformanceAction.TRIGGER:
    case getProfilePerformanceAction.SUCCESS:
      return listReducer(state, type, payload, 'profilePerformances');

    case getRepertoireProductionListAction.FAILURE:
    case getRepertoireProductionListAction.TRIGGER:
    case getRepertoireProductionListAction.SUCCESS:
      return listReducer(state, type, payload, 'repetorieProductions');

    case getArtistRecommendationsAction.FAILURE:
    case getArtistRecommendationsAction.TRIGGER:
    case getArtistRecommendationsAction.SUCCESS:
      return listReducer(state, type, payload, 'recommendations');

    case getRepertoireVideoListAction.FAILURE:
    case getRepertoireVideoListAction.TRIGGER:
    case getRepertoireVideoListAction.SUCCESS:
      return listReducer(state, type, payload, 'repetorieVideos');

    case getRepertoireImageListAction.FAILURE:
    case getRepertoireImageListAction.TRIGGER:
    case getRepertoireImageListAction.SUCCESS:
      return listReducer(state, type, payload, 'repetorieImages');

    case updateProfileInfoAction.TRIGGER:
    case updateProfileInfoAction.FAILURE:
    case updateProfileInfoAction.SUCCESS:
      return entityReducer(state, type, payload, 'profileData');

    case getChosenProfileBioAction.TRIGGER:
    case getChosenProfileBioAction.FAILURE:
    case getChosenProfileBioAction.SUCCESS:
      return listReducer(state, type, payload, state.bio.listName);

    case getContactsAction.TRIGGER:
    case getContactsAction.FAILURE:
    case getContactsAction.SUCCESS:
      return entityReducer(
        state,
        type,
        payload.data ? { ...payload, data: payload.data.map(el => ({ ...el, name: el.name.toLowerCase() })) } : payload,
        'profileContacts',
      );

    case updateContactsAction.SUCCESS:
      return { ...state, profileContacts: { ...state.profileContacts, data: payload?.entity?.contacts } };

    case updateBioAction.TRIGGER:
    case addBioAction.TRIGGER: {
      const bioListName = state.bio.listName;
      return { ...state, [bioListName]: { ...state[bioListName], loading: true } };
    }
    case updateBioAction.FAILURE:
    case addBioAction.FAILURE: {
      const bioListName = state.bio.listName;
      return { ...state, [bioListName]: { ...state[bioListName], loading: false } };
    }

    case updateBioAction.SUCCESS: {
      const bioListName = state.bio.listName;
      const bios = [...(state[bioListName]?.data || [])];
      const updatedBioIndex = findIndex(state[bioListName]?.data || [], { id: payload?.entity?.id });
      bios.splice(updatedBioIndex, 1, payload?.entity);
      return { ...state, [bioListName]: { ...state[bioListName], data: bios, loading: false } };
    }

    case addBioAction.SUCCESS: {
      const bioListName = state.bio.listName;
      const bios = [...(state[bioListName]?.data || []), payload?.entity];
      return { ...state, [bioListName]: { ...state[bioListName], data: bios, loading: false } };
    }

    case DELETE_BIO: {
      const bios = [...(state?.bio?.data || [])].filter(b => b?.id !== payload?.id);
      return { ...state, bio: { ...state.bio, data: bios } };
    }

    case getChosenProfileAgreementsAction.FAILURE:
    case getChosenProfileAgreementsAction.TRIGGER:
    case getChosenProfileAgreementsAction.SUCCESS:
      return listReducer(state, type, payload, 'artistAgreements');

    case addReviewAction.TRIGGER:
      return { ...state, mediaUpdating: true };
    case addReviewAction.SUCCESS:
    case addReviewAction.FAILURE:
      return { ...state, mediaUpdating: false };

    case updateReviewAction.TRIGGER:
      return { ...state, mediaUpdating: true };
    case updateReviewAction.FAILURE:
      return { ...state, mediaUpdating: false };
    case updateReviewAction.SUCCESS:
      return {
        ...state,
        mediaUpdating: false,
        reviews: {
          ...state?.reviews,
          data: state?.reviews?.data?.map(review => {
            if (payload?.entity?.id === review?.id) {
              return payload?.entity;
            }

            return review;
          }),
        },
        productionReviews: {
          ...state.productionReviews,
          data: state?.productionReviews?.data?.map(review => {
            if (payload?.entity?.id === review?.id) {
              return payload?.entity;
            }

            return review;
          }),
        },
      };

    case getProfileProductionsSeasonsAction.FAILURE:
    case getProfileProductionsSeasonsAction.TRIGGER:
    case getProfileProductionsSeasonsAction.SUCCESS:
      return listReducer(state, type, payload, 'seasons');

    case getDatesInProductionsAction.FAILURE:
    case getDatesInProductionsAction.TRIGGER:
    case getDatesInProductionsAction.SUCCESS:
      return listReducer(state, type, payload, 'artistDatesInProductions');

    case getProfileWorksListAction.FAILURE:
    case getProfileWorksListAction.TRIGGER:
    case getProfileWorksListAction.SUCCESS:
      return { ...listReducer(state, type, payload, 'worksList'), worksListStartSearching: false };

    case CLEAR_PROFILE_WORKS_LIST:
      return { ...state, worksList: { ...state.worksList, data: [] } };

    case START_SEARCHING_PROFILE_WORKS_LIST:
      return { ...state, worksListStartSearching: true };

    case DELETE_IMAGE:
      return {
        ...state,
        images: { ...state.images, data: state.images.data.filter(i => i?.id !== payload?.id) },
        bannerImages: { ...state.bannerImages, data: state.bannerImages.data.filter(bI => bI?.id !== payload?.id) },
      };
    case DELETE_VIDEO:
      return { ...state, video: { ...state.video, data: state.video.data.filter(i => i?.id !== payload?.id) } };

    case deleteProductionAction.SUCCESS:
      return {
        ...state,
        artistProductions: {
          ...state.artistProductions,
          data: get(state, 'artistProductions.data', []).filter(p => p?.id !== payload.id),
        },
      };
    case deleteProductionAction.FAILURE:
      return { ...state, error: payload };

    case getArtistImagesAction.FAILURE:
    case getArtistImagesAction.TRIGGER:
    case getArtistImagesAction.SUCCESS:
      return listReducer(state, type, payload, initialState.images.listName);

    case getArtistProductionImagesAction.FAILURE:
    case getArtistProductionImagesAction.TRIGGER:
    case getArtistProductionImagesAction.SUCCESS:
      return listReducer(state, type, payload, initialState.productionImages.listName);

    case getArtistBannerImagesAction.FAILURE:
    case getArtistBannerImagesAction.TRIGGER:
      return { ...state, bannerImages: {} };
    case getArtistBannerImagesAction.SUCCESS:
      return listReducer(state, type, payload, initialState.bannerImages.listName);

    case deleteImageAction.SUCCESS: {
      const mediaId = payload?.id;

      return {
        ...state,
        [initialState.bannerImages.listName]: {
          ...state?.[initialState.bannerImages.listName],
          data: state?.[initialState.bannerImages.listName]?.data?.filter(media => media?.id !== mediaId),
        },
      };
    }

    case uploadArtistBannerAction.SUCCESS: {
      return {
        ...state,
        [initialState.bannerImages.listName]: {
          ...state?.[initialState.bannerImages.listName],
          data: payload,
        },
      };
    }

    case updateArtistBannerAction.SUCCESS: {
      const mediaId = payload?.id;

      return {
        ...state,
        [initialState.bannerImages.listName]: {
          ...state?.[initialState.bannerImages.listName],
          data: state?.[initialState.bannerImages.listName]?.data?.map(media => {
            if (media?.id === mediaId) {
              return payload;
            }

            return media;
          }),
        },
      };
    }

    case deleteReviewAction.TRIGGER:
      return { ...state, mediaUpdating: true };
    case deleteReviewAction.SUCCESS: {
      return {
        ...state,
        reviews: { ...state.reviews, data: state.reviews.data.filter(r => r?.id !== payload?.id) },
        mediaUpdating: false,
      };
    }
    case deleteReviewAction.FAILURE:
      return { ...state, mediaUpdating: false };

    case getProfileVideoAction.FAILURE:
    case getProfileVideoAction.TRIGGER:
    case getProfileVideoAction.SUCCESS:
      return listReducer(state, type, payload, initialState.video.listName);

    case getArtistProfileIntroShowReelsAction.FAILURE:
    case getArtistProfileIntroShowReelsAction.TRIGGER:
    case getArtistProfileIntroShowReelsAction.SUCCESS:
      return listReducer(state, type, payload, initialState.introReels.listName);

    case getArtistProfileAuditionShowReelsAction.FAILURE:
    case getArtistProfileAuditionShowReelsAction.TRIGGER:
    case getArtistProfileAuditionShowReelsAction.SUCCESS:
      return listReducer(state, type, payload, initialState.auditionReels.listName);

    case getArtistProductionVideosAction.FAILURE:
    case getArtistProductionVideosAction.TRIGGER:
    case getArtistProductionVideosAction.SUCCESS:
      return listReducer(state, type, payload, initialState.productionVideos.listName);

    case getProfileLSVODAction.FAILURE:
    case getProfileLSVODAction.TRIGGER:
    case getProfileLSVODAction.SUCCESS:
      return listReducer(state, type, payload, initialState.lsVOD.listName);

    case updateProfileVideoAction.TRIGGER:
      return { ...state, mediaUpdating: true };
    case updateProfileVideoAction.SUCCESS: {
      const video = [...state?.video?.data];
      const updatedReviewIndex = findIndex(state?.video?.data, { id: payload?.entity?.id });
      video.splice(updatedReviewIndex, 1, payload?.entity);
      return { ...state, video: { ...state.video, data: video }, mediaUpdating: false };
    }
    case updateProfileVideoAction.FAILURE:
      return { ...state, mediaUpdating: false };

    case addProfileVideoAction.TRIGGER:
      return { ...state, mediaUpdating: true };
    case addProfileVideoAction.SUCCESS: {
      return {
        ...state,
        video: { ...state.video, data: [...state.video.data, payload?.entity] },
        mediaUpdating: false,
      };
    }
    case addProfileVideoAction.FAILURE:
      return { ...state, mediaUpdating: false };

    case updateRepertoireModalAction.SUCCESS: {
      return {
        ...state,
        repertoireModal: payload?.flag,
        chosenRepetorie: payload?.rep || {},
        repetorieProductions: defaultEntityParams,
        repetorieImages: defaultEntityParams,
        repetorieVideos: defaultEntityParams,
      };
    }

    case removeProductionAction.SUCCESS:
      return {
        ...state,
        artistProductions: {
          ...state.artistProductions,
          data: state.artistProductions.data.filter(el => el.id !== payload.entity?.production?.id),
        },
      };

    case updateProductionAction.SUCCESS:
      return {
        ...state,
        artistProductions: {
          ...state.artistProductions,
          data: state.artistProductions.data.map(el => {
            if (el.id === payload.entity?.production?.id) {
              const newPrivacy = [
                ...el.privacy.filter(item => item?.profile?.id !== payload.entity.profile?.id),
                pick(payload.entity, ['profile', 'privacyAttribute', 'acknowledged']),
              ];
              return { ...el, privacy: newPrivacy };
            }
            return el;
          }),
        },
      };

    case REMOVE_REPERTOIRE_RECORD:
      return {
        ...state,
        repertoire: { ...state.repertoire, data: state.repertoire?.data?.filter(record => record?.id !== payload?.id) },
      };

    case getArtistProductionsAction.FAILURE:
    case getArtistProductionsAction.TRIGGER:
    case getArtistProductionsAction.SUCCESS:
      return listReducer(state, type, payload, 'productions');

    case getArtistVideoFormatAttrTypesAction.FAILURE:
    case getArtistVideoFormatAttrTypesAction.TRIGGER:
    case getArtistVideoFormatAttrTypesAction.SUCCESS:
      return listReducer(state, type, payload, state.videoFormats.listName);

    case getArtistImageFormatAttrTypesAction.FAILURE:
    case getArtistImageFormatAttrTypesAction.TRIGGER:
    case getArtistImageFormatAttrTypesAction.SUCCESS:
      return listReducer(state, type, payload, state.imageFormats.listName);

    case getArtistTranslationsAction.TRIGGER:
    case getArtistTranslationsAction.SUCCESS:
    case getArtistTranslationsAction.FAILURE:
      return listReducer(state, type, payload, 'translations');

    case deleteArtistTranslationsAction.TRIGGER:
    case deleteArtistTranslationsAction.FAILURE:
      return { ...state, translations: { ...state.translations, loading: true } };
    case deleteArtistTranslationsAction.SUCCESS: {
      return {
        ...state,
        translations: {
          ...state.translations,
          data: state.translations.data.filter(d => d?.id !== payload?.id),
          loading: false,
        },
      };
    }

    case updateProductionPrivacyAction.FAILURE:
    case updateProductionPrivacyAction.TRIGGER:
    case updateProductionPrivacyAction.SUCCESS:
      return { ...state, privacyProductionIdUpdating: payload?.productionId };

    case updateRepertoireAction.SUCCESS: {
      const repertoireIndex = state.repertoire.data.findIndex(el => el.id === payload.entity.id);
      return {
        ...state,
        repertoire: {
          ...state.repertoire,
          data: Object.assign([], state.repertoire.data, { [repertoireIndex]: payload.entity }),
        },
      };
    }
    default:
      return state;
  }
};

export default artistReducer;
