// URL to test analytics: http://localhost:3000/v4/analytics/en
import React, { useState, useEffect, useMemo, memo } from 'react';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import classnames from 'classnames';
import { PrimaryButton, TertiaryButton } from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import { EVENT_TYPES } from 'components/Globals/Analytics/constants';
import { useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';
import useAppContext from 'utils/hooks/useAppContext';
import { getProfileName } from 'utils/profileDropdown';
import { getPageTranslations } from 'src/i18n';
import classes from './analytics.module.scss';

const NUMBER_OF_EVENTS_TO_SHOW = 100;

export const FingerprintUsageReport = ({ usage = {}, thresholds = {} }) => (
  <div className={classes.fingerprintReport}>
    <div className={classes.report_header}>
      <Typography variant="p" size="14" weight="bold">
        Fingerprint Usage
      </Typography>
    </div>
    <div className={classes.fingerprintReport_data}>
      {_sortBy(Object.keys(thresholds), key => parseInt(key.replace(/[^\d]/, ''), 10)).map((key, index) => {
        const usageScore = usage[key] || 0;
        const softWallScore = thresholds[key]?.softWall || 0;
        const hardWallScore = thresholds[key]?.hardWall || 0;
        const isSoftWallExceeded = softWallScore && usageScore > softWallScore;
        const isHardWallExceeded = hardWallScore && usageScore > hardWallScore;

        return (
          <div
            className={classnames(classes.fingerprintReport_cell, {
              [classes.fingerprintReport_cell_softWall]: isSoftWallExceeded && !isHardWallExceeded,
              [classes.fingerprintReport_cell_hardWall]: isHardWallExceeded,
            })}
            key={key}
          >
            <div className={classes.fingerprintReport_cell_title}>
              {key.replace(/[^\d]/, '')}
              {index > 0 ? ' days' : ' day'}
            </div>
            <div className={classes.fingerprintReport_cell_value}>
              <Typography variant="p" size="11">
                <Typography variant="span" weight="bold" size="11">
                  SW:{' '}
                </Typography>
                {softWallScore}
              </Typography>
              <Typography variant="p" size="11">
                <Typography variant="span" weight="bold" size="11">
                  HW:{' '}
                </Typography>
                {hardWallScore}
              </Typography>
              <Typography variant="p" size="14">
                {usageScore}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

const EventInfo = ({ info, event }) => <span title={info}>{event[info]}</span>;

const EventTag = ({ className, text, strike = false, title }) => {
  if (!text) {
    return <div className={className} />;
  }

  return (
    <div className={className} title={title}>
      <span className={classnames(classes.tag, { [classes.tag_strike]: strike })}>{text}</span>
    </div>
  );
};

const EventRow = ({ event, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { eventType } = event;
  const isErrored = useMemo(() => {
    if (event?.pageCategory === 'stub' && !event?.pageEntityId) {
      return true;
    }

    if (eventType === 'impression') {
      if (!event?.entityType || !event?.entityId) {
        return true;
      }

      if (!event?.section) {
        return true;
      }
    }

    if (eventType === 'click') {
      if (!event?.section) {
        return true;
      }

      if (event?.entityType && !event?.entityId) {
        return true;
      }
    }

    return false;
  }, [event, eventType]);

  return (
    <div className={classnames(classes.event, eventType, { [classes.event_errored]: isErrored })}>
      <div className={classes.event_basic} onClick={() => setIsExpanded(!isExpanded)}>
        <div className={classes.event_basic_index}>
          <div>{index}</div>
        </div>
        <div className={classes.event_basic_details}>
          <div className={classes.event_basic_extra}>
            <EventInfo info="eventName" event={event} />
          </div>
          <div className={classes.event_basic_main}>
            <EventInfo info="eventType" event={event} />
            {eventType === 'pageView' && <EventInfo info="pageEntityType" event={event} />}
            {eventType !== 'pageView' && <EventInfo info="entityType" event={event} />}
            <EventInfo info="category" event={event} />
            {eventType === 'search' && <EventInfo info="searchQuery" event={event} />}
          </div>
          <div className={classes.event_basic_extra}>
            <EventInfo info="section" event={event} />
            <EventInfo info="component" event={event} />
            <EventInfo info="subComponent" event={event} />
          </div>
          <div className={classes.event_basic_extra}>
            <EventInfo info="pageCategory" event={event} />
            <EventInfo info="pageType" event={event} />
            <EventInfo info="pageEntityType" event={event} />
            <EventInfo info="pageEntityId" event={event} />
            <EventInfo info="pageEntitySubscriptionStatus" event={event} />
          </div>
        </div>
        <div className={classes.event_basic_actions}>
          {eventType === 'pageView' ? (
            <EventTag className={classes.event_basic_actions__id} text={event?.pageEntityId} title="pageEntityId" />
          ) : (
            <EventTag className={classes.event_basic_actions__id} text={event?.entityId} title="entityId" />
          )}
          <EventTag
            className={classes.event_basic_actions__pro}
            text="PRO"
            strike={!event?.isProView}
            title="isProView"
          />
          <EventTag
            className={classes.event_basic_actions__edit}
            text="EDIT"
            strike={!event?.isEditView}
            title="isEditView"
          />
          {eventType === 'pageView' && (
            <EventTag
              className={classes.event_basic_actions__entityName}
              text={event?.pageEntityName}
              title="pageEntityName"
            />
          )}
          {eventType !== 'pageView' && (
            <EventTag className={classes.event_basic_actions__entityName} text={event?.entityName} title="entityName" />
          )}
          <EventTag
            className={classes.event_basic_actions__fingerprint}
            text={`Fingerprint: ${event?.fingerprint?.score || 0}`}
            title={event?.fingerprint?.message}
          />
        </div>
      </div>
      {isExpanded && (
        <div className={classes.event_raw}>
          <textarea value={JSON.stringify(event, null, 2)} />
        </div>
      )}
    </div>
  );
};

const AggregateRow = memo(
  ({ group, details = {} }) => {
    const { count, data, ...rest } = details;
    const [isExpanded, setIsExpanded] = useState(() => {
      if (count === 1) {
        return data?.length > 0;
      }

      return false;
    });

    return (
      <div className={classes.aggregateReport_row}>
        <div className={classes.aggregateReport_row_data} onClick={() => setIsExpanded(!isExpanded)}>
          <div>
            {isExpanded || data ? '-' : '+'} {group}
          </div>
          <div className={classes.aggregateReport_row_data__count}>{count}</div>
        </div>
        {isExpanded && data?.map((event, index) => <EventRow key={event.timestamp} event={event} index={index + 1} />)}
        {isExpanded &&
          Object.keys(rest)
            .sort()
            .map(subGroup => <AggregateRow key={subGroup} group={subGroup} details={rest[subGroup]} />)}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps?.details?.count === nextProps?.details?.count,
);

const DebouncedInput = ({ initialValue, onChange, placeholder }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, 500);

    return () => clearTimeout(timeout);
  }, [value, onChange]);

  return <input type="text" value={value} placeholder={placeholder} onChange={e => setValue(e.target.value)} />;
};

const AggregateEventsReport = ({ events }) => {
  const [filteredEntityId, setFilteredEntityId] = useState(null);

  const groupedEvents = useMemo(() => {
    const aggregate = events.reduce((acc, event) => {
      const { eventType, category, meta } = event;
      const entityType = eventType === 'pageView' ? event.pageEntityType : event.entityType;
      const entityId = eventType === 'pageView' ? event.pageEntityId : event.entityId;
      const entityName = eventType === 'pageView' ? event.pageEntityName : event.entityName;
      const { searchSessionId } = meta || {};

      if (filteredEntityId && entityId != filteredEntityId) {
        return acc;
      }

      if (!acc[eventType]) {
        acc[eventType] = {};
      }

      acc[eventType].count = (acc[eventType].count || 0) + 1;

      if (eventType === 'search') {
        const searchSessionIdKey = searchSessionId || 'missing search session';

        if (!acc[eventType][searchSessionIdKey]) {
          acc[eventType][searchSessionIdKey] = {
            data: [],
          };
        }
        acc[eventType][searchSessionIdKey].count = (acc[eventType][searchSessionIdKey].count || 0) + 1;
        acc[eventType][searchSessionIdKey].data.push(event);

        return acc;
      }

      const entityTypeKey = `${entityType || 'others'}`;
      if (!acc[eventType][entityTypeKey]) {
        acc[eventType][entityTypeKey] = {};
      }

      acc[eventType][entityTypeKey].count = (acc[eventType][entityTypeKey].count || 0) + 1;

      const entityIdKey = entityId ? `${entityName} (${entityId})` : 'others';

      if (!acc[eventType][entityTypeKey][entityIdKey]) {
        acc[eventType][entityTypeKey][entityIdKey] = {};
      }

      acc[eventType][entityTypeKey][entityIdKey].count = (acc[eventType][entityTypeKey][entityIdKey].count || 0) + 1;

      const entityCategoryKey = category || 'without category';

      if (!acc[eventType][entityTypeKey][entityIdKey][entityCategoryKey]) {
        acc[eventType][entityTypeKey][entityIdKey][entityCategoryKey] = {
          data: [],
        };
      }
      acc[eventType][entityTypeKey][entityIdKey][entityCategoryKey].count =
        (acc[eventType][entityTypeKey][entityIdKey][entityCategoryKey].count || 0) + 1;
      acc[eventType][entityTypeKey][entityIdKey][entityCategoryKey].data.push(event);

      return acc;
    }, {});

    return aggregate;
  }, [events, filteredEntityId]);

  return (
    <div className={classes.aggregateReport}>
      <div className={classes.report_header}>
        <p className={classes.report_title}>Aggregated Events</p>
        <div>
          <DebouncedInput
            initialValue={filteredEntityId}
            onChange={setFilteredEntityId}
            placeholder="Filter by entity id"
          />
        </div>
      </div>
      {Object.keys(groupedEvents)
        .sort()
        .map(eventType => (
          <AggregateRow key={eventType} group={eventType} details={groupedEvents[eventType]} />
        ))}
    </div>
  );
};

const TestingDashboard = () => {
  const [events, setEvents] = useState([]);
  const [usage, setUsage] = useState({});
  const [thresholds, setThresholds] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [filteredEventType, setFilteredEventType] = useState(null);
  const [onlyFingerprintEvents, showOnlyFingerprintEvents] = useState(false);

  useEffect(() => {
    if (!window) return;

    window.addEventListener(
      'message',
      event => {
        if (event?.data?.type === 'analytics') {
          const { events: eventsLog = [], scores } = event.data || {};
          const newEvents = eventsLog.map((entry, index) => ({
            ...entry,
            fingerprint: scores?.[index],
          }));
          if (event?.data?.usage) {
            setUsage(event?.data?.usage);
          }

          if (event?.data?.thresholds) {
            setThresholds(event?.data?.thresholds);
          }

          setEvents(prevEvents => [...newEvents.reverse(), ...prevEvents]);
        }
      },
      false,
    );
  }, []);

  const totalEvents = events?.length;

  if (!totalEvents) {
    return null;
  }

  const eventsToRender =
    showAll || filteredEventType || onlyFingerprintEvents ? events : events.slice(0, NUMBER_OF_EVENTS_TO_SHOW);

  return (
    <div className={classes.report}>
      <div>
        <div className={classes.report_header}>
          <p className={classes.report_title}>All Events</p>
          <div className={classes.report_actions}>
            <div>
              <input
                type="checkbox"
                checked={onlyFingerprintEvents}
                onChange={e => showOnlyFingerprintEvents(e.target.checked)}
              />{' '}
              Fingerprint Score &gt; 0
            </div>
            <select
              onChange={e => {
                const { value } = e.target;
                setFilteredEventType(value === 'all' ? null : value);
              }}
            >
              <option value="all" selected={!filteredEventType}>
                All Types
              </option>
              {Object.values(EVENT_TYPES).map(eventType => (
                <option key={eventType} value={eventType} selected={filteredEventType === eventType}>
                  {eventType}
                </option>
              ))}
            </select>
          </div>
        </div>

        {eventsToRender.map((event, idx) => {
          if (filteredEventType && filteredEventType !== event.eventType) {
            return null;
          }

          if (onlyFingerprintEvents && (!event?.fingerprint || event?.fingerprint?.score <= 0)) {
            return null;
          }

          return <EventRow key={`${event?.timestamp}_${idx}`} event={event} index={totalEvents - idx} />;
        })}
        {events?.length > NUMBER_OF_EVENTS_TO_SHOW && !filteredEventType && (
          <PrimaryButton
            shape="rectangle"
            onClick={() => setShowAll(!showAll)}
            size="small"
            styles={{
              root: classes.showMoreButton,
            }}
          >
            {showAll ? 'Show Less' : `Show ${events.length - NUMBER_OF_EVENTS_TO_SHOW} more`}
          </PrimaryButton>
        )}
      </div>
      <div className={classes.report_consolidated}>
        <AggregateEventsReport events={events} />
        <FingerprintUsageReport usage={usage} thresholds={thresholds} />
        <div className={classes.report_actions}>
          <TertiaryButton
            shape="rectangle"
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(events));
            }}
            size="small"
          >
            Copy as JSON
          </TertiaryButton>
          <TertiaryButton shape="rectangle" onClick={() => setEvents([])} size="small">
            Clear events
          </TertiaryButton>
        </div>
      </div>
    </div>
  );
};
const AnalyticsTester = () => {
  const { language, isLoggedIn } = useAppContext();
  const baseURL = `${process.env.FRONTBASE_URL}/${language}`;
  const activeProfile = useActiveProfileData();
  const [inputValue, setInputValue] = useState(baseURL);

  const handleSubmit = path => {
    const mainApp = window.open(path);
    mainApp.onload = () => {
      mainApp.analyticsWindow = window;
    };
  };

  const ctaLabel = useMemo(() => {
    let label = 'Start Testing';

    if (isLoggedIn) {
      if (activeProfile?.name) {
        label += ` (${getProfileName(activeProfile)})`;
      } else {
        label += ' (Logged in)';
      }
    } else {
      label += ' (Logged out)';
    }

    return label;
  }, [isLoggedIn, activeProfile]);

  return (
    <div className={classes.analytics_dashboard}>
      <div className={classes.formContent}>
        Enter a Page URL below and click Start Testing to open the URL in a new window and begin analyzing analytics
        data. Note: Events are displayed in reverse chronological order.{' '}
        <input
          placeholder={`Please enter URL, Examples: ${baseURL} or ${baseURL}/anna-netrebko-a1344/en`}
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          className={classes.input}
        />
        <PrimaryButton shape="rectangle" onClick={() => handleSubmit(inputValue)}>
          {ctaLabel}
        </PrimaryButton>
      </div>
      <TestingDashboard />
    </div>
  );
};

AnalyticsTester.i18nNamespaces = getPageTranslations(['NS_ENTITY_STUB_PAGE']);

export default AnalyticsTester;
