import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { dateSendFormat } from 'utils/globals/app';
import { ARTIST_TYPE_ID, PRODUCTION_VALIDITY_IDENTIFIERS } from 'constants/index';
import { getHomePerformancesAction, getHomeNumbersAction, getHomeArtistsAction } from './actions';
import { getProductions, getHomeNumbers, searchProfiles } from '../../utils/API';

export function* fetchArtists({ serverCookies }) {
  try {
    const response = yield call(
      searchProfiles,
      ARTIST_TYPE_ID,
      '',
      '',
      { limit: 14, page: 1 },
      { sort: 'random', tier: 'A' },
      null,
      serverCookies,
    );
    yield put(getHomeArtistsAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getHomeArtistsAction.failure(err.message));
  } finally {
    yield put(getHomeArtistsAction.fulfill());
  }
}

export function* fetchPerformances({ payload, serverCookies }) {
  try {
    const response = yield call(
      getProductions,
      {
        ...payload,
        date_from: dateSendFormat(),
        date_to: dateSendFormat(),
        is_producer_approved: true,
        approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
        sort: 'random',
      },
      { limit: 5 },
      serverCookies,
    );
    yield put(getHomePerformancesAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getHomePerformancesAction.failure(err.message));
  } finally {
    yield put(getHomePerformancesAction.fulfill());
  }
}

export function* fetchHomeNumbers({ serverCookies }) {
  try {
    const response = yield call(getHomeNumbers, { id: 'all' }, serverCookies);

    yield put(getHomeNumbersAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getHomeNumbersAction.failure(err.message));
  } finally {
    yield put(getHomeNumbersAction.fulfill());
  }
}

export default function* homeSaga() {
  yield takeLatest(getHomeArtistsAction.TRIGGER, fetchArtists);
  yield takeLatest(getHomePerformancesAction.TRIGGER, fetchPerformances);
  yield takeLatest(getHomeNumbersAction.TRIGGER, fetchHomeNumbers);
}
