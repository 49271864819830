import {
  getOfficesAction,
  getArtistsAction,
  getAgencyAgreementsAction,
  getAgencyCountriesAction,
  getAgencyInfoAction,
  getUserProfileAgentInfoAction,
  addArtistToRosterAction,
  checkArtistInAgencyAction,
  getSingleArtistInfoAction,
  getAgencyCommonInfoAction,
  getAgentsAction,
  getArtistsListSuggestionsAction,
  clearStatusOfArtistInAgencyDataAction,
  getAgencyContactAction,
  updateAgencyAction,
  updateArtistAction,
} from './actions';
import { SET_AGENCY_INFO } from './constants';

export const key = 'Agencies';

export const initialState = {
  agencyInfo: {},
  agencyContact: {},
  agencyAgents: [],
  agentInfoOfUserProfile: {},
  offices: [],
  artists: [],
  agreements: [],
  agencyCountries: [],
  loading: false,
  error: null,
  artistInRosterData: null,
  artistInfo: {},
  artistsSuggestionsList: {},
  artistsSuggestionsListLoading: false,
  agencyUpdating: false,
  checkArtistInAgencyLoading: false,
};

const agenciesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getAgencyCommonInfoAction.TRIGGER:
      return { ...state, loading: true, error: null };
    case getAgencyCommonInfoAction.SUCCESS:
      return { ...state, agencyInfo: payload, loading: false };
    case getAgencyInfoAction.FAILURE:
    case getAgencyCommonInfoAction.FAILURE:
      return { ...state, error: payload, loading: false };

    case getOfficesAction.SUCCESS:
      return { ...state, offices: payload };
    case getOfficesAction.FAILURE:
      return { ...state, error: payload, offices: [] };

    case getArtistsAction.TRIGGER:
      return { ...state, error: null };
    case getArtistsAction.SUCCESS:
      return { ...state, artists: payload };
    case getArtistsAction.FAILURE:
      return { ...state, error: payload };

    case getAgencyContactAction.TRIGGER:
      return { ...state, error: null };
    case getAgencyContactAction.SUCCESS:
      return { ...state, agencyContact: payload };
    case getAgencyContactAction.FAILURE:
      return { ...state, error: payload };

    case getAgentsAction.TRIGGER:
      return { ...state, agencyAgents: [] };
    case getAgentsAction.SUCCESS:
      return { ...state, agencyAgents: payload };
    case getAgentsAction.FAILURE:
      return { ...state, error: payload, agencyAgents: [] };

    case getAgencyAgreementsAction.TRIGGER:
      return { ...state, error: null };
    case getAgencyAgreementsAction.SUCCESS:
      return { ...state, agreements: payload };
    case getAgencyAgreementsAction.FAILURE:
      return { ...state, error: payload };

    case getAgencyCountriesAction.SUCCESS:
      return { ...state, agencyCountries: payload };
    case getAgencyCountriesAction.FAILURE:
      return { ...state, error: payload };

    case getUserProfileAgentInfoAction.TRIGGER:
      return { ...state, agentInfoOfUserProfile: {} };
    case getUserProfileAgentInfoAction.SUCCESS:
      return { ...state, agentInfoOfUserProfile: payload };
    case getUserProfileAgentInfoAction.FAILURE:
      return { ...state, error: payload };

    case addArtistToRosterAction.TRIGGER:
      return { ...state, agencyUpdating: true };
    case addArtistToRosterAction.SUCCESS:
      return { ...state, agencyUpdating: false };
    case addArtistToRosterAction.FAILURE:
      return { ...state, error: payload, agencyUpdating: false };

    case checkArtistInAgencyAction.TRIGGER:
      return { ...state, artistInRosterData: null, checkArtistInAgencyLoading: true };

    case clearStatusOfArtistInAgencyDataAction.TRIGGER:
      return { ...state, artistInRosterData: null };
    case checkArtistInAgencyAction.SUCCESS:
      return { ...state, artistInRosterData: payload, checkArtistInAgencyLoading: false };
    case checkArtistInAgencyAction.FAILURE:
      return { ...state, error: payload, artistInRosterData: null, checkArtistInAgencyLoading: false };

    case getSingleArtistInfoAction.TRIGGER:
      return { ...state, artistInfo: {} };
    case getSingleArtistInfoAction.SUCCESS:
      return { ...state, artistInfo: payload };
    case getSingleArtistInfoAction.FAILURE:
      return { ...state, error: payload, artistInfo: {} };

    case getArtistsListSuggestionsAction.TRIGGER:
      return { ...state, artistsSuggestionsList: {}, artistsSuggestionsListLoading: true };
    case getArtistsListSuggestionsAction.SUCCESS:
      return { ...state, artistsSuggestionsList: payload, artistsSuggestionsListLoading: false };
    case getArtistsListSuggestionsAction.FAILURE:
      return { ...state, error: payload, artistsSuggestionsList: {}, artistsSuggestionsListLoading: false };

    case updateAgencyAction.TRIGGER:
    case updateArtistAction.TRIGGER:
      return { ...state, agencyUpdating: true };
    case updateAgencyAction.SUCCESS:
    case updateAgencyAction.FAILURE:
    case updateArtistAction.SUCCESS:
    case updateArtistAction.FAILURE:
      return { ...state, agencyUpdating: false };

    case SET_AGENCY_INFO:
      return { ...state, agencyInfo: payload?.agency };

    default:
      return state;
  }
};

export default agenciesReducer;
