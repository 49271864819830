import React, { useCallback, useEffect } from 'react';

import AppVersionNotification from 'components/Globals/AppVersionNotification';
import { enqueueSnackbar, SNACKBAR_VARIANTS } from 'components/uiLibrary/Snackbar';

import useInterval from 'utils/hooks/useInterval';
import { useQuery } from 'utils/react-query';
import { setCookie, getCookie } from 'utils/cookie';
import { isExpiredAppVersion } from 'utils/globals/appVersion';
import { OB_APP_VERSION, EXPIRY_TIME } from 'constants/cookieConstants';

import globalQueries from 'containers/Globals/queries';

const RENDERED_BUILD_NUMBER = parseInt(process.env.BUILD_NUMBER, 10);
const VERSION_CHECK_INTERVAL = EXPIRY_TIME.HOUR * parseFloat(process.env.APP_VERSION_CHECK_INTERVAL_HOURS || 3, 10);

const setAppVersionCookie = buildNumber => {
  if (!buildNumber) {
    return;
  }

  // NOTE: Adding 1 second to the expiry check interval
  const expiry = new Date(new Date().getTime() + VERSION_CHECK_INTERVAL + 1000);

  setCookie(OB_APP_VERSION, buildNumber, expiry);
};

const notifyAppVersionUpdate = ({ latestVersion, isDeprecated = false }) =>
  enqueueSnackbar(
    <AppVersionNotification
      latestVersion={latestVersion}
      renderedVersion={RENDERED_BUILD_NUMBER}
      isDeprecated={isDeprecated}
    />,
    {
      key: OB_APP_VERSION,
      variant: SNACKBAR_VARIANTS.WARNING,
      persist: true,
      preventDuplicate: true,
      hasCloseIcon: false,
    },
  );
const useAppVersionStatus = () => {
  useEffect(() => {
    if (RENDERED_BUILD_NUMBER) {
      // NOTE: RENDERED_BUILD_NUMBER is latest when the app is loaded
      setAppVersionCookie(RENDERED_BUILD_NUMBER);
    }

    // NOTE: Setting the cookie only once on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.notifyAppVersionUpdate = notifyAppVersionUpdate;
    }
  }, []);

  const { data, refetch: checkAppStatus } = useQuery(
    globalQueries.checkAppVersionStatus({
      queryConfig: {
        enabled: false, // NOTE: Avoiding fetching the app status on initial render
        select: response => ({
          ...response,
          latestVersion: parseInt(response?.latestVersion, 10) || null,
        }),
        onSuccess: response => {
          setAppVersionCookie(response?.latestVersion);
          if (response?.expired) {
            notifyAppVersionUpdate({ latestVersion: response?.latestVersion, isDeprecated: response?.deprecated });
          }
        },
        initialData: {
          expired: false,
          latestVersion: RENDERED_BUILD_NUMBER,
        },
      },
    }),
  );

  const showNotification = useCallback(
    latestVersion => {
      notifyAppVersionUpdate({ latestVersion, isDeprecated: data?.deprecated });
    },
    [data?.deprecated],
  );

  useInterval(() => {
    if (RENDERED_BUILD_NUMBER) {
      const savedVersionInCookie = parseInt(getCookie(OB_APP_VERSION), 10);

      if (
        savedVersionInCookie &&
        isExpiredAppVersion({ renderedVersion: RENDERED_BUILD_NUMBER, latestVersion: savedVersionInCookie })
      ) {
        // NOTE: Cookie value will always have the latest app version
        showNotification(savedVersionInCookie);
      } else {
        checkAppStatus();
      }
    }
  }, VERSION_CHECK_INTERVAL);
};

export default useAppVersionStatus;
