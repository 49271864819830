import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as API from '../../utils/API';
import endpointConstants from '../../constants/endpointConstants';
import { enqueueSnackbarAction } from '../App/actions';
import { createImagesAction } from './actions';
import { errorMessageConverter } from 'utils/common';

export function* uploadMedia({ payload }) {
  try {
    const data = get(payload, 'data', []);
    const response = yield all(
      data.map(media => {
        if (media?.oldBannerId) {
          return call(API.updateOne, endpointConstants.MEDIA, media?.oldBannerId, media?.data, media?.file);
        }
        return call(API.create, endpointConstants.MEDIA, media?.data, media?.file);
      }),
    );
    yield put(createImagesAction.success(response?.map(r => r?.data)));
    const callback = payload?.callback;

    if (callback && typeof callback === 'function') {
      payload.callback(response[0]?.data);
    }

    yield put(
      enqueueSnackbarAction({
        message: `Success adding images`,
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    const callback = payload?.callback;
    if (callback && typeof callback === 'function') {
      payload.callback(false);
    }

    yield put(
      enqueueSnackbarAction({
        message: `Error adding images: ${errorMessageConverter(err)}`,
        options: {
          variant: 'error',
        },
      }),
    );
    yield put(createImagesAction.failure(errorMessageConverter(err)));
  }
}

export default function* EditImagesSaga() {
  yield takeLatest(createImagesAction.TRIGGER, uploadMedia);
}
