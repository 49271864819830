const parseBuildNumber = version => parseInt(version, 10);

const isExpiredAppVersion = ({ renderedVersion, latestVersion }) => {
  const renderedVersionInt = parseBuildNumber(renderedVersion);
  const latestVersionInt = parseBuildNumber(latestVersion);

  if (!renderedVersionInt || !latestVersionInt) {
    return false;
  }

  return renderedVersionInt < latestVersionInt;
};

const isDeprecatedAppVersion = version => {
  if (process.env.APP_VERSION_CHECK_DEPRECATED_ENABLED !== 'true') {
    return false;
  }

  const renderedVersionInt = parseBuildNumber(version);

  const deprecatedVersionInt = parseBuildNumber(process.env.APP_VERSION_CHECK_DEPRECATED_VERSION);

  if (!renderedVersionInt || !deprecatedVersionInt) {
    return false;
  }

  return renderedVersionInt <= deprecatedVersionInt;
};

module.exports = {
  isExpiredAppVersion,
  isDeprecatedAppVersion,
};
