import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import get from 'lodash/get';
import rootSaga from './saga';
import createReducer, { reducersList } from './reducers';
import { getCookiesOnServer } from '../utils/auth';

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

// const reducer = (state, action) => {
//   if (action.type === HYDRATE) {
//     const nextState = {
//       ...state, // use previous state
//       ...action.payload, // apply delta from hydration
//     };
//     if (state.count) {
//       nextState.count = state.count; // preserve count value on client side navigation
//     }

//     return nextState;
//   }
//   const red = createReducer();
//   // console.log(red(state, action))
//   return red(state, action);
// };

const prepareReducer = injectedReducers => {
  const reducer = createReducer(injectedReducers);

  return (state, action) => {
    if (action.type === HYDRATE) {
      const nextState = {
        ...state, // use previous state
        ...action.payload, // apply delta from hydration
      };
      if (state.count) {
        nextState.count = state.count; // preserve count value on client side navigation
      }

      return nextState;
    }
    return reducer(state, action);
  };
};

function configureStore(nextContext) {
  const serverContext = nextContext.ctx;
  const sagaMiddleware = createSagaMiddleware();

  const addServerCookies = store => next => action => {
    // add one more parameter to payload
    if (serverContext && serverContext.req && serverContext.req.headers && serverContext.req.headers.cookie) {
      action.serverCookies = getCookiesOnServer(serverContext);
    }

    // change
    next(action);
  };
  let store;
  if (typeof window !== 'undefined') {
    const initialState = get(window, '__NEXT_DATA__.props.initialState', {});
    const initialStateKeys = Object.keys(initialState);
    const preloadReducers = {};
    reducersList
      .filter(r => initialStateKeys.includes(r.key))
      .forEach(({ key, reducer: red }) => {
        preloadReducers[key] = red;
      });
    store = createStore(
      prepareReducer(preloadReducers),
      initialState,
      bindMiddleware([sagaMiddleware, addServerCookies]),
    );
  } else {
    store = createStore(prepareReducer(), bindMiddleware([sagaMiddleware, addServerCookies]));
  }
  store.sagaTask = sagaMiddleware.run(rootSaga);

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  return store;
}

export const wrapper = createWrapper(configureStore, { debug: false });
