import React, { useEffect } from 'react';

import CommonTrans from 'components/Globals/CommonTrans';
import LinkButton from 'components/uiLibrary/LinkButton';
import useTracking from 'components/Globals/Analytics';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { useSnackbar } from 'components/uiLibrary/Snackbar';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import { TP } from 'constants/index';
import { OB_APP_VERSION } from 'constants/cookieConstants';

import classes from './AppVersionNotification.module.scss';

const AppVersionNotification = ({ latestVersion, renderedVersion, isDeprecated = false }) => {
  const track = useTracking();
  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    track.actionCallback({
      section: SECTIONS.NOTIFICATION_APP_EXPIRED,
      meta: {
        renderedVersion,
        latestVersion,
        isDeprecated,
      },
    });

    // NOTE: Track only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    track.click({
      section: SECTIONS.NOTIFICATION_APP_EXPIRED,
      component: COMPONENTS.CLOSE_CTA,
      meta: {
        renderedVersion,
        latestVersion,
        isDeprecated,
      },
    });

    closeSnackbar(OB_APP_VERSION);
  };

  useScrollBlocker(isDeprecated);

  return (
    <>
      {isDeprecated && <div className={classes.bgBlur} />}
      <span>
        <CommonTrans
          ns="NS_APP_GLOBALS"
          i18nKey={isDeprecated ? `${TP}.FN_OB_APP_DEPRECATED_MESSAGE` : `${TP}.FN_OB_APP_OUTDATED_MESSAGE`}
          components={{
            action: <LinkButton variant="text" onClick={() => window.location.reload()} />,
          }}
        />
        {!isDeprecated && <SpriteIcon icon="close" aria-hidden onClick={handleClose} />}
      </span>
    </>
  );
};

export default AppVersionNotification;
