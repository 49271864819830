import { isEmpty, map, find, flatten, omit } from 'lodash';

import { AGREEMENTS_TYPES, AGENCY_TERRITORIES } from '../containers/Managers/constants';

export const createProfessionalAgreementData = (profession, agreements = []) => {
  const isGM = !isEmpty(agreements?.find(agreement => agreement?.agreementType === AGREEMENTS_TYPES.GENERAL_MENEGMENT));

  return {
    profession,
    agreements: AGENCY_TERRITORIES?.map(territory => {
      const matchingAgreement = agreements.find(
        agreement => agreement?.agreementType === territory?.type && agreement?.exclusive === territory?.isExclusive,
      );

      return {
        ...territory,
        representatives: map(matchingAgreement?.contactPersons, person => ({
          label: person.agentName,
          value: person.id,
        })),
        includedRegions: map(matchingAgreement?.includedCountries, region => ({
          value: region?.id,
          label: region?.name,
          type: region?.type,
        })),
        excludedRegions: map(matchingAgreement?.excludedCountries, region => ({
          value: region?.id,
          label: region?.name,
          type: region?.type,
        })),
      };
    }),
    isGM,
  };
};

export const getAgreementCountries = agreement => {
  const countries = agreement?.countries?.map(country => ({ ...country, type: 'country' })) || [];
  const regions = agreement?.regions?.map(region => ({ ...region, type: 'region' })) || [];

  return {
    includedCountries: [
      ...regions?.filter(c => c?.isExcluded === false),
      ...countries?.filter(c => c?.isExcluded === false),
    ],
    excludedCountries: [
      ...regions?.filter(c => c?.isExcluded === true),
      ...countries?.filter(c => c?.isExcluded === true),
    ],
  };
};

const getAgreementCountryRegions = ({
  initialCountries = [],
  initialRegions = [],
  agreementRegions,
  isExcluded = false,
}) => {
  const countries = [...initialCountries];
  const regions = [...initialRegions];

  map(agreementRegions, ({ value, type }) => {
    const payload = {
      id: value,
      isExcluded,
    };

    if (type === 'country') {
      countries.push(payload);
    } else {
      regions.push(payload);
    }
  });

  return { countries, regions };
};

export const getAgreementsPayload = (artistId, representationAgreements, professionalAgreements, agencyProfessions) => {
  const createdAgreements = [];

  const getMatchingAgencyProfessionId = profession => {
    const matchedProfession = agencyProfessions?.find(
      agencyProf => agencyProf?.profession?.id === profession?.professionTypeId,
    );

    return matchedProfession?.id;
  };

  const getAgreementLocations = agreement => {
    const { countries: initialCountries, regions: initialRegions } = getAgreementCountryRegions({
      agreementRegions: agreement?.includedRegions,
    });
    const { countries, regions } = getAgreementCountryRegions({
      initialCountries,
      initialRegions,
      agreementRegions: agreement?.excludedRegions,
      isExcluded: true,
    });

    return { countries, regions };
  };

  map(professionalAgreements, ({ profession, agreements, isGM, representatives }) => {
    const agencyProfessionId = getMatchingAgencyProfessionId(profession);

    if (isGM) {
      createdAgreements.push({
        agencyConnectionProfession: { id: agencyProfessionId },
        agreementType: AGREEMENTS_TYPES.GENERAL_MENEGMENT,
        checked: true,
        connection: { id: artistId },
        contactPersons: representatives?.map(agent => ({ id: agent?.value })),
        countries: [],
        exclusive: false,
        regions: [],
        profession: profession?.professionTypeId,
        type: AGREEMENTS_TYPES.GENERAL_MENEGMENT,
      });
    }

    map(agreements, agreement => {
      const { countries: selectedCountries, regions: selectedRegions } = getAgreementLocations(agreement);

      if (selectedCountries?.length > 0 || selectedRegions?.length > 0) {
        createdAgreements.push({
          agencyConnectionProfession: { id: agencyProfessionId },
          agreementType: agreement?.type,
          connection: { id: artistId },
          contactPersons: representatives?.map(agent => ({ id: agent?.value })),
          exclusive: agreement?.isExclusive,
          countries: selectedCountries,
          regions: selectedRegions,
          profession: profession?.professionTypeId,
        });
      }
    });
  });

  const profileAgreements = flatten(map(representationAgreements, 'agreements'));

  const payload = map(createdAgreements, agreement => {
    const matchedAgreement = find(profileAgreements, {
      agreementType: agreement?.agreementType,
      exclusive: agreement?.exclusive,
      agencyConnectionProfession: { id: agreement?.agencyConnectionProfession?.id },
    });

    if (matchedAgreement) {
      return {
        ...omit(matchedAgreement, ['excludedCountries', 'includedCountries', 'status']),
        contactPersons: agreement?.contactPersons,
        countries: agreement?.countries,
        regions: agreement?.regions,
      };
    }

    return agreement;
  });

  return payload;
};
