import React from 'react';
import nextCookie from 'next-cookies';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import route from '../constants/routes';
import { USER_TOKEN_COOKIE_KEY, ACTIVE_PROFILE_ID } from '../constants/cookieConstants';

import { getHttpInstance } from './fetch';
export const nextPageQueryName = 'page';

export function makeRedirect(ctx, toUrl) {
  const { req, res } = ctx;
  const isServer = !!req;
  if (isServer) {
    const url = req.originalUrl ? `${toUrl}?${nextPageQueryName}=${encodeURIComponent(req.originalUrl)}` : toUrl;
    res.writeHead(302, {
      Location: url,
    });
    res.end();
  } else {
    const url = ctx.asPath ? `${toUrl}?${nextPageQueryName}=${ctx.asPath}` : toUrl;
    window.location.replace(url);
  }
}

export const getToken = ctx => {
  const cookies = nextCookie(ctx);
  return get(cookies, USER_TOKEN_COOKIE_KEY);
};

export const getActiveProfileIdFromCookies = ctx => {
  const cookies = nextCookie(ctx);
  return parseInt(get(cookies, ACTIVE_PROFILE_ID), 10);
};

export const auth = async ctx => {
  let isLoggedIn = false;
  if (ctx && ctx.req && ctx.req.session) {
    const userSession = ctx.req.session.get('ob_user');
    isLoggedIn = !isEmpty(userSession) ? userSession.isLoggedIn : false;
  } else {
    const userSession = await getUserInfo();
    isLoggedIn = !isEmpty(userSession) ? userSession.isLoggedIn : false;
  }
  if (!isLoggedIn) {
    makeRedirect(ctx, route.LOGIN);
  }
};

export const withAuthSync = WrappedComponent => {
  const Wrapper = props => <WrappedComponent {...props} />;

  Wrapper.getInitialProps = async ctx => {
    // check whether user is logged in or not
    await auth(ctx);
    const { store } = ctx;

    const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));

    return { store, ...componentProps };
  };

  return Wrapper;
};

export const getUserInfo = async () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const url = `${process.env.FRONTBASE_URL}/auth/userSession`;
  const axiosInstance = getHttpInstance(new URL(url).origin);

  let payload;
  try {
    payload = await axiosInstance(url, {
      method: 'GET',
      headers,
    });
  } catch (err) {
    payload = err.response;
  }
  return payload?.data;
};

export const getCookiesOnServer = ctx => {
  if (!ctx || !ctx.req || !ctx.req.headers) {
    return null;
  }

  return {
    cookies: ctx.req.headers.cookie,
    language: getLanguageOnServer(ctx.req),
    pathname: ctx?.resolvedUrl || ctx?.asPath,
  };
};

export const getLanguageOnServer = req => get(req, 'language', 'en');
