import {
  getVideosAction,
  getExpiringVideosAction,
  getFilteredVideosAction,
  getAndResetFilteredVideosAction,
  getAndResetVideosAction,
} from './actions';
import { RESET_VIDEOS_LIST } from './constants';
export const key = 'Videos';
import { listReducer, namedListParams } from '../../utils/crudUtils';

export const initialState = {
  videos: namedListParams('videos'),
  expiringVideos: [],
  filterVideos: namedListParams('filterVideos'),
};

const videosReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getVideosAction.TRIGGER:
    case getVideosAction.FAILURE:
    case getVideosAction.SUCCESS:
      if (payload?.data?.length) {
        payload.data = [...state.videos.data, ...payload.data];
      }
      return listReducer(state, type, payload, state.videos.listName);

    case getAndResetVideosAction.TRIGGER:
    case getAndResetVideosAction.FAILURE:
    case getAndResetVideosAction.SUCCESS:
      return listReducer(state, type, payload, state.videos.listName);

    case getFilteredVideosAction.TRIGGER:
    case getFilteredVideosAction.FAILURE:
    case getFilteredVideosAction.SUCCESS:
      if (payload?.data?.length) {
        payload.data = [...state.filterVideos.data, ...payload.data];
      }
      return listReducer(state, type, payload, state.filterVideos.listName);
    case getAndResetFilteredVideosAction.TRIGGER:
    case getAndResetFilteredVideosAction.FAILURE:
    case getAndResetFilteredVideosAction.SUCCESS:
      return listReducer(state, type, payload, state.filterVideos.listName);
    case getExpiringVideosAction.TRIGGER:
      return { ...state, loading: true };
    case getExpiringVideosAction.SUCCESS:
      return { ...state, ...{ expiringVideos: payload, loading: false } };
    case getExpiringVideosAction.FAILURE:
      return { ...state, error: payload };

    default:
      return state;
  }
};

export default videosReducer;
