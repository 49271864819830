import { takeLatest, put, call } from 'redux-saga/effects';
import get from 'lodash/get';
import { getComposerWorksAction } from './actions';
// Reason: eslint not recognizing imports
// eslint-disable-next-line import/named
import * as API from '../../utils/API';
import { MUSICAL_WORK_ENDPOINT, PRODUCTIONS_AGGREGATIONS } from '../../constants/endpointConstants';

export function* fetchComposerWorks({ payload, serverCookies }) {
  try {
    const workResponse = yield call(API.getList, MUSICAL_WORK_ENDPOINT, payload || {}, serverCookies);
    const workIds = get(workResponse, 'data.data', []).map(works => works?.id);
    let workCountResponse;
    if (workIds.length !== 0) {
      const params = {
        queryParams: {
          aggregation_type: 'work_id',
          composer_id: payload?.queryParams.composer_id,
          work_id: workIds,
        },
      };
      workCountResponse = yield call(API.getList, PRODUCTIONS_AGGREGATIONS, params, serverCookies);
    }

    const response = { work: workResponse, count: workCountResponse };

    yield put(getComposerWorksAction.success(response));
  } catch (err) {
    yield put(getComposerWorksAction.failure(err.message));
  } finally {
    yield put(getComposerWorksAction.fulfill());
  }
}

export default function* composerSaga() {
  yield takeLatest(getComposerWorksAction.TRIGGER, fetchComposerWorks);
}
