import { TP } from 'constants/index';

export const GET_LANGUAGES = 'app/AccountSettings/GET_LANGUAGES';
export const GET_PRODUCTS = 'app/AccountSettings/GET_PRODUCTS';
export const GET_ALL_PRODUCTS = 'app/AccountSettings/GET_ALL_PRODUCTS';
export const GET_USER_PAYMENTS = 'app/AccountSettings/GET_USER_PAYMENTS';
export const CHANGE_PASSWORD = 'app/AccountSettings/CHANGE_PASSWORD';
export const GET_ACCESS_REQUESTS = 'app/AccountSettings/GET_ACCESS_REQUESTS';
export const GET_PAYMENTS = 'app/AccountSettings/GET_PAYMENTS';
export const REACTIVATE_SUBSCRIPTION = 'app/AccountSettings/REACTIVATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'app/AccountSettings/UPDATE_SUBSCRIPTION';
export const GET_ARTISTS_WORKED_WITH = 'app/AccountSettings/GET_ARTISTS_WORKED_WITH';
export const GET_GIFT_SUBSCRIPTIONS = 'app/AccountSettings/GET_GIFT_SUBSCRIPTIONS';
export const GET_USER_REFERRALS = 'app/AccountSettings/GET_USER_REFERRALS';
export const GET_USER_REWARD_BALANCE = 'app/AccountSettings/GET_USER_REWARD_BALANCE';

export const AS_SECTION_GENERAL = 'general';
export const AS_SECTION_LOGIN = 'login';
export const AS_SECTION_PAYMENTS = 'payments';
export const AS_SECTION_SUBSCRIPTIONS = 'subscriptions';
export const AS_SECTION_PAGE_MANAGEMENT = 'page_management';
export const AS_SECTION_GIFT_SUBSCRIPTIONS = 'gift_subscriptions';

export const ADDONS_NAME_TRANSLATION_MAP = {
  'casting-tool-annual': `${TP}.ADDONS_NAME_CASTING_ANNUAL`,
  'casting-tool': `${TP}.ADDONS_NAME_CASTING_MONTHLY`,
  'archive-access-annual': `${TP}.ADDONS_NAME_ARCHIVE_ANNUAL`,
  'archive-access': `${TP}.ADDONS_NAME_ARCHIVE_MONTHLY`,
};

export const PAYMENT_SECTIONS = { method: 'card', history: 'history', billing: 'billing' };

export const SUBSCRIPTIONS_SLUG = {
  AOSFree: 'aos_free',
  AOSPro: 'aos_pro',
  AgencyFree: 'agency_free',
  AgencyPro: 'agency_pro',
  ArtistFree: 'artist_free',
  ArtistPro: 'artist_pro',
  ProTools: 'pro-tools',
};

export const SUBSCRIPTION_BILLING_PERIOD = {
  free: 'free',
  annual: 'yearly',
  monthly: 'monthly',
};

export const SUBSCRIPTIONS_STATUSES = {
  active: 'active',
  cancelled: 'cancelled',
  expired: 'expired',
  nonRenewing: 'non_renewing',
  future: 'future',
  inTrial: 'in_trial',
  paused: 'paused',
  processing: 'processing',
};

export const FREE_TO_PRO_SUBSCRIPTIONS = {
  1: 2, // AOS FREE -> AOS PRO
  3: 4, // Agent FREE -> Agent PRO
  5: 6, // Artist FREE -> Artist PRO
};

export const WALLET_TYPES = {
  card: 'card',
  applePay: 'apple_pay',
  googlePay: 'google_pay',
  sepaDirectDebit: 'sepa/direct_debit',
  paypalEx: 'paypal/express_checkout',
  check: 'check',
  cash: 'cash',
  bankTransfer: 'bank_transfer',
  other: 'other',
};

export const REFERRAL_STATUS = {
  GIFT_SENT: 'gift_sent',
  GIFT_ACCEPTED: 'gift-accepted',
  GIFT_EXPIRED: 'gift-expired',
};

export const REFUND_STATUSES = {
  adjusted: `${TP}.CB_TOTAL_ADJUSTED`,
  refunded: `${TP}.FN_REFUNDED`,
  refund_due: `${TP}.FN_REFUND_DUE`,
};

export const INVOICE_SORT_KEYS = {
  '-payment_date': '-id',
  payment_date: 'id',
  '-amount': '-totalPriceInclVat.amount',
  amount: 'totalPriceInclVat.amount',
};

export const REFUNDS_SORT_KEYS = {
  '-payment_date': '-id',
  payment_date: 'id',
  '-amount': '-total_amount',
  amount: 'total_amount',
};
