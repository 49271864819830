import { takeLatest, put, call, all } from 'redux-saga/effects';
import { get, merge } from 'lodash';
import { FIRST_PAGE, PAGINATION_LIMIT_1000 } from 'utils/queries/constants';
import { TP, MEDIA_TAGS_TYPES_IDS, MEDIA_TAG_RELATIONS } from 'constants/index';
import { errorMessageConverter } from 'utils/common';
import { MEDIA_TYPES_IDS } from '../../constants/deprecate';
import {
  getChosenProfileReviewsAction,
  getChosenProductionReviewsAction,
  getChosenProfileAgreementsAction,
  getChosenProfileProductionsAction,
  getDatesInProductionsAction,
  addAndUpdateContactsAction,
  getRepertoireProductionListAction,
  getRepertoireImageListAction,
  getRepertoireVideoListAction,
  updateRepertoireModalAction,
  updateArtistBannerAction,
  uploadArtistBannerAction,
  getChosenProfileDataAction,
  updateProductionAction,
  removeProductionAction,
  updateProductionPrivacyAction,
  updateProfileInfoAction,
  getChosenProfileChangeRequestAction,
  getArtistTranslationsAction,
  updateArtistTranslationsAction,
  getProfileWorksListAction,
  getProfilePerformanceAction,
} from './actions';
// Reason: eslint not recognizing imports
// eslint-disable-next-line import/named
import { formatResponseToEntity, formatResponseToList } from '../../utils/crudUtils';
import * as API from '../../utils/API';
import {
  PRODUCTIONS,
  PROFILES_ENDPOINT,
  MEDIA,
  PRODUCTION_PRIVACY,
  CHANGE_REQUEST,
  MUSICAL_WORK_ENDPOINT,
  USER_PERFORMANCES,
} from '../../constants/endpointConstants';
import { enqueueSnackbarAction, useBufferValueAction } from '../App/actions';
import { i18n } from '../../src/i18n';

export function* fetchProfileData({ payload, serverCookies }) {
  try {
    const profileData = yield call(API.getOne, PROFILES_ENDPOINT, payload?.id, serverCookies);
    const data = formatResponseToEntity(profileData);
    yield put(getChosenProfileDataAction.success(data));
  } catch (err) {
    yield put(getChosenProfileDataAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getChosenProfileDataAction.fulfill());
  }
}

export function* fetchProfileAgreements({ payload, serverCookies }) {
  const queryParams = {
    artist_profile_id: payload?.profileId,
    ...(payload?.show_all ? { as_edit: true } : {}),
    ...(payload?.display_status && { display_status: payload?.display_status }),
  };

  try {
    let agreementData;
    const agencyResponse = yield call(API.getArtistAgencies, { ...queryParams }, {}, serverCookies);
    const agencyIds = agencyResponse && get(get(agencyResponse, 'data'), 'data').map(x => x.id);

    if (agencyIds?.length > 0) {
      const agreementResponse = yield call(API.getArtistsAgreements, agencyIds, {}, serverCookies);

      const agencies = get(get(agencyResponse, 'data'), 'data');
      const agreements = agreementResponse && get(get(agreementResponse, 'data'), 'data');
      agreementData = {
        agencies,
        agreements,
      };
    }

    yield put(getChosenProfileAgreementsAction.success({ data: agreementData }));
  } catch (err) {
    yield put(getChosenProfileAgreementsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getChosenProfileAgreementsAction.fulfill());
  }
}

export function* fetchProfileReviews({ payload, serverCookies }) {
  try {
    const response = yield call(
      API.getReviews,
      payload.id,
      payload.pagination,
      get(payload, 'query', {}),
      serverCookies,
    );
    yield put(getChosenProfileReviewsAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getChosenProfileReviewsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getChosenProfileReviewsAction.fulfill());
  }
}

export function* fetchProfileProductionReviews({ payload, serverCookies }) {
  try {
    const response = yield call(
      API.getReviews,
      payload.id,
      payload.pagination,
      get(payload, 'query', {}),
      serverCookies,
    );
    yield put(getChosenProductionReviewsAction.success(get(response, 'data')));
  } catch (err) {
    yield put(getChosenProductionReviewsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getChosenProductionReviewsAction.fulfill());
  }
}

export function* fetchProfilePerformances({ payload, serverCookies }) {
  try {
    const params = payload?.params ?? {};
    const paramsWithPagination = merge(params, { pagination: { page: FIRST_PAGE, ...PAGINATION_LIMIT_1000 } });
    const response = yield call(API.getList, USER_PERFORMANCES, paramsWithPagination, serverCookies);
    yield put(getProfilePerformanceAction.success(formatResponseToList(response)));
  } catch (err) {
    yield put(getProfilePerformanceAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getProfilePerformanceAction.fulfill());
  }
}

export function* fetchProfileChangeRequests({ payload, serverCookies }) {
  try {
    const params = payload?.params ?? {};
    const paramsWithPagination = merge(params, { pagination: { page: FIRST_PAGE, ...PAGINATION_LIMIT_1000 } });
    const response = yield call(API.getList, CHANGE_REQUEST, paramsWithPagination, serverCookies);
    yield put(getChosenProfileChangeRequestAction.success(formatResponseToList(response)));
  } catch (err) {
    yield put(getChosenProfileChangeRequestAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getChosenProfileChangeRequestAction.fulfill());
  }
}

export function* fetchProfileProductions({ payload, serverCookies }) {
  try {
    const response = yield call(API.getList, PRODUCTIONS, payload?.params || {}, serverCookies);
    const productionsIds = get(response, 'data.data', []).map(production => production?.id);
    const profileId = payload?.profileId;

    if (productionsIds.length !== 0) {
      yield call(
        fetchProfilePerformedDates,
        {
          productionsIds,
          profileId,
        },
        serverCookies,
      );
    }

    yield put(getChosenProfileProductionsAction.success(formatResponseToList(response)));
  } catch (err) {
    yield put(getChosenProfileProductionsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getChosenProfileProductionsAction.fulfill());
  }
}

export function* fetchProfileRepetorieProductions({ payload, serverCookies }) {
  try {
    const response = yield call(API.getList, PRODUCTIONS, payload?.params || {}, serverCookies);
    const productionsIds = get(response, 'data.data', []).map(production => production?.id);
    if (productionsIds.length > 0 && payload?.others) {
      const imageParams = {
        queryParams: {
          media_type_id: MEDIA_TYPES_IDS.IMAGE,
          media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION,
          media_tag_id: payload?.profileId,
          production_id: productionsIds,
          media_tag_relation: MEDIA_TAG_RELATIONS.CAST,
          validation_status_id: '1,2,3,5',
        },
        pagination: { limit: 50 },
      };
      yield call(getRepetorieImages, { imageParams, serverCookies });
      const videoParams = {
        queryParams: {
          media_type_id: MEDIA_TYPES_IDS.VIDEO,
          media_tag_type_id: MEDIA_TAGS_TYPES_IDS.PRODUCTION_CONTRIBUTION,
          media_tag_id: payload?.profileId,
          production_id: productionsIds,
          media_tag_relation: MEDIA_TAG_RELATIONS.CAST,
          validation_status_id: '1,2,3,5',
        },
        pagination: { limit: 20 },
      };

      yield call(getRepetorieVideos, { videoParams, serverCookies });
    }

    yield put(getRepertoireProductionListAction.success(formatResponseToList(response)));
  } catch (err) {
    yield put(getRepertoireProductionListAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getRepertoireProductionListAction.fulfill());
  }
}

function* getRepetorieImages({ imageParams, serverCookies }) {
  try {
    const responseImage = yield call(API.getList, MEDIA, imageParams, serverCookies);
    yield put(getRepertoireImageListAction.success(get(responseImage, 'data')));
  } catch (err) {
    yield put(getRepertoireImageListAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getRepertoireImageListAction.fulfill());
  }
}

function* getRepetorieVideos({ videoParams, serverCookies }) {
  try {
    const responseVideo = yield call(API.getList, MEDIA, videoParams, serverCookies);
    yield put(getRepertoireVideoListAction.success(get(responseVideo, 'data')));
  } catch (err) {
    yield put(getRepertoireVideoListAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getRepertoireVideoListAction.fulfill());
  }
}

export function* updateRepetorieModal({ payload }) {
  yield put(updateRepertoireModalAction.success(payload));
}

function* fetchProfilePerformedDates({ productionsIds, profileId }, serverCookies) {
  try {
    const dates = yield call(
      API.getList,
      `${PROFILES_ENDPOINT}/productions/dates`,
      {
        queryParams: {
          production_id: productionsIds,
          id: profileId,
        },
      },
      serverCookies,
    );
    yield put(getDatesInProductionsAction.success(formatResponseToList(dates)));
  } catch (err) {
    yield put(getDatesInProductionsAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getDatesInProductionsAction.fulfill());
  }
}

export function* addAndUpdateContacts({ payload, serverCookies }) {
  try {
    const newContacts = get(payload, 'newContacts', []);
    const updatingContacts = get(payload, 'updatingContacts', []);
    const deletedContactsIds = get(payload, 'deletedContactsIds', []);
    const endpoint = get(payload, 'endpoint');
    const callback = get(payload, 'callback');
    yield all([
      ...(newContacts?.length > 0 ? [call(API.create, endpoint, { data: newContacts }, null, serverCookies)] : []),
      ...(updatingContacts?.length > 0
        ? [call(API.updateOne, endpoint, updatingContacts[0]?.id, { data: updatingContacts }, null, serverCookies)]
        : []),
      ...deletedContactsIds.map(deletedContactId => call(API.deleteOne, endpoint, deletedContactId, {}, serverCookies)),
    ]);

    if (callback && typeof callback === 'function') {
      payload.callback(true);
    }

    if (!payload?.isHideSuccessMessage) {
      yield put(
        enqueueSnackbarAction({
          message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_DATA`),
          options: {
            variant: 'success',
          },
        }),
      );
    }
  } catch (err) {
    const callback = get(payload, 'callback');
    if (callback && typeof callback === 'function') {
      payload.callback(false);
    }

    if (!payload?.isHideSuccessMessage) {
      yield put(
        enqueueSnackbarAction({
          message: i18n.t(`${TP}.FN_ERROR_UPDATING_DATA`, {
            endpoint: get(payload, 'endpoint'),
            error: errorMessageConverter(err),
          }),
          options: {
            variant: 'error',
          },
        }),
      );
    }
  }
}

export function* updateBanner({ payload, serverCookies }) {
  try {
    const oldBannerId = get(payload, 'oldBannerId');
    const files = get(payload, 'files');
    const data = get(payload, 'data', {});
    const callback = get(payload, 'callback');
    yield put(useBufferValueAction.trigger());
    if (oldBannerId) {
      const updatedBannerData = yield call(API.updateOne, MEDIA, oldBannerId, data, files, serverCookies);

      yield put(updateArtistBannerAction.success(updatedBannerData?.data));
      yield put(useBufferValueAction.success(updatedBannerData?.data));
    } else {
      const newBannerData = yield call(API.create, MEDIA, data, files, serverCookies);

      yield put(uploadArtistBannerAction.success([newBannerData?.data]));
      yield put(useBufferValueAction.success(newBannerData?.data));
    }

    if (callback && typeof callback === 'function') {
      callback(true);
    }

    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_BANNER`),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_BANNER`, { error: errorMessageConverter(err) }),
        options: {
          variant: 'error',
        },
      }),
    );

    const onError = get(payload, 'onError');
    if (onError && typeof onError === 'function') {
      payload.onError(false);
    }
  }
}

export function* updateProductionPrivacy({ payload, serverCookies }) {
  const { productionId, profileId, body, isRemoved, callback } = payload;
  try {
    const productionPrivacyData = yield call(API.getOne, PRODUCTIONS, `${productionId}/privacy`, serverCookies);
    const privacyId = productionPrivacyData?.data?.data.find(el => el.profile?.id === profileId)?.id;
    let updatedPrivacy;
    if (privacyId) {
      updatedPrivacy = yield call(
        API.updateOne,
        PRODUCTION_PRIVACY(productionId),
        privacyId,
        body,
        null,
        serverCookies,
      );
    } else {
      updatedPrivacy = yield call(API.create, PRODUCTION_PRIVACY(productionId), body, null, serverCookies);
    }
    if (isRemoved) {
      yield put(removeProductionAction.success({ entity: updatedPrivacy?.data }));
    } else {
      yield put(updateProductionAction.success({ entity: updatedPrivacy?.data }));
    }
    if (callback && typeof callback === 'function') {
      callback();
    }
    yield put(updateProductionPrivacyAction.success());
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_DATA`, { endpoint: PRODUCTION_PRIVACY(productionId) }),
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (err) {
    yield put(updateProductionPrivacyAction.failure(errorMessageConverter(err)));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_DATA`, {
          endpoint: PRODUCTION_PRIVACY(productionId),
          error: err.message,
        }),
        options: {
          variant: 'error',
        },
      }),
    );
  }
}

export function* updateProfileInfo({ payload, serverCookies }) {
  const { id, params, files, callback } = payload;
  try {
    const profileData = yield call(API.updateOne, PROFILES_ENDPOINT, id, params, files, serverCookies);
    const data = formatResponseToEntity(profileData);

    yield put(updateProfileInfoAction.success(data));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_SUCCESS_UPDATING_DATA`, { endpoint: PROFILES_ENDPOINT }),
        options: {
          variant: 'success',
        },
      }),
    );
    if (typeof callback === 'function') {
      payload.callback(true);
    }
  } catch (err) {
    yield put(updateProfileInfoAction.failure(errorMessageConverter(err)));
    yield put(
      enqueueSnackbarAction({
        message: i18n.t(`${TP}.FN_ERROR_UPDATING_DATA`, {
          endpoint: PROFILES_ENDPOINT,
          error: err.message,
        }),
        options: {
          variant: 'error',
        },
      }),
    );
    if (typeof callback === 'function') {
      payload.callback(false);
    }
  } finally {
    yield put(updateProfileInfoAction.fulfill());
  }
}

export function* updateArtistTranslations({ payload, serverCookies }) {
  const callback = get(payload, 'callback');
  const artistId = get(payload, 'profileId');
  try {
    const translations = yield call(API.updateArtistTranslations, artistId, payload.artistTranslations, serverCookies);
    yield put(getArtistTranslationsAction.success(get(translations, 'data')));

    if (callback && typeof callback === 'function') payload.callback(true);
  } catch (err) {
    if (callback && typeof callback === 'function') payload.callback(false);
    yield put(
      enqueueSnackbarAction({
        message: `Error updating artist name: ${errorMessageConverter(err)}`,
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getProfileWorksListAction.fulfill());
  }
}

export function* fetchProfileWorks({ payload, serverCookies }) {
  try {
    const response = yield call(
      API.getList,
      MUSICAL_WORK_ENDPOINT,
      {
        queryParams: {
          creator_profile_id: payload?.id,
          validation_status: payload?.validation_status,
          sort: payload?.sort,
        },
        pagination: payload?.pagination,
      },
      serverCookies,
    );
    yield put(getProfileWorksListAction.success(response));
  } catch (err) {
    yield put(getProfileWorksListAction.failure(err.message));
    yield put(
      enqueueSnackbarAction({
        message: errorMessageConverter(err),
        options: {
          variant: 'error',
        },
      }),
    );
  } finally {
    yield put(getProfileWorksListAction.fulfill());
  }
}

export default function* artistSaga() {
  yield takeLatest(getChosenProfileDataAction.TRIGGER, fetchProfileData);
  yield takeLatest(updateRepertoireModalAction.TRIGGER, updateRepetorieModal);
  yield takeLatest(getChosenProfileReviewsAction.TRIGGER, fetchProfileReviews);
  yield takeLatest(getChosenProductionReviewsAction.TRIGGER, fetchProfileProductionReviews);
  yield takeLatest(getChosenProfileAgreementsAction.TRIGGER, fetchProfileAgreements);
  yield takeLatest(getChosenProfileProductionsAction.TRIGGER, fetchProfileProductions);
  yield takeLatest(getChosenProfileChangeRequestAction.TRIGGER, fetchProfileChangeRequests);
  yield takeLatest(getProfilePerformanceAction.TRIGGER, fetchProfilePerformances);
  yield takeLatest(addAndUpdateContactsAction.TRIGGER, addAndUpdateContacts);
  yield takeLatest(getRepertoireProductionListAction.TRIGGER, fetchProfileRepetorieProductions);
  yield takeLatest(updateArtistBannerAction.TRIGGER, updateBanner);
  yield takeLatest(updateProductionPrivacyAction.TRIGGER, updateProductionPrivacy);
  yield takeLatest(updateProfileInfoAction.TRIGGER, updateProfileInfo);
  yield takeLatest(updateArtistTranslationsAction.TRIGGER, updateArtistTranslations);
  yield takeLatest(getProfileWorksListAction.TRIGGER, fetchProfileWorks);
}
