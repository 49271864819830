import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import MuiTooltip from '@mui/material/Tooltip';
import styles from './Tooltip.module.scss';

const placementMap = {
  'bottom-end': 'bottom-end',
  'bottom-start': 'bottom-start',
  bottom: 'bottom',
  'top-end': 'top-end',
  'top-start': 'top-start',
  top: 'top',
  'left-end': 'left',
  'left-start': 'left',
  left: 'left',
  'right-end': 'right',
  'right-start': 'right',
  right: 'right',
};

const Tooltip = ({ title, children, placement, className, variant = 'black', disable }) => {
  if (disable) {
    return children;
  }

  return (
    <MuiTooltip
      classes={{
        popper: styles.tooltipPopper,
        tooltip: classnames(styles.tooltip, className, {
          [styles.tooltip__white]: variant === 'white',
        }),
        arrow: classnames({
          [styles.arrow__black]: variant === 'black',
          [styles.arrow__white]: variant === 'white',
        }),
      }}
      title={<div className={styles.title}>{title}</div>}
      placement={placementMap[placement]}
      arrow
    >
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  variant: PropTypes.oneOf(['black', 'white']),
  placement: PropTypes.string,
  className: PropTypes.string,
};

export default Tooltip;
