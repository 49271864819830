import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectSettingsDomain = state => state.AccountSettings || initialState;

const selectUserPayments = () => createSelector(selectSettingsDomain, subState => subState.payments);
const selectProducts = () => createSelector(selectSettingsDomain, subState => subState.products);

const selectUserInvoicesList = () => createSelector(selectSettingsDomain, subState => subState.userInvoices);
const selectUserPaymentsList = () => createSelector(selectSettingsDomain, subState => subState.userPayments);

const selectArtistsWorkedWith = () => createSelector(selectSettingsDomain, subState => subState.artistsWorkedWith);

const selectGiftSubscriptions = () => createSelector(selectSettingsDomain, subState => subState.giftSubscriptions);
const selectUserReferrals = () => createSelector(selectSettingsDomain, subState => subState.userReferrals);
const selectUserRewardBalance = () => createSelector(selectSettingsDomain, subState => subState.rewardBalance);

export {
  selectUserPayments,
  selectProducts,
  selectUserInvoicesList,
  selectUserPaymentsList,
  selectArtistsWorkedWith,
  selectGiftSubscriptions,
  selectUserReferrals,
  selectUserRewardBalance,
};
