import { takeLatest, put, call } from 'redux-saga/effects';
import {
  getTitlesAction,
  getComposersAction,
  getCityStatsAction,
  getCountryStatsAction,
  getCombinedStatsAction,
  getAllAction,
  getCombinedComposersStatsAction,
} from './actions';
import { getStatistics } from '../../utils/API';

export function* fetchTitles({ payload, serverCookies }) {
  try {
    const response = yield call(getStatistics, 'works', payload, serverCookies);
    yield put(getTitlesAction.success(response));
  } catch (err) {
    yield put(getTitlesAction.failure(err.message));
  } finally {
    yield put(getTitlesAction.fulfill());
  }
}

export function* fetchComposers({ payload, serverCookies }) {
  try {
    const response = yield call(getStatistics, 'composers', payload, serverCookies);
    yield put(getComposersAction.success(response));
  } catch (err) {
    yield put(getComposersAction.failure(err.message));
  } finally {
    yield put(getComposersAction.fulfill());
  }
}

export function* fetchCityStats({ payload, serverCookies }) {
  try {
    const response = yield call(getStatistics, 'cities', payload, serverCookies);
    yield put(getCityStatsAction.success(response));
  } catch (err) {
    yield put(getCityStatsAction.failure(err.message));
  } finally {
    yield put(getCityStatsAction.fulfill());
  }
}

export function* fetchCountryStats({ payload, serverCookies }) {
  try {
    const response = yield call(getStatistics, 'countries', payload, serverCookies);
    yield put(getCountryStatsAction.success(response));
  } catch (err) {
    yield put(getCountryStatsAction.failure(err.message));
  } finally {
    yield put(getCountryStatsAction.fulfill());
  }
}

export function* fetchCombinedStats({ payload, serverCookies }) {
  try {
    const response = yield call(getStatistics, 'combined', payload, serverCookies);
    yield put(getCombinedStatsAction.success(response));
  } catch (err) {
    yield put(getCombinedStatsAction.failure(err.message));
  } finally {
    yield put(getCombinedStatsAction.fulfill());
  }
}

export function* fetchCombinedComposersStats({ payload, serverCookies }) {
  try {
    const response = yield call(getStatistics, 'combined_composers', payload, serverCookies);
    yield put(getCombinedComposersStatsAction.success(response));
  } catch (err) {
    yield put(getCombinedComposersStatsAction.failure(err.message));
  } finally {
    yield put(getCombinedComposersStatsAction.fulfill());
  }
}

export function* fetchAllStats({ payload, serverCookies }) {
  try {
    const response = yield call(
      getStatistics,
      undefined,
      {
        limit: 10,
        composers_limit: 10,
        works_limit: 1,
        ...payload,
      },
      serverCookies,
    );
    yield put(getAllAction.success(response));
  } catch (err) {
    yield put(getAllAction.failure(err.message));
  } finally {
    yield put(getAllAction.fulfill());
  }
}

export default function* statisticsSaga() {
  yield takeLatest(getAllAction.TRIGGER, fetchAllStats);
  yield takeLatest(getTitlesAction.TRIGGER, fetchTitles);
  yield takeLatest(getComposersAction.TRIGGER, fetchComposers);
  yield takeLatest(getCityStatsAction.TRIGGER, fetchCityStats);
  yield takeLatest(getCountryStatsAction.TRIGGER, fetchCountryStats);
  yield takeLatest(getCombinedStatsAction.TRIGGER, fetchCombinedStats);
  yield takeLatest(getCombinedComposersStatsAction.TRIGGER, fetchCombinedComposersStats);
}
