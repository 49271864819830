import { useMemo } from 'react';

import { VALID_AGENCY_STATUSES } from 'constants/index';

import {
  useUserData,
  useUserPermissions,
  useActiveProfilePermissions,
  useActiveProfileData,
  useActiveProfileId,
  useUserProfiles,
} from 'utils/hooks/useAuthenticatedUser';
import useAppContext from './hooks/useAppContext';

function usePermissions(targetId) {
  const { isLoggedIn } = useAppContext();
  const profilePermissions = useActiveProfilePermissions();
  const userData = useUserData();
  const userPermissions = useUserPermissions();
  const userProfile = useActiveProfileData();
  const userProfiles = useUserProfiles();

  const permissions = useMemo(() => {
    const permSet = new Set();
    const isPermReady = profilePermissions.loadedOnce && userPermissions.loadedOnce;
    if (isPermReady) {
      profilePermissions.permissions.forEach(p => permSet.add(p.value));
      userPermissions.permissions.forEach(p => permSet.add(p.identifier));
    }
    const hasAgencyAccess = VALID_AGENCY_STATUSES.includes(
      userProfile?.agencies?.find(agency => agency?.id === targetId)?.status,
    );
    const isTargetMyOrganization = !!userProfile?.organizations?.find(org => org?.organization?.id === targetId);
    const isTargetMyProfile = userProfile?.id === targetId;

    const newPermissions = {
      castingToolPermissions: {
        hasAccess: permSet.has('applications.frontbase.castingTool'),
      },
      productionPermissions: {
        canDeleteAsAdmin: permSet.has('productions.delete.all') && permSet.has('apiConstants.override'),
        canDelete: permSet.has('productions.delete.all'),
        canCancel: permSet.has('productions.update.own'),
        canPublish: permSet.has('productionActions.publish.own'),
        canPublishAll: permSet.has('productionActions.publish.all'),
        canScrape: permSet.has('scrapeRequests.create.all'),
      },
      artistPermissions: {
        canEdit: permSet.has('profiles.update.all') || (permSet.has('profiles.update.own') && isTargetMyProfile),
        repertoire: {
          canView:
            permSet.has('profileRepertoire.get.all') || (permSet.has('profileRepertoire.get.own') && isTargetMyProfile),
          canEdit:
            permSet.has('profileRepertoire.update.all') ||
            (permSet.has('profileRepertoire.update.own') && isTargetMyProfile),
          canDelete:
            permSet.has('profileRepertoire.delete.all') ||
            (permSet.has('profileRepertoire.delete.own') && isTargetMyProfile),
          canAdd:
            permSet.has('profileRepertoire.create.all') ||
            (permSet.has('profileRepertoire.create.own') && isTargetMyProfile),
        },
        addInfo: {
          profileInfo: permSet.has('profiles.update.all') || (permSet.has('profiles.update.own') && isTargetMyProfile),
          biography: permSet.has('profiles.update.all') || (permSet.has('profiles.update.own') && isTargetMyProfile),
          updatePerformances:
            permSet.has('productions.update.all') || (permSet.has('productions.update.own') && isTargetMyProfile),
          addPerformances:
            permSet.has('productions.create.all') || (permSet.has('productions.create.own') && isTargetMyProfile),
          musicalWorks: permSet.has('works.create.own') || (permSet.has('works.create.all') && isTargetMyProfile),
          changeRequest:
            permSet.has('productions.update.all') || (permSet.has('productions.update.own') && isTargetMyProfile),
          digitalPresence:
            permSet.has('profileContacts.update.all') ||
            (permSet.has('profileContacts.update.own') && isTargetMyProfile),
          photos: permSet.has('media.create.all') || (permSet.has('media.create.own') && isTargetMyProfile),
          videos: permSet.has('media.create.all') || (permSet.has('media.create.own') && isTargetMyProfile),
          repertoire:
            permSet.has('profileRepertoire.update.all') ||
            (permSet.has('profileRepertoire.update.own') && isTargetMyProfile),
          reviews:
            permSet.has('profileReviews.update.all') || (permSet.has('profileReviews.update.own') && isTargetMyProfile),
          contactInfo:
            permSet.has('profileContacts.update.all') ||
            (permSet.has('profileContacts.update.own') && isTargetMyProfile),
          agencyInfo:
            permSet.has('profileContacts.update.all') ||
            (permSet.has('profileContacts.update.own') && isTargetMyProfile),
          socialMedia:
            permSet.has('profileContacts.update.all') ||
            (permSet.has('profileRepertoire.create.all') && isTargetMyProfile),
        },
        showHide: {
          photos: permSet.has('media.showHide.all') || (permSet.has('media.showHide.own') && isTargetMyProfile),
          videos: permSet.has('media.showHide.all') || (permSet.has('media.showHide.own') && isTargetMyProfile),
          reviews: permSet.has('reviews.showHide.all') || (permSet.has('reviews.showHide.own') && isTargetMyProfile),
          performances:
            permSet.has('productions.showHide.all') || (permSet.has('productions.showHide.own') && isTargetMyProfile),
          artistPerformedWith: permSet.has('cast.showHide.own') && isTargetMyProfile,
          orgWorkedWith: permSet.has('organizations.showHide.own') && isTargetMyProfile,
          exploreArtist: permSet.has('search.showHide.own') && isTargetMyProfile,
          agencyRepresentation: permSet.has('agencyArtists.showHide.own') && isTargetMyProfile,
          agencyRepresentationOnCastingTool:
            permSet.has('agencyArtistsCastingTool.showHide.all') ||
            (permSet.has('agencyArtistsCastingTool.showHide.own') && isTargetMyProfile),
          contactInfoOnPublicSite: permSet.has('profileContacts.showHide.own') && isTargetMyProfile,
          contactInfoOnCastingTool:
            permSet.has('profileContactsCastingTool.showHide.all') ||
            (permSet.has('profileContactsCastingTool.showHide.own') && isTargetMyProfile),
          repertoire: permSet.has('repertoire.showHide.own') && isTargetMyProfile,
          futureRepertoireOnCastingTool: permSet.has('futureRepertoire.showHide.own') && isTargetMyProfile,
        },
        canShareAccess: permSet.has('profiles.shareAccess.own') && isTargetMyProfile,
        redMaskRequest: permSet.has('applications.frontbase.redMask'),
      },
      organizationPermissions: {
        canEdit:
          permSet.has('organizations.update.all') ||
          (permSet.has('organizations.update.own') && isTargetMyOrganization),
        addInfo: {
          info:
            permSet.has('organizations.create.all') ||
            (permSet.has('organizations.create.own') && isTargetMyOrganization),
          rentals: permSet.has('rentals.create.all') || (permSet.has('rentals.create.own') && isTargetMyOrganization),
          updatePerformances:
            permSet.has('productions.create.all') || (permSet.has('productions.create.own') && isTargetMyOrganization),
          addPerformances:
            permSet.has('productions.create.all') || (permSet.has('productions.create.own') && isTargetMyOrganization),
          digitalPresence:
            permSet.has('organizationContacts.create.all') ||
            (permSet.has('organizationContacts.create.own') && isTargetMyOrganization),
          photos: permSet.has('media.create.all') || (permSet.has('media.create.own') && isTargetMyOrganization),
          videos: permSet.has('media.create.all') || (permSet.has('media.create.own') && isTargetMyOrganization),
          reviews: permSet.has('reviews.create.all') || (permSet.has('reviews.create.own') && isTargetMyOrganization),
          managementStaff:
            permSet.has('organizationEmployees.create.all') ||
            (permSet.has('organizationEmployees.create.own') && isTargetMyOrganization),
          contactInfo:
            permSet.has('organizationContacts.create.all') ||
            (permSet.has('organizationContacts.create.own') && isTargetMyOrganization),
          agencyInfo:
            permSet.has('organizationContacts.create.all') ||
            (permSet.has('organizationContacts.create.own') && isTargetMyOrganization),
        },
        showHide: {
          photos: permSet.has('media.showHide.all') || (permSet.has('media.showHide.own') && isTargetMyOrganization),
          videos: permSet.has('media.showHide.all') || (permSet.has('media.showHide.own') && isTargetMyOrganization),
          reviews:
            permSet.has('reviews.showHide.all') || (permSet.has('reviews.showHide.own') && isTargetMyOrganization),
          performances:
            permSet.has('productions.showHide.all') ||
            (permSet.has('productions.showHide.own') && isTargetMyOrganization),
        },
        canShareAccess:
          permSet.has('profiles.shareAccess.all') ||
          (permSet.has('profiles.shareAccess.own') && isTargetMyOrganization),
        archiveAccess: permSet.has('data.archive.access'),
        castingTool: permSet.has('applications.frontbase.castingTool'),
        redMaskRequest: permSet.has('applications.frontbase.redMask'),
      },
      agencyPermissions: {
        canEdit: permSet.has('agencies.create.all') || (permSet.has('agencies.create.own') && hasAgencyAccess),
        canAddOffice:
          permSet.has('agencyOffices.create.all') || (permSet.has('agencyOffices.create.own') && hasAgencyAccess),
        canAddArtist:
          permSet.has('agencyArtists.create.all') || (permSet.has('agencyArtists.create.own') && hasAgencyAccess),
      },
      isAdmin: permSet.has('applications.frontbase.admin'),
      isTranslator: permSet.has('applications.frontbase.translator'),
      isPaidAccess: permSet.has('applications.frontbase.paidAccess'),
      activeProfile: userProfile,
      isTargetMyProfile,
      isLoggedIn,
      isTargetMyOrganization,
      userData,
      userProfiles,
    };

    return newPermissions;
  }, [profilePermissions, userPermissions, targetId, userProfile, userData, userProfiles, isLoggedIn]);

  return permissions;
}

export function useProductionPermissions() {
  const activeProfileId = useActiveProfileId();
  const { productionPermissions, isAdmin } = usePermissions(activeProfileId);

  const canPublishAll = isAdmin || productionPermissions.canPublishAll;

  return { canPublishAll };
}

export default usePermissions;
