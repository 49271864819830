const jwt = require('jsonwebtoken');
const { isEmpty } = require('lodash');
const Cookies = require('cookies');
const { getHttpInstance } = require('../serverHttpInstance');
const { ACTIVE_PROFILE_ID, USER_SESSION, USER_SESSION_INFO, OB_USER_ID } = require('../../constants/cookieConstants');
const logger = require('../logger');

/*eslint-disable */
const FUSION_API_URL = process.env.FUSION_API_URL;
const COOKIE_ENCRYPTION_KEY = process.env.COOKIE_ENCRYPTION_KEY;
const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN;
/* eslint-enable */

const getToken = req => {
  if (req && req.session && !isEmpty(req.session.get(USER_SESSION_INFO))) {
    return req.session.get(USER_SESSION_INFO).token;
  }

  return global.accessToken;
};

const getTokenForAuthRequests = req => {
  if (req && req.session && !isEmpty(req.session.get(USER_SESSION_INFO))) {
    return req.session.get(USER_SESSION_INFO).token;
  }

  return null;
};

const getRefreshToken = req => {
  if (req && req.session && !isEmpty(req.session.get(USER_SESSION_INFO))) {
    return req.session.get(USER_SESSION_INFO).refreshToken;
  }

  return null;
};

const getProfileId = req => {
  if (req && req.session && !isEmpty(req.session.get(USER_SESSION_INFO))) {
    const { activeProfileId } = req.session.get(USER_SESSION_INFO);
    return activeProfileId || null;
  }
  return null;
};

const setToken = token => {
  global.accessToken = token;
};

const isTokenValid = () => {
  const token = getToken();
  if (!token) {
    return false;
  }

  let payload = null;
  try {
    payload = jwt.decode(token);
  } catch (ex) {
    console.error(ex);
    return false;
  }

  // consider the token expired prior to 1 minutes of the set expiry time
  return new Date() < new Date((payload.exp - 60) * 1000);
};

const hasToken = () => typeof global.accessToken !== 'undefined' && global.accessToken !== null;

const refreshToken = async () => {
  if (!hasToken() || !isTokenValid()) {
    const token = await createAccessToken();
    if (token) {
      setToken(token);
    }
  }
};

const createAccessToken = async () => {
  const url = `${FUSION_API_URL}/auth`;
  const instance = await getHttpInstance(new URL(url).origin);

  const data = {
    type: 'accessKey',
    application: 'frontbase',
    accessKey: {
      key: process.env.ACCESS_KEY,
      email: process.env.ACCESS_EMAIL,
    },
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  let token = null;

  try {
    const response = await instance(url, {
      method: 'POST',
      headers,
      data,
    });

    token = response.data.token;
  } catch (ex) {
    throw new Error('Error fetching accessKey token', ex);
  }

  return token;
};

const deleteCookiesOnLogout = requestCookies => {
  requestCookies.set(ACTIVE_PROFILE_ID, '', {
    expires: new Date(1970, 1, 1),
    secure: false,
    httpOnly: false,
    signed: true,
    domain: COOKIE_DOMAIN,
  });
  requestCookies.set(OB_USER_ID, '', {
    expires: new Date(1970, 1, 1),
    secure: false,
    httpOnly: false,
    signed: true,
    domain: COOKIE_DOMAIN,
  });
};

const destroyUserSession = async (req, res, task) => {
  try {
    // delete cookies on logout
    const requestCookies = new Cookies(req, res, { keys: [COOKIE_ENCRYPTION_KEY] });
    deleteCookiesOnLogout(requestCookies);

    try {
      // destroy session
      if (task === 'unsetSession') {
        res.clearCookie(USER_SESSION);
      } else {
        await req.session.destroy();
      }
    } catch (err) {
      logger.error('Failed destroying session :: ', err);
    }

    return null;
  } catch (err) {
    logger.error('Error destroying user session :: ', err);
    return null;
  }
};

const clearInvalidSessionMiddleware = (req, res, next) => {
  const requestCookies = req.cookies;
  const sessionCookies = requestCookies ? requestCookies[USER_SESSION] : null;
  const userInfo = req.session.get(USER_SESSION_INFO);
  // clear tampered cookie
  if (sessionCookies && !userInfo) {
    res.clearCookie(USER_SESSION);
    res.clearCookie(ACTIVE_PROFILE_ID);
  }

  return next();
};

module.exports = {
  hasToken,
  refreshToken,
  getToken,
  getRefreshToken,
  getProfileId,
  getTokenForAuthRequests,
  destroyUserSession,
  clearInvalidSessionMiddleware,
};
