/* eslint-disable no-restricted-syntax */
import upperFirst from 'lodash-es/upperFirst';
import { v4 as uuidv4 } from 'uuid';
import pickBy from 'lodash-es/pickBy';
import { TRACKING_SESSION_KEY } from 'constants/cookieConstants';
import { setCookie, getCookie } from 'utils/cookie';
import { EVENT_TYPES } from 'components/Globals/Analytics/constants';

const getHumanReadabbeMetaInfo = meta => {
  const section = meta.section ? `${upperFirst(meta.section)}` : '';
  const component = meta.component ? ` / ${upperFirst(meta.component)}` : '';
  const subComponent = meta.subComponent ? ` / ${upperFirst(meta.subComponent)}` : '';
  const entityType = meta.entityType ? `${upperFirst(meta.entityType)} ` : '';
  return {
    section,
    component,
    subComponent,
    entityType,
  };
};

export const getHumanReadableEventName = (eventType, meta) => {
  if (eventType === EVENT_TYPES.PAGE_VIEW) {
    // Viewed Landing Page: Home
    return `Viewed ${upperFirst(meta.pageCategory)} Page: ${upperFirst(meta.pageType)}`;
  }

  const { section, component, subComponent, entityType } = getHumanReadabbeMetaInfo(meta);

  if (eventType === EVENT_TYPES.SEARCH) {
    return `${entityType}Searched (${section}${component}${subComponent})`;
  }

  if (eventType === EVENT_TYPES.IMPRESSION || eventType === EVENT_TYPES.CLICK) {
    // Artist Impression (ArtistSection / ArtistCard)
    // Artist Clicked (ArtistSection / ArtistCard)
    return eventType === EVENT_TYPES.IMPRESSION
      ? `${entityType}Impression (${section}${component}${subComponent})`
      : `${entityType}Clicked (${section}${component}${subComponent})`;
  }

  if (eventType === EVENT_TYPES.ACTION_CALL_BACK) {
    // Action Callback (ReturningUser / Modal / viewReturningUserModal / success)
    return `Action Callback (${section}${component}${subComponent}${meta?.step ? ` / ${upperFirst(meta.step)}` : ''}${
      meta?.actionStatus ? ` / ${upperFirst(meta.actionStatus)}` : ''
    })`;
  }

  return '';
};

export const removeEmptyValues = data => pickBy(data, value => value !== null && value !== undefined && value !== '');

const inThirtyMinutes = () => new Date(new Date().getTime() + 30 * 60 * 1000);

export const generateSessionId = () => {
  let sessionId = getCookie(TRACKING_SESSION_KEY);
  if (!sessionId) {
    sessionId = uuidv4();
    setCookie(TRACKING_SESSION_KEY, sessionId, inThirtyMinutes());
  }
  return sessionId;
};

export const isInternalUser = email => {
  if (!email) return false;
  // Need to check with ronak or vinit for user action control
  return ['@transfunnel', '@operabase', '@truelinked', '@artsconsolidated'].some(str => email.includes(str));
};

/**
 * Converts array values to string
 * Input {"work_id":[3979,4039],"work_creator_id":[2152455],"co_cast_id":[2152455,19657],"work_creator_profession":"composer"}
 * Output: {"work_id":'3979,4039',"work_creator_id":'2152455',"co_cast_id":'2152455,19657',"work_creator_profession":"composer"}
 */
export const convertArrayValuesToStrings = obj => {
  const formattedObject = {};

  // Iterate over each key in the input object
  // eslint-disable-next-line prefer-const
  for (let key of Object.keys(obj)) {
    // Convert the value to a string if it's an array, otherwise keep it as is
    formattedObject[key] = Array.isArray(obj[key]) ? obj[key].join(',') : String(obj[key]);
  }

  return formattedObject;
};
