import React, { useRef } from 'react';
import { QueryClientProvider, Hydrate } from 'utils/react-query';

import { newQueryClient } from 'utils/queriesUtil';

const ReactQueryProvider = ({ dehydratedState, children }) => {
  const queryClientRef = useRef();

  if (!queryClientRef.current) {
    queryClientRef.current = newQueryClient();
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={dehydratedState}>{children}</Hydrate>
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
