const convertNoIndexURL = url => url.replace(/([^/]+)$/, 'noindex-$1');
const noIndexImageTransformer = image =>
  Object.fromEntries(
    Object.entries(image || {}).map(([key, value]) => [key, key === 'blurHash' ? value : convertNoIndexURL(value)]),
  );

export const transformImagesWithNoIndex = ({ data = [], ...rest } = {}) => {
  const transformedData = data.map(item => {
    if (item?.artist?.image) {
      return {
        ...item,
        artist: {
          ...item.artist,
          image: noIndexImageTransformer(item.artist.image),
        },
      };
    }

    if (item?.venue?.logo) {
      return {
        ...item,
        venue: {
          ...item.venue,
          logo: noIndexImageTransformer(item.venue.logo),
        },
      };
    }

    if (item?.organization?.logo) {
      return {
        ...item,
        organization: {
          ...item.organization,
          logo: noIndexImageTransformer(item.organization.logo),
        },
      };
    }

    if (item?.image) {
      return {
        ...item,
        image: noIndexImageTransformer(item.image),
      };
    }

    if (item?.logo) {
      return {
        ...item,
        logo: noIndexImageTransformer(item.logo),
      };
    }

    return item;
  });

  return {
    ...rest,
    data: transformedData,
  };
};
