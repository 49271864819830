import { useTranslation } from 'src/i18n';

export default function useLanguageDisplayLabel() {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const getLanguageLabel = (language, translationFirst = false) => {
    const languageName = language?.display_name;
    const translatedName = t(language?.translation_name);

    let displayLabel = translationFirst ? translatedName : languageName;

    if (languageName?.toLocaleLowerCase() !== translatedName.toLocaleLowerCase()) {
      displayLabel += ` (${translationFirst ? languageName : translatedName})`;
    }

    return displayLabel;
  };

  return getLanguageLabel;
}
