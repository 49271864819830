import React from 'react';

import useAppVersionStatus from 'utils/hooks/useAppVersionStatus';

const AppVersionWrapper = ({ children }) => {
  useAppVersionStatus();

  return children;
};

const AppVersionCheck = ({ children }) => {
  const isAppVersionCheckEnabled = process.env.APP_VERSION_CHECK_ENABLED === 'true';

  if (!isAppVersionCheckEnabled) {
    return children;
  }

  return <AppVersionWrapper>{children}</AppVersionWrapper>;
};

// TODO: Move this out of HOC folder
export default AppVersionCheck;
