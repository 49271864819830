import { dehydrate, QueryClient } from 'utils/react-query';
import isMobile from 'ismobilejs';
import omit from 'lodash/omit';

import {
  ENTITY_MAIN_TABS,
  ENTITY_DETAILS_TAB,
  ENTITY_TYPE,
  RESPONSE_STATUS_CODES,
  BASE_PAGE_ROUTES,
  MEDIA_SHOWREEL_OPTIONS,
  REPERTOIRE_SECTION_TYPES,
  ARTIST_TYPE_ID,
  SORT_OPTION_VALUES,
  PAGINATION_LIMITS,
  DATE_FORMATS,
  SORT_OPTION_TYPES,
  FEATURED_ENTITY_SECTIONS,
} from 'constants/index';
import { FILTER_TYPES, FILTER_SLUG_TYPE } from 'constants/filters';
import { USER_SESSION_INFO } from 'constants/cookieConstants';

import { getRouteValidityStatus } from 'utils/globals';
import getLinkProps from 'utils/globals/getLinkProps';
import { getArtistIndexQueryParams } from 'utils/artists';
import { getCookiesOnServer } from 'utils/auth';
import getRouteContext from 'utils/globals/getRouteContext';
import { createDate } from 'utils/date';
import { getActiveProfileId } from 'utils/hooks/useAuthenticatedUser';

import { getRedirectPageRoute } from 'containers/Globals/queries';
import { selectTrendingArtistProfessions } from 'containers/Artist/transformers';

import { getServerLanguage } from 'src/i18n';

import QUERIES from './queries';

const getTime = () => Date.now();

const getEntitySSRQueries = (context = {}, extras = {}) => {
  const { entityType, mainPath, subPath, filterParams } = context;
  const { isMobileDevice, entity } = extras;
  let queryList = [];
  const includeProQueries =
    entityType !== ENTITY_TYPE.ARTIST || (entityType === ENTITY_TYPE.ARTIST && entity?.stats?.isPro);

  switch (mainPath) {
    case ENTITY_MAIN_TABS.PERFORMANCES: {
      const isUpcomingProduction = subPath !== ENTITY_DETAILS_TAB.PAST && !filterParams?.season_id?.[0];

      if (isUpcomingProduction) {
        queryList = [QUERIES.PRODUCTIONS.GET_SEASON_PERFORMANCES];
      } else {
        queryList = [QUERIES.PRODUCTIONS.GET_SEASON_PRODUCTIONS];
      }

      break;
    }
    case ENTITY_MAIN_TABS.VIDEOS: {
      if (subPath) {
        queryList = includeProQueries ? [QUERIES.MEDIA.GET_FEATURED_VIDEOS({ infinite: true })] : [];
      } else {
        queryList = [
          QUERIES.MEDIA.GET_DIGITAL_VIDEOS({ limit: 6 }),
          ...(includeProQueries
            ? [
                QUERIES.MEDIA.GET_FEATURED_VIDEOS({ limit: 3 }),
                QUERIES.MEDIA.GET_SHOWREEL_VIDEOS({ limit: 3, showreelTypeId: MEDIA_SHOWREEL_OPTIONS.INTRO.id }),
                QUERIES.MEDIA.GET_PRODUCTION_PAST_VIDEOS({ distinctLimit: 4 }),
                QUERIES.MEDIA.GET_PRODUCTION_UPCOMING_VIDEOS({ distinctLimit: 4 }),
              ]
            : []),
        ];
      }

      break;
    }
    case ENTITY_MAIN_TABS.IMAGES: {
      if (!includeProQueries) {
        queryList = [];
        break;
      }

      if (subPath) {
        queryList = [QUERIES.MEDIA.GET_FEATURED_PHOTOS({ infinite: true })];
      } else {
        queryList = [
          QUERIES.MEDIA.GET_FEATURED_PHOTOS({ limit: 3 }),
          QUERIES.MEDIA.GET_PRODUCTION_PAST_PHOTOS({ distinctLimit: 4 }),
          QUERIES.MEDIA.GET_PRODUCTION_UPCOMING_PHOTOS({ distinctLimit: 4 }),
        ];
      }

      break;
    }
    case ENTITY_MAIN_TABS.REVIEWS: {
      if (!includeProQueries) {
        queryList = [];
        break;
      }

      queryList = [QUERIES.REVIEWS.GET_FEATURED_REVIEWS, QUERIES.REVIEWS.GET_PRODUCTION_REVIEWS];

      break;
    }
    case ENTITY_MAIN_TABS.BIO: {
      if (!includeProQueries) {
        queryList = [];
        break;
      }

      queryList = [
        QUERIES.ARTISTS.GET_BIO,
        QUERIES.MEDIA.GET_FEATURED_PHOTOS({ infinite: true }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ARTISTS }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.VENUES }),
      ];
      break;
    }
    case ENTITY_MAIN_TABS.ABOUT: {
      if (entityType === ENTITY_TYPE.ORGANIZATION) {
        queryList = [QUERIES.ORGANIZATIONS.GET_TRANSLATIONS];
      }

      break;
    }
    case ENTITY_MAIN_TABS.CONTACT: {
      if (entityType === ENTITY_TYPE.ARTIST) {
        queryList = [
          QUERIES.MANAGERS.GET_AGENCIES,
          ...(includeProQueries
            ? [
                QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ARTISTS }),
                QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS }),
                QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.VENUES }),
              ]
            : []),
        ];
      }

      break;
    }
    case ENTITY_MAIN_TABS.RENTALS: {
      queryList = [QUERIES.PRODUCTIONS.GET_RENTALS];

      break;
    }
    case ENTITY_MAIN_TABS.REPERTOIRE: {
      if (!includeProQueries) {
        queryList = [];
        break;
      }

      queryList = [
        QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({ type: REPERTOIRE_SECTION_TYPES.FUTURE }),
        QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({ type: REPERTOIRE_SECTION_TYPES.PAST }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ARTISTS }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS }),
        QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.VENUES }),
      ];
      break;
    }
    case ENTITY_MAIN_TABS.HOME:
    default: {
      switch (entityType) {
        case ENTITY_TYPE.ARTIST: {
          const featuredMediaQueries = [
            ...(extras?.entity?.stats?.media?.images?.featured?.exists
              ? [QUERIES.MEDIA.GET_FEATURED_PHOTOS({ limit: 3 })]
              : [QUERIES.MEDIA.GET_PRODUCTION_PHOTOS({ limit: 3 })]),
            ...(extras?.entity?.stats?.media?.videos?.featured?.exists
              ? [QUERIES.MEDIA.GET_FEATURED_VIDEOS({ limit: 3 })]
              : [QUERIES.MEDIA.GET_PRODUCTION_VIDEOS({ limit: 3 })]),
          ];
          queryList = [
            ...(includeProQueries
              ? [
                  ...featuredMediaQueries,
                  QUERIES.ARTISTS.GET_BIO,
                  QUERIES.REVIEWS.GET_FEATURED_REVIEWS,
                  QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({ type: REPERTOIRE_SECTION_TYPES.FUTURE }),
                  QUERIES.ARTISTS.GET_REPERTOIRE_DETAILS({ type: REPERTOIRE_SECTION_TYPES.PAST }),
                  QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ARTISTS }),
                  QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.ORGANIZATIONS }),
                  QUERIES.ARTISTS.GET_PREFERRED_ENTITIES({ section: FEATURED_ENTITY_SECTIONS.VENUES }),
                ]
              : []),
            QUERIES.MEDIA.GET_MEDIA_SUGGESTIONS,
            QUERIES.PRODUCTIONS.GET_PRODUCTION_CREDITS,
            QUERIES.PRODUCTIONS.GET_PAST_PRODUCTIONS({ limit: 5, mediaLimit: 5, hasMedia: true }),
            QUERIES.PRODUCTIONS.GET_SEASON_PERFORMANCES({ limit: 15 }),
            QUERIES.PRODUCTIONS.GET_SEASON_PRODUCTIONS({ limit: isMobileDevice ? 3 : 6 }),
            QUERIES.PRODUCTIONS.GET_PAST_PRODUCTIONS({ limit: 1 }),
            QUERIES.MANAGERS.GET_AGENCIES,
          ];

          break;
        }
        case ENTITY_TYPE.ORGANIZATION: {
          queryList = [
            QUERIES.MEDIA.GET_MEDIA_SUGGESTIONS,
            QUERIES.ORGANIZATIONS.GET_TRANSLATIONS,
            QUERIES.ORGANIZATIONS.GET_ASSOCIATED_ORGANIZATIONS,
            QUERIES.PRODUCTIONS.GET_PRODUCTION_CREDITS,
            QUERIES.MEDIA.GET_DIGITAL_VIDEOS({ limit: 2 }),
            QUERIES.MEDIA.GET_FEATURED_VIDEOS({ limit: 1 }),
            QUERIES.MEDIA.GET_FEATURED_PHOTOS({ limit: 1 }),
            QUERIES.PRODUCTIONS.GET_UPCOMING_PRODUCTIONS({ limit: 3, infinite: true }),
            QUERIES.PRODUCTIONS.GET_SEASON_PERFORMANCES({ limit: 15 }),
            QUERIES.PRODUCTIONS.GET_SEASON_PRODUCTIONS({ limit: isMobileDevice ? 3 : 6 }),
            QUERIES.MANAGERS.GET_AGENCIES,
          ];

          break;
        }
        case ENTITY_TYPE.PRODUCTION: {
          queryList = [
            QUERIES.MEDIA.GET_MEDIA_SUGGESTIONS,
            QUERIES.PRODUCTIONS.GET_RENTAL_CONTACTS,
            QUERIES.PRODUCTIONS.GET_DESCRIPTIONS,
          ];

          break;
        }
        default: {
          break;
        }
      }

      break;
    }
  }

  return queryList;
};

const getIndexSSRQueries = (context = {}, extras = {}) => {
  const { basePath, paths, queryParams } = context;
  const { country, allProfessionsList, isMobileDevice, selectedOrgType } = extras;
  const { professions, voiceTypes, instrumentTypes } = allProfessionsList?.data || {};

  switch (basePath) {
    case BASE_PAGE_ROUTES.ARTISTS: {
      const artistQueries = [];
      if (paths?.length === 1 || queryParams?.query || queryParams?.letter) {
        const appliedFilters = getArtistIndexQueryParams({
          paths,
          queryParams,
          professions,
          voiceTypes,
          instrumentTypes,
          getSelectedProfession: true,
        });

        const filters = omit(appliedFilters, 'selectedProfession');

        if (Number(queryParams?.page) > 0) {
          artistQueries.push(
            QUERIES.ARTISTS.GET_ARTISTS({
              filters,
              limit: 30,
            }),
          );
        } else if (appliedFilters?.selectedProfession?.pro > 0) {
          artistQueries.push(
            QUERIES.ARTISTS.GET_TRENDING_ARTISTS({
              filters,
              limit: 40,
            }),
          );
        } else {
          artistQueries.push(
            QUERIES.ARTISTS.GET_ARTISTS({
              filters: {
                ...filters,
                sort: SORT_OPTION_VALUES.RANDOM,
                profile_type_id: ARTIST_TYPE_ID,
              },
              limit: 16,
              infinite: true,
            }),
          );
        }
      } else if (paths?.length === 0) {
        artistQueries.push(QUERIES.GLOBAL.GET_HOME_NUMBERS());
        artistQueries.push(QUERIES.GLOBAL.GET_TESTIMONIALS({ limit: 10 }));
        const trendingProfessions = selectTrendingArtistProfessions(allProfessionsList, 5);

        if (trendingProfessions?.length > 0) {
          trendingProfessions.forEach(item => {
            artistQueries.push(
              QUERIES.ARTISTS.GET_TRENDING_ARTISTS({
                filters: {
                  primary_profession_id: item?.id,
                },
                limit: 8,
              }),
            );
          });
        }

        artistQueries.push(QUERIES.GLOBAL.GET_TESTIMONIALS({ limit: 10 }));
      }

      return artistQueries;
    }
    case BASE_PAGE_ROUTES.ORGANIZATIONS: {
      const { orgTypes } = extras;
      let organizationQueries = [];

      const hasFiltersApplied = Object.keys(context?.filters).length > 0;
      const showTabularList =
        hasFiltersApplied && (context?.filters?.page > 0 || !!context?.filters?.letter || !!context?.filters?.query);

      if (hasFiltersApplied) {
        if (showTabularList) {
          const shouldUseGlobalSearch = context?.filters?.query?.length > 0;

          if (shouldUseGlobalSearch) {
            organizationQueries.push(
              QUERIES.GLOBAL.GET_GLOBAL_SEARCH_SUGGESTIONS({ limit: PAGINATION_LIMITS.ORGANIZATION_INDEX_PAGE_LIST }),
            );
          } else {
            organizationQueries.push(
              QUERIES.ORGANIZATIONS.GET_ORGANIZATIONS({
                filters: { sort: SORT_OPTION_VALUES.NAME_ASC, ...context?.filterParams },
                limit: PAGINATION_LIMITS.ORGANIZATION_INDEX_PAGE_LIST,
              }),
            );
          }
        } else if (selectedOrgType?.pro >= 8) {
          organizationQueries.push(QUERIES.ORGANIZATIONS.GET_TRENDING_ORGANIZATIONS);
        }
      } else {
        organizationQueries.push(QUERIES.GLOBAL.GET_HOME_NUMBERS());
        organizationQueries.push(QUERIES.GLOBAL.GET_TESTIMONIALS({ limit: 10 }));
        const trendingOrgTypes = orgTypes?.data?.filter(type => type?.total > 8);
        const trendingQueries = trendingOrgTypes?.map(type =>
          QUERIES.ORGANIZATIONS.GET_TRENDING_ORGANIZATIONS({ filters: { organization_type_id: type.id } }),
        );

        organizationQueries = [...organizationQueries, ...trendingQueries];
      }

      return organizationQueries;
    }
    case BASE_PAGE_ROUTES.COMPOSERS: {
      return [QUERIES.GLOBAL.GET_COMPOSER_LIST({ query: queryParams?.query })];
    }
    case BASE_PAGE_ROUTES.SEASONS: {
      if (country) {
        const years = context?.context?.years || context?.context?.year || [];
        const season_id = years?.[0]?.id || createDate().format(DATE_FORMATS.YEAR);

        const filters = {
          has_productions: 'yes',
          sort: SORT_OPTION_TYPES.CITY_ASC.value,
          ...(country?.id && { country_id: country?.id }),
          ...(season_id && { season_id }),
        };

        return [QUERIES.ORGANIZATIONS.GET_ORGANIZATIONS({ filters, limit: 12, infinite: true })];
      }
      const years = context?.context?.years || context?.context?.year || [];
      const season_id = years?.[0]?.id || createDate().format(DATE_FORMATS.YEAR);

      return [QUERIES.PRODUCTIONS.GET_COUNTRIES({ filters: { season_id } })];
    }
    case BASE_PAGE_ROUTES.HOME:
    case '': {
      const artistQueries = [];
      const trendingProfessions = selectTrendingArtistProfessions(allProfessionsList, 10);

      if (trendingProfessions?.length > 0) {
        trendingProfessions.forEach(item => {
          const filters = {};
          if (item.profession_id) {
            filters.primary_profession_id = item.profession_id;
          }
          if (item.profession_qualification_id) {
            filters.primary_profession_qualification_id = item.profession_qualification_id;
          }

          artistQueries.push(
            QUERIES.ARTISTS.GET_TRENDING_ARTISTS({
              filters: {
                primary_profession_id: item?.id,
              },
              limit: 4,
            }),
          );
        });
      }

      return [
        QUERIES.PRODUCTIONS.GET_FEATURED({ limit: isMobileDevice ? 3 : 15, infinite: true }),
        QUERIES.GLOBAL.GET_TESTIMONIALS({ limit: 10 }),
        QUERIES.GLOBAL.GET_HOME_NUMBERS,
        ...artistQueries,
      ];
    }
    default:
      return [];
  }
};

export const withPerformanceMeta = fn => (...args) => {
  const start = getTime();
  const promise = fn(...args);

  const calcElapsedTime = data => ({
    ...data,
    __performance__: {
      responseTime: getTime() - start,
      size: `${(new TextEncoder().encode(JSON.stringify({ ...data })).length / 1024).toFixed(2)}kB`,
    },
  });

  return promise.then(calcElapsedTime, calcElapsedTime);
};

export const getEntityDetails = (
  { entityType, entityId, showApprovedHidden, asEdit, program_published, queryConfig },
  cookies,
  language,
) => {
  if (!entityId) {
    return {};
  }

  const { queryFn, getProps = () => {} } = QUERIES.GLOBAL.GET_ENTITY_DETAILS({
    entityType,
    entityId,
    showApprovedHidden,
    asEdit,
    program_published,
    queryConfig,
  });

  return queryFn(getProps(), cookies, language);
};

export const fetchQueryPromises = (
  queryClient,
  queryList,
  { context = {}, extraParams = {}, cookies, language, prefetch = true },
) => {
  const promises = queryList?.reduce((queries, queryInfo) => {
    let queryObj = queryInfo;

    if (typeof queryInfo === 'function') {
      queryObj = queryInfo({});
    }

    const isEnabled = queryObj?.isEnabled ? queryObj.isEnabled({ context, extras: extraParams }) : true;

    if (isEnabled) {
      const { queryFn, getProps, infinite = false } = queryObj;
      const params = getProps ? getProps({ context, extras: extraParams }) : {};
      const getData = queryFn(params, cookies, language);

      const queryProps = { queryKey: getData.queryKey, queryFn: withPerformanceMeta(getData.queryFn) };

      if (infinite) {
        if (prefetch) {
          queries.push(queryClient.prefetchInfiniteQuery(queryProps));
        } else {
          queries.push(queryClient.fetchInfiniteQuery(queryProps));
        }
      } else if (prefetch) {
        queries.push(queryClient.prefetchQuery(queryProps));
      } else {
        queries.push(queryClient.fetchQuery(queryProps));
      }
    }

    return queries;
  }, []);

  return promises || [];
};

export const fetchPrerequisiteQueries = async (queryClient, queries, { context, extraParams, cookies, language }) => {
  const { queryList, queryProcessor } = queries.reduce(
    (acc, entry) => {
      acc.queryList.push(entry?.query);
      acc.queryProcessor.push({
        key: entry?.key,
        select: entry?.select,
      });

      return acc;
    },
    { queryList: [], queryProcessor: [] },
  );

  const promises = fetchQueryPromises(queryClient, queryList, {
    context,
    extraParams,
    cookies,
    language,
    prefetch: false,
  });

  const responses = await Promise.allSettled(promises).then(res => ({
    ...res.reduce((acc, entry, index) => {
      const processor = queryProcessor[index];
      const selector = processor?.select ? processor?.select : response => response;

      return {
        ...acc,
        [processor?.key]: selector(entry?.value || null),
      };
    }, {}),
  }));

  return responses;
};

const getServerProps = async ctx => {
  const start = getTime();
  const { req, query, resolvedUrl } = ctx;

  const userAgent = req.headers['user-agent'];
  const isMobileDevice = isMobile(userAgent).any;
  const userSessionInfo = req?.session?.get(USER_SESSION_INFO);
  const { user = null, isLoggedIn = false } = userSessionInfo || {};
  const isTrustedBot = req.fingerprint?.isBot && req.fingerprint?.trusted;
  const isNextDataRequest = req.nextdata || false;

  const language = getServerLanguage(req);
  const cookies = getCookiesOnServer(ctx);
  const context = getRouteContext({ query, asPath: resolvedUrl, isLoggedIn }, language);

  const {
    edit,
    entityId,
    entityType,
    countrySlug,
    mainPath,
    subPath,
    filterParams,
    program_published,
    paths,
    basePath,
    pro,
    orgTypeSlug,
  } = context;

  let entity = null;
  let redirect = false;
  let location = null;
  let selectedSeasonKey = null;
  let statusCode = RESPONSE_STATUS_CODES.SUCCESS;

  const queryClient = new QueryClient();

  const baseUserQueries = [];

  if (isLoggedIn && !isNextDataRequest) {
    baseUserQueries.push({
      query: QUERIES.ACCOUNTS.GET_USER_PROFILES,
      key: 'userProfiles',
      select: response => response?.data,
    });

    baseUserQueries.push({
      query: QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_ID,
      key: 'savedActiveProfileId',
      select: response => response?.data,
    });
  }

  const { userProfiles, savedActiveProfileId } = await fetchPrerequisiteQueries(queryClient, baseUserQueries, {
    context,
    extraParams: { user },
    cookies,
    language,
  });

  const activeProfileId = getActiveProfileId(userProfiles, savedActiveProfileId);

  const dependentUserQueries = [];

  if (isLoggedIn && !isNextDataRequest) {
    const userQueries = [
      QUERIES.ACCOUNTS.GET_USER_DETAILS,
      QUERIES.ACCOUNTS.GET_USER_PERMISSIONS,
      QUERIES.ACCOUNTS.GET_USER_SUBSCRIPTIONS,
      QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_DETAILS,
      QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_PERMISSIONS,
      QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_SUBSCRIPTIONS,
    ];

    dependentUserQueries.push(...userQueries);
  }

  if ((!edit && entityType && entityId) || entityType === ENTITY_TYPE.MANAGER) {
    const showApprovedHidden = false; // TODO: Solve for SSR user permissions

    const { queryKey, queryFn } = getEntityDetails(
      { entityType, entityId, showApprovedHidden, asEdit: edit, program_published },
      cookies,
      language,
    );

    entity = queryKey && (await queryClient.fetchQuery(queryKey, withPerformanceMeta(queryFn)));
  }

  const prerequisiteQueries = [];

  if (activeProfileId) {
    prerequisiteQueries.push({
      query: QUERIES.ACCOUNTS.GET_ACTIVE_PROFILE_DETAILS,
      key: 'activeProfile',
    });
  }

  if (countrySlug) {
    prerequisiteQueries.push({
      query: QUERIES.GLOBAL.GET_LOCATION,
      key: 'country',
    });
  }

  if (
    entityId &&
    [ENTITY_TYPE.ORGANIZATION, ENTITY_TYPE.ARTIST].includes(entityType) &&
    (!mainPath || ENTITY_MAIN_TABS.PERFORMANCES === mainPath)
  ) {
    prerequisiteQueries.push({
      query: QUERIES.PRODUCTIONS.GET_SEASONS({ upcoming: false }),
      key: 'pastSeasons',
    });
    prerequisiteQueries.push({
      query: QUERIES.PRODUCTIONS.GET_SEASONS({ upcoming: true }),
      key: 'upcomingSeasons',
    });
  }

  if (entityType === ENTITY_TYPE.ARTIST && !entityId && paths?.length > 0) {
    prerequisiteQueries.push({
      query: QUERIES.ARTISTS.GET_PROFESSION_DETAILS({ slug: paths?.[0] }),
      key: 'artistProfession',
    });
  }

  if ((entityType === ENTITY_TYPE.ARTIST && !entityId) || (!mainPath && !basePath)) {
    prerequisiteQueries.push({
      query: QUERIES.ARTISTS.GET_ALL_PROFESSIONS_LIST,
      key: 'allProfessionsList',
    });
  } else if (entityType === ENTITY_TYPE.ORGANIZATION && !entityId) {
    prerequisiteQueries.push({
      query: QUERIES.ORGANIZATIONS.GET_ORGANIZATION_TYPES,
      key: 'orgTypes',
    });
  }

  const {
    country,
    upcomingSeasons,
    pastSeasons,
    allProfessionsList,
    activeProfile,
    orgTypes,
    artistProfession,
  } = await fetchPrerequisiteQueries(queryClient, prerequisiteQueries, {
    context,
    extraParams: { entity, activeProfileId },
    cookies,
    language,
  });

  if ((countrySlug?.length > 0 && country?.slug !== countrySlug) || (entityId === null && mainPath !== null)) {
    statusCode = RESPONSE_STATUS_CODES.NOT_FOUND;
  }

  let selectedOrgType = null;

  if (orgTypeSlug) {
    selectedOrgType = orgTypes?.data?.find(type => type?.slug === orgTypeSlug);

    if (selectedOrgType) {
      context.filterParams[FILTER_SLUG_TYPE[FILTER_TYPES.ORGANIZATION_TYPE]] = [selectedOrgType?.id];
    } else {
      statusCode = RESPONSE_STATUS_CODES.NOT_FOUND;
    }
  }

  if (
    entityType === ENTITY_TYPE.PRODUCTION &&
    entityId &&
    filterParams?.date_from &&
    !entity?.performances?.some(item => item?.startDate === filterParams?.date_from)
  ) {
    statusCode = RESPONSE_STATUS_CODES.NOT_FOUND;
  }

  if (entityType === ENTITY_TYPE.ARTIST && !entityId && paths?.length > 0) {
    if (paths?.length > 1) {
      statusCode = RESPONSE_STATUS_CODES.NOT_FOUND;
    } else {
      const selectedProfessionSlug = paths?.[0];

      if (artistProfession?.data?.slug !== selectedProfessionSlug) {
        statusCode = RESPONSE_STATUS_CODES.NOT_FOUND;
      }
    }
  }

  if (mainPath === ENTITY_MAIN_TABS.PERFORMANCES) {
    const selectedSeasonId = filterParams?.season_id?.[0];
    const isUpcomingProductionTab = subPath !== ENTITY_DETAILS_TAB.PAST && !selectedSeasonId;

    if (isUpcomingProductionTab) {
      if (!entity?.stats?.productions?.future?.exists && entity?.stats?.productions?.past?.exists) {
        redirect = true;
        statusCode = RESPONSE_STATUS_CODES.TEMPORARY_REDIRECT;
        const { getSubPath } = getLinkProps({
          entityType,
          entity,
          path: ENTITY_MAIN_TABS.PERFORMANCES,
          language,
        });

        location = getSubPath({
          path: ENTITY_DETAILS_TAB.PAST,
        })?.url;
      }
    } else if (!entity?.stats?.productions?.past?.exists && entity?.stats?.productions?.future?.exists) {
      redirect = true;
      statusCode = RESPONSE_STATUS_CODES.TEMPORARY_REDIRECT;
      location = getLinkProps({
        entityType,
        entity,
        path: ENTITY_MAIN_TABS.PERFORMANCES,
        language,
      })?.url;
    }

    if (!isUpcomingProductionTab && selectedSeasonId) {
      selectedSeasonKey = pastSeasons?.data?.find(season => season?.id === selectedSeasonId)?.key;
    }
  }

  if (statusCode === RESPONSE_STATUS_CODES.SUCCESS) {
    if (entityType && entityId && entity?.id !== entityId) {
      const newRoute =
        entityId &&
        entityType &&
        (await getRedirectPageRoute({ entityType, entityId, url: req?.url, language }, cookies, language));

      if (newRoute) {
        redirect = true;
        statusCode = RESPONSE_STATUS_CODES.PERMANENT_REDIRECT;
        location = newRoute;

        if (pro && entityType === ENTITY_TYPE.ARTIST) {
          location = `${BASE_PAGE_ROUTES.CASTING}${location}`;
        }
      } else if (!edit) {
        statusCode = RESPONSE_STATUS_CODES.NOT_FOUND;
      }
    } else {
      const { shouldRedirect, validURL, responseStatusCode } = getRouteValidityStatus({ ...context, entity });
      statusCode = responseStatusCode;

      if (shouldRedirect) {
        redirect = shouldRedirect;
        location = validURL;
      } else if (statusCode === RESPONSE_STATUS_CODES.SUCCESS) {
        const extraParams = {
          user,
          entity,
          country,
          upcomingSeasons,
          pastSeasons,
          isMobileDevice,
          isTrustedBot,
          isLoggedIn,
          selectedSeasonKey,
          allProfessionsList,
          userProfiles,
          savedActiveProfileId,
          activeProfileId,
          activeProfile,
          orgTypes,
          selectedOrgType,
        };
        const pageQueryList =
          entityType && entityId ? getEntitySSRQueries(context, extraParams) : getIndexSSRQueries(context, extraParams);
        const queryList = [...dependentUserQueries, ...pageQueryList];

        if (queryList?.length > 0) {
          const promises = fetchQueryPromises(queryClient, queryList, {
            context,
            extraParams,
            cookies,
            language,
            prefetch: true,
          });

          await Promise.allSettled(promises);
        }
      }
    }
  }

  const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient))); // NOTE: Its a fix for variation for data structure support from API
  const prefetchQueryTime = getTime() - start;

  let redirectProps = null;

  if (redirect) {
    redirectProps = {
      destination: location,
      permanent: statusCode === RESPONSE_STATUS_CODES.PERMANENT_REDIRECT,
      statusCode,
    };
  }

  return {
    notFound: statusCode === RESPONSE_STATUS_CODES.NOT_FOUND,
    context: {
      entity,
      ...context,
    },
    ...(redirect &&
      !req.nextdata && {
        redirect: redirectProps,
      }),
    props: {
      statusCode,
      redirect: redirectProps,
      prefetchQueryTime,
      userAgent,
      dehydratedState,
    },
  };
};

export default getServerProps;
