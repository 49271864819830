import { createRoutine } from 'redux-saga-routines';

import {
  GET_PROFILE,
  GET_PROFESSIONS,
  GET_ROLES,
  ADD_REPERTOIRE,
  GET_MW_LIST,
  GET_COMPOSERS_LIST,
  RESET_LIST,
} from './constants';

export const getProfileAction = createRoutine(GET_PROFILE);
export const getProfessionsAction = createRoutine(GET_PROFESSIONS);
export const getRolesAction = createRoutine(GET_ROLES);
export const addRepertoireAction = createRoutine(ADD_REPERTOIRE);
export const getMWListAction = createRoutine(GET_MW_LIST);
export const getComposersListAction = createRoutine(GET_COMPOSERS_LIST);

export function resetListAction(fieldName) {
  return {
    type: RESET_LIST,
    payload: { fieldName },
  };
}
