export const reducerName = 'EditImages';

export const GET_IMAGES = `app/${reducerName}/GET_IMAGES`;
export const CLEAR_IMAGES = `app/${reducerName}/CLEAR_IMAGES`;
export const GET_IMAGE_FORMAT_TYPES = `app/${reducerName}/GET_IMAGE_FORMAT_TYPES`;
export const GET_IMAGES_MEDIA_ATTRIBUTE_TYPES = `app/${reducerName}/GET_IMAGES_MEDIA_ATTRIBUTE_TYPES`;
export const DELETE_IMAGE = `app/${reducerName}/DELETE_IMAGE`;
export const UPDATE_IMAGE = `app/${reducerName}/UPDATE_IMAGE`;
export const CREATE_IMAGE = `app/${reducerName}/CREATE_IMAGE`;
export const UPDATE_MEDIA_TAG = `app/${reducerName}/UPDATE_MEDIA_TAG`;
export const GET_EDIT_IMAGES_PRODUCTIONS = `app/${reducerName}/GET_EDIT_IMAGES_PRODUCTIONS`;
