import { createRoutine } from 'redux-saga-routines';

import {
  GET_WORK,
  GET_WORK_PERF_FUTURE,
  GET_WORK_PERF_PAST,
  GET_WORKS_PRODUCTIONS,
  GET_WORKS_PERFORMANCES,
  SET_WORKS_PERFORMANCES,
} from './constants';

export const getWorkAction = createRoutine(GET_WORK);
export const getWorkPerfPastAction = createRoutine(GET_WORK_PERF_PAST);
export const getWorkPerfFutureAction = createRoutine(GET_WORK_PERF_FUTURE);
export const getProductionsAction = createRoutine(GET_WORKS_PRODUCTIONS);
export const getPerformancesAction = createRoutine(GET_WORKS_PERFORMANCES);

export function setPerformances(performances) {
  return {
    type: SET_WORKS_PERFORMANCES,
    payload: { performances },
  };
}
