import React, { useMemo } from 'react';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';

const ExternalLink = ({ className, renderDomain = true, url, title }) => {
  const { urlToRender, isValidURL, domain } = useMemo(() => {
    try {
      const { hostname } = new URL(url);
      return {
        urlToRender: renderDomain ? hostname : url,
        isValidURL: true,
        domain,
      };
    } catch (e) {
      return {
        urlToRender: url,
        isValidURL: false,
      };
    }
  }, [renderDomain, url]);

  if (isValidURL) {
    return (
      <LinkButton className={className} title={title || domain} url={url} variant="text" external isLink>
        <Typography color="link">{urlToRender}</Typography>
      </LinkButton>
    );
  }
  return <Typography className={className}>{urlToRender}</Typography>;
};

export default ExternalLink;
