import { getCampaignAttributionDetails } from 'utils/cookie';

import usePageContext from './usePageContext';

const useCampaignAttributionDetails = () => {
  const { utmParams } = usePageContext();

  if (!utmParams?.utmCampaign) {
    return getCampaignAttributionDetails();
  }

  return utmParams;
};

export default useCampaignAttributionDetails;
