import { createRoutine } from 'redux-saga-routines';
import {
  GET_COUNTRIES,
  GET_PERFORMANCES_NUMBER,
  GET_ARTISTS_NUMBER,
  GET_REGIONS,
  GET_USER_CONSTANTS,
  SET_LANGUAGE,
  SET_TAB_SCROLL_POSITION,
} from './consts';

export const getCountriesAction = createRoutine(GET_COUNTRIES);
export const getRegionsAction = createRoutine(GET_REGIONS);
export const getPerformancesNumberAction = createRoutine(GET_PERFORMANCES_NUMBER);
export const getArtistsNumberAction = createRoutine(GET_ARTISTS_NUMBER);
export const getUserConstants = createRoutine(GET_USER_CONSTANTS);
export const setLanguageAction = createRoutine(SET_LANGUAGE);
export const setTabScrollPositionAction = createRoutine(SET_TAB_SCROLL_POSITION);
