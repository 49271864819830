/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';
import isbot from 'isbot';
import get from 'lodash/get';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import { getRouteWithSlug } from 'utils/globals/app';
import routesWithTitle from 'constants/routesWithTitle';

import fetchPageInfo from './pageRouter';
import { getCookie, setCookie } from './cookie';
import { TRACKING_SESSION_KEY, USER_COUNTRY_CODE, ACTIVE_PROFILE_ID } from '../constants/cookieConstants';
import { ARTIST_TYPE_ID, AGENT_TYPE_ID, AOS_TYPE_ID } from '../constants/consts';
import { getLanguage } from './queriesUtil';
import route from '../constants/routes';

const website_version = process.env.WEBSITE_VERSION || 'local';
// eslint-disable-next-line prefer-destructuring
const NEXT_PUBLIC_REQUEST_COUNTRY_HEADER = process.env.NEXT_PUBLIC_REQUEST_COUNTRY_HEADER;

export const isTrackingDisabled = req => {
  const isBot = isbot(req.get('user-agent'));
  const regions = process.env.EXCLUDE_REGIONS_FOR_BOTS;
  let disableTracking = false;
  if (regions) {
    const regionList = regions.split(',');
    const isRegionExist = regionList.find(country => req.headers[NEXT_PUBLIC_REQUEST_COUNTRY_HEADER] === country);
    disableTracking = isRegionExist ? isBot : false;
  } else {
    disableTracking = isBot;
  }
  return disableTracking;
};

export const getArtistProfileDetails = profile => {
  const socialProfiles = reduce(
    get(profile, 'contacts', []),
    (acc, contact) => {
      if (contact?.contactType?.slug === 'sns') {
        // eslint-disable-next-line no-param-reassign
        acc = {
          ...acc,
          [contact.name]: contact.value,
        };
      }

      return acc;
    },
    {},
  );

  return {
    'First & Middle name': profile?.firstName,
    'Last name': profile?.lastName,
    'Profile url': `${window.location.protocol}//${window.location.host}${getRouteWithSlug(route.ARTISTS, profile)}/`,
    Profession: profile?.profession?.name,
    'Profile photo': profile?.image?.original,
    Alias: profile?.alias,
    Mobile: find(get(profile, 'contacts', []), { name: 'phone' })?.value,
    'Contact e-mail': find(get(profile, 'contacts', []), { name: 'email' })?.value,
    'Website url': find(get(profile, 'contacts', []), { name: 'website' })?.value,
    'Page on Agency web-site': find(get(profile, 'contacts', []), { name: 'companywebsite' })?.value,
    Bio: includes(profile?.completedSections, 'Biography').toString(),
    Citizenship: profile?.personal?.citizenship,
    'Birth data': profile?.personal?.birth,
    'Social presence': socialProfiles,
    Video: includes(profile?.completedSections, 'Video').toString(),
    Images: includes(profile?.completedSections, 'Images')?.toString(),
    'Review & publications': includes(profile?.completedSections, 'Reviews & Publications').toString(),
  };
};

const getProfileData = ({ profile, activeSubscription }) => {
  const profileType = profile?.profileType?.id;
  let profileDetails = {
    'Active Profile ID': profile?.id,
    'Number of profiles': profile?.allProfileWithAccess,
    'Registration date': profile?.registrationCompleteDate,
    'Profile created date': profile?.createdAt,
    'Hidden profile': profile?.isHidden?.toString(),
    'Profile Type': profile?.profileType?.id,
    isPro: profile?.isPro?.toString(),
    'Subscription status': profile?.subscriptionStatus,
    'Subscription start date': activeSubscription?.[0]?.startAt,
    'Subscription renew date': activeSubscription?.[0]?.renewAt,
    'Subscription name': activeSubscription?.[0]?.subscription?.description,
    Addon: activeSubscription?.[0]?.addons,
  };

  switch (profileType) {
    case ARTIST_TYPE_ID:
      // eslint-disable-next-line no-case-declarations
      const artistInfo = getArtistProfileDetails(profile);

      profileDetails = {
        ...profileDetails,
        ...artistInfo,
      };
      break;
    case AGENT_TYPE_ID:
      profileDetails = {
        ...profileDetails,
        'Agency ID': profile?.agencies?.[0]?.id,
        'Profile url': `${window.location.protocol}//${window.location.host}${getRouteWithSlug(
          route.MANAGERS,
          get(profile, 'agencies[0]'),
        )}`,
      };
      break;
    case AOS_TYPE_ID:
      profileDetails = {
        ...profileDetails,
        'Company ID': profile?.companies?.[0]?.company?.id,
        'Profile url': `${window.location.protocol}//${window.location.host}${getRouteWithSlug(
          `${route.COMPANIES}/${get(profile, 'companies[0].company.country.slug')}`,
          get(profile, 'companies[0].company'),
        )}`,
      };
      break;
    default:
      break;
  }

  return profileDetails;
};

export const trackSearch = (item, value) => ({
  name: TRACK_EVENTS.SEARCH_TRACK,
  item: SEARCH_LABELS.DYNAMIC_FIELDS[item] || item,
  value,
});

export const trackSort = value => ({
  name: TRACK_EVENTS.SEARCH_SORT,
  sort_by: SORT_LABELS[value] || value,
});

export const trackClearForm = () => ({
  name: TRACK_EVENTS.SEARCH_CLEAR,
});

export const trackSearchFilter = (filter_on, filter_value, namedValue = '') => ({
  name: TRACK_EVENTS.SEARCH_LIST_FILTER,
  filter_on: SEARCH_FILTER_LABELS.DYNAMIC_FIELDS[filter_on] || filter_on,
  filter_value,
  filter_named_value: namedValue,
});

export const trackSearchGo = query => ({
  name: TRACK_EVENTS.SEARCH_GO,
  ...formatQuery(query),
});

const isInternalUser = email => {
  if (!email) return 'false';

  return [
    '@transfunnel',
    '@operabase',
    '@truelinked',
    '@artsconsolidated',
    '@softteco',
    'ravikiran',
    'glgorb',
    'hleb',
    'rajesh',
    'mukund',
    'rekha',
    'test',
    'tes-tes',
    'atul.',
    'alemuresti@gmail.com',
  ]
    .some(str => email.includes(str))
    .toString();
};

export const trackUserAction = ({ payload, router, profile, userDetails, activeSubscription }) => {
  const url = router.asPath;
  const path = router.pathname === '/' ? '/' : url.slice(0, url.lastIndexOf('/'));
  const pageInfo = fetchPageInfo(path, router.query);
  const profileDetails = getProfileData({ profile, activeSubscription });

  const data = { ...profileDetails };
  data.page_type = pageInfo && pageInfo.page_type ? pageInfo.page_type : url;
  data.session_id = generateSessionId();
  data.website_version = website_version;
  data.req_country_code = getCountryCode();
  data.req_profile_id = getProfileId();
  data.language = getLanguage();
  data.page_url = url;
  data.user_created_date = userDetails?.createdAt;
  data.is_internal_user = isInternalUser(userDetails?.email);

  const trackData = { ...data, ...payload };

  return trackData;
};

const inThirtyMinutes = () => new Date(new Date().getTime() + 30 * 60 * 1000);

export const generateSessionId = () => {
  let sessionId = getCookie(TRACKING_SESSION_KEY);
  if (!sessionId) {
    sessionId = uuidv4();
    setCookie(TRACKING_SESSION_KEY, sessionId, inThirtyMinutes());
  }
  return sessionId;
};

export const updateSessionIdInCookie = () => {
  const sessionId = getCookie(TRACKING_SESSION_KEY) || uuidv4();
  setCookie(TRACKING_SESSION_KEY, sessionId, inThirtyMinutes());
};

export const getCountryCode = () => getCookie(USER_COUNTRY_CODE);

export const userIdentifyEvent = (userData, eventType) => {
  const { name, email, profileId, profileInfo } = userData;
  const profileDetails = getProfileData({ profile: profileInfo });
  const traits = {
    ...profileDetails,
    name,
    email,
    event: eventType || 'logged_in',
    req_profile_id: profileId,
    req_country_code: getCountryCode(),
    website_version,
    session_id: generateSessionId(),
    language: getLanguage(),
    user_created_date: userData?.createdAt,
    is_internal_user: isInternalUser(userData?.email),
  };

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-unused-expressions
    window.dataLayer?.push({ ...traits });
  }
};

export const resetUserOnLogout = userData => {
  if (typeof window !== 'undefined') {
    userIdentifyEvent(userData, 'logged_out');
  }
};

export const pageTracking = ({ router, url, profile, userDetails }) => {
  if (typeof window !== 'undefined') {
    const path = url;
    let pageInfo = fetchPageInfo(path, router.query);
    const profileDetails = getProfileData({ profile });

    pageInfo = {
      ...pageInfo,
      ...profileDetails,
    };

    pageInfo.session_id = generateSessionId();
    pageInfo.req_country_code = getCountryCode();
    pageInfo.req_profile_id = getProfileId();
    pageInfo.language = getLanguage();
    pageInfo.page_url = router.asPath;
    pageInfo.user_created_date = userDetails?.createdAt;
    pageInfo.is_internal_user = isInternalUser(userDetails?.email);
    return pageInfo;
  }

  return {};
};

const getProfileId = () => getCookie(ACTIVE_PROFILE_ID) || null;

export const clickReadMore = (data = {}) => ({
  name: TRACK_EVENTS.CLICK_READ_MORE,
  ...data,
});

export const clickFbTab = (data = {}) => ({
  name: TRACK_EVENTS.CLICK_FB_TAB,
  ...data,
});

export const clickDetailsLink = (data = {}) => ({
  name: TRACK_EVENTS.CLICK_SEE_MORE,
  ...data,
});

export const trackExpandProduction = (data = {}) => ({
  name: TRACK_EVENTS.EXPAND_PRODUCTION,
  ...data,
});

export const trackViewAll = (tab, data = {}) => ({
  name: TRACK_EVENTS.VIEW_ALL_TAB(tab),
  ...data,
});

export const clickProPageTab = (tab, data = {}) => ({
  name: TRACK_EVENTS.CLICK_PRO_PAGE_TAB,
  tab_item: tab,
  ...data,
});

export const trackMediaPlayer = (data = {}) => ({
  name: TRACK_EVENTS.CLICK_MEDIA_PLAYER,
  ...data,
});

export const loadMoreArtistPerformances = (data = {}) => ({
  name: TRACK_EVENTS.LOAD_MORE_ARTIST_PERFORMANCES,
  ...data,
});

export const trackViewArtistProfile = (data = {}) => ({
  name: TRACK_EVENTS.VIEW_ARTIST_PROFILE,
  ...data,
});

export const trackViewArtistEditProfile = (data = {}) => ({
  name: TRACK_EVENTS.VIEW_ARTIST_EDIT_PROFILE,
  ...data,
});

export const trackUpdateArtistProfile = (data = {}, propertyName) => ({
  name: `${TRACK_EVENTS.UPDATE_ARTIST_PROFILE}_${propertyName}`,
  ...data,
});

export const trackViewAOSProfile = (data = {}) => ({
  name: TRACK_EVENTS.VIEW_AOS_PROFILE,
  ...data,
});

export const trackViewAOSEditProfile = (data = {}) => ({
  name: TRACK_EVENTS.VIEW_AOS_EDIT_PROFILE,
  ...data,
});

export const trackUpdateAOSProfile = (data = {}, propertyName) => ({
  name: `${TRACK_EVENTS.UPDATE_AOS_PROFILE}_${propertyName}`,
  ...data,
});

export const trackShareAccess = (data = {}) => ({
  name: TRACK_EVENTS.SHARE_ACCESS,
  ...data,
});

export const trackViewAgencyProfile = (data = {}) => ({
  name: TRACK_EVENTS.VIEW_AGENCY_PROFILE,
  ...data,
});

export const trackViewAgencyEditProfile = (data = {}) => ({
  name: TRACK_EVENTS.VIEW_AGENCY_EDIT_PROFILE,
  ...data,
});

export const trackRequestArtistAccess = (data = {}) => ({
  name: TRACK_EVENTS.REQUEST_ARTIST_ACCESS,
  ...data,
});

export const trackAddAgencyArtist = (data = {}) => ({
  name: TRACK_EVENTS.ADD_AGENCY_ARTIST,
  ...data,
});

export const trackUpdateAgencyProfile = (data = {}) => ({
  name: TRACK_EVENTS.UPDATE_AGENCY_PROFILE,
  ...data,
});

export const trackUpdateAgencyAgent = (data = {}) => ({
  name: TRACK_EVENTS.UPDATE_AGENCY_AGENT,
  ...data,
});

export const trackLoggedIn = (data = {}) => ({
  name: TRACK_EVENTS.LOGGED_IN,
  ...data,
});

export const trackFormSubmit = (data = {}) => ({
  name: TRACK_EVENTS.FORM_SUBMIT,
  ...data,
});

export const pingSegment = () => ({
  name: TRACK_EVENTS.PING,
});

export const trackEvent = ({ name, data }) => ({
  name: TRACK_EVENTS[name] || name,
  ...data,
});

export const trackPaymentCompleted = (data = {}) => ({
  name: TRACK_EVENTS.PAYMENT_COMPLETED,
  ...data,
});

export const trackClickTickets = (data = {}) => ({
  name: TRACK_EVENTS.CLICKED_TICKETS,
  ...data,
});

export const trackClickedFAQClickHere = (profileType, data = {}) => ({
  name: TRACK_EVENTS.CLICKED_FAQ_CLICK_HERE(profileType),
  ...data,
});

export const trackVideoClick = (type, data = {}) => ({
  name: TRACK_EVENTS[`CLICK_VIDEO_${type.toUpperCase()}`] || TRACK_EVENTS.CLICK_VIDEO_BUTTONS(type),
  ...data,
});

export const trackOnVideoHover = (data = {}) => ({
  name: TRACK_EVENTS.HOVER_ON_VIDEO_POSTER,
  ...data,
});

export const trackClickReview = (data = {}) => ({
  name: TRACK_EVENTS.CLICK_VIDEO_REVIEWS_LINK,
  ...data,
});

export const getVideoPrefix = isWelcomeVideo =>
  isWelcomeVideo ? TRACK_EVENTS.WELCOME_VIDEO : TRACK_EVENTS.SECTION_VIDEO;
export const trackVideoClose = (prefix, data = {}) => ({
  name: prefix,
  actionType: VIDEO_EVENTS.CLICK_CLOSE,
  ...data,
});
export const trackVideoMarkDoNotShowAgain = (prefix, data = {}) => ({
  name: prefix,
  actionType: VIDEO_EVENTS.MARK_DO_NOT_SHOW_AGAIN,
  ...data,
});
export const trackVideoOpen = (prefix, data = {}) => ({
  name: prefix,
  actionType: VIDEO_EVENTS.CLICK_OPEN,
  ...data,
});

export const trackVideoSkip = (prefix, data = {}) => ({ name: prefix, actionType: VIDEO_EVENTS.CLICK_SKIP, ...data });

const VIDEO_EVENTS = {
  CLICK_CLOSE: 'click_close_button',
  MARK_DO_NOT_SHOW_AGAIN: 'mark_do_not_show_again',
  CLICK_OPEN: 'click_open_button',
  CLICK_SKIP: 'click_skip_button',
};

export const TRACK_EVENTS = {
  SEARCH_TRACK: 'search_track',
  SEARCH_GO: 'search_go',
  SEARCH_CLEAR: 'search_clear',
  SEARCH_SORT: 'search_sort',
  SEARCH_LIST_FILTER: 'search_list_filter',
  CLICK_FB_TAB: 'click_tab',
  CLICK_PRO_PAGE_TAB: `pro_page_tab`,
  CLICK_READ_MORE: 'click_read_more',
  CLICK_SEE_MORE: 'click_see_more',
  EXPAND_PRODUCTION: 'expand_production',
  VIEW_ALL_TAB: tab => `artist_${tab}`,
  CLICK_MEDIA_PLAYER: 'click_media_player',
  LOAD_MORE_ARTIST_PERFORMANCES: 'load_more_artist_performances',
  VIEW_ARTIST_PROFILE: 'view_artist_profile',
  VIEW_ARTIST_EDIT_PROFILE: 'view_artist_edit_profile',
  UPDATE_ARTIST_PROFILE: 'update_artist_profile',
  VIEW_AOS_PROFILE: 'view_aos_profile',
  VIEW_AOS_EDIT_PROFILE: 'view_aos_edit_profile',
  UPDATE_AOS_PROFILE: 'update_aos_profile',
  SHARE_ACCESS: 'share_access',
  VIEW_AGENCY_PROFILE: 'view_agency_profile',
  VIEW_AGENCY_EDIT_PROFILE: 'view_agency_edit_profile',
  REQUEST_ARTIST_ACCESS: 'request_artist_access',
  ADD_AGENCY_ARTIST: 'add_agency_artist',
  UPDATE_AGENCY_PROFILE: 'update_agency_profile',
  UPDATE_AGENCY_AGENT: 'update_agency_agent',
  LOGGED_IN: 'logged_in',
  PING: 'ping',
  FORM_SUBMIT: 'form_submit',
  PAYMENT_COMPLETED: 'payment_completed',
  CLICKED_TICKETS: 'clicked_tickets',
  CLICKED_FAQ_CLICK_HERE: profileType => `clicked_FAQ_click_here_${profileType}`,
  BILLING_ADDRESS_POPUP_SHOWN: 'shown_billing_popup',
  BILLING_ADDRESS_POPUP_COUNTRY_UPDATED: 'updated_billing_country',
  BILLING_ADDRESS_ACS_UPDATED: 'updated_billing_info',
  CLICK_REGISTER_NOW_TOP: 'click_register_now_top',
  CLICK_REGISTER_NOW_BELOW: 'click_register_now_below',
  CLICK_LERN_MORE: 'click_learn_more',
  START_REGISTRATION: 'start_registration',
  CREATE_PROFILE: 'create_profile',
  CLICK_CONTACT_US_REGISTRATION: 'click_contact_us_registration',
  CLICK_VIDEO_BUTTONS: buttonType => `click_video_${buttonType}`,
  CLICK_VIDEO_POSTER: 'click_video_poster',
  CLICK_VIDEO_WATCH: 'click_video_watch',
  CLICK_VIDEO_WATCH_NOW: 'click_video_watch_now',
  CLICK_VIDEO_LIVESTREAM: 'click_video_livestream',
  CLICK_VIDEO_TRAILER: 'click_video_trailer',
  CLICK_VIDEO_ADD: 'click_video_add',
  CLICK_VIDEO_LIVE_NOW: 'click_video_live_now',
  CLICK_VIDEO_LINK: 'click_video_link',
  CLICK_VIDEO_IMAGES_LINK: 'click_video_images_link',
  CLICK_VIDEO_REVIEWS_LINK: 'click_review_link',
  HOVER_ON_VIDEO_POSTER: 'hover_on_video_poster',
  WELCOME_VIDEO: 'welcome_video',
  SECTION_VIDEO: 'section_video',
  CLICK: 'click',
  SELECT_LANGUAGE: 'select_language',
  SEARCH: 'search',
  ARTIST_PRO_VIEW_LANDING_PAGE_VIEW: 'artist_pro_view_landing_page_view',
  PROFILE_MATCH_RESULTS: 'profile_match_results',
  VIEW_PAYWALL: 'view_paywall',
  SHARE_ENTITY: 'share_entity',
  CLICK_UPGRADE_POPUP: 'click_upgrade_popup',
  SUBSCRIPTION_UPGRADE_COMPLETED: 'subscription_upgrade_completed',
  CLICK_REACTIVATE_POPUP: 'click_reactivate_popup',
  SUBSCRIPTION_REACTIVATE_COMPLETED: 'subscription_reactivate_completed',
  VIEW_ARTIST_SWITCH_PROFILE_MODAL: 'view_artist_switch_profile_modal',
  VIEW_ARTIST_REQUEST_ACCESS_MODAL: 'view_artist_request_access_modal',
  VIEW_PROFILE_ACCESS_REQUEST_MODAL: 'view_profile_access_request_modal',
  VIEW_ARTIST_PAYMENT_MODAL: 'view_artist_payment_modal',
  VIEW_ARTIST_PAYMENT_NUDGES_MODAL: 'view_artist_payment_nudges_modal',
  VIEW_ARTIST_UPGRADE_CHECKOUT_MODAL: 'view_artist_upgrade_checkout_modal',
};

export const formatQuery = query => {
  const obj = {};
  for (const [key, value] of Object.entries(query)) {
    const newKey = SEARCH_LABELS.DYNAMIC_FIELDS[key] || key;
    const isKeyExist = searchExhaustiveList.find(val => val === newKey);
    if (isKeyExist) {
      obj[newKey] = value;
    } else {
      obj.other = {
        key: newKey,
        value,
      };
    }
  }
  return obj;
};

export const searchExhaustiveList = [
  'country',
  'city',
  'company',
  'composer',
  'work',
  'from',
  'to',
  'voice',
  'profession',
  'date',
  'status',
  'age_limit_men',
  'age_limit_women',
  'year',
  'seasons',
  'production',
];

export const PRO_PAGES_TRACKING = {
  PRO_PAGE_LOGIN_CLICK: 'pro_page_login_click',
  PRO_PAGE_REGISTER_CLICK: 'pro_page_register_click',
};

export const GLOBAL_HEADER_LINKS = {
  GLOBAL_HEADER_CASTING_TOOL_LINK: 'global_header_casting_tool_link',
  GLOBAL_HEADER_RENTALS_LINK: 'global_header_rentals_link',
  GLOBAL_HEADER_UPDATE_PROFILE: 'global_header_update_profile',
  GLOBAL_HEADER_PRO_TOOLS_LINK: 'global_header_pro_tools_link',
  GLOBAL_HEADER_HOME_LINK: 'global_header_home_link',
  GLOBAL_HEADER_PERFORMANCES_LINK: 'global_header_performances_link',
  GLOBAL_HEADER_PROFILES_LINK: 'global_header_profiles_link',
  GLOBAL_HEADER_COMPANIES_LINK: 'global_header_companies_link',
  GLOBAL_HEADER_FESTIVALS_LINK: 'global_header_festivals_link',
  GLOBAL_HEADER_MANAGERS_LINK: 'global_header_managers_link',
  GLOBAL_HEADER_VENUES_LINK: 'global_header_venues_link',
  GLOBAL_HEADER_SEASONS_LINK: 'global_header_seasons_link',
  GLOBAL_HEADER_COMPETITIONS_LINK: 'global_header_competitions_link',
  GLOBAL_HEADER_WORKS_LINK: 'global_header_works_link',
  GLOBAL_HEADER_VIDEOS_LINK: 'global_header_videos_link',
  GLOBAL_HEADER_ABOUT_OPERA_BASE: 'global_header_about_opera_base',
  GLOBAL_HEADER_INDUSTRY_PROFESSION_LINK: 'global_header_industry_profession_link',
  GLOBAL_HEADER_AUDIENCE_LINK: 'global_header_industry_audience_link',
  GLOBAL_HEADER_LOGIN_CLICK: 'global_header_login_click',
};

export const GLOBAL_SEARCH_EVENT_IDS = {
  GLOBAL_SEARCH_BUTTON: 'global_search_button',
  GLOBAL_SEARCH_ENTITY_TAB: 'global_search_entity_tab',
  GLOBAL_SEARCH_CLEAR_BUTTON: 'global_search_clear_button',
  GLOBAL_SEARCH_RECENT_SEARCH_SEE_ALL: 'global_search_recent_search_see_all',
  GLOBAL_SEARCH_RECENT_SEARCH_REMOVE: 'global_search_recent_search_remove',
  GLOBAL_SEARCH_RECENT_VISITED_SEE_ALL: 'global_search_recent_visited_see_all',
  GLOBAL_SEARCH_PERFORMANCE_TICKETS_CLICKS: 'global_search_performace_tickets_clicks',
  GLOBAL_SEARCH_ENTITY_SEE_ALL: entity => `global_search_${entity}_see_all`,
  GLOBAL_SEARCH_CARD_CLICK: entity => `global_search_${entity}_card_click`,
  GLOBAL_SEARCH_QUERY: 'global_search_query',
  GLOBAL_SEARCH_QUERY_INPUT_CLICK: 'global_search_query_input_click',
  GLOBAL_SEARCH_ENTITY_TAB_DROPDOWN: 'global_search_entity_tab_dropdown',
  GLOBAL_SEARCH_CASTING_TOOL_LINK_CLICK: 'global_search_casting_tool_link_click',
};

export const HEADER_NAV_IDS_URL_MAPPING = {
  [routesWithTitle.HOME.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_HOME_LINK,
  [routesWithTitle.RENTALS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_RENTALS_LINK,
  [routesWithTitle.PRODUCTIONS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_PERFORMANCES_LINK,
  [routesWithTitle.ARTISTS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_PROFILES_LINK,
  [routesWithTitle.COMPANIES.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_COMPANIES_LINK,
  [routesWithTitle.FESTIVALS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_FESTIVALS_LINK,
  [routesWithTitle.MANAGERS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_MANAGERS_LINK,
  [routesWithTitle.VENUES.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_VENUES_LINK,
  [routesWithTitle.SEASONS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_SEASONS_LINK,
  [routesWithTitle.COMPETITIONS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_COMPETITIONS_LINK,
  [routesWithTitle.WORKS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_WORKS_LINK,
  [routesWithTitle.VIDEOS.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_VIDEOS_LINK,
  [routesWithTitle.CASTING_TOOL.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_CASTING_TOOL_LINK,
  [routesWithTitle.INDUSTRY_PROFESSION.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_INDUSTRY_PROFESSION_LINK,
  [routesWithTitle.INDUSTRY_PROFESSION.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_PRO_TOOLS_LINK,
  [routesWithTitle.INTRO_AUDIENCE.path]: GLOBAL_HEADER_LINKS.GLOBAL_HEADER_AUDIENCE_LINK,
};

export const REGISTRATION_EVENT_IDS = {
  REGISTRATION_CONTINUE_BUTTON: 'registration_continue_button',
  REGISTRATION_SKIP_BUTTON: 'registration_skip_button',
  REGISTRATION_BACK_BUTTON: 'registration_back_button',
  REGISTRATION_REGISTER_BUTTON: 'registration_register_button',
  REGISTRATION_VERIFY_CODE_BUTTON: 'registration_verify_code_button',
  REGISTRATION_RESEND_CODE_BUTTON: 'registration_resend_code_button',
  REGISTRATION_REGISTER_REFERRAL_BUTTON: 'registration_register_referral_button',
  REGISTRATION_IDENTITY_VERIFICATION_START_BUTTON: 'registration_identity_verification_start_button',
  REGISTRATION_IDENTITY_VERIFICATION_FINISHED: 'registration_identity_verification_finished',
  REGISTRATION_CREATE_NEW_PROFESSION_OPTION: 'registration_create_new_profession_option',
};

export const PROFILE_DROPDOWN_EVENT_IDS = {
  PROFILE_DROPDOWN_OPEN: 'profile_dropdown_open',
  PROFILE_DROPDOWN_CLOSE: 'profile_dropdown_close',
  PROFILE_DROPDOWN_UPDATE_PROFILE: 'profile_dropdown_update_profile',
  PROFILE_DROPDOWN_PROFILE_ACTIVE_NAME: 'profile_dropdown_active_profile_name',
  PROFILE_DROPDOWN_NOTIFICATION: 'profile_dropdown_notification',
  PROFILE_DROPDOWN_LOGOUT: 'profile_dropdown_logout',
  PROFILE_DROPDOWN_ACTION_CENTER: 'profile_dropdown_action_center',
  PROFILE_DROPDOWN_GIFT_SUBSCRIPTION: 'profile_dropdown_gift_subscription',
  PROFILE_DROPDOWN_LANGUAGE_SELECTOR: 'profile_dropdown_language_selector',
  PROFILE_DROPDOWN_ACCOUNT_SETTINGS: 'profile_dropdown_account_settings',
  PROFILE_DROPDOWN_MANAGE: 'profile_dropdown_manage',
  BACK: {
    notifications: 'profile_dropdown_notification_back',
    profileSelector: 'profile_dropdown_manage_back',
    languageSelector: 'profile_dropdown_language_selector_back',
  },
  PROFILE_DROPDOWN_SETUP_ACCOUNT: 'profile_dropdown_setup_account',
  PROFILE_DROPDOWN_ADD_NEW_PROFILE: 'profile_dropdown_add_new_profile',
  PROFILE_DROPDOWN_SWITCH_PROFILE: 'profile_dropdown_switch_profile',
  PROFILE_DROPDOWN_CLICK_NOTIFICATION: 'profile_dropdown_click_notification',
  PROFILE_DROPDOWN_VIEW_NOTIFICATION: 'profile_dropdown_view_notification',
  PROFILE_DROPDOWN_SELECT_LANGUAGE: 'profile_dropdown_select_language',
  PROFILE_DROPDOWN_CONTACT_US: 'profile_dropdown_goto_contact_us',
};

export const PAYWALL_EVENT_IDS = {
  PAYWALL_LOGIN_REGISTER_POPUP: 'paywall_login_register_popup',
  PAYWALL_OPEN: 'paywall_open',
  PAYWALL_IGNORE: 'paywall_ignore',
  PAYWALL_COHORT_CLICK: 'paywall_cohort_click',
  PAYWALL_SUBSCRIPTION_CLICK: 'paywall_subscription_click',
};

export const SEARCH_LABELS = {
  DYNAMIC_FIELDS: {
    country_id: 'country',
    date_from: 'from',
    date_to: 'to',
    city_id: 'city',
    composer_id: 'composer',
    company_id: 'company',
    work_id: 'work',
    managers_id: 'manager',
    competition_id: 'competition',
    city: 'city',
    status: 'status',
    age_limit_men: 'age_limit_men',
    age_limit_women: 'age_limit_women',
    year: 'year',
  },
  COMPANY: 'company',
  MANAGER: 'manager',
  COMPETITIONS: 'competitions',
  DATE: 'date',
  ARTIST: 'artist',
  FESTIVALS: 'festivals',
  VENUES: 'venues',
  PAGE: 'page',
  SUBSCRIPTION: 'subscription',
  PAYMENT: 'payment',
};

export const SORT_LABELS = {
  year: 'performance_date',
  'productions.name': 'musical_work_name',
  'workComposer.name': 'composer_name',
  'country.name': 'country_name',
  'city.name': 'city_name',
  'conductor.name': 'conductor_name',
  'director.name': 'director_name',
  'company.name': 'company_name',
};

export const SEARCH_FILTER_LABELS = {
  COUNTRY: 'country',
  SEASONS: 'seasons',
  DYNAMIC_FIELDS: {
    profession_id: 'profession',
    profession_qualification_id: 'voice',
    country_id: 'country',
    years: 'years',
    work_id: 'work',
    year: 'year',
    company_id: 'company',
    page_id: 'page',
    subscription_id: 'subscription',
    payment_id: 'payment',
  },
};

export const ACTION_CENTER_EVENT_IDS = {
  ACTION_CENTER_IDENTITY_VERIFICATION: 'action_center_identity_verification',
  ACTION_CENTER_PASSWORD_UPDATE: 'action_center_password_update',
  ACTION_CENTER_GOTO_CASTING_TOOL: 'action_center_goto_casting_tool',
  ACTION_CENTER_ADD_NEW_PROFILE: 'action_center_add_new_profile',
  ACTION_CENTER_RECOMMENDED_ACTION: 'action_center_recommended_action',
  ACTION_CENTER_VIEW_CONTINUE_PROFILE: 'action_center_view_continue_profile',
  ACTION_CENTER_VIEW_PROFILE: 'action_center_view_profile',
  ACTION_CENTER_SEE_ALL_RECENTLY_VISITED: 'action_center_see_all_recently_visited',
  ACTION_CENTER_HELP_CLICK: 'action_center_help_click',
  ACTION_CENTER_CLOSE_CLICK: 'action_center_close_click',
};

export const ARTIST_PRO_VIEW = {
  ARTIST_PRO_VIEW_MODAL_BUTTON: 'artist_pro_view_modal_button',
  ARTIST_PRO_VIEW_SWITCH: 'artist_pro_view_switch',
};

export const ONBOARDING_EVENT_IDS = {
  NEXT_BUTTON: 'onboarding_next_button',
  BACK_BUTTON: 'onboarding_back_button',
};

export const INSIGHTS_EVENT_IDS = {
  HELP_BUTTON: 'insights_help_button',
  BAR_CHART_VIEW_MORE_BUTTON: 'bar_chart_view_more_button',
  SCROLL_RIGHT_BUTTON: 'scroll_right_button',
  SCROLL_LEFT_BUTTON: 'scroll_left_button',
};

export const ARTIST_REQUEST_ACCESS_IDS = {
  SWITCH_PROFILE_VIEW_INSIGHTS: 'artist_switch_profile_view_insights',
  SWITCH_PROFILE_EDIT: 'artist_switch_profile_edit',
  REQUEST_ACCESS: 'artist_request_access',
  ARTIST_PROFILE_ACCESS_REQUEST_ACCEPT: 'artist_profile_access_request_accept',
  ARTIST_PROFILE_ACCESS_REQUEST_REJECT: 'artist_profile_access_request_reject',
  ARTIST_PROFILE_ACCESS_REQUEST_VIEW_ALL: 'artist_profile_access_request_view_all',
};

export const CONTACT_NO_ACCESS_IDS = {
  REGISTER: 'contact_no_access_register',
  VIEW: 'view_contact_no_access_modal',
};

export const ARTIST_SEARCH_SELECT = {
  SEARCH: 'artist_search',
  SELECT: 'artist_search_profile_card',
};

export const PROFESSION_SEARCH_SELECT = {
  SEARCH: 'profession_search',
  SELECT: 'profession_search_select',
};

export const BANNERS = {
  CASTING_LOGIN_CLICK: 'casting_login_click',
  CASTING_ACCESS_CLICK: 'casting_access_click',
  ACCESS_LOGIN_CLICK: 'access_login_click',
};

export const CUSTOMER_LOGOS = {
  SINGLE_LOGO_CLICK: 'customer_logo_click',
  VIEW_ALL_LOGOS_CLICK: 'view_all_logos_click',
};

export const VIEW_IN_CASTING_TOOL = 'casting_tool_view';

export const ARTIST_ALPHABETIC_SELECT = 'artist_alphabetic_select';
export const ARTIST_PROFESSION_PAGE_CLICK = 'artist_profession_page_click';
export const PROFESSION_LINK_CLICK = 'profession_link_click';
export const VIEW_ALL_SELECTED_PROFESSION_ARTISTS = 'view_all_selected_profession_artists';

export const ENTITY_CARD_CLICK = entityType => `${entityType}_card_click`;
