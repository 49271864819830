import React from 'react';

import Typography from 'components/uiLibrary/Typography';

import { Trans } from 'src/i18n';
import classes from './CommonTrans.module.scss';

const NonBreakingSpace = () => <>&nbsp;</>;

const CommonTrans = ({ components = {}, ...props }) => {
  const translationComponents = {
    bold: <span className={classes.bold} />,
    i: <span className={classes.italic} />,
    ul: <ul />,
    li: <li />,
    ol: <ol />,
    p: <p />,
    castingtool: <Typography variant="span" color="inherit" weight="bold" truncate secondaryFont />,
    nbsp: <NonBreakingSpace />,
  };

  return <Trans {...props} components={{ ...translationComponents, ...components }} />;
};

export default CommonTrans;
