export const transformGetCustomerLogos = response => ({
  ...response,
  data:
    response?.data?.map(org => ({
      id: org?.id,
      slug: org?.slug,
      name: org?.name,
      organizationType: org?.organizationType,
      logo: org?.logo?.small || org?.logo?.medium || org?.logo?.large,
    })) || [],
});
