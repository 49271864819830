import { createRoutine } from 'redux-saga-routines';

import {
  GET_PROFILE_DATA,
  GET_PROFILE_REPERTOIRE,
  SET_PROFILE_ACTIVE_VIDEO,
  GET_PROFILE_REVIEWS,
  GET_PROFILE_PRODUCTION_REVIEWS,
  GET_PROFILE_PRODUCTIONS,
  UPDATE_PROFILE,
  SET_PROFILE,
  GET_PROFILE_BIO,
  UPDATE_BIO,
  ADD_BIO,
  GET_CONTACTS,
  UPDATE_CONTACTS,
  ADD_REVIEW,
  GET_PROFILE_AGREEMENTS,
  UPDATE_REVIEW,
  GET_PROFILE_PRODUCTIONS_SEASONS,
  GET_PROFILE_WORKS_LIST,
  GET_ARTIST_RECOMMENDATIONS,
  GET_ARTIST_PROFESSIONS,
  GET_ARTIST_ROLES,
  GET_PRODUCTION_CAST,
  REMOVE_PRODUCTION,
  DELETE_PRODUCTION,
  PUBLISH_PRODUCTION,
  CLEAR_PROFILE_WORKS_LIST,
  START_SEARCHING_PROFILE_WORKS_LIST,
  GET_DATES_IN_PRODUCTIONS,
  GET_PROFILE_IMAGES,
  GET_PROFILE_BANNER_IMAGES,
  UPDATE_VIDEO,
  ADD_VIDEO,
  GET_VIDEO,
  GET_LSVOD,
  GET_INTRO_SHOW_REELS_ACTION,
  GET_AUDITION_SHOW_REELS_ACTION,
  ADD_UPDATE_CONTACTS,
  UPDATE_REPERTOIRE_STATUS,
  GET_PROFILE_REPERTOIRE_PRODUCTION,
  UPDATE_REPERTOIRE_MODAL,
  DELETE_BIO,
  UPDATE_ARTIST_BANNER,
  UPLOAD_ARTIST_BANNER,
  DELETE_IMAGE,
  DELETE_VIDEO,
  DELETE_REVIEW,
  GET_PROFILE_REPERTOIRE_IMAGES,
  GET_PROFILE_REPERTOIRE_VIDEOS,
  REMOVE_REPERTOIRE_RECORD,
  GET_ARTIST_PRODUCTIONS,
  GET_VIDEO_FORMAT_TYPES,
  UPDATE_REPERTOIRE,
  GET_IMAGE_FORMAT_TYPES,
  UPDATE_PRODUCTION,
  UPDATE_PRODUCTION_PRIVACY,
  PROFILE_ACTIONS,
  REVIEW_ACTIONS,
  GET_PROFILE_CHANGE_REQUEST,
  ARTIST_AGENCY_ACTIONS,
  GET_PROFILE_PRODUCTION_IMAGES,
  GET_PROFILE_PRODUCTION_VIDEOS,
  GET_PROFILE_PERFORMANCES,
  GET_ARTIST_TRANSLATIONS,
  UPDATE_ARTIST_TRANSLATIONS,
  DELETE_ARTIST_TRANSLATIONS,
} from './constants';

export const getChosenProfileDataAction = createRoutine(GET_PROFILE_DATA);
export const getChosenProfileProductionsAction = createRoutine(GET_PROFILE_PRODUCTIONS);
export const getProfilePerformanceAction = createRoutine(GET_PROFILE_PERFORMANCES);
export const getChosenProfileChangeRequestAction = createRoutine(GET_PROFILE_CHANGE_REQUEST);
export const getChosenProfileBioAction = createRoutine(GET_PROFILE_BIO);
export const getRepertoireProductionListAction = createRoutine(GET_PROFILE_REPERTOIRE_PRODUCTION);
export const getRepertoireImageListAction = createRoutine(GET_PROFILE_REPERTOIRE_IMAGES);
export const getRepertoireVideoListAction = createRoutine(GET_PROFILE_REPERTOIRE_VIDEOS);
export const getChosenProfileRepertoireAction = createRoutine(GET_PROFILE_REPERTOIRE);
export const getChosenProfileAgreementsAction = createRoutine(GET_PROFILE_AGREEMENTS);
export const getChosenProfileReviewsAction = createRoutine(GET_PROFILE_REVIEWS);
export const getChosenProductionReviewsAction = createRoutine(GET_PROFILE_PRODUCTION_REVIEWS);
export const setChosenProfileActiveVideoAction = createRoutine(SET_PROFILE_ACTIVE_VIDEO);
export const getArtistImagesAction = createRoutine(GET_PROFILE_IMAGES);
export const getArtistProductionImagesAction = createRoutine(GET_PROFILE_PRODUCTION_IMAGES);
export const getArtistProductionVideosAction = createRoutine(GET_PROFILE_PRODUCTION_VIDEOS);
export const getArtistBannerImagesAction = createRoutine(GET_PROFILE_BANNER_IMAGES);
export const updateRepertoireStatusAction = createRoutine(UPDATE_REPERTOIRE_STATUS);
export const updateRepertoireModalAction = createRoutine(UPDATE_REPERTOIRE_MODAL);

export const getProfileProductionsSeasonsAction = createRoutine(GET_PROFILE_PRODUCTIONS_SEASONS);
export const getProfileWorksListAction = createRoutine(GET_PROFILE_WORKS_LIST);
export const getArtistRecommendationsAction = createRoutine(GET_ARTIST_RECOMMENDATIONS);
export const getArtistProfessions = createRoutine(GET_ARTIST_PROFESSIONS);
export const getArtistRoles = createRoutine(GET_ARTIST_ROLES);
export const getProductionCastAction = createRoutine(GET_PRODUCTION_CAST);
export const getDatesInProductionsAction = createRoutine(GET_DATES_IN_PRODUCTIONS);
export const updateProfileVideoAction = createRoutine(UPDATE_VIDEO);
export const addProfileVideoAction = createRoutine(ADD_VIDEO);
export const getProfileVideoAction = createRoutine(GET_VIDEO);
export const getArtistProfileIntroShowReelsAction = createRoutine(GET_INTRO_SHOW_REELS_ACTION);
export const getArtistProfileAuditionShowReelsAction = createRoutine(GET_AUDITION_SHOW_REELS_ACTION);
export const getProfileLSVODAction = createRoutine(GET_LSVOD);
export const updateArtistBannerAction = createRoutine(UPDATE_ARTIST_BANNER);
export const uploadArtistBannerAction = createRoutine(UPLOAD_ARTIST_BANNER);
export const deleteReviewAction = createRoutine(DELETE_REVIEW);
export const getArtistProductionsAction = createRoutine(GET_ARTIST_PRODUCTIONS);
export const getArtistVideoFormatAttrTypesAction = createRoutine(GET_VIDEO_FORMAT_TYPES);
export const updateRepertoireAction = createRoutine(UPDATE_REPERTOIRE);
export const getArtistImageFormatAttrTypesAction = createRoutine(GET_IMAGE_FORMAT_TYPES);
export const agencyActions = createRoutine(ARTIST_AGENCY_ACTIONS);
export const getArtistTranslationsAction = createRoutine(GET_ARTIST_TRANSLATIONS);
export const updateArtistTranslationsAction = createRoutine(UPDATE_ARTIST_TRANSLATIONS);
export const deleteArtistTranslationsAction = createRoutine(DELETE_ARTIST_TRANSLATIONS);

export function deleteBioAction(id) {
  return {
    type: DELETE_BIO,
    payload: { id },
  };
}

export function setProfileValueAction(profile) {
  return {
    type: SET_PROFILE,
    payload: { profile },
  };
}

export function clearMVList() {
  return {
    type: CLEAR_PROFILE_WORKS_LIST,
    payload: {},
  };
}

export function setStartingOfMVAggregation() {
  return {
    type: START_SEARCHING_PROFILE_WORKS_LIST,
    payload: {},
  };
}

export function deleteImageAction(id) {
  return {
    type: DELETE_IMAGE,
    payload: { id },
  };
}

export function deleteProfileVideoAction(id) {
  return {
    type: DELETE_VIDEO,
    payload: { id },
  };
}

export function removeRepertoire(id) {
  return {
    type: REMOVE_REPERTOIRE_RECORD,
    payload: { id },
  };
}

// update profile

export const updateProfileInfoAction = createRoutine(UPDATE_PROFILE);

export const updateBioAction = createRoutine(UPDATE_BIO);
export const addBioAction = createRoutine(ADD_BIO);
export const getContactsAction = createRoutine(GET_CONTACTS);
export const updateContactsAction = createRoutine(UPDATE_CONTACTS);
export const addReviewAction = createRoutine(ADD_REVIEW);
export const updateReviewAction = createRoutine(UPDATE_REVIEW);
export const removeProductionAction = createRoutine(REMOVE_PRODUCTION);
export const updateProductionAction = createRoutine(UPDATE_PRODUCTION);
export const deleteProductionAction = createRoutine(DELETE_PRODUCTION);
export const publishProductionAction = createRoutine(PUBLISH_PRODUCTION);
export const addAndUpdateContactsAction = createRoutine(ADD_UPDATE_CONTACTS);
export const updateProductionPrivacyAction = createRoutine(UPDATE_PRODUCTION_PRIVACY);

export const profileActions = createRoutine(PROFILE_ACTIONS);
export const reviewActions = createRoutine(REVIEW_ACTIONS);
