export const GET_ARTISTS = 'app/Artists/GET_ARTISTS';
export const GET_PROFILE_REPERTOIRE = 'app/Artists/GET_PROFILE_REPERTOIRE';
export const ARTIST_INTERACTION_ACTION_TYPES = {
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
};

export const VIEW_TYPE = {
  GALLERY: 'GALLERY',
  LIST_VIEW: 'LIST_VIEW',
};

export const listingFields = ['query', 'profession_qualification_id', 'profession_id', 'letter'];
export const defaultFilterAndSort = { tier: 'A', sort: 'random' };
