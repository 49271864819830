import { createRoutine } from 'redux-saga-routines';

import {
  GET_VIDEOS,
  GET_EXPIRING_VIDEOS,
  GET_TRAILER,
  GET_FILTERED_VIDEOS,
  GET_MEDIA_VIDEO,
  GET_PRODUCTION_VIDEO,
  GET_AND_RESET_FILTERED_VIDEOS,
  GET_AND_RESET_VIDEOS,
  MEDIA_ORDER,
} from './constants';

export const getVideosAction = createRoutine(GET_VIDEOS);
export const getExpiringVideosAction = createRoutine(GET_EXPIRING_VIDEOS);
export const getTrailerAction = createRoutine(GET_TRAILER);
export const getFilteredVideosAction = createRoutine(GET_FILTERED_VIDEOS);
export const getProductionVideoAction = createRoutine(GET_PRODUCTION_VIDEO);
export const getMediaVideoAction = createRoutine(GET_MEDIA_VIDEO);
export const getAndResetFilteredVideosAction = createRoutine(GET_AND_RESET_FILTERED_VIDEOS);
export const getAndResetVideosAction = createRoutine(GET_AND_RESET_VIDEOS);
export const mediaOrderAction = createRoutine(MEDIA_ORDER);
