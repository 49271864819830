import omit from 'lodash/omit';

import { useMutation } from 'utils/react-query';
import { getRequest, create, getList, getHomeNumbers, getOne, getWholeList } from 'utils/API';
import { getLanguage } from 'utils/queriesUtil';
import {
  BIO_SUMMARY,
  PRODUCTIONS_AGGREGATIONS,
  ENTITY_RECOMMENDATIONS,
  COUNTRIES_ENDPOINT,
  CITY_ENDPOINT,
  FILTER_AGGREGATION_SEARCH,
  AGENCIES,
  PAGERULES_ENDPOINT,
  PROFILES_VIEWED,
  PROFESSIONS,
} from 'constants/endpointConstants';
import {
  ENTITY_TYPE,
  ENTITY_API_PATH,
  AGGREGATION_TYPES,
  LOCATION_TYPE,
  PAGERULE_ENTITY_TYPE_MAP,
  PRODUCTION_VALIDITY_IDENTIFIERS,
} from 'constants/index';

import { FOOTPRINT_TRACKING_ENDPOINT } from 'constants/footPrintTracking';
import { getOption } from 'utils/globals/app';
import { getQuery, postQuery, transformQueryFn, withSelectFn } from 'utils/globals/queries';
import { transformImagesWithNoIndex } from 'utils/globals/transformers';
import { PARENT_PROFESSION_SLUG, PROFESSIONS_AVAILABILITY, TAG_TYPE } from 'constants/consts';

import { selectData, transformLanguageResults, transformSearchResultsToEntity } from './transformers';

const ENDPOINTS = {
  STATIC: {
    SCRAPE_REQUESTS: 'scrape_requests',
    TESTIMONIALS: 'users/testimonials',
    APP_VERSION_STATUS: 'app-version/status',
    OFFICES: 'offices',
    SEARCH: 'search',
    REGIONS: 'regions',
    LANGUAGES: 'languages',
  },
  DYNAMIC: {},
};

export const useFootPrintTrackingMutation = (options = {}) =>
  useMutation(
    payload => create(FOOTPRINT_TRACKING_ENDPOINT.postFingerprint, payload),
    {
      onSuccess: () => {
        if (typeof options?.onSettled === 'function') {
          const { onSettled } = options;
          onSettled();
        }
      },
    },
    options,
  );

const defaultConfig = {
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 10 * 60 * 1000,
};

const fetchBioSummary = ({ entityType, entityId }, cookies) => {
  const entityValue = ENTITY_API_PATH[entityType];
  if (entityValue) {
    return getList(BIO_SUMMARY(entityValue, entityId), {}, cookies).then(response => {
      if (response?.total) {
        const modifiedData = response?.data?.data?.reduce((acc, item) => {
          const description = item?.content || item?.description;
          acc.push({
            ...item,
            excerpt: description.substring(0, 800),
          });
          return acc;
        }, []);

        return {
          ...response?.data,
          data: modifiedData,
        };
      }
      return response?.data;
    });
  }
  return null;
};

const fetchCountries = ({ filters, limit = 10 }, cookies) =>
  getList(COUNTRIES_ENDPOINT, { queryParams: filters, pagination: { limit } }, cookies).then(
    response => response?.data,
  );

const fetchCities = ({ filters, limit = 10 }, cookies) =>
  getList(CITY_ENDPOINT, { queryParams: filters, pagination: { limit } }, cookies).then(response => response?.data);

const fetchRegions = ({ filters, limit = 10 }, cookies) =>
  getList(ENDPOINTS.STATIC.REGIONS, { queryParams: filters, pagination: { limit } }, cookies).then(
    response => response?.data,
  );

const fetchCountriesRegions = async ({ filters, limit = 10 }, cookies) =>
  Promise.allSettled([fetchCountries({ filters, limit }, cookies), fetchRegions({ filters, limit }, cookies)]).then(
    results => {
      const countries = results?.[0]?.value?.data?.map(option => ({ ...option, type: 'country' }));
      const regions = results?.[1]?.value?.data?.map(option => ({ ...option, type: 'region' }));

      return {
        data: [...regions, ...countries],
      };
    },
  );

const fetchEntityLocation = ({ type, filters, limit = 1 }, cookies) => {
  const endpoint = type === LOCATION_TYPE.COUNTRY ? COUNTRIES_ENDPOINT : CITY_ENDPOINT;

  return getList(endpoint, { queryParams: filters, pagination: { limit } }, cookies).then(response =>
    limit === 1 ? response.data?.data?.[0] : response.data,
  );
};

const fetchAggregatedLocationListing = ({ entityType, aggregationType, queryFilters = {}, limit = 10 }, cookies) => {
  let endpoint = '';
  const queryParams = {
    aggregation_type: aggregationType,
    approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
    sort: 'name',
    has_productions: 'yes',
    ...(queryFilters?.country_id && { country_id: queryFilters?.country_id }),
    ...(queryFilters?.aggregation_query && { aggregation_query: queryFilters?.aggregation_query }),
    ...(aggregationType === AGGREGATION_TYPES.CITY && {
      city_id: queryFilters?.city_id,
    }),
  };

  switch (entityType) {
    case ENTITY_TYPE.PRODUCTION:
      endpoint = PRODUCTIONS_AGGREGATIONS;
      break;
    case ENTITY_TYPE.MANAGER:
      endpoint = FILTER_AGGREGATION_SEARCH(AGENCIES);
      break;
    default:
  }
  return getList(endpoint, { queryParams, pagination: { limit } }, cookies).then(response => response.data);
};

const fetchEntityRecommendations = ({ entityType, entityId, limit = 10 }, cookies) => {
  const entityValue = ENTITY_API_PATH[entityType];

  const params = {
    ...(entityType === ENTITY_TYPE.ARTIST && { queryParams: { enable_ml: true } }),
    pagination: { limit },
  };

  return getList(ENTITY_RECOMMENDATIONS(entityValue, entityId), params, cookies).then(response => response.data);
};

const fetchProfilesViewed = ({ limit = 10 }, cookies) =>
  getList(PROFILES_VIEWED, { pagination: { limit } }, cookies).then(response => response.data);

const fetchScrapeRequestStatus = ({ id }, cookies) =>
  getOne(ENDPOINTS.STATIC.SCRAPE_REQUESTS, id, null, cookies).then(response => response.data);

export const getRedirectPageRoute = ({ entityId, entityType, url, language }, cookies) => {
  const targetEntityType = PAGERULE_ENTITY_TYPE_MAP[entityType];
  const targetEntityId = entityId;
  const enabled = (targetEntityType && targetEntityId) || url;

  const queryParams =
    targetEntityType && targetEntityId
      ? { target_entity_type: targetEntityType, target_entity_id: targetEntityId }
      : { url };

  if (enabled) {
    return getList(PAGERULES_ENDPOINT, { queryParams }, cookies).then(response => {
      const newTarget = response?.data?.data?.[0]?.target;

      if (newTarget) {
        let lng = '';
        if (!/.+\/[a-zA-Z]{2,3}$/.test(newTarget)) {
          lng = `/${language || 'en'}`;
        }

        return `${newTarget}${lng}`;
      }

      return null;
    });
  }

  return null;
};

const getUserByFingerprint = ({ id }, cookies) => {
  const queryParams = {
    visitor_id: id,
  };
  return getList(FOOTPRINT_TRACKING_ENDPOINT.getUserByFingerprint, { queryParams }, cookies).then(
    response => response.data,
  );
};

const fetchTestimonials = ({ limit = 20 }, cookies) => {
  const queryParams = {
    limit,
    is_active: true,
    tag_name: 'Casting tool AOS',
  };

  return getList(ENDPOINTS.STATIC.TESTIMONIALS, { queryParams }, cookies).then(response => response.data);
};

const fetchAppVersionStatus = async (_props, cookies) =>
  getRequest(ENDPOINTS.STATIC.APP_VERSION_STATUS, null, cookies).then(response => response?.data?.data);

const fetchInstrumentsProfessionList = ({ filters, limit = 10 }, cookies) => {
  const query = {
    queryParams: {
      ...filters,
      parent_profession: PARENT_PROFESSION_SLUG.INSTRUMENTALIST,
    },
    pagination: { limit },
  };

  return getList(PROFESSIONS, query, cookies).then(
    response => response?.data?.data?.map(record => ({ ...record, ...getOption(record) })) || [],
  );
};

const fetchSingerProfessionList = ({ filters, limit = 10 }, cookies) => {
  const query = {
    queryParams: {
      ...filters,
      parent_profession: PARENT_PROFESSION_SLUG.SINGER,
    },
    pagination: { limit },
  };

  return getList(PROFESSIONS, query, cookies).then(
    response => response?.data?.data?.map(record => ({ ...record, ...getOption(record) })) || [],
  );
};

const fetchProfessionList = ({ filters, limit = 10 }, cookies) => {
  const query = {
    queryParams: {
      ...filters,
      tag_type: TAG_TYPE.AVAILABILITY,
      tag: [PROFESSIONS_AVAILABILITY.UPDATE_PROFILE, PROFESSIONS_AVAILABILITY.CREATE_CREW_PROFILE].join(','),
    },
    pagination: { limit },
  };

  return getList(PROFESSIONS, query, cookies).then(
    response => response?.data?.data?.map(record => ({ ...record, ...getOption(record) })) || [],
  );
};

const fetchGlobalSearchSuggestions = ({ filters, page = 1, limit = 10 }) => {
  const params = {
    queryParams: {
      ...omit(filters, 'profession_id'),
      ...(filters?.profession_id && { primary_profession_id: filters?.profession_id }),
    },
    pagination: { limit, page },
  };

  return getList(ENDPOINTS.STATIC.SEARCH, params).then(response => response?.data);
};

const fetchComposerList = ({ filters, page = 1, limit = 10 }) =>
  fetchGlobalSearchSuggestions({
    filters: { ...filters, has_creations: true, creator_profession: 'composer' },
    page,
    limit,
  });

const fetchAllLanguages = ({ filters }, cookies) => {
  const query = {
    queryParams: filters,
  };

  return getWholeList(ENDPOINTS.STATIC.LANGUAGES, query, cookies).then(response => response);
};

const queries = {
  getEntityBioDetails: getQuery('GET_ENTITY_BIO', fetchBioSummary, props => ({
    ...(props || {}),
    queryConfig: {
      ...(props.queryConfig || {}),
      enabled: props.queryConfig?.enabled && props?.entityType === ENTITY_TYPE.ARTIST,
    },
  })),
  getCountries: getQuery('GET_COUNTRIES', fetchCountries),
  getCities: getQuery('GET_CITIES', fetchCities),
  getRegions: getQuery('GET_REGIONS', fetchRegions),
  getCountriesRegions: getQuery('GET_COUNTRIES_REGIONS', fetchCountriesRegions),
  getLocation: getQuery('GET_LOCATION', fetchEntityLocation),
  getAggregatedLocationListing: (
    { entityType, aggregationType = AGGREGATION_TYPES.COUNTRY, queryFilters = {}, queryConfig = {}, limit },
    cookies,
    language,
  ) => ({
    queryKey: [
      getLanguage(language),
      'GET_AGGREGATED_LOCATION_LISTING',
      `${entityType}`,
      `${aggregationType}`,
      `${limit}`,
      JSON.stringify(queryFilters),
    ],
    queryFn: async () => fetchAggregatedLocationListing({ entityType, aggregationType, queryFilters, limit }, cookies),
    select: response => ({
      ...response,
      data: response?.data?.map(option =>
        getOption(option, 'id', 'name', { id: option?.id, slug: option?.slug, name: option?.name }, true),
      ),
    }),
    ...defaultConfig,
    ...queryConfig,
  }),
  getEntityRecommendations: getQuery(
    'GET_ENTITY_RECOMMENDATIONS',
    transformQueryFn(fetchEntityRecommendations, transformImagesWithNoIndex),
  ),
  getProfilesViewed: getQuery('GET_PROFILES_VIEWED', transformQueryFn(fetchProfilesViewed, transformImagesWithNoIndex)),
  getHomeNumbers: getQuery('GET_HOME_NUMBERS', getHomeNumbers),
  createScrapeRequest: postQuery(ENDPOINTS.STATIC.SCRAPE_REQUESTS),
  getScrapeRequestStatus: getQuery('GET_SCRAPE_REQUEST_STATUS', fetchScrapeRequestStatus),
  getUserByVisitorId: getQuery('GET_USER_BY_FINGERPRINT', getUserByFingerprint),
  getTestimonials: getQuery(
    'GET_TESTIMONIALS',
    transformQueryFn(fetchTestimonials, response => ({
      ...response,
      data: [...(response?.data || [])]?.sort(() => 0.5 - Math.random()),
    })),
  ),
  checkAppVersionStatus: getQuery('GET_APP_VERSION_STATUS', fetchAppVersionStatus),
  getInstrumentsProfessionList: getQuery('GET_INSTRUMENTS_PROFESSIONS_LIST', fetchInstrumentsProfessionList),
  getSingerProfessionList: getQuery('GET_SINGER_PROFESSIONS_LIST', fetchSingerProfessionList),
  getProfessionList: getQuery('GET_PROFESSIONS_LIST', fetchProfessionList),
  getComposerList: getQuery('GET_COMPOSER_LIST', transformQueryFn(fetchComposerList, transformSearchResultsToEntity)),
  getGlobalSearchSuggestions: getQuery('GET_GLOBAL_SEARCH_SUGGESTIONS', fetchGlobalSearchSuggestions),
  getAllLanguages: getQuery(
    'GET_ALL_LANGUAGES',
    transformQueryFn(fetchAllLanguages, transformLanguageResults),
    withSelectFn(selectData),
  ),
};

export default queries;
