import { getRouteWithSlug } from 'utils/globals/app';
import { createDate } from 'utils/date';

import { CHART_TYPES, INSIGHTS_TYPES, DATE_FORMATS, TP } from 'constants/index';

import route from 'constants/routes';

export const getArtistRoute = artistProfile => getRouteWithSlug(route.ARTISTS, artistProfile);

export const getProfileProfession = profile => {
  let primaryProfession = profile?.professions?.find(prof => prof?.isPrimary);
  primaryProfession = primaryProfession ? [primaryProfession?.profession?.name] : undefined;
  const secProfessions = profile?.professions
    ?.filter(prof => !prof?.isPrimary)
    ?.reduce((result, prof) => [...result, prof?.profession?.name], []);

  let profileProfession = [];

  if (primaryProfession?.length > 0) {
    profileProfession = primaryProfession;
  }

  if (secProfessions?.length > 0) {
    profileProfession.push(...secProfessions);
  }

  return profileProfession.join(', ');
};

export const getArtisLabelNameWithRole = profile => {
  const profession = getProfileProfession(profile);
  return profession
    ? `${profile?.fullName?.length > 0 ? profile?.fullName : profile?.name} (${profession})`
    : profile?.name;
};

const getPremiumSubscriptionDates = markers =>
  markers
    .filter(marker => marker.key === 'ACTIVE_PREMIUM_SUBSCRIPTION')
    .map(marker => ({
      premiumStartedMinusOneMonth: createDate(marker.from)
        .subtract(1, 'month')
        .format(DATE_FORMATS.FULL_DATE),
      premiumEndedDate: createDate(marker.to).format(DATE_FORMATS.FULL_DATE),
    }));

export const processImpressionData = data => {
  let impressionData = data;
  if (!impressionData || !impressionData?.[0] || !impressionData?.[0]?.stats?.timeseries) {
    impressionData = [
      {
        category: 'insights',
        stats: {
          timeseries: [{ data: createDate().format(DATE_FORMATS.FULL_DATE) }],
          markers: [],
        },
      },
    ]; // NOTE: This is to remove empty line in case data is not available
  }

  const timeSeriesData = impressionData?.[0]?.stats?.timeseries || [];
  const markers = impressionData?.[0]?.stats?.markers || {};

  const allMonths = timeSeriesData.map(item => createDate(item.date).format(DATE_FORMATS.MONTH_YEAR));

  const premiumPeriods = getPremiumSubscriptionDates(markers);
  const productUpdateMarkers = markers
    .filter(marker => marker.key === 'PRODUCT_UPDATE')
    .map(marker => ({
      ...marker,
      date: createDate(marker.date).format(DATE_FORMATS.MONTH_YEAR),
    }));

  const { lineData, premiumLineData, premiumDates } = timeSeriesData.reduce(
    (acc, item) => {
      const { date, value: count } = item;
      const month = createDate(date).format(DATE_FORMATS.MONTH_YEAR);

      acc.lineData[month] = count;

      const isInPremiumPeriod = premiumPeriods.some(
        period => date >= period.premiumStartedMinusOneMonth && date <= period.premiumEndedDate,
      );

      if (isInPremiumPeriod) {
        acc.premiumLineData[month] = count;
        if (!acc.premiumDates.includes(month)) {
          acc.premiumDates.push(month);
        }
      }

      return acc;
    },
    { lineData: {}, premiumLineData: {}, premiumDates: [] },
  );
  return { premiumLineData, premiumDates, allMonths, lineData, productUpdateMarkers };
};

export const sumObjectValues = obj => {
  if (!obj || typeof obj !== 'object') {
    return 0;
  }
  return Object.values(obj).reduce((sum, value) => (typeof value === 'number' ? sum + value : sum), 0);
};

export const createChartConfig = (
  metricData,
  metricType,
  barGraphData,
  isChurned,
  isActiveSubscription,
  productionCount,
  trackingComponent,
) => {
  const barData = barGraphData?.data?.data?.[0]?.stats?.bargraph;
  const { premiumLineData, premiumDates, allMonths, lineData, productUpdateMarkers } = processImpressionData(
    metricData?.data?.data || [],
  );

  const lines = [
    {
      type: 'linear',
      stroke: '#383838',
      activeDot: { r: 3 },
      data: lineData,
    },
    {
      type: 'linear',
      stroke: '#F36D2A',
      data: premiumLineData,
    },
  ];

  const areas = [
    {
      type: 'monotone',
      fill: '#FFF2EB',
      stroke: '#FFF2EB',
      data: premiumDates,
    },
  ];

  const referenceLines = productUpdateMarkers?.map(marker => ({
    x: marker?.date,
    info: marker?.info,
    stroke: '#7F0181',
    label: '',
    strokeDasharray: '3 3',
  }));

  const impressionCount = sumObjectValues(lineData);

  const chartDescription = () => {
    if (metricType === INSIGHTS_TYPES.IMPRESSIONS) {
      return `INSIGHTS_IMPRESSION_DESC`;
    }
    if (metricType === INSIGHTS_TYPES.PAGEVIEWS) {
      return `INSIGHTS_PAGEVIEW_DESC`;
    }
    if (metricType === INSIGHTS_TYPES.SEARCH_APPEARANCES) {
      return `INSIGHTS_SEARCH_APPEARANCES_DESC`;
    }
    return '';
  };

  const createBarChartData = (title, dataSegment) => ({
    title,
    type: CHART_TYPES.BAR,
    data: dataSegment ? Object.entries(dataSegment).map(([label, value]) => ({ label, value })) : [],
  });

  const countryChartData = createBarChartData(`${TP}.m_COUNTRY`, barData?.country, true);

  const consolidatedBarData = [countryChartData];
  const leftMargin = (() => {
    const values = Object.values(lines?.[0]?.data || {});
    const maxValue = values?.length ? Math.max(...values) : 0;

    switch (true) {
      case maxValue > 100000:
        return 0;
      case maxValue > 1000:
        return -20;
      default:
        return -30;
    }
  })();

  const formattedData = {
    title: metricType,
    description: chartDescription(),
    count: impressionCount,
    trackingComponent,
    cardsData: [
      {
        title: '',
        type: CHART_TYPES.COMBO,
        xValues: allMonths,
        lines,
        areas,
        chartTitle: metricType,
        referenceLines,
        customTooltip: true,
        toolTipLabel: metricType,
        premiumStarted: '', // TODO: API need to send this data as premium last activated
        config: {
          margin: { top: 20, right: 5, bottom: 0, left: leftMargin },
          width: '100%',
          height: 240,
          mobileHeight: 170,
        },
        formatXAxis: tick => tick.slice(0, 3),
      },
      ...consolidatedBarData,
    ],
  };
  return formattedData;
};
