import {
  getHomePerformancesAction,
  getHomeNumbersAction,
  getHomeArtistsAction,
  getHomeHighlightsAction,
} from './actions';
import { defaultListParams, listReducer } from '../../utils/crudUtils';

export const key = 'Home';

export const initialState = {
  artists: defaultListParams,
  highlights: defaultListParams,
  performances: defaultListParams,
  loading: false,
  error: null,
  // common Info
  homeNumbers: null,
  homeNumbersLoading: false,
};

const homeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case getHomePerformancesAction.TRIGGER:
      return { ...state, error: null };
    case getHomePerformancesAction.SUCCESS:
      return { ...state, performances: payload };
    case getHomePerformancesAction.FAILURE:
      return { ...state, error: payload, loading: false };

    case getHomeNumbersAction.TRIGGER:
      return { ...state, homeNumbersLoading: true };
    case getHomeNumbersAction.SUCCESS:
      return { ...state, homeNumbers: payload, homeNumbersLoading: false };
    case getHomeNumbersAction.FAILURE:
      return { ...state, homeNumbersLoading: false, error: payload };

    case getHomeArtistsAction.TRIGGER:
    case getHomeArtistsAction.SUCCESS:
    case getHomeArtistsAction.FAILURE:
      return listReducer(state, type, payload, 'artists');

    case getHomeHighlightsAction.TRIGGER:
    case getHomeHighlightsAction.SUCCESS:
    case getHomeHighlightsAction.FAILURE:
      return listReducer(state, type, payload, 'highlights');

    default:
      return state;
  }
};

export default homeReducer;
